<template>
  <div class="template">
    <PrmZoomParams v-if="store.prmZoom" />
    <LoaderFullPage v-if="store.loading" />
    <PrmZoomDatas v-else />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PrmZoomParams from "@/components/prm/organisms/PrmZoomParams.vue";
import PrmZoomDatas from "@/components/prm/organisms/PrmZoomDatas.vue";
import { usePrmStore } from "@/stores/prm.js";

const route = useRoute();
const store = usePrmStore();

if (
  store.prmZoom?._id !== route.params._id ||
  store.prevZoomUrlQuery !== JSON.stringify(route.query)
) {
  store.fetchOnePersonnality(route.params._id);
}
</script>
