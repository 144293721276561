<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink to="/jorfs" class="back">
        <i class="fa-solid fa-chevron-left" /> Retour à la liste
      </RouterLink>
      <div
        v-if="store.previousJorfID || store.nextJorfID"
        class="previous-next-wrapper"
      >
        <!-- Next and Previous Items -->
        <RouterLink
          v-if="store.previousJorfID"
          :to="`/jorfs/${store.previousJorfID}`"
          class="btn-primary previous"
        >
          <i class="fa-solid fa-chevron-left" />Précédent
        </RouterLink>
        <RouterLink
          v-if="store.nextJorfID"
          :to="`/jorfs/${store.nextJorfID}`"
          class="btn-primary next"
        >
          Suivant<i class="fa-solid fa-chevron-right" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useJorfsStore } from "@/stores/jorfs";

const store = useJorfsStore();
</script>

<style lang="scss" scoped>
.previous-next-wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}
</style>
