<template>
  <div class="charts-wrapper">
    <ActivityChartCard
      :loading="store.isActivitiesChartLoading"
      :activity-datas="store.activitiesChart"
    />
    <TopTopicsChartCard
      :loading="store.isTopThematicsLoading"
      :datas="store.topThematics"
    />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import ActivityChartCard from "@/components/prm/atoms/ActivityChartCard.vue";
import TopTopicsChartCard from "@/components/prm/atoms/TopTopicsChartCard.vue";
import { usePrmStore } from "@/stores/prm";

const route = useRoute();
const store = usePrmStore();
store.fetchOnePersonnalityTemporality(route.params._id);
store.fetchOnePersonnalityTopThematics(route.params._id);
</script>

<style scoped>
.charts-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
