<template>
  <div class="time-nav">
    <button class="previous-time-button" @click="$emit('previousTime')">
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    {{ label }}
    <button class="next-time-button" @click="$emit('nextTime')">
      <i class="fa-solid fa-chevron-right"></i>
    </button>
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import { computed } from "vue";
import { TIME_INTERVALS } from "@/stores/agenda";

const props = defineProps({
  start: {
    type: String,
    required: true,
  },
  end: {
    type: String,
    required: true,
  },
  range: {
    type: String,
    required: true,
  },
});

const label = computed(() => {
  switch (props.range) {
    case TIME_INTERVALS.DAY:
      return `${dayjs(props.start).format("DD MMMM YYYY")}`;
    case TIME_INTERVALS.MONTH:
      return `${dayjs(props.start).format("MMMM YYYY")}`;
    case TIME_INTERVALS.WEEK:
    default:
      if (dayjs(props.start).month() === dayjs(props.end).month()) {
        return `Semaine ${dayjs(props.start).week()} (${dayjs(
          props.start
        ).format("MMMM YYYY")})`;
      } else {
        return `Semaine ${dayjs(props.start).week()} (${dayjs(
          props.start
        ).format("MMM")} - ${dayjs(props.end).format("MMM YYYY")})`;
      }
  }
});

defineEmits(["previousTime", "nextTime"]);
</script>

<style lang="scss" scoped>
.time-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  border-radius: 20px;
  background-color: var(--clr-p5);
  color: white;
  text-transform: capitalize;
}

.previous-time-button,
.next-time-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 21px;
  margin: 3px 10px;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px #d1bfd9;
  }
  &:active {
    background-color: black;
  }
}
</style>
