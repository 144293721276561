<template>
  <div
    v-if="!disabled"
    v-on-click-outside="() => (isMenuOpen = false)"
    class="filter-multiples"
    :class="mode"
  >
    <div class="select-box" @click.self="isMenuOpen = !isMenuOpen">
      <legend @click.self="isMenuOpen = !isMenuOpen">
        <slot>Label</slot>
      </legend>

      <div class="tools">
        <!-- selected marker -->
        <span v-if="activeFilters.length" class="nb-selected" :class="mode">
          {{ activeFilters.length }}
        </span>

        <div class="arrow">
          <i
            class="fa-solid"
            :class="isMenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
      </div>
    </div>

    <div v-if="isMenuOpen" class="menu" @wheel.self.prevent>
      <!-- SearchBar -->
      <SelectSearchBar
        v-model.trim="searchField"
        @update:model-value="search"
      />

      <!-- Option to select -->
      <template v-if="!isLoading">
        <div v-if="activeFilters.length > 0" class="selected-list" @wheel.stop>
          <div
            v-for="(item, index) in activeFilters"
            :key="index"
            class="option"
            @click="uncheckFilter(item)"
          >
            <span class="check">
              <!-- checked -->
              <i class="fa-solid fa-square-check"></i>
            </span>
            <div class="text">
              {{ getLabel(item) }}
            </div>
          </div>
        </div>
        <hr v-if="activeFilters.length > 0" @wheel.prevent />
        <div class="options-list">
          <template v-for="(item, index) in items" :key="index">
            <div
              v-if="!activeFilters.includes(item)"
              class="option"
              @click="checkFilter(item)"
            >
              <span class="check">
                <!-- unchecked -->
                <i class="fa-regular fa-square"></i>
              </span>
              <div class="text">
                {{ getLabel(item) }}
              </div>
            </div>
          </template>

          <!-- Tip when no search -->
          <p v-if="items.length <= 0 && !isLoading" class="tip">
            {{
              searchField && !isTyping
                ? "Aucun auteur correspondant à cette recherche"
                : "Écrivez quelques lettres pour commencer la recherche"
            }}
          </p>
        </div>
      </template>

      <!-- Loading -->
      <div v-else class="loading-wrapper">
        <hollow-dots-spinner
          :animation-duration="1000"
          :color="'var(--clr-p6)'"
        />
      </div>

      <!-- Reset -->
      <div class="reset">
        <button class="btn-reset btn-secondary" :class="mode" @click="reset">
          <i class="fa-solid fa-arrow-rotate-left"></i>
          <span class="label">Réinitialiser</span>
        </button>
      </div>
    </div>
  </div>

  <div v-else class="filter-multiples disabled" :class="mode">
    <div class="select-box">
      <legend>
        <slot>Label</slot>
      </legend>

      <div class="select-arrow">
        <i class="fa-solid fa-chevron-down"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
import { HollowDotsSpinner } from "epic-spinners";
import SelectSearchBar from "@/components/reusable/atoms/SelectSearchBar.vue";

const props = defineProps({
  filter: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  itemLabel: {
    type: String,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  activeFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  mode: {
    type: String,
    validator(value) {
      // The value must match one of these strings
      return ["purple", "blue", "topbar"].includes(value);
    },
    default: "purple",
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "search",
  "resetFilter",
  "addFilterItem",
  "removeFilterItem",
]);

const isMenuOpen = ref(false);
const searchField = ref("");
const isTyping = ref(false);

const search = () => {
  isTyping.value = true;
  debounceSearch();
};
const debounceSearch = useDebounceFn(() => {
  isTyping.value = false;
  emit("search", searchField.value);
}, 500);

const getLabel = (item) => (props.itemLabel ? item[props.itemLabel] : item);

const checkFilter = (item) => {
  emit("addFilterItem", { filter: props.filter, item: item });
};

const uncheckFilter = (item) => {
  emit("removeFilterItem", { filter: props.filter, item: item });
};

const reset = () => {
  searchField.value = "";
  emit("resetFilter", props.filter);
};
</script>

<style lang="scss" scoped>
/* Purple & Topbar styles
========================================================================== */
.purple,
.topbar {
  color: var(--clr-n8);
  .select-box {
    border-radius: 15px;
    background: white;
  }
  .menu {
    border-radius: 13px;
  }
  .nb-selected,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-p2);
  }
}

.topbar {
  .select-box {
    color: white;
    background: var(--clr-p5);
  }
  .menu {
    color: var(--clr-n8);
  }
}

/* Blue styles
========================================================================== */
.blue {
  color: var(--clr-s8);
  .select-box,
  .menu {
    border-radius: 4px;
    background: white;
  }
  .nb-selected,
  .btn-reset,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-s2);
  }
}

/* Whole component
========================================================================== */
.filter-multiples {
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  /* Input
========================================================================== */
  .select-box {
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 30px;
    box-shadow: 0 3px 5px #00000014;
    padding: 4px 15px;
    cursor: pointer;

    .tools {
      display: flex;
      margin-left: 5px;
      pointer-events: none;

      .nb-selected {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 5px;
        border-radius: 12px;
        min-width: 20px;
        height: 20px;
        line-height: initial;
        font-size: 14px;
      }
    }
  }

  &.disabled > .select-box {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

/* Popover Menu
========================================================================== */
.menu {
  position: absolute;
  z-index: 3;
  top: 33px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0 1px 6px #0000005d;
  width: 220px;
  max-height: 430px;
  padding: 5px;
  background: white;

  .selected-list {
    flex-shrink: 0;
    margin-left: 8px;
    max-height: 120px;
    overflow-y: auto;
    overscroll-behavior: contain;
  }
  .options-list {
    margin-left: 8px;
    overflow-y: auto;
    overscroll-behavior: contain;
  }
}

/* Individual items
========================================================================== */
hr {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.tip {
  margin: 0;
  margin-left: 8px;
  padding: 2px;
  opacity: 0.6;
  font-style: italic;
}

.option {
  display: flex;
  line-height: 24px;
  cursor: pointer;

  .check {
    font-size: 1.063em;
  }

  .text {
    margin-left: 11px;
    text-transform: capitalize;
  }

  &:hover {
    background-color: var(--clr-p1);
    border-radius: 4px;
  }
}

.reset {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 4px 0;
}

.btn-reset {
  font-size: 0.9rem;

  .label {
    margin-left: 4px;
  }
}

/* Scrollbar
========================================================================== */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
}
</style>
