<template>
  <div class="bar-wrapper">
    <div class="percentage" :style="{ width: props.progression + '%' }"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  progression: { type: Number, required: true },
});
</script>

<style scoped>
.bar-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 16px;
  background-color: white;
  border: solid 2px var(--clr-n4);
  border-radius: 20px;
  overflow: hidden;
}

.percentage {
  height: 15px;
  background-color: var(--clr-s2);
}
</style>
