<template>
  <div class="template">
    <JorfZoomParams />
    <LoaderFullPage v-if="jorfsStore.loading" />
    <JorfZoomDatas v-else />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import JorfZoomParams from "@/components/jorfs/organisms/JorfZoomParams.vue";
import JorfZoomDatas from "@/components/jorfs/organisms/JorfZoomDatas.vue";
import { useJorfsStore } from "@/stores/jorfs";

const route = useRoute();
const jorfsStore = useJorfsStore();
jorfsStore.fetchOneJorf(route.params._id);
</script>
