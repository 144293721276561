<template>
  <div class="template">
    <LoaderFullPage v-if="store.loading" />
    <template v-else>
      <BreadCrumbs :crumbs="crumbsArray" />
      <MinisterZoomCard :minister="store.zoom.minister" />
      <CabinetMembersCard
        :members="store.zoom.members"
        :has-poles="store.zoom.poles.length > 0"
        start-opened
      />
      <CabinetMembersCard
        v-for="pole in store.zoom.poles"
        :key="pole"
        :members="pole.members"
        :has-poles="store.zoom.poles.length > 0"
        :cabinet-pole-name="pole.name"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useCabinetsStore } from "@/stores/cabinets";
import { useRoute } from "vue-router";
import MinisterZoomCard from "@/components/cabinets/molecules/MinisterZoomCard.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import CabinetMembersCard from "@/components/cabinets/molecules/CabinetMembersCard.vue";
import BreadCrumbs from "@/components/reusable/atoms/BreadCrumbs.vue";

const store = useCabinetsStore();
const route = useRoute();
store.fetchOneCabinet(route.params._id);

const crumbsArray = computed(() => [
  {
    text: "Cabinets",
    to: { name: "Cabinets" },
  },
  {
    text: store.zoom ? `Cabinet de ${store.zoom?.minister.name}` : "Cabinet",
  },
]);
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
