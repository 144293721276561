<template>
  <div class="template">
    <RouterRadioButtons :options="navigationOptions" />
    <RouterView />
  </div>
</template>

<script setup>
import { RouterView } from "vue-router";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";

const navigationOptions = [
  { label: "Eliasse", value: "eliasse" },
  { label: "En Séance", value: "en-seance" },
];
</script>
