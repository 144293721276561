import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

// Defining store
export const useCabinetsStore = defineStore("cabinets", () => {
  const loading = ref(false);

  const cabinets = ref([]);
  const filters = ref([]);
  const searchCurrent = ref("");
  const zoomedCabinet = ref(null);

  const searchedMinisters = computed(() => {
    return cabinets.value.filter((item) => {
      return (
        item.ministry
          .toLowerCase()
          .includes(searchCurrent.value.toLowerCase().trim()) ||
        item.minister.name
          .toLowerCase()
          .includes(searchCurrent.value.toLowerCase().trim()) ||
        item.minister.position
          .toLowerCase()
          .includes(searchCurrent.value.toLowerCase().trim())
      );
    });
  });

  const groupedSearchedMinistersByMinistries = computed(() => {
    return Object.groupBy(searchedMinisters.value, ({ ministry }) => ministry);
  });

  // Get cabinets from backend
  const fetchCabinetsList = async () => {
    loading.value = true;

    try {
      const response = await httpRequest("get", "/cabinets/listing");

      if (response.data) {
        cabinets.value = response.data.items;
        filters.value = response.data.filters;
      }
    } catch (err) {
      cabinets.value = [];
      filters.value = [];
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOneCabinet = async (cabinetId) => {
    loading.value = true;

    try {
      const response = await httpRequest("get", `/cabinets/zoom/` + cabinetId);

      if (response.data) {
        zoomedCabinet.value = response.data.items[0];
      }
    } catch (err) {
      zoomedCabinet.value = [];
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const getOneGroupedMinistry = (value) => {
    return searchedMinisters.value[value];
  };

  const resetStore = () => {
    cabinets.value = [];
    filters.value = [];
    loading.value = null;
  };

  return {
    loading,
    zoom: zoomedCabinet,
    searchCurrent,
    fetchCabinetsList,
    resetStore,
    getOneGroupedMinistry,
    groupedSearchedMinistersByMinistries,
    fetchOneCabinet,
  };
});
