<template>
  <div v-if="store.prmZoom" class="zoom-datas">
    <PrmZoomCard :key="store.prmZoom._id" :prospect="store.prmZoom" />
    <RouterView />
  </div>
  <NoData v-else />
</template>

<script setup>
import PrmZoomCard from "../molecules/PrmZoomCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";

import { usePrmStore } from "@/stores/prm";

const store = usePrmStore();
</script>

<style lang="scss" scoped>
.zoom-datas {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;

  @container (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}
</style>
