<template>
  <div class="card">
    <div class="card-header">
      <div class="header-title">Top 5 des sujets abordés</div>
    </div>
    <div v-if="loading" class="loading-wrapper">
      <HollowDotsSpinner :animation-duration="1000" color="var(--clr-p6)" />
    </div>
    <div v-else-if="datas.length" class="chart-wrapper">
      <BarChart
        :chart-data="barChartData"
        :options="options"
        :plugins="[ChartDataLabels]"
      />
    </div>
    <EmptyChart v-else />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { HollowDotsSpinner } from "epic-spinners";
import { BarChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import EmptyChart from "@/components/reusable/molecules/EmptyChart.vue";
import { getCSSVariable } from "@/utils/helper.utils.js";

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  datas: {
    type: Array,
    required: true,
  },
});

// Activities Chart
const data = computed(() => {
  const sortedData = {
    amendments: [],
    questions: [],
    publications: [],
    totals: [],
  };
  props.datas
    .map((thematic) => thematic.counts)
    .forEach((counts) => {
      sortedData.amendments.push(counts.amendment);
      sortedData.questions.push(counts.question);
      sortedData.publications.push(counts.publication);
      sortedData.totals.push(counts.total);
    });
  return sortedData;
});

const barChartData = computed(() => ({
  labels: props.datas.map((thematic) => thematic.label),
  datasets: [
    {
      label: "Publications",
      data: data.value.publications,
      backgroundColor: getCSSVariable("--clr-network"),
      borderColor: "white",
      borderWidth: 2,
    },
    {
      label: "Amendements",
      data: data.value.amendments,
      backgroundColor: getCSSVariable("--clr-service-amendment"),
      borderColor: "white",
      borderWidth: 2,
    },
    {
      label: "Questions",
      data: data.value.questions,
      backgroundColor: getCSSVariable("--clr-service-question"),
      borderColor: "white",
      borderWidth: 2,
    },
  ],
}));

const options = {
  responsive: true,
  indexAxis: "y",
  layout: {
    padding: {
      right: 50,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "black",
      },
      stacked: true,
    },
    y: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: "black",
      },
      stacked: true,
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        color: "black",
        padding: 32,
      },
    },
    datalabels: {
      color: "black",
      anchor: "end",
      align: "right",
      offset: 4,
      formatter: (value, context) => {
        if (context.dataset.label === "Questions") {
          return data.value.totals[context.dataIndex];
        } else {
          return "";
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;

  .card-header {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 20px;

    .header-title {
      font-weight: 600;
    }
  }
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
