<template>
  <VueWordCloud
    style="height: 480px; width: 100%"
    :words="props.words"
    :color="props.color"
    font-family="DM Sans"
    :font-size-ratio="5"
    :spacing="0.33"
  >
    <template #default="{ text, weight, word }">
      <div :title="weight" class="word" @click="onWordClick(word)">
        {{ text }}
      </div>
    </template></VueWordCloud
  >
</template>

<script setup>
import VueWordCloud from "vuewordcloud";

const props = {
  color: { type: String, default: "black" },
  words: { type: Array, required: true },
};

const emit = defineEmits(["clickWord"]);

function onWordClick(item) {
  const [word] = item;
  emit("clickWord", word);
}
</script>

<style lang="scss" scoped>
.word {
  line-height: 1;
  cursor: pointer;
}
</style>
