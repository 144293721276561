<template>
  <div class="card">
    <div class="header-row">
      {{ getDayTimeFormat(reunion.start) }}
      <GenericTag v-if="isPast" background-color="#87D4FF">
        A eu lieu
      </GenericTag>
      <GenericTag v-else background-color="#FFE39B">À venir</GenericTag>
    </div>
    <div class="body-row">
      <InstitutionBadge :institution="reunion.institution" />
      <div class="content-col">
        <div class="title">{{ reunion.title }}</div>
        <div v-if="reunion.cr_ref" class="links-row">
          <RouterLink :to="`/comptes-rendus/${reunion.cr_ref}`">
            Voir le compte-rendu
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getDayTimeFormat } from "@/utils/formatDate";
import InstitutionBadge from "./InstitutionBadge.vue";
import dayjs from "dayjs";
import { computed } from "vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";

const props = defineProps({ reunion: { type: Object, required: true } });

const isPast = computed(() => {
  const now = dayjs();

  return dayjs(props.reunion.start) < now;
});
</script>

<style scoped lang="scss">
.card {
  color: var(--clr-s8);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  max-width: 600px;
  height: 100%;
}

.header-row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding-bottom: 5px;
  border-bottom: 1px solid var(--clr-s2);
}

.body-row {
  width: 100%;
  display: flex;
  gap: 15px;
}

.body-column {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 700;
}
</style>
