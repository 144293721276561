export function isCurrentTab(tab, currentRoute) {
  const matchedPaths = currentRoute.matched.map((m) => m.path);

  if (matchedPaths.includes(tab.path)) {
    return true;
  }

  for (const secondaryTab of tab.secondary_tabs || []) {
    if (isCurrentTab(secondaryTab, currentRoute)) {
      return true;
    }
  }

  for (const secondaryRoute of tab.secondary_routes || []) {
    if (isCurrentTab(secondaryRoute, currentRoute)) {
      return true;
    }
  }

  for (const childTab of tab?.children || []) {
    if (isCurrentTab(childTab, currentRoute)) {
      return true;
    }
  }

  return false;
}
