<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink to="/petitions" class="back">
        <i class="fa-solid fa-chevron-left" /> Petitions
      </RouterLink>
      <div
        v-if="store.previousPetition?._id || store.nextPetition?._id"
        class="previous-next-wrapper"
      >
        <!-- Next and Previous Items -->
        <RouterLink
          v-if="store.previousPetition?._id"
          :to="`/petitions/${store.previousPetition._id}`"
          class="btn-primary previous"
        >
          <i class="fa-solid fa-chevron-left" />Précédente
        </RouterLink>
        <RouterLink
          v-if="store.nextPetition?._id"
          :to="`/petitions/${store.nextPetition._id}`"
          class="btn-primary next"
        >
          Suivante<i class="fa-solid fa-chevron-right" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePetitionsStore } from "@/stores/petitions";
const store = usePetitionsStore();
</script>
