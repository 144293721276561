export function getTimelineUrl(baseRoute, timeline, stepIdx) {
  const step = timeline[stepIdx];

  if (step.dossier_etape?.match(/dépot/i)) {
    return `${baseRoute}/${stepIdx}/texte-initial`;
  }

  if (step.dossier_etape?.match(/lecture/i)) {
    return `${baseRoute}/${stepIdx}/commission/full-text`;
  }

  if (step.dossier_etape?.match(/mixte paritaire/i)) {
    return `${baseRoute}/${stepIdx}/cmp_travaux`;
  }

  if (step.dossier_etape?.match(/constitutionnel/i)) {
    return `${baseRoute}/${stepIdx}/conseil-consti`;
  }

  if (step.dossier_etape?.match(/promulgation/i)) {
    return `${baseRoute}/${stepIdx}/prom_texte`;
  }

  return `${baseRoute}/${stepIdx}`;
}
