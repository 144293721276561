<template>
  <template v-if="questionsStore.items.length">
    <div class="responsive-cards-wrapper">
      <QuestionCard
        v-for="question in questionsStore.items"
        :key="question._id"
        :question="question"
        :to="`/questions/${question._id}`"
      />
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="questionsStore.item_count"
      :key="`${questionsStore.item_count}${questionsStore.selectedFilters.from}${questionsStore.selectedFilters.size}`"
      :total-items="questionsStore.item_count"
      :current-page="questionsStore.selectedFilters.from"
      :items-limit="questionsStore.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-else-if="questionsStore.hasActiveFilters" />
  <NoDataFollawed
    v-else-if="questionsStore.isFollawedActive"
    feature="questions"
  />
  <NoData v-else />
</template>

<script setup>
import QuestionCard from "../atoms/QuestionCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { useQuestionsStore } from "@/stores/questions";

const questionsStore = useQuestionsStore();
const changeCurrentPage = (from) => {
  questionsStore.selectedFilters.from = from;
  questionsStore.fetchQuestionsStats();
  questionsStore.fetchQuestionsList();
};
</script>
<style scoped lang="scss">
.counter-stats-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin: 0 0 10px 0;
}
.table-graph-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  margin: 0 0 10px 0;
  table {
    width: 100%;
  }
}
</style>
