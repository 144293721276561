<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink to="/amendments" class="back">
        <i class="fa-solid fa-chevron-left"></i> Retour à la liste
      </RouterLink>
      <div
        v-if="store.previousAmendmentId || store.nextAmendmentId"
        class="previous-next-wrapper"
      >
        <!-- Next and Previous Items -->
        <RouterLink
          v-if="store.previousAmendmentId"
          :to="`/amendments/${store.previousAmendmentId}`"
          class="btn-primary previous"
        >
          <i class="fa-solid fa-chevron-left" />Précédent
        </RouterLink>
        <RouterLink
          v-if="store.nextAmendmentId"
          :to="`/amendments/${store.nextAmendmentId}`"
          class="btn-primary next"
        >
          Suivant<i class="fa-solid fa-chevron-right" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAmendmentsStore } from "@/stores/amendments.js";

const store = useAmendmentsStore();
</script>
