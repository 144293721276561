export const AMENDMENTS_STATUS_COLORS = {
  "à discuter": {
    background: "#00BA8F",
    text: "white",
  },
  discuté: {
    background: "#00BA8F",
    text: "white",
  },
  "en recevabilité": {
    background: "#0E73D8",
    text: "white",
  },
  "en traitement": {
    background: "#0E73D8",
    text: "white",
  },
  irrecevable: {
    background: "#B70A4C",
    text: "white",
  },
  "irrecevable 40": {
    background: "#B70A4C",
    text: "white",
  },
  retiré: {
    background: "#E87613",
    text: "white",
  },
  effacé: {
    background: "#969BAC",
    text: "white",
  },
  adopté: {
    background: "#00C726",
    text: "white",
  },
  tombé: {
    background: "#BA0F08",
    text: "white",
  },
  rejeté: {
    background: "#ED2424",
    text: "white",
  },
  "non soutenu": {
    background: "#969BAC",
    text: "white",
  },
};
