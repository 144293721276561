import { defineStore } from "pinia";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { httpRequest } from "@/utils/request.utils";
import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const toast = useToast();

export const useRegisterStore = defineStore("registerStore", () => {
  const isLoading = ref(false);

  const occupations = ref([]);
  const sectors = ref([]);
  const user = ref(null);

  const getRegisterOptions = async () => {
    isLoading.value = true;

    try {
      const response = await httpRequest("get", "/users/registration-filters");
      if (response.data) {
        occupations.value = response.data.occupations;
        sectors.value = response.data.sectors;
      }
    } catch (err) {
      handleNetworkError(err);
    }
    isLoading.value = false;
  };

  const registerUser = async (payload) => {
    isLoading.value = true;

    const payloadCopy = { ...payload };
    if (payload.sectors) {
      payloadCopy.sectors = [payload.sectors];
    }

    if (!payload) {
      return;
    }

    try {
      const response = await httpRequest(
        "post",
        "/users/register",
        payloadCopy
      );
      if (response.status === 201) {
        user.value = response.data;
        router.push("/login");
      }

      toast.success(
        "Votre compte a été créé avec succès, merci de suivre les instructions reçues par email"
      );
    } catch (err) {
      handleNetworkError(err);
    }
    isLoading.value = false;
  };

  return {
    isLoading,
    occupations,
    sectors,
    user,
    getRegisterOptions,
    registerUser,
  };
});
