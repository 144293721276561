import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

/**
 * Fetches author filters from the amendments endpoint.
 * @param {Object} payload - payload for request authors.
 * @returns {Promise<Array>} - A promise that resolves to an array of author filters.
 * @throws {Error} - If there is a network error during the API call.
 */
export const fetchSearchAuthors = async (name) => {
  try {
    const response = await httpRequest("post", "/social-media/search_authors", {
      name,
    });
    return response.data?.personalities;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchAuthors = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/authors",
      payload
    );

    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchPoliticalMap = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/authors-classification",
      { ...payload, classification: "group" }
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchSocietalMap = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/authors-classification",
      { ...payload, classification: "major_stakeholder" }
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchPublications = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/publications",
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchTotals = async (payload) => {
  try {
    const response = await httpRequest("post", "/social-media/totals", payload);
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchTimeCurve = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/time_curve",
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchRankings = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/rankings",
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchGroupRanking = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/group_ranking",
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchMajorStakeholderRanking = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/major_stakeholder_ranking",
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchLevelRanking = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/social-media/level_ranking",
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchArticle = async (payload) => {
  try {
    const response = await httpRequest("post", "/social-media/links", payload);
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};
