<template>
  <div class="card">
    <div class="header">
      <p class="title">{{ preset.name }}</p>
    </div>
    <div class="body">
      <FilterTag :text="getShortTime" />
      <FilterTag
        v-for="thematic in presetThematics"
        :key="thematic.id"
        :text="thematic.name"
      />
      <FilterTag
        v-for="subthematic in presetSubthematics"
        :key="subthematic.id"
        :text="subthematic.name"
      />
      <FilterTag v-if="preset.filter.search" :text="preset.filter.search" />
      <template v-for="(filter, key) in features" :key="key">
        <FilterTag
          v-if="preset.filter[key]"
          :text="`${filter.name} : ${getNumberOfActiveFilters(preset.filter[key])}`"
        />
      </template>
    </div>
    <div class="footer">
      <div class="actions">
        <button class="btn-secondary" @click="$emit('openUpdate', preset)">
          <i class="fa-solid fa-pen"></i>Modifier
        </button>
        <button class="btn-secondary" @click="$emit('openDelete', preset)">
          <i class="fa-solid fa-trash"></i>Supprimer
        </button>
      </div>
      <button class="btn-primary" @click="$emit('apply', preset.filter)">
        Appliquer
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import FilterTag from "@/components/reusable/atoms/FilterTag.vue";
import { TIME_INTERVALS } from "@/stores/user";
import { features } from "@/utils/features";

const props = defineProps({
  preset: { type: Object, required: true },
  thematics: { type: Array, default: () => [] },
});

defineEmits(["openUpdate", "openDelete", "apply"]);

const isCustomIntervalValid = computed(
  () =>
    !!props.preset.filter.start &&
    !!props.preset.filter.end &&
    dayjs(props.preset.filter.start) < dayjs(props.preset.filter.end)
);
const getShortTime = computed(() => {
  switch (props.preset.filter.selected_time_range) {
    case TIME_INTERVALS.LAST_24H:
      return "< 24h";
    case TIME_INTERVALS.LAST_WEEK:
      return "< 1 semaine";
    case TIME_INTERVALS.LAST_MONTH:
      return "< 1 mois";
    case TIME_INTERVALS.LAST_YEAR:
      return "< 1 an";
    case TIME_INTERVALS.LEGI_15:
      return "XVe legi.";
    case TIME_INTERVALS.LEGI_16:
      return "XVIe legi.";
    case TIME_INTERVALS.LEGI_17:
      return "XVIIe legi.";
    case TIME_INTERVALS.FREE:
      if (isCustomIntervalValid.value) {
        return `${dayjs(props.preset.filter.start).format("DD/MM/YY")} - ${dayjs(
          props.preset.filter.end
        ).format("DD/MM/YY")}`;
      }
    // We want to fall through if the condition is not fullfilled
    // eslint-disable-next-line no-fallthrough
    default:
      return "Période";
  }
});

const presetThematics = computed(() =>
  props.preset.filter.thematics.map((id) =>
    props.thematics.find((t) => t._id === parseInt(id))
  )
);
const allSubthematics = computed(() => {
  return props.thematics.reduce((acc, them) => {
    return [...acc, ...them.subthematics];
  }, []);
});

const presetSubthematics = computed(() =>
  props.preset.filter.subthematics.map((id) =>
    allSubthematics.value.find((t) => t._id === parseInt(id))
  )
);

const getNumberOfActiveFilters = (featureFilters) => {
  return Object.values(featureFilters)
    .filter((element) => element !== null)
    .reduce((acc, curr) => acc + curr.length, 0);
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 570px;
}

.header {
  border-color: var(--clr-p2);
  padding-bottom: 8px;

  .title {
    margin: 0;
    font-weight: var(--fw-semibold);
    line-height: var(--lh-normal);
  }
}

.body {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  .actions {
    display: flex;
    gap: 8px;

    button {
      display: flex;
      gap: 8px;

      .fa-pen {
        color: var(--clr-s7);
      }

      .fa-trash {
        color: var(--clr-e300);
      }
    }
  }
}
</style>
