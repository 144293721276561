import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

export const fetchArticles = async (payload) => {
  try {
    const response = await httpRequest("post", "/links", payload);
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};
