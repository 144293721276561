export const JORF_LEVELS = [
  "root",
  "niv_1",
  "niv_2",
  "niv_3",
  "niv_4",
  "niv_5",
  "niv_6",
  "niv_7",
  "niv_8",
];

export const LEGIDOC_LEVELS = [
  "root",
  "section",
  "chapitre",
  "titre",
  "partie",
];
