<template>
  <div class="card wrapper">
    <div class="card-header">
      <div class="header-title">Groupes Politiques</div>
      <div class="header-tools">
        <RadioButtons
          :options="groupsDisplayOptions"
          :active-option="activeDisplayOption"
          @select-option="selectDisplay($event)"
        />
      </div>
    </div>
    <div v-if="isLoading" class="loading-wrapper">
      <HollowDotsSpinner :animation-duration="1000" color="var(--clr-p6)" />
    </div>
    <div
      v-else-if="groups[activeDisplayOption]?.length > 0"
      class="charts-wrapper"
    >
      <div class="single-chart-wrapper">
        <DoughnutChart
          :options="hemicycleChartOptions"
          :chart-data="hemicycleChartData"
        />
      </div>
      <div class="single-chart-wrapper">
        <BarChart
          :options="barChartOptions"
          :chart-data="barChartData"
          :plugins="[ChartDataLabels]"
        />
      </div>
    </div>
    <div v-else class="no-data-wrapper">
      <NoData />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { BarChart, DoughnutChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { HollowDotsSpinner } from "epic-spinners";

import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { useSocialMediaStatsStore } from "@/stores/social-media/statistics";

const store = useSocialMediaStatsStore();

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
  groups: {
    type: Object,
    required: true,
  },
});

const groupsDisplayOptions = [
  {
    label: "Auteurs",
    value: "authors",
  },
  {
    label: "Publications",
    value: "publications",
  },
];

const activeDisplayOption = ref(groupsDisplayOptions[0].value);

const selectDisplay = (event) => {
  activeDisplayOption.value = event;
};

const simplifiedGroups = computed(() => {
  const relevantGroups = [
    // In case of discrepancy between prod and dev environments, those should be the production groups

    // AN
    "DEM",
    "DR",
    "ECOLO",
    "EPR",
    "GDR",
    "HOR",
    "LFI",
    "LIOT",
    "MODEM",
    "NI",
    "RN",
    "SOC",
    "UDR",
    // SN
    "LR-SN",
    "SER",
    "UC",
    "RDPI",
    "LIRT",
    "RDSE",
    "GEST",
    "CRCE-K",
    "NI-SN",
  ];

  return selectedRanking.value.filter((group) =>
    relevantGroups.includes(group.acronym)
  );
});

const selectedRanking = computed(() => {
  return props.groups[activeDisplayOption.value];
});

const getRankingPercentages = (number) => {
  const totalNumber = simplifiedGroups.value.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    0
  );
  return Math.round((number / totalNumber) * 1000) / 10;
};

const barChartOptions = {
  responsive: true,
  layout: {
    padding: {
      right: 35,
    },
  },
  plugins: {
    legend: { display: false },
    datalabels: {
      color: "black",
    },
  },
};

const barChartData = computed(() => ({
  labels: simplifiedGroups.value.map((element) => element.acronym),
  datasets: [
    {
      label: groupsDisplayOptions.find(
        (element) => element.value === activeDisplayOption.value
      ).label,
      indexAxis: "y",
      backgroundColor: simplifiedGroups.value.map((element) => element.color),
      data: simplifiedGroups.value.map((element) => element.count),
      datalabels: {
        anchor: "end",
        align: "right",
      },
    },
  ],
}));

const hemicycleChartOptions = {
  responsive: true,
  circumference: 180,
  rotation: -90,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  onClick: (evt, elements) => {
    if (elements.length === 0) {
      return;
    }

    const clickedIdx = elements[0].index;
    const clickedAcronym = simplifiedGroups.value[clickedIdx].acronym;

    if (clickedAcronym === "Autres") {
      // Prevent adding the "others" filter
      return;
    }

    store.addFilterItem({ filter: "group", item: clickedAcronym });
  },
  onHover: (evt) => {
    evt.native.target.style.cursor = "pointer";
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 25,
        font: {
          size: 15,
        },
      },
    },
  },
};

const hemicycleChartData = computed(() => ({
  labels: simplifiedGroups.value.map(
    (element) => `${element.acronym} : ${getRankingPercentages(element.count)}%`
  ),
  datasets: [
    {
      label: groupsDisplayOptions.find(
        (element) => element.value === activeDisplayOption.value
      ).label,

      indexAxis: "y",
      backgroundColor: simplifiedGroups.value.map((element) => element.color),
      data: simplifiedGroups.value.map((element) => element.count),
    },
  ],
}));
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;

  .card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .header-title {
      font-size: 1.063em;
      font-weight: 600;
      color: black;
    }
  }
}

.charts-wrapper {
  display: flex;
  .single-chart-wrapper {
    flex: 50%;
  }
}

.loading-wrapper,
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
