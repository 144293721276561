<template>
  <div class="template">
    <template v-if="userSettingsStore.hasPermission('SOCIETAL')">
      <NominationsParams />
      <LoaderFullPage v-if="store.loading" />
      <NominationsDatas v-else />
    </template>
    <template v-else>
      <FreemiumUpgradePopup :closable="false" title="Nominations">
        <p>Ce contenu est réservé aux abonnements payants.</p>
        <p>
          En activant celui-ci, vous pouvez suivre toutes les nominations
          importantes dans votre secteur.
        </p>
      </FreemiumUpgradePopup>
    </template>
  </div>
</template>

<script setup>
import NominationsParams from "@/components/nominations/organisms/NominationsParams.vue";
import NominationsDatas from "@/components/nominations/organisms/NominationsDatas.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useNominationsStore } from "@/stores/nominations.js";
import { useUserSettingsStore } from "@/stores/user-settings";
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";

const store = useNominationsStore();
const userSettingsStore = useUserSettingsStore();

store.selectedFilters.from = 0;
store.fetchNominationsList();
</script>

<style scoped>
p {
  margin: 0;
}
</style>
