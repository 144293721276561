<template>
  <div class="template">
    <template v-if="userSettingsStore.hasPermission('SOCIETAL')">
      <slot></slot>
    </template>
    <template v-else>
      <FreemiumUpgradePopup :closable="false" title="Veille Réseaux Sociaux">
        <p>Ce contenu est réservé aux abonnements payants.</p>
        <p>
          En activant celui-ci, vous pourrez analyser efficacement toutes les
          prises de parole des parties prenantes influentes : médias,
          journalistes, lobbies, politiques... Et ce, en fonction de vos enjeux
          et sur tous les réseaux grâce à notre panel de plus de 20 000
          personnalités clés au niveau français et européen.
        </p>
      </FreemiumUpgradePopup>
    </template>
  </div>
</template>

<script setup>
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";
import { useUserSettingsStore } from "@/stores/user-settings";
const userSettingsStore = useUserSettingsStore();
</script>

<style scoped>
p {
  margin: 0;
}
</style>
