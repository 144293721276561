// Function to detect highlighted content on a content
function hasHighlight(textContent) {
  const highlightRegex = /<mark class="highlight">.*?<\/mark>/gi;
  const matches = textContent.match(highlightRegex) || [];
  return matches.length;
}

// Function used to navigate to a specific article by its identifier
function goToArticle(articleTitle) {
  if (articleTitle) {
    const trimmedTitle = articleTitle.trim();
    const articleElement = Array.from(
      document.querySelectorAll(".collapse-title")
    ).find((el) => el.textContent.trim() === trimmedTitle);
    if (articleElement) {
      articleElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
}

export { hasHighlight, goToArticle };
