<template>
  <RouterLink :to="`/cabinets/${cabinet._id}`">
    <div class="card card-hover">
      <div class="minister-picture-and-info">
        <div class="minister-picture-container">
          <CardProfilePicture
            :picture-url="cabinet.minister.profile_picture"
            class="picture"
            :size="52"
          />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div class="minister-info">
          <h5 class="minister-name" v-html="processedMinisterName"></h5>
          <div
            class="typo-body-regular"
            v-html="processedMinisterPosition"
          ></div>
        </div>
        <!-- eslint-enable vue/no-v-html -->
      </div>
      <div class="members-container">
        <div class="membres">
          Membres :&nbsp;
          <p class="number-of-members">{{ cabinet.number_of_members }}</p>
        </div>
      </div>
    </div>
  </RouterLink>
</template>

<script setup>
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import { computed } from "vue";
import { useCabinetsStore } from "@/stores/cabinets";
import { generateHighlight } from "@/utils/helper.utils";

const store = useCabinetsStore();

const props = defineProps({
  cabinet: {
    type: Object,
    required: true,
  },
});

const processedMinisterName = computed(() =>
  generateHighlight(store.searchCurrent, props.cabinet.minister.name)
);

const processedMinisterPosition = computed(() =>
  generateHighlight(store.searchCurrent, props.cabinet.minister.position)
);
</script>

<style lang="scss" scoped>
.membres {
  color: var(--clr-s7);
  display: flex;
  flex-direction: row;
}

.card {
  display: flex;
  flex-direction: column;
  color: var(--clr-n8);
  row-gap: 8px;
  padding: 16px;
}

.number-of-members {
  margin: 0;
  color: var(--clr-n8);
}

.minister-picture-and-info {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.minister-picture-container {
  display: flex;
  justify-content: center;
}

.minister-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

h3 {
  margin: 0;
}

@container (min-width: 1200px) {
  .card {
    height: 100%;
    grid-template-rows: 1fr 1fr;
  }

  .members-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
