<template>
  <div class="title">Synthèse du vote</div>

  <PieChart
    :chart-data="chartData"
    :height="300"
    :options="chartOptions"
    :plugins="[ChartDataLabels]"
  />

  <div class="additional-data">
    <p>Votants: {{ scrutin.voters }}</p>
    <p>Suffrages exprimés: {{ scrutin.effective_votes }}</p>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { PieChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";

const props = defineProps({ scrutin: { type: Object, required: true } });

function getCssVar(cssName) {
  return getComputedStyle(document.documentElement).getPropertyValue(cssName);
}

const chartData = computed(() => ({
  labels: ["Pour", "Contre", "Abstension"],
  datasets: [
    {
      backgroundColor: [
        getCssVar("--color-for"),
        getCssVar("--color-against"),
        getCssVar("--color-abstention"),
      ],
      data: [
        props.scrutin.voted_for,
        props.scrutin.voted_against,
        props.scrutin.all_abstentions,
      ],
    },
  ],
}));

const chartOptions = {
  plugins: {
    datalabels: { color: "white", font: { size: 12 } },
    legend: {
      position: "bottom",
      labels: {
        color: "#0A2E65",
        usePointStyle: true,
        font: {
          size: 12,
        },
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  text-align: center;
  font-weight: 400;
  padding-bottom: 10px;
}

.additional-data {
  text-align: center;
}
</style>
