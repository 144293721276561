<template>
  <AbstractCard
    color="var(--clr-service-agenda)"
    icon="fa-solid fa-calendar-lines"
    follawed-name="follawed_events"
    :doc-id="event._id"
    @select-event="$emit('selectEvent', event)"
    @unselect-event="$emit('unselectEvent', event)"
  >
    <template #header>
      <div class="date">
        {{ getDateTimeLongFormat(event.start) }}-{{
          formatDateToCompare(event.start) === formatDateToCompare(event.end)
            ? getHourTimeFormat(event.end)
            : getDateTimeLongFormat(event.end)
        }}
      </div>
    </template>
    <template #content>
      <EventCardContent :event="event" />
    </template>
  </AbstractCard>
</template>

<script setup>
import AbstractCard from "../../reusable/abstract/AbstractCorpusCard.vue";
import EventCardContent from "@/components/agenda/organisms/EventCardContent.vue";
import dayjs from "dayjs";
import { getDateTimeLongFormat, getHourTimeFormat } from "@/utils/formatDate";

defineProps({
  event: {
    type: Object,
    required: true,
  },
});

defineEmits(["selectEvent", "unselectEvent"]);

const formatDateToCompare = (date) => {
  return dayjs(date).format("YYYYMMDD");
};
</script>

<style lang="scss" scoped>
.date {
  text-transform: capitalize;
}
</style>
