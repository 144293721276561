<template>
  <div class="card">
    <h4>Saisine</h4>
    <div>
      À date du :
      <span class="emphasis">{{ getDateLongFormat(data.date_depot) }}</span>
    </div>
    <div>
      Par : <span class="emphasis">{{ data.saisine }}</span>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h4>Conclusion</h4>
      <GenericTag>{{ data.conclusion.conclusion }}</GenericTag>
    </div>
    <div>
      <BaseLink :href="data.conclusion.url_conclusion">
        Conclusion n° {{ data.conclusion.decision_number }} du
        {{ getDateLongFormat(data.conclusion.date_conclusion) }}
      </BaseLink>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import { useLegiWorkStore } from "@/stores/legi-work";
import { getDateLongFormat } from "@/utils/formatDate";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";

const store = useLegiWorkStore();
const route = useRoute();

// Find conseil consti data based on the route
const data = computed(() => {
  const step = store.legiWorkZoom.timeline[route.params.stepIdx];
  return step;
});
</script>

<style scoped lang="scss">
.emphasis {
  font-weight: 600;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
