<template>
  <LoaderFullPage v-if="store.loadingPetitions" />
  <template v-else-if="store.petitions.length > 0">
    <div class="englobe-params">
      <div class="params-row">
        <div>Total: {{ store.petitionsCount }}</div>
        <div class="page-settings">
          <!--Sorters-->
          <SortBy
            :items="sortersNames"
            :selected-sort-item="currentSortLabel"
            @change-sort="handleSortChange"
          />
          <!--Items limiters-->
          <SelectItemsLimit
            :items-limit="store.petitionsFilters.size"
            @change-limit="changeLimit"
            >Pétitions</SelectItemsLimit
          >
        </div>
      </div>
    </div>
    <ul class="petitions-list">
      <li v-for="petition in store.petitions" :key="petition._id">
        <div class="card">
          <div class="header">
            <div>{{ getDateShortFormat(petition.published_at) }}</div>
            <div class="signatures">
              <i class="fa-regular fa-pen-field" aria-label="Signatures"></i>
              {{ formatNumbers(petition.signatures_number) }} /
              {{ formatNumbers(petition.signatures_target) }}
            </div>
          </div>
          <div class="body">
            <div class="title">{{ petition.title }}</div>
            <div class="link">
              <RouterLink :to="`/petitions/${petition._id}`"
                >Voir la pétition</RouterLink
              >
            </div>
          </div>
        </div>
      </li>
    </ul>

    <PaginationElement
      v-if="store.petitionsCount"
      :key="`${store.petitionsCount}${store.petitionsFilters.from}${store.petitionsFilters.size}`"
      :total-items="store.petitionsCount"
      :current-page="store.petitionsFilters.from"
      :items-limit="store.petitionsFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <div v-else class="empty">
    <img
      src="@/assets/img/institutional-groups/empty-states/commissions-petitions-nodatas.svg"
      alt="aria-hidden=true"
    />
    <p>Aucune pétition associée à cet organe.</p>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { useRoute } from "vue-router";
import formatNumbers from "@/utils/formatNumbers";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import { storeToRefs } from "pinia";
const store = useInstituGroupsStore();

const route = useRoute();
const { petitionsFilters } = storeToRefs(store);

const changeCurrentPage = (from) => {
  store.petitionsFilters.from = from;
  store.fetchPetitions(route.params.missionId || route.params._id);
};

const sortersNames = {
  published_at: "Date de publication",
  signatures_number: "Nombre de signatures",
};

// Sorting
const currentSortField = ref(
  Object.keys(petitionsFilters.value.sort)[0] ?? "published_at"
);

const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      petitionsFilters.value.sort = { [currentSortField.value]: "desc" };

      petitionsFilters.value.from = 0;
      store.fetchPetitions(route.params.missionId || route.params._id);
    }
  }
};

function changeLimit(size) {
  store.petitionsFilters.size = size;
  store.petitionsFilters.from = 0;
  store.fetchPetitions(route.params.missionId || route.params._id);
}

store.fetchPetitions(route.params.missionId || route.params._id);
</script>

<style scoped lang="scss">
.empty > img {
  max-width: 247px;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  font-size: 1.125em;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
  small {
    font-size: 1em;
  }
}

.petitions-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;

  :deep(.card) {
    display: flex;
    flex-direction: column;
    height: 100%;

    .signatures {
      font-weight: 300;
    }

    .body {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      gap: 15px;

      .link {
        margin-top: auto;
        align-self: flex-end;
      }
    }
  }
}
</style>
