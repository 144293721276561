<template>
  <div class="collapse collapse-item" :class="{ 'is-active': active }">
    <div class="collapse-header" @click.prevent="active = !active">
      <h5 class="title">Article :</h5>
      <div>
        <span class="collapse-label">{{
          active ? "Voir moins" : "Voir plus"
        }}</span>
        <transition name="rotate" mode="out-in">
          <i v-if="active" key="up" class="fa-solid fa-chevron-up"></i>
          <i v-else key="down" class="fa-solid fa-chevron-down"></i>
        </transition>
      </div>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-show="active"
      class="collapse-content typo-body-regular"
      v-html="article"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  article: {
    type: String,
    required: true,
  },
});

const active = ref(true);
</script>

<style lang="scss" scoped>
.collapse {
  border: 1px solid var(--clr-s2);

  border-radius: 4px;
  height: 100%;
  padding: 8px 16px;
  background-color: white;
}

.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;

  .title {
    margin: 0;
    font-size: 0.938em;
    font-style: italic;
  }

  .collapse-label {
    margin-right: 10px;
  }
}

.collapse-content {
  line-height: normal;

  :deep(p:first-child) {
    margin-top: 0;
  }

  :deep(p:last-child) {
    margin-bottom: 0;
  }
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease-in-out;
}

.rotate-leave-to {
  transform: rotateZ(180deg);
}
</style>
