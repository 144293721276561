<template>
  <ScrollTop v-if="store.publications.length > 0" />
  <LoaderFullPage v-if="store.loadingPublications" />
  <template v-else-if="store.publications.length > 0">
    <ul class="publications-list">
      <li v-for="elem in store.publications" :key="elem._id">
        <PublicationCard :post="elem" />
      </li>
    </ul>

    <PaginationElement
      v-if="store.publicationsCount"
      :key="`${store.publicationsCount}${store.publicationsFilters.from}${store.publicationsFilters.size}`"
      :total-items="store.publicationsCount"
      :current-page="store.publicationsFilters.from"
      :items-limit="store.publicationsFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <div v-else class="empty">
    <img
      src="@/assets/img/institutional-groups/empty-states/commissions-publications-nodatas.svg"
      alt="aria-hidden=true"
    />
    <p>
      Aucun membre de cet organe n’a publié de posts correspondant à la veille
      sélectionnée.
    </p>
  </div>
</template>

<script setup>
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { useRoute } from "vue-router";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
const store = useInstituGroupsStore();

const route = useRoute();

const changeCurrentPage = (from) => {
  store.publicationsFilters.from = from;
  store.fetchPublications(route.params.missionId || route.params._id);
};

store.fetchPublications(route.params.missionId || route.params._id);
</script>

<style scoped lang="scss">
.empty > img {
  max-width: 314px;
}
.publications-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 10px;

  :deep(.card) {
    height: 100%;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  font-size: 1.125em;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
  small {
    font-size: 1em;
  }
}
</style>
