<template>
  <div class="template">
    <RouterRadioButtons :options="menuOptions" />
    <RouterView />
  </div>
</template>

<script setup>
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";

import { RouterView } from "vue-router";

const menuOptions = [
  {
    label: "Mon compte",
    value: "/user-settings/account",
  },
  {
    label: "Préférences de notification",
    value: "/user-settings/notifications",
  },
];
</script>

<style lang="scss" scoped>
h2 {
  margin: 0 16px;
}
</style>
