<template>
  <LoaderFullPage v-if="store.loadingAmendmentsStats" />
  <div
    v-else-if="
      !store.zoomAmendmentsStats ||
      Object.keys(store.zoomAmendmentsStats).length === 0 ||
      !shouldDisplayStatsWrapper
    "
    class="empty"
  >
    <img
      src="@/assets/img/legislation/empty-states/no-stats-infos.svg"
      aria-hidden="true"
    />
    <p>Pas de statistique d’amendement à afficher</p>
    <small>Aucun amendement n’a été proposé sur ce texte.</small>
  </div>
  <div v-else class="stats-wrapper">
    <div class="card">
      <p>
        Nombre total d'amendements:
        {{ amendmentsCount }}
      </p>
      <p v-if="amendmentsCount > 0">{{ totalAdoptedPercent }}% Adoptés</p>

      <AmendmentStatBar
        :data="store.zoomAmendmentsStats.total_stats || {}"
        :has-legend="true"
      />

      <div class="sub-stats">
        <div class="sub-tile">
          <p>
            Amendements proposés par la <strong>MAJORITÉ</strong> :
            {{ store.zoomAmendmentsStats.majority_count }}
          </p>
          <AmendmentStatBar
            v-if="store.zoomAmendmentsStats.majority_count > 0"
            :data="store.zoomAmendmentsStats.majority_stats || {}"
          />
          <div v-else class="no-data">Pas d'amendements</div>
        </div>
        <div class="sub-tile">
          <p>
            Amendements proposés par le <strong>GOUVERNEMENT</strong> :
            {{ store.zoomAmendmentsStats.government_count }}
          </p>
          <AmendmentStatBar
            v-if="store.zoomAmendmentsStats.government_count"
            :data="store.zoomAmendmentsStats.government_stats || {}"
          />
          <div v-else class="no-data">Pas d'amendements</div>
        </div>
        <div class="sub-tile">
          <p>
            Amendements proposés par l'<strong>OPPOSITION</strong> :
            {{ store.zoomAmendmentsStats.opposition_count }}
          </p>
          <AmendmentStatBar
            v-if="store.zoomAmendmentsStats.opposition_count > 0"
            :data="store.zoomAmendmentsStats.opposition_stats || {}"
          />
          <div v-else class="no-data">Pas d'amendements</div>
        </div>
        <div class="sub-tile">
          <p>
            Amendement proposés par les <strong>RAPPORTEURS</strong>:
            {{ store.zoomAmendmentsStats.rapporteurs_count }}
          </p>
          <AmendmentStatBar
            v-if="store.zoomAmendmentsStats.rapporteurs_count > 0"
            :data="store.zoomAmendmentsStats.rapporteurs_stats || {}"
          />
          <div v-else class="no-data">Pas d'amendements</div>
        </div>
      </div>
    </div>
    <div class="card group-pie">
      <p>Amendements proposés par groupe</p>
      <PieChart
        v-if="amendmentsCount > 0"
        :height="500"
        :chart-data="groupChartData"
        :options="groupChartOptions"
      />
      <p v-else class="no-data">Pas d'amendments</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import { useLegiWorkStore } from "@/stores/legi-work";
import { PieChart } from "vue-chart-3";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import AmendmentStatBar from "@/components/legi-work/atoms/AmendmentStatBar.vue";

const store = useLegiWorkStore();
const route = useRoute();

const isCommission = computed(() => route.path.includes("commission"));

// Find text id based on the route (many routes use this component to render a full text)
const textRef = computed(() => {
  const step = store.legiWorkZoom.timeline[route.params.stepIdx];
  return isCommission.value ? step.texte_associe : step.debats_seance.text_in;
});

const amendmentsCount = computed(
  () => store.zoomAmendmentsStats.amendment_count
);

const totalAdoptedPercent = computed(
  () =>
    Math.round(
      10000 *
        ((store.zoomAmendmentsStats?.total_stats.adopté || 0) /
          amendmentsCount.value)
    ) / 100
);

const groupChartData = computed(() => {
  const data = store.zoomAmendmentsStats.groups_stats;

  if (!data) {
    return {
      labels: [],
      datasets: [],
    };
  }
  const labels = data.map((elem) => elem.acronym);

  return {
    labels,
    datasets: [
      {
        backgroundColor: data.map((elem) => elem.color),
        data: data.map((elem) => elem.count),
      },
    ],
  };
});

const groupChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "black",
        usePointStyle: true,
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

store.loadAmendmentsStats(
  textRef.value,
  isCommission.value ? "commissions" : "session"
);

const shouldDisplayStatsWrapper = computed(() => {
  // Check if any specific count properties are greater than 0
  const hasCountsGreaterThanZero =
    store.zoomAmendmentsStats.majority_count > 0 ||
    store.zoomAmendmentsStats.government_count > 0 ||
    store.zoomAmendmentsStats.opposition_count > 0 ||
    store.zoomAmendmentsStats.rapporteurs_count > 0;

  // If the above checks pass, we don't need to check the rest of the object
  if (hasCountsGreaterThanZero) return true;

  // Check if any property in the object is an object with a count property greater than 0
  return Object.values(store.zoomAmendmentsStats).some((stat) => {
    // Check if 'stat' is an object and has a 'count' property
    return typeof stat === "object" && stat !== null && stat.count > 0;
  });
});
</script>

<style scoped lang="scss">
img {
  max-width: 241.84px;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  font-size: 1.3rem;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
  small {
    font-size: 1em;
  }
}
.stats-wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: 3fr 1fr;

  text-align: center;
}

.sub-stats {
  margin-top: 15px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  .sub-tile {
    display: flex;
    flex-direction: column;

    .no-data {
      padding-top: 20px;
    }
  }
}

.group-pie {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>
