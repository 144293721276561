<template>
  <div class="search-field" :class="props.mode">
    <input
      ref="inputDom"
      type="search"
      placeholder="Chercher"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <span class="icon">
      <i class="fa-regular fa-magnifying-glass"></i>
    </span>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
  mode: {
    validator(value) {
      // The value must match one of these strings
      return ["purple", "blue", "topbar", "form-input"].includes(value);
    },
    default: "purple",
  },
  modelValue: {
    type: String,
    required: true,
  },
});
defineEmits(["update:modelValue"]);

const inputDom = ref(null);

onMounted(() => inputDom.value.focus());
</script>

<style lang="scss" scoped>
.purple,
.topbar,
.form-input {
  input {
    border: 1px solid var(--clr-p2);
    border-radius: 10px;

    &::placeholder {
      color: var(--clr-n8);
    }
  }
  .fa-magnifying-glass {
    color: var(--clr-n8);
  }
}

.blue {
  input {
    border: 1px solid var(--clr-s2);
    border-radius: 4px;

    &::placeholder {
      color: var(--clr-s9);
    }
  }
  .fa-magnifying-glass {
    color: var(--clr-s9);
  }
}

.search-field {
  position: relative;
  min-width: 200px;

  input {
    width: 100%;
    height: 30px;
    padding: 4px 0 3px 15px;
    background: white;

    &:focus::placeholder {
      color: transparent;
    }

    &:focus {
      border: 2px solid var(--clr-p4);
    }
  }

  .icon {
    position: absolute;
    right: 5%;
    top: 20%;

    .fa-magnifying-glass {
      cursor: pointer;
    }
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>
