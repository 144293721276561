<template>
  <div class="radio-button-group" :class="{ column }">
    <CheckboxRadioOption
      v-for="option in options"
      :key="option"
      :text="option.text"
      :value="option.value"
      :selected="modelValue"
      :disabled="option.disabled"
      @input="emit('update:modelValue', option.value)"
    />
  </div>
</template>

<script setup>
import CheckboxRadioOption from "../atoms/CheckboxRadioOption.vue";

defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  column: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);
</script>

<style lang="scss" scoped>
.radio-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.column {
  flex-direction: column;
}
</style>
