<template v-if="store.items.length">
  <div class="card">
    <h6>Liste des ministères</h6>
    <ul v-if="Object.keys(store.groupedSearchedMinistersByMinistries).length">
      <!-- eslint-disable vue/no-v-html -->
      <li
        v-for="item in Object.keys(store.groupedSearchedMinistersByMinistries)"
        :key="item"
        class="ministry"
        @click="selectMinistry(item)"
        v-html="generateHighlight(store.searchCurrent, item)"
      ></li>
      <!-- eslint-enable vue/no-v-html -->
    </ul>
    <div v-else class="no-ministry">
      Aucun ministère correspondant à la recherche
    </div>
  </div>
</template>

<script setup>
import { useCabinetsStore } from "@/stores/cabinets";
import { generateHighlight } from "@/utils/helper.utils";

const selectMinistry = (item) => {
  if (item) {
    const element = Array.from(
      document.querySelectorAll(".ministry-card")
    ).find((el) => el.textContent.includes(item));
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
};

const store = useCabinetsStore();
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 8px;
  border-radius: 2px;
}

.ministry:hover {
  cursor: pointer;
  background-color: var(--clr-s2);
}

.no-ministry {
  color: var(--clr-n7);
  font-weight: 400;
  line-height: 150%;
}
</style>
