<template>
  <div class="error">
    <img src="@/assets/img/Error.svg" alt="error" class="error-img" />
    <div class="error-title">Erreur</div>
    <div class="error-message">
      Une erreur s'est produite, rechargez la page et si l'erreur persiste
      veuillez nous contacter.
    </div>
    <div class="error-message bold">
      Code de l'erreur :
      <span class="error-code">{{ props.error.status }}</span>
    </div>
    <div class="error-message bold">
      Message d'erreur :
      <span class="error-code">{{ props.error.message }}</span>
    </div>
    <div class="error-action">
      <button class="btn-primary" @click="refresh">Recharger la page</button>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";

const props = defineProps({
  error: {
    type: Object,
    default: () => {
      return { status: 404, message: "Page non trouvée" };
    },
  },
});
function refresh() {
  router.go();
}
</script>

<style scoped lang="scss">
.error {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;

  .error-img {
    margin-bottom: 20px;
    width: 33%;
    height: 33%;
  }

  .error-title {
    font-size: 1.313em;
    font-weight: bold;
  }

  .error-message {
    color: var(--clr-e200);
    font-size: 1.188em;

    &.bold {
      font-size: 1.125em;
      font-weight: 600;
    }
  }

  .error-action {
    margin-top: 10px;
  }
}
</style>
