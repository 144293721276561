<template>
  <component
    :is="currentPlatformCardComponent"
    v-if="currentPlatformCardComponent"
    :document="item.document"
  />
</template>

<script setup>
import { computed } from "vue";
import HomepageAmendmentCard from "./HomepageAmendmentCard.vue";
// import HomepageArticleCard from "./HomepageArticleCard.vue";
import HomepageEventCard from "./HomepageEventCard.vue";
import HomepageJorfCard from "./HomepageJorfCard.vue";
import HomepageLegiCard from "./HomepageLegiCard.vue";
import HomepageNewsCard from "./HomepageNewsCard.vue";
import HomepageNominationCard from "./HomepageNominationCard.vue";
import HomepagePostCard from "./HomepagePostCard.vue";
import HomepageQuestionCard from "./HomepageQuestionCard.vue";
import HomepageReportCard from "./HomepageReportCard.vue";
import { useHomepageStore } from "@/stores/homepage";

const store = useHomepageStore();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const dynamicComponent = {
  [store.CORPUSES.AMENDMENTS]: HomepageAmendmentCard,
  // [store.CORPUSES.ARTICLES]: HomepageArticleCard, <!-- TODO : Add socials articles cards once backend is fixed (currently receive news with the "article" type)
  [store.CORPUSES.AGENDA]: HomepageEventCard,
  [store.CORPUSES.JORFS]: HomepageJorfCard,
  [store.CORPUSES.LEGIDOCS]: HomepageLegiCard,
  [store.CORPUSES.NEWS]: HomepageNewsCard,
  [store.CORPUSES.NOMINATIONS]: HomepageNominationCard,
  [store.CORPUSES.POSTS]: HomepagePostCard,
  [store.CORPUSES.QUESTIONS]: HomepageQuestionCard,
  [store.CORPUSES.REPORTS]: HomepageReportCard,
};

const currentDataType = computed(() => props.item.data_type);
const currentPlatformCardComponent = computed(() => {
  return dynamicComponent[currentDataType.value];
});
</script>
