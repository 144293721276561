<template>
  <div class="template">
    <QuestionsZoomParams />
    <LoaderFullPage v-if="store.loading" />
    <QuestionsZoomDatas v-else />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import QuestionsZoomParams from "@/components/questions/organisms/QuestionsZoomParams.vue";
import QuestionsZoomDatas from "@/components/questions/organisms/QuestionsZoomDatas.vue";
import { useQuestionsStore } from "@/stores/questions";

const store = useQuestionsStore();
const route = useRoute();

store.fetchOneQuestion(route.params._id);
</script>
