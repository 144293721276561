<template>
  <div class="englobe-params">
    <div class="infos">
      <i class="fa-regular fa-circle-info"></i>
      <p>
        Configurez vos alertes personnelles pour recevoir les nouveaux éléments
        de votre veille dans le canal de votre choix.<br />
        Choisissez votre canal de préférence ou mettez en pause vos alertes sur
        votre page
        <RouterLink to="/user-settings/notifications"
          >Préférences de notifications</RouterLink
        >.
      </p>
    </div>
    <div class="params-row">
      <button class="btn-primary" @click="openCreateDialog()">
        + Ajouter une alerte
      </button>
    </div>
  </div>
  <PopupDialog ref="createModal" title="Ajouter une alerte">
    <template #body>
      <AddEditAlertForm v-if="showForm" ref="createForm" />
    </template>
    <template #footer>
      <button v-if="showForm" class="btn-primary" @click="createAlert">
        Ajouter
      </button>
    </template>
  </PopupDialog>
</template>

<script setup>
import { ref } from "vue";
import { RouterLink } from "vue-router";
import AddEditAlertForm from "../molecules/AddEditAlertForm.vue";
import PopupDialog from "@/components/reusable/atoms/PopupDialog.vue";

const createModal = ref();
const showForm = ref(false);
const openCreateDialog = () => {
  showForm.value = true;
  createModal.value?.open();
};

const createForm = ref();
const createAlert = () => {
  if (createForm.value?.validate()) {
    createForm.value?.form.requestSubmit();
    showForm.value = false;
  }
};
</script>

<style lang="scss" scoped>
.infos {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.938em;
  font-weight: 600;

  p {
    margin: 0;
  }

  i {
    font-size: 1.35em;
  }
}
</style>
