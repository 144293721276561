<template>
  <LoaderFullPage v-if="isUserLoading" />
  <template v-else-if="!hasValidToken || route.name === 'Création de compte'">
    <RegisterView v-if="route.name === 'Création de compte'" />
    <LoginView v-else />
  </template>
  <template v-else>
    <AppStructure />
    <AppProductTour
      v-if="!productTourCompleted"
      @product-tour-complete="productTourComplete"
    />
  </template>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import AppStructure from "@/components/general/templates/AppStructure.vue";
import AppProductTour from "@/components/general/templates/AppProductTour.vue";
import LoginView from "./views/LoginView.vue";
import RegisterView from "./views/RegisterView.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import loadCrisp from "@/utils/load_crisp_chat.js";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useUserSettingsStore } from "@/stores/user-settings";

const authStore = useAuthStore();
const userStore = useUserStore();
const userSettingsStore = useUserSettingsStore();

const { hasValidToken } = storeToRefs(authStore);
const { productTourCompleted } = storeToRefs(userStore);

const hasFetchedUserTopbar = ref(false);
const hasFetchedUserSettings = ref(false);

const route = useRoute();

const isUserLoading = computed(
  () =>
    !authStore.hasCheckedToken ||
    (hasValidToken.value &&
      (!hasFetchedUserTopbar.value || !hasFetchedUserSettings.value))
);

const productTourComplete = () => {
  productTourCompleted.value = true;
};
// Alert the user that the application is not responsive
if (window.innerWidth < 800) {
  alert(
    "Cette application n'est pas optimisée pour les appareils mobiles. Nous vous recommandons d'utiliser l'application sur un ordinateur de bureau ou en tournant votre téléphone en mode paysage."
  );
}

// Check for authentication
// (Disable the check if using the auto-login flow)
const query = new URLSearchParams(window.location.search);
if (!query.get("auto_login_email") || !query.get("auto_login_code")) {
  authStore.checkCurrentToken();
}

watch(hasValidToken, async (newHasValidToken) => {
  if (newHasValidToken) {
    // Fetch user topbar info
    await userStore.getUserAndFilters();

    hasFetchedUserTopbar.value = true;

    // Fetch user info for Crisp
    await userSettingsStore.getUserSettings();

    hasFetchedUserSettings.value = true;

    if (import.meta.env.VITE_CRISP_ID) {
      // Set the crisp session ID to the user ID
      window.CRISP_TOKEN_ID = userSettingsStore.user._id;
      // Load the Crisp chat module if it's a new session otherwise show it
      if (!window.$crisp) {
        loadCrisp();
      } else {
        // Reset the session and show the chatbox
        window.$crisp.push(["do", "session:reset"]);
        window.$crisp.push(["do", "chat:show"]);
      }
      window.$crisp.push(["set", "session:event", ["user:login"]]);
    }

    if (window.$crisp) {
      // Configure callback when sending message
      window.$crisp.push([
        "on",
        "message:sent",
        () =>
          window.$crisp.push([
            "set",
            "session:event",
            [[["last_message_url", { url: window.location.href }, "pink"]]],
          ]),
      ]);

      // Send user info to Crisp
      window.$crisp.push(["set", "user:email", userSettingsStore.user.email]);
      window.$crisp.push([
        "set",
        "user:nickname",
        `${userSettingsStore.user.first_name} ${userSettingsStore.user.last_name}`,
      ]);
      window.$crisp.push([
        "set",
        "user:company",
        [
          userSettingsStore.user.organisation,
          {
            employment: [
              userSettingsStore.user.occupation,
              userSettingsStore.user.occupation,
            ],
          },
        ],
      ]);
    }
  }
});
</script>
