<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink to="/legislatives" class="back">
        <i class="fa-solid fa-chevron-left"></i> Dossiers législatifs
      </RouterLink>
    </div>
  </div>
</template>
