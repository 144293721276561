import { ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import {
  fetchSearchAuthors,
  fetchPublications,
} from "@/services/apiSocialMedia";
import { FOLLAWED_FEATURES, useUserStore } from "@/stores/user";
import { PLATFORM_TYPES, Platforms } from "@/utils/social360-platforms.js";

const initialPublicationsFilters = {
  from: 0, //* Page number
  size: 20, //* Number of items per page
  sort_field: "like", //* Sort field
  sort_order: "desc", //* Sort order
  highlight: true, //* Retrieve highlighted text based on queries/dashboard
  //* Other options include : “filter” string for search, “start” and “end” strings for dates (dd/mm/yyyy)
};

export const useSocialMediaStore = defineStore("publications", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const indexesFilters = ref([...PLATFORM_TYPES]);
  const bypassQuery = ref(false);
  const items = ref([]);
  const item_count = ref(null);
  const postsCounts = ref({});
  const filters = ref(null);
  const selectedFilters = ref({ ...initialPublicationsFilters });
  const loading = ref(false);
  const isAuthorFilterLoading = ref(false);
  const isFollawedActive = ref(false);

  const authors = ref([]);

  const getSearchAuthorsFilters = async (nameSearch) => {
    isAuthorFilterLoading.value = true;

    try {
      authors.value = await fetchSearchAuthors(nameSearch);
    } finally {
      isAuthorFilterLoading.value = false;
    }
  };

  const getPublicationsList = async (author_id = null) => {
    loading.value = true;

    //* Create the payload as a separate copy of selectedFilters so that the added sorters don't affect the original object
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
      indexes: indexesFilters.value,
      get_filters: true,
      exclude_retweets: true,
    };

    //* If there is author filters selected map them to only their ids
    if (payload.author?.length > 0) {
      payload.author = payload.author.map((author) => author._id);
    }

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (author_id) {
      payload.author = [author_id];
    }

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    if (payload.indexes?.includes(Platforms.TWITTER)) {
      payload.exclude_retweets = false;
    }

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.PUBLICATIONS];
    }

    try {
      const response = await fetchPublications(payload);

      if (response) {
        item_count.value = response.item_count;
        postsCounts.value = response.counts;
        items.value = response.items;
        filters.value = response.filters;
      }
    } finally {
      loading.value = false;
    }
  };

  const resetSearchAuthorsFilters = () => {
    authors.value = [];
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialPublicationsFilters };
  };

  const resetStore = () => {
    items.value = [];
    filters.value = null;
    selectedFilters.value = { ...initialPublicationsFilters };
    loading.value = false;

    item_count.value = null;
    postsCounts.value = {};
  };

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    getPublicationsList();
  };

  const route = useRoute();
  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        (route.name === "Publications" ||
          route.name === "Publications - Follawed")
      ) {
        selectedFilters.value.from = 0;
        getPublicationsList();
      }
    }
  );

  return {
    items,
    item_count,
    filters,
    selectedFilters,
    authors,
    getPublicationsList,
    resetFilters,
    resetSearchAuthorsFilters,
    resetStore,
    loading,
    isAuthorFilterLoading,
    getSearchAuthorsFilters,
    indexesFilters,
    bypassQuery,
    postsCounts,
    toggleFollawed,
    isFollawedActive,
  };
});
