<template>
  <AbstractFormInput v-bind="$props">
    <SelectFilter
      v-model="model"
      v-bind="{ items, name }"
      mode="form-input"
      :error="!!error"
    />
  </AbstractFormInput>
</template>

<script setup>
import { computed } from "vue";
import AbstractFormInput from "../abstract/AbstractFormInput.vue";
import SelectFilter from "../atoms/SelectFilter.vue";

const props = defineProps({
  modelValue: { type: String, required: true },
  label: { type: String, required: true },
  name: { type: String, required: true },
  tip: { type: String, default: null },
  error: { type: String, default: null },
  tooltip: { type: String, default: null },
  required: { type: Boolean, default: false },
  // Select
  items: { type: Array, required: true },
});
const emit = defineEmits(["update:modelValue"]);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});
</script>

<style lang="scss" scoped></style>
