<template>
  <div class="card zoom">
    <div v-if="topDate.platform === Platforms.TWITTER" class="header">
      <div class="row">
        <h3 class="title">{{ title }}</h3>
        <div class="tools">
          <RadioButtons
            :options="displayOptions"
            :active-option="activeDisplay"
            @select-option="changeDisplay($event)"
          />
          <button class="close-btn" @click="closesZoom">
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
      </div>
      <div class="row sorters">
        <!-- Tweets Sort-->
        <SortBy
          v-if="activeDisplay === VIEWS.PUBLICATIONS"
          :items="currentSortersNames"
          :selected-sort-item="currentSortLabel"
          @change-sort="handleSortChange"
        />
        <!-- Articles Sort -->
        <SortBy
          v-else
          :items="articlesSortersNames"
          :selected-sort-item="currentArticlesSortLabel"
          @change-sort="handleArticlesSortChange"
        />
      </div>
    </div>

    <div v-else class="header">
      <div class="row">
        <h3 class="title">{{ title }}</h3>

        <div class="row sorters">
          <SortBy
            v-if="activeDisplay === VIEWS.PUBLICATIONS"
            :items="currentSortersNames"
            :selected-sort-item="currentSortLabel"
            @change-sort="handleSortChange"
          />

          <button class="close-btn" @click="closesZoom">
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="body">
      <div v-if="isPublicationsLoading" class="loading-wrapper">
        <HollowDotsSpinner :animation-duration="1000" color="var(--clr-p6)" />
      </div>

      <template
        v-else-if="
          activeDisplay === VIEWS.PUBLICATIONS && topPublications?.length > 0
        "
      >
        <PublicationCard
          v-for="post in topPublications"
          :key="post._id"
          :post="post"
          is-overview
        />
      </template>

      <template
        v-else-if="activeDisplay === VIEWS.ARTICLES && topArticles.length > 0"
      >
        <XArticleCard
          v-for="article in topArticles"
          :key="article._id"
          :article="article"
        />
      </template>
      <div v-else class="no-data-wrapper"><NoData /></div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import dayjs from "dayjs";
import { HollowDotsSpinner } from "epic-spinners";

import XArticleCard from "@/components/social-media/molecules/XArticleCard.vue";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { Platforms } from "@/utils/social360-platforms.js";
import { getDateShortFormat } from "@/utils/formatDate";

const props = defineProps({
  authorId: { type: String, required: false, default: null },
  isPublicationsLoading: { type: Boolean, required: true },
  topArticles: { type: Array, required: false, default: null },
  topArticlesCount: { type: Number, default: 0 },
  topArticlesFilters: { type: Object, required: false, default: null },
  topDate: { type: Object, required: true },
  topPublications: { type: Array, required: false, default: null },
  topPublicationsCount: { type: Number, default: 0 },
});
const emit = defineEmits([
  "closesZoom",
  "updatePublicationsFilters",
  "updateTopArticlesFilters",
]);

const VIEWS = {
  PUBLICATIONS: "publications",
  ARTICLES: "links",
};

const displayOptions = [
  {
    label: "Publications",
    value: VIEWS.PUBLICATIONS,
  },
  {
    label: "Articles",
    value: VIEWS.ARTICLES,
  },
];

const activeDisplay = ref("publications");

const defaultSortOptions = { like: "Like" };
const sortOptionsByPlatform = {
  [Platforms.TWITTER]: {
    retweet: "Repartage",
    impression: "Impressions",
    ...defaultSortOptions,
  },
  [Platforms.TIKTOK]: defaultSortOptions,
  [Platforms.LINKEDIN]: defaultSortOptions,
  [Platforms.INSTAGRAM]: defaultSortOptions,
  [Platforms.BLUESKY]: defaultSortOptions,
};

const currentSortField = ref("like");
const currentSortersNames = computed(
  () => sortOptionsByPlatform[currentPlatform.value] || defaultSortOptions
);
const currentSortLabel = computed(
  () => currentSortersNames.value[currentSortField.value] || "Sélectionner"
);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(currentSortersNames.value).find(
    (key) => currentSortersNames.value[key] === selectedItem
  );

  if (selectedSortField && currentSortField.value !== selectedSortField) {
    currentSortField.value = selectedSortField;

    emit("updatePublicationsFilters", {
      sort_field: selectedSortField,
      sort_order: "desc",
    });
  }
};

const handleArticlesSortChange = (selectedItem) => {
  const selectedArticlesSortField = Object.keys(articlesSortersNames).find(
    (key) => articlesSortersNames[key] === selectedItem
  );

  if (
    selectedArticlesSortField &&
    currentArticlesSortField.value !== selectedArticlesSortField
  ) {
    currentArticlesSortField.value = selectedArticlesSortField;

    emit("updateTopArticlesFilters", {
      sort_field: currentArticlesSortField.value,
      sort_value: "desc",
    });
  }
};

// Articles Sorting
const articlesSortersNames = {
  number_of_tweets: "Publications",
  number_of_retweets: "Repartage",
};

const currentArticlesSortField = ref(
  Object.keys(props.topArticlesFilters.sort_field) ?? "Publications"
);

const currentArticlesSortLabel = computed(
  () => articlesSortersNames[currentArticlesSortField.value] || "Sélectionner"
);

const title = computed(() => {
  const formatDateToDay = getDateShortFormat(props.topDate.date.toLowerCase());
  const count =
    activeDisplay.value === "publications"
      ? props.topPublicationsCount
      : props.topArticlesCount;
  if (dayjs(formatDateToDay, "MMMM YYYY", true).isValid()) {
    return `Top ${Math.min(count, 10)} de ${formatDateToDay} :`;
  } else if (dayjs(formatDateToDay, "DD/MM/YYYY", true).isValid()) {
    return `Top ${Math.min(count, 10)} du ${dayjs(
      formatDateToDay,
      "DD/MM/YYYY"
    ).format("DD MMMM YYYY")} :`;
  } else {
    return `Top ${Math.min(count, 10)} du ${dayjs(
      formatDateToDay,
      "lll"
    ).format("DD MMMM YYYY à HH:mm")} :`;
  }
});

const changeDisplay = (event) => {
  activeDisplay.value = event;
};

const closesZoom = () => {
  emit("closesZoom");
};

const currentPlatform = computed(() => props.topDate.platform);
</script>

<style lang="scss" scoped>
.card.zoom {
  padding: 16px 4px 4px 4px;
}

.close-btn {
  font-size: 0.8em;
  width: 25px;
  height: 25px;
  padding: 4px;
  border-radius: 50%;

  &:hover {
    background-color: var(--clr-p5);
    color: white;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin-bottom: 5px;
  padding: 0 11px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.sorters {
      flex: 1;
      justify-content: flex-end;
      gap: 16px;
    }
  }

  .title {
    margin: 0;
    font-size: 1em;
  }

  .tools {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
  height: 400px;
  padding: 0;
  overflow-y: auto;

  .loading-wrapper,
  .no-data-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100%;
  }
}

/* Scrollbar
========================================================================== */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--clr-p4);
}
</style>
