<template>
  <div v-if="deputiesList.length > 0" class="deputies">
    <div class="deputies-title">
      <h5>{{ title }} :</h5>
      <ListGridRadioButtons
        :is-list-active="!isDisplayBadges"
        @toggle-display="isDisplayBadges = !isDisplayBadges"
      />
      <button
        v-if="deputiesList.length > LIST_LIMIT"
        class="btn-secondary"
        @click="() => (isFullList = !isFullList)"
      >
        Voir {{ isFullList ? "moins" : "plus" }}
      </button>
    </div>
    <div v-if="isDisplayBadges" class="deputies-picture-grid">
      <PersonBadge
        v-for="deputy in showDeputiesList"
        :key="deputy._id"
        :person="{ ...deputy, is_prm: true }"
      />
      <div v-if="deputiesList.length > LIST_LIMIT && !isFullList">
        + {{ deputiesList.length - LIST_LIMIT }}
      </div>
    </div>
    <p v-else class="deputies-list">
      <RouterLink
        v-for="(deputy, idx) in showDeputiesList"
        :key="deputy._id"
        :to="`/prm-national/${deputy._id}`"
        title="Page PRM"
        class="prm-link"
      >
        {{ deputy.name
        }}<span v-if="idx < showDeputiesList.length - 1">, </span>
      </RouterLink>
      <span v-if="deputiesList.length > LIST_LIMIT && !isFullList">, ...</span>
    </p>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import ListGridRadioButtons from "@/components/reusable/atoms/ListGridRadioButtons.vue";
import PersonBadge from "@/components/reusable/molecules/PersonBadge.vue";

const props = defineProps({
  deputiesList: { type: Array, required: true },
  title: { type: String, default: "Député(e)s" },
});

const LIST_LIMIT = 25;

const isFullList = ref(false);

const isDisplayBadges = ref(true);

const showDeputiesList = computed(() => {
  return isFullList.value
    ? props.deputiesList
    : props.deputiesList.slice(0, LIST_LIMIT);
});
</script>

<style scoped lang="scss">
h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--clr-s8);
}

.deputies-title {
  display: flex;
  align-items: center;
  gap: 8px;

  padding-bottom: 10px;
}

.deputies {
  p:first-of-type {
    margin-top: 0;
  }
  .deputies-list {
    margin-bottom: 0;
    font-weight: 300;
    text-transform: capitalize;

    .prm-link:hover {
      text-decoration: underline;
    }
  }

  .deputies-picture-grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
}
</style>
