import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { httpRequest } from "@/utils/request.utils";
import {
  getEndDateFromPreset,
  getStartDateFromPreset,
} from "@/utils/date.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

export const TIME_INTERVALS = {
  LAST_24H: "last_24h",
  LAST_WEEK: "last_week",
  LAST_MONTH: "last_month",
  LAST_YEAR: "last_year",
  LEGI_15: "legislature_15",
  LEGI_16: "legislature_16",
  LEGI_17: "legislature_17",
  FREE: "free",
};

export const FOLLAWED_FEATURES = {
  AMENDMENTS: "follawed_amendments",
  JORFS: "follawed_jorfs",
  LEGIDOCS: "follawed_legi_docs",
  QUESTIONS: "follawed_questions",
  PETITIONS: "follawed_petitions",
  TWEET_AUTHORS: "follawed_tweet_authors",
  PRM: "follawed_prms",
  COMMISSION: "follawed_commissions",
  LOBBIES: "follawed_lobbies",
  ORDRE_DU_JOUR_SN: "follawed_ordre_du_jour",
  FEUILLE_VERTE: "follawed_feuille_verte",
  COMPTES_RENDUS: "follawed_compte_rendus",
  AGENDA: "follawed_events",
  NEWS: "follawed_news",
  NOMINATIONS: "follawed_nominations",
  ARTICLES: "follawed_articles",
  PUBLICATIONS: "follawed_publications",
};

const toast = useToast();

export const useUserStore = defineStore("user", () => {
  const route = useRoute();
  const router = useRouter();

  const dashboards = ref([]);
  const thematics = ref([]);
  const subthematics = ref([]);
  const productTourCompleted = ref(false);

  const loading = ref(false);

  const selectedDashboard = ref(null);

  const selectedInterval = ref({
    selected_from_date: null,
    selected_to_date: null,
    selected_time_range: TIME_INTERVALS.LEGI_17,
  });

  const follawed = ref({
    [FOLLAWED_FEATURES.AMENDMENTS]: [],
    [FOLLAWED_FEATURES.JORFS]: [],
    [FOLLAWED_FEATURES.LEGIDOCS]: [],
    [FOLLAWED_FEATURES.QUESTIONS]: [],
    [FOLLAWED_FEATURES.PETITIONS]: [],
    [FOLLAWED_FEATURES.TWEET_AUTHORS]: [],
    [FOLLAWED_FEATURES.PRM]: [],
    [FOLLAWED_FEATURES.COMMISSION]: [],
    [FOLLAWED_FEATURES.LOBBIES]: [],
    [FOLLAWED_FEATURES.ORDRE_DU_JOUR_SN]: [],
    [FOLLAWED_FEATURES.FEUILLE_VERTE]: [],
    [FOLLAWED_FEATURES.COMPTES_RENDUS]: [],
    [FOLLAWED_FEATURES.AGENDA]: [],
    [FOLLAWED_FEATURES.NEWS]: [],
    [FOLLAWED_FEATURES.NOMINATIONS]: [],
    [FOLLAWED_FEATURES.ARTICLES]: [],
    [FOLLAWED_FEATURES.PUBLICATIONS]: [],
  });

  // Those filters are shared between services, and are local to the front-end
  // (not saved in the back-end as preference)
  const sharedFilters = ref({
    selected_keyword: "",
    selected_thematics: [],
    selected_subthematics: [],
  });

  const searchBoxCurrentValue = ref(sharedFilters.value.selected_keyword);

  const getUserAndFilters = async () => {
    loading.value = true;

    try {
      const response = await httpRequest("get", "/users/topbar");
      if (response.data) {
        dashboards.value = response.data.dashboards;
        thematics.value = response.data.thematics;
        subthematics.value = response.data.subthematics;
        productTourCompleted.value = response.data.product_tour_completed;

        await router.isReady();
        if (
          route.query.dashboard &&
          dashboards.value.find(
            (dashboard) => dashboard._id === parseInt(route.query.dashboard)
          )
        ) {
          selectedDashboard.value = parseInt(route.query.dashboard);
        } else {
          selectedDashboard.value = response.data.selected_dashboard;

          if (
            route.query.dashboard &&
            !dashboards.value.find(
              (dashboard) => dashboard._id === parseInt(route.query.dashboard)
            )
          ) {
            return router.push({
              path: "/forbidden",
              query: {
                accessTo: route.path,
                withDashboard: route.query.dashboard,
              },
            });
          }

          router.push({
            query: {
              ...route.query,
              dashboard: selectedDashboard.value,
            },
          });
        }
        if (typeof selectedDashboard.value !== "number") {
          toast.warning(
            "Vous n'avez pas sélectionné de veille.\nChoisissez une veille pour utiliser la plateforme."
          );
        }

        Object.keys(selectedInterval.value).forEach((key) => {
          selectedInterval.value[key] = response.data[key];
        });
        Object.keys(follawed.value).forEach((key) => {
          follawed.value[key] = response.data[key];
        });
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const updateFilters = async (payload) => {
    loading.value = true;

    if (
      payload.selected_time_range &&
      payload.selected_time_range !== TIME_INTERVALS.FREE
    ) {
      delete payload.selected_from_date;
      delete payload.selected_to_date;
    }

    try {
      await httpRequest("PATCH", `/users`, payload);
    } catch (err) {
      handleNetworkError(err);
    }

    loading.value = false;
  };

  const isItemFollawed = (feature, id) => {
    return follawed.value[feature]?.includes(id);
  };

  const toggleFollawed = async (feature, id) => {
    const isFollawed = isItemFollawed(feature, id);
    if (isFollawed) {
      follawed.value[feature] = follawed.value[feature].filter(
        (elem) => elem !== id
      );
    } else {
      follawed.value[feature] = [...(follawed.value[feature] || []), id];
    }

    const payload = {
      [feature]: follawed.value[feature],
    };

    try {
      await httpRequest("PATCH", `/users`, payload);
    } catch (err) {
      handleNetworkError(err);
    }
  };

  const sharedFiltersAsPayload = computed(() => {
    const payload = {};

    payload.dashboard_id = selectedDashboard.value;

    if (selectedInterval.value.selected_time_range === TIME_INTERVALS.FREE) {
      payload.start = selectedInterval.value.selected_from_date;
      payload.end = selectedInterval.value.selected_to_date;
    } else {
      payload.start =
        getStartDateFromPreset(selectedInterval.value.selected_time_range) +
        "T00:00:00+02:00";
      // End parameter is optional and only for some presets
      const end = getEndDateFromPreset(
        selectedInterval.value.selected_time_range
      );
      if (end) {
        payload.end = end + "T23:59:00+02:00";
      }
    }

    if (sharedFilters.value.selected_keyword?.length > 0) {
      payload.filter = sharedFilters.value.selected_keyword;
    }

    if (sharedFilters.value.selected_thematics?.length > 0) {
      payload.thematic_ids = sharedFilters.value.selected_thematics;
    }

    if (sharedFilters.value.selected_subthematics?.length > 0) {
      payload.subthematic_ids = sharedFilters.value.selected_subthematics;
    }

    return payload;
  });

  const selectedDashboardName = computed(() => {
    return dashboards.value.find((d) => d._id === selectedDashboard.value)
      ?.name;
  });

  const confirmSearchBox = () => {
    sharedFilters.value.selected_keyword = searchBoxCurrentValue.value;
  };

  watch(
    [
      () => route.query.search,
      () => route.query.thematics,
      () => route.query.subthematics,
    ],
    ([newSearch, newThematics, newSubthematics]) => {
      sharedFilters.value.selected_keyword = newSearch ?? "";
      searchBoxCurrentValue.value = newSearch ?? "";
      sharedFilters.value.selected_thematics = newThematics
        ? newThematics.split(",").map((id) => parseInt(id))
        : [];
      sharedFilters.value.selected_subthematics = newSubthematics
        ? newSubthematics.split(",").map((id) => parseInt(id))
        : [];
    }
  );

  return {
    dashboards,
    thematics,
    subthematics,
    selectedDashboard,
    selectedDashboardName,
    selectedInterval,
    follawed,
    sharedFilters,
    loading,
    productTourCompleted,
    searchBoxCurrentValue,
    sharedFiltersAsPayload,
    isItemFollawed,
    getUserAndFilters,
    updateFilters,
    confirmSearchBox,
    toggleFollawed,
  };
});
