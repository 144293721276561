<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          v-if="!store.isFollawedActive"
          filter="author"
          :items="store.authors"
          item-label="name"
          item-value="_id"
          :active-filters="selectedFilters.author"
          :is-loading="store.isAuthorFilterLoading"
          mode="purple"
          @search="fetchSearchAuthors"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetAuthorFilter"
        >
          Auteurs
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>
      <FollawedRadioButtons
        all-match-route="/posts"
        follawed-route="/posts/follawed"
      />
    </div>

    <div class="params-row request-and-check-all">
      <SwitchSlide :is-active="!store.bypassQuery" @toggle="toggleUseRequest">
        Utiliser la requête
      </SwitchSlide>
      <CheckBox
        :checked="isAllSelected"
        @change="
          () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
        "
      >
        Toutes les activités
      </CheckBox>
    </div>
    <div class="params-row">
      <div class="page-settings filters">
        <FilterCheckBox
          v-for="itemSocialMedia in itemsFiltersSocialMedia"
          :key="itemSocialMedia.label"
          :label="itemSocialMedia.label"
          :color="itemSocialMedia.color"
          :item-count="
            store.postsCounts[`${itemSocialMedia.indexes}_count`] || 0
          "
          :is-checked="indexesFilters.includes(itemSocialMedia.indexes)"
          @check="addFilter(itemSocialMedia.indexes)"
          @uncheck="removeFilter(itemSocialMedia.indexes)"
        />
      </div>
    </div>

    <div class="params-row">
      <TotalMatched :item-count="store.item_count" />

      <div class="page-settings">
        <SortBy
          :items="sortersNames"
          :selected-sort-item="currentSortLabel"
          @change-sort="handleSortChange"
        />
        <SelectItemsLimit
          :items-limit="selectedFilters.size"
          @change-limit="changeLimit"
        >
          Publication
        </SelectItemsLimit>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useSocialMediaStore } from "@/stores/social-media/posts";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import { Platforms, PLATFORM_TYPES } from "@/utils/social360-platforms.js";

const store = useSocialMediaStore();
const route = useRoute();

const { selectedFilters, indexesFilters } = storeToRefs(store);

// All sorters in wanted display order with their labels
const sortersNames = { created_at: "Date", like: "Engagement" };

// All filters in wanted display order with their labels
const filtersNames = {
  group: "Groupe",
  major_stakeholder: "Grande Partie Prenante",
  stakeholder: "Partie Prenante",
  level: "Niveau",
  circo_name: "Localité",
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  selectedFilters.value.author?.forEach((filter) => {
    arr = [
      ...arr,
      {
        filter: "author",
        item: filter,
      },
    ];
  });
  return arr;
});

const currentSortLabel = computed(
  () => sortersNames[selectedFilters.value.sort_field] || "Sélectionner"
);

const fetchPublications = async () => {
  selectedFilters.value.from = 0;
  await store.getPublicationsList(route.query.author_id ?? null);
};

const fetchSearchAuthors = (nameSearch) => {
  if (nameSearch.length > 0) {
    store.getSearchAuthorsFilters(nameSearch);
  } else {
    store.resetSearchAuthorsFilters();
  }
};

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (selectedFilters.value.sort_field !== selectedSortField) {
      selectedFilters.value.sort_field = selectedSortField;
      fetchPublications();
    }
  }
};

const selectAllFilters = () => {
  indexesFilters.value = [...PLATFORM_TYPES];
  store.getPublicationsList();
};

const unselectAllFilters = () => {
  indexesFilters.value = [];
  store.getPublicationsList();
};

const addFilter = (filter) => {
  indexesFilters.value.push(filter);
  store.getPublicationsList();
};

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchPublications();
};

const removeFilter = (filter) => {
  indexesFilters.value = indexesFilters.value.filter((f) => f !== filter);
  store.getPublicationsList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }

  fetchPublications();
};

const showScrollTop = computed(() => selectedFilters.value.size > 20);

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchPublications();
};

const resetAuthorFilter = () => {
  store.resetSearchAuthorsFilters();
  delete selectedFilters.value.author;
  fetchPublications();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchPublications();
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  fetchPublications();
};

const itemsFiltersSocialMedia = ref([
  {
    label: "𝕏",
    color: "var(--clr-twitter)",
    indexes: Platforms.TWITTER,
  },
  {
    label: "Linkedin",
    color: "var(--clr-linkedin)",
    indexes: Platforms.LINKEDIN,
  },
  {
    label: "Instagram",
    color: "var(--clr-instagram)",
    indexes: Platforms.INSTAGRAM,
  },
  {
    label: "Tiktok",
    color: "var(--clr-tiktok)",
    indexes: Platforms.TIKTOK,
  },
  {
    label: "Bluesky",
    color: "var(--clr-bluesky)",
    indexes: Platforms.BLUESKY,
  },
]);

const isAllSelected = computed(() => {
  return indexesFilters.value.length === Object.keys(Platforms)?.length;
});

const toggleUseRequest = () => {
  store.bypassQuery = !store.bypassQuery;
  store.getPublicationsList();
};
</script>

<style lang="scss" scoped>
.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}

.filters {
  gap: 10px;
}

.filter {
  width: auto;
  gap: 40px;
}
</style>
