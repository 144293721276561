<template>
  <dialog ref="dialog" class="popup-dialog" @click="closeDialog">
    <div class="dialog-content" @click="(e) => e.stopPropagation()">
      <div class="header">
        <h3>{{ title }}</h3>
        <CloseButton class="dialog-close" @click="closeDialog" />
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
      <div class="footer">
        <button class="btn-secondary" @click="closeDialog">Annuler</button>
        <slot name="footer"></slot>
      </div>
    </div>
  </dialog>
</template>

<script setup>
import { ref } from "vue";
import CloseButton from "./CloseButton.vue";

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["open", "close"]);

const dialog = ref();

const openDialog = () => {
  dialog.value.inert = true;
  dialog.value?.showModal();
  dialog.value.inert = false;
  emit("open");
};

const closeDialog = () => {
  dialog.value?.close();
  emit("close");
};

defineExpose({
  open: openDialog,
  close: closeDialog,
});
</script>

<style lang="scss" scoped>
.popup-dialog {
  box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  border: none;
  padding: 0;

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    width: fit-content;
    padding: 20px 40px;
    color: var(--clr-n8);
    background-color: white;
  }

  .header {
    .dialog-close {
      position: absolute;
      top: 17px;
      right: 13px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
</style>
