<template>
  <LoaderFullPage v-if="store.loading || userSettingsStore.loadingFetch" />
  <div v-else class="card wrapper">
    <section class="section">
      <div class="section-title">
        <div class="section-title-and-save-status">
          <h3>Canal de préférence</h3>
          <template v-if="showChannelSectionLoader"
            ><div v-if="store.isChannelLoading" class="save save-in-progress">
              <i class="fa-duotone fa-spinner-third fa-spin save-icon"></i>
              <p>En cours de sauvegarde</p>
            </div>
            <div v-else class="save save-done">
              <i class="fa-solid fa-circle-check save-icon"></i>
              <p>Changements sauvegardés</p>
            </div>
          </template>
        </div>
        <p>
          Le canal de préférence sera choisi comme canal par défaut lors de la
          création d'une nouvelle notification mais reste modifiable pour les
          alertes et les récapitulatifs.
        </p>
      </div>
      <div class="channel-configuration-row">
        <h4>Configuration du canal de préférence</h4>
        <p class="desc">
          Veuillez remplir les champs correspondant aux canaux que vous
          souhaitez sélectionner.
        </p>
        <div class="channels">
          <div class="channel">
            <div class="channel-label-row">
              <label>Email professionnel :</label>
            </div>
            <input
              id="email"
              v-model="userSettingsStore.user.email"
              type="text"
              disabled
            />
            <p class="desc">
              Les notifications emails sont toujours envoyées à votre adresse.
              Pour changer l'adresse mail liée à votre compte veuillez
              <a href="mailto:support@follaw.sv"> nous contacter. </a>
            </p>
          </div>
          <div class="channel">
            <div class="channel-label-row">
              <label>Id de canal Télégram :</label>
            </div>
            <input
              id="telegram"
              v-model="store.settings.telegram_channel_id"
              type="text"
              @input="debouncedPatchChannelConfig"
            />
            <div class="info-and-desc">
              <i class="fa-regular fa-circle-info"></i>
              <p class="desc">
                Vous pouvez retrouver l'ID sur le canal Follaw en invoquant la
                commande suivante: "/recevoirID". Scannez le qr code ci-contre
                pour un accès rapide au canal.
              </p>
            </div>
            <TelegramQrCode />
          </div>
          <div class="channel">
            <div class="channel-label-row">
              <label>Webhook Slack :</label>
              <a
                class="more-info"
                href="https://docs.google.com/document/d/1ggGyt41o3QekpkPJsSj-ENbaoiRectyo4HE5upFn2yU/edit?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
                title="Comment obtenir un webhook"
              >
                <i class="fa-regular fa-circle-info"></i>
              </a>
            </div>
            <input
              id="slack"
              v-model="store.settings.slack_webhook"
              type="text"
              @input="debouncedPatchChannelConfig"
            />
          </div>
          <div class="channel">
            <div class="channel-label-row">
              <label>Webhook Teams :</label>
              <a
                class="more-info"
                href="https://learn.microsoft.com/fr-be/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=newteams%2Cdotnet#create-an-incoming-webhook"
                rel="noopener noreferrer"
                target="_blank"
                title="Comment obtenir un webhook"
              >
                <i class="fa-regular fa-circle-info"></i>
              </a>
            </div>
            <input
              id="teams"
              v-model="store.settings.msteams_webhook"
              type="text"
              @input="debouncedPatchChannelConfig"
            />
          </div>
        </div>
      </div>
      <div class="channel-configuration-row">
        <h4>Choix du canal de préférence</h4>
        <div class="parameter-row with-underline">
          <div class="title-and-desc">
            <div class="icon-and-title">
              <i class="fa-solid fa-bell"></i>
              <h6>Alertes</h6>
            </div>
            <div class="desc">
              Les alertes permettent de recevoir les nouveaux éléments de votre
              veille dans le canal de votre choix.
            </div>

            <RouterLink to="/alerting/alerts">Voir mes alertes</RouterLink>
          </div>
          <NotificationTypesRadio
            v-model="store.settings.alert_notification_type"
            :telegram-disabled="store.settings.telegram_channel_id.length <= 0"
            :slack-disabled="store.settings.slack_webhook.length <= 0"
            :teams-disabled="store.settings.msteams_webhook.length <= 0"
            @value-changed="debouncedPatchChannelConfig"
          />
        </div>
      </div>

      <div class="parameter-row with-underline">
        <div class="title-and-desc">
          <div class="icon-and-title">
            <i class="fa-solid fa-clock"></i>
            <h6>Récapitulatifs</h6>
          </div>
          <div class="desc">
            Les récapitulatifs sont des résumés quotidiens ou hebdomadaires
            reprenant l'ensemble des nouveaux éléments sur la période écoulée.
          </div>
          <RouterLink to="/alerting/alerts">Voir mes récapitulatifs</RouterLink>
        </div>
        <NotificationTypesRadio
          v-model="store.settings.digest_notification_type"
          :telegram-disabled="store.settings.telegram_channel_id.length <= 0"
          :slack-disabled="store.settings.slack_webhook.length <= 0"
          :teams-disabled="store.settings.msteams_webhook.length <= 0"
          @value-changed="debouncedPatchChannelConfig"
        />
      </div>
      <div class="parameter-row">
        <div class="title-and-desc">
          <div class="icon-and-title">
            <img src="@/assets/img/alerting/follaw-up-icon.svg" />
            <h6>Follaw ups</h6>
          </div>
          <div class="desc">
            Les follaw ups sont les notifications liées aux éléments que vous
            avez Follawed (suivi). Elles sont envoyées lorsqu'une mise à jour
            est apportée à l'élément que vous suivez.
          </div>
        </div>
        <NotificationTypesRadio
          v-model="store.settings.followup_notification_type"
          with-no-notification-option
          :telegram-disabled="store.settings.telegram_channel_id.length <= 0"
          :slack-disabled="store.settings.slack_webhook.length <= 0"
          :teams-disabled="store.settings.msteams_webhook.length <= 0"
          @value-changed="debouncedPatchChannelConfig"
        />
      </div>
    </section>
    <section
      :title="
        isFollawupSectionDisabled
          ? 'Activez les follaw ups pour accéder à leur configuration'
          : ''
      "
      class="section"
      :class="{ disabled: isFollawupSectionDisabled }"
    >
      <div class="section-title">
        <div class="section-title-and-save-status">
          <h3>Configuration des Follaw ups</h3>
          <template v-if="showFollawupSectionLoader">
            <div
              v-if="store.isFollawupConfigLoading"
              class="save save-in-progress"
            >
              <i class="fa-duotone fa-spinner-third fa-spin save-icon"></i>
              <p>En cours de sauvegarde</p>
            </div>
            <div v-else class="save save-done">
              <i class="fa-solid fa-circle-check save-icon"></i>
              <p>Changements sauvegardés</p>
            </div>
          </template>
        </div>
        <p>
          Les Follaw ups sont les notifications liées aux éléments que vous avez
          Follawed (suivi). Elles sont envoyées lorsqu'une mise à jour est
          apportée à l'élément que vous suivez.
        </p>
      </div>
      <div class="parameter-row with-underline">
        <div class="title-and-desc">
          <div class="icon-and-title">
            <i class="fa-kit fa-dossierlegi"></i>
            <h4>Dossiers législatifs</h4>
          </div>
          <div class="desc">
            Mises à jour que vous souhaitez recevoir sur les dossiers
            législatifs
          </div>
        </div>
        <div class="legi-checks">
          <CheckBox
            :checked="store.hasFollowupService('legislation-amendment')"
            :disabled="isFollawupSectionDisabled"
            @click="
              () => {
                toggleFollawupService('legislation-amendment');
              }
            "
          >
            Mises à jour sur les amendements liés au dossier
          </CheckBox>
          <CheckBox
            :checked="store.hasFollowupService('legislation-rapport')"
            :disabled="isFollawupSectionDisabled"
            @click="
              () => {
                toggleFollawupService('legislation-rapport');
              }
            "
          >
            Mises à jour sur les rapports issus du dossier
          </CheckBox>
          <CheckBox
            :checked="store.hasFollowupService('legislation')"
            :disabled="isFollawupSectionDisabled"
            @click="
              () => {
                toggleFollawupService('legislation');
              }
            "
          >
            Autres mises à jour
          </CheckBox>
        </div>
      </div>
      <div class="parameter-row with-underline">
        <div class="title-and-desc">
          <div class="icon-and-title">
            <i class="fa-solid fa-file-signature"></i>
            <h4>Pétitions</h4>
          </div>
          <div class="desc">
            Seuil de nouvelles signatures à partir duquel vous souhaitez être
            alerté.
          </div>
        </div>
        <div class="input-and-desc">
          <input
            v-model="store.settings.petition_percentage"
            type="number"
            min="5"
            max="100"
            :disabled="isFollawupSectionDisabled"
            :class="petitionsError ? 'input-error' : ''"
            @input="debouncedPatchFollawUpsConfig"
          />
          <span v-if="petitionsError" class="error">
            <i class="fa-solid fa-ban"></i>
            Le seuil doit être de minimum 5%
          </span>
          <div class="percentage-label">%</div>
          <p>
            Pourcentage de signatures supplémentaires depuis la dernière mise à
            jour. Aucun follaw up ne sera envoyé en dessous de 200 signatures.
          </p>
        </div>
      </div>
      <div class="parameter-row">
        <div class="title-and-desc">
          <div class="icon-and-title">
            <i class="fa-solid fa-users"></i>
            <h4>PRM : Anniversaire</h4>
          </div>
          <div class="desc">
            Nombre de jours avant l'envoi d'un mail de rappel pour
            l'anniversaire d'une personnalité que vous avez follawed.
          </div>
        </div>
        <div class="input-and-desc">
          <input
            v-model="store.settings.days_before_prm_birthday"
            type="number"
            min="0"
            max="7"
            :disabled="isFollawupSectionDisabled"
            :class="{ 'input-error': anniversaryError }"
            @input="debouncedPatchFollawUpsConfig"
          />
          <span v-if="anniversaryError" class="error">
            <i class="fa-solid fa-ban"></i>
            Vous ne pouvez pas dépasser 7 jours
          </span>
          <p>Choisissez entre 0 (le jour même) et 7 jours.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useDebounceFn } from "@vueuse/core";

import {
  useAlertSettingsStore,
  NO_NOTIFICATION,
} from "@/stores/alert-settings";
import NotificationTypesRadio from "../atoms/NotificationTypesRadio.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import TelegramQrCode from "@/components/alerting/atoms/TelegramQrCode.vue";
import { useUserSettingsStore } from "@/stores/user-settings";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";

const store = useAlertSettingsStore();
const userSettingsStore = useUserSettingsStore();

const showChannelSectionLoader = ref(false);
const showFollawupSectionLoader = ref(false);

const isFollawupSectionDisabled = computed(
  () => store.settings.followup_notification_type === NO_NOTIFICATION
);

const petitionsError = computed(
  () =>
    store.settings.petition_percentage < 5 ||
    store.settings.petition_percentage > 100
);

const anniversaryError = computed(
  () =>
    store.settings.days_before_prm_birthday < 0 ||
    store.settings.days_before_prm_birthday > 7 ||
    store.settings.days_before_prm_birthday === ""
);

const debouncedPatchChannelConfig = useDebounceFn(() => {
  store.patchChannelConfig();
  showChannelSectionLoader.value = true;
}, 500);

const debouncedPatchFollawUpsConfig = useDebounceFn(() => {
  if (!petitionsError.value && !anniversaryError.value) {
    store.patchFollawUpsConfig();
    showFollawupSectionLoader.value = true;
  }
}, 500);

const toggleFollawupService = (followup) => {
  store.toggleFollowupService(followup);
  showFollawupSectionLoader.value = true;
};

store.fetchAlertSettings();
</script>

<style lang="scss" scoped>
h6 {
  margin: 0;
}
p {
  margin: 0;
}

input:disabled {
  cursor: not-allowed;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.parameter-row {
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding-bottom: 16px;
}

.legi-checks {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.input-and-desc {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .percentage-label {
    position: absolute;
    z-index: 2;
    left: 310px;
    top: 5px;
  }

  input {
    display: flex;
    width: 337px;
    padding: 4px 35px 4px 4px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--clr-p2);
    background: var(--clr-n2);
  }

  .input-error {
    display: flex;
    width: 337px;
    padding: 4px 35px 4px 4px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--clr-e300);
    background: var(--clr-n2);
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .section-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: solid 1px var(--clr-p2);

    .section-title-and-save-status {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .save {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }

      .save-in-progress {
        color: var(--clr-s7);
      }

      .save-done {
        color: var(--clr-g400);
      }

      .save-icon {
        width: 15px;
        height: 15px;
      }

      p {
        padding: 0;
      }
    }

    p {
      padding-bottom: 16px;
    }
  }

  .with-underline {
    border-bottom: solid 1px var(--clr-p2);
  }

  .channel-configuration-row {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .channels {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 32px;

      .channel {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 337px;

        .channel-label-row {
          display: flex;
          justify-content: space-between;

          a {
            color: var(--clr-p9);
          }
        }

        .info-and-desc {
          display: flex;
          flex-direction: row;
          gap: 4px;
        }
      }
    }
  }

  .title-and-desc {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 290px;
    min-width: 290px;
    .icon-and-title {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      i {
        width: 14px;
        height: 16px;
        color: var(--clr-s6);
      }

      h6 {
        font-size: var(--size-body);
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.desc {
  color: var(--clr-n7);
  font-size: var(--size-small);
}

.disabled {
  opacity: 0.45;

  &:hover {
    cursor: not-allowed;
  }

  input:hover {
    cursor: not-allowed;
  }
}

//hide input arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--clr-e300);
}

@media screen and (max-width: 1000px) {
  .parameter-row {
    flex-direction: column;
  }
  .section .title-and-desc {
    min-width: unset;
    width: 100%;
  }
}
</style>
