<template>
  <div class="template">
    <LegiWorkZoomParams />
    <LoaderFullPage v-if="store.loading" />
    <LegiWorkZoomDatas v-else />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";

import LegiWorkZoomParams from "@/components/legi-work/organisms/LegiWorkZoomParams.vue";
import LegiWorkZoomDatas from "@/components/legi-work/organisms/LegiWorkZoomDatas.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useLegiWorkStore } from "@/stores/legi-work";

const store = useLegiWorkStore();
const route = useRoute();

if (
  store.legiWorkZoom?.ref !== route.params.ref ||
  store.prevZoomUrlQuery !== JSON.stringify(route.query)
) {
  store.fetchOneLegiWork(route.params.ref);
}
</script>
