<template>
  <div class="template">
    <template v-if="userSettingsStore.hasPermission('SOCIETAL')">
      <PetitionsZoomParams />
      <LoaderFullPage v-if="store.loading" />
      <PetitionsZoomDatas v-else />
    </template>
    <template v-else>
      <PetitionFreemiumPopup />
    </template>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PetitionsZoomParams from "@/components/petitions/organisms/PetitionsZoomParams.vue";
import PetitionsZoomDatas from "@/components/petitions/organisms/PetitionsZoomDatas.vue";
import { usePetitionsStore } from "@/stores/petitions";
import { useUserSettingsStore } from "@/stores/user-settings";
import PetitionFreemiumPopup from "@/components/petitions/molecules/PetitionFreemiumPopup.vue";

const route = useRoute();
const store = usePetitionsStore();
const userSettingsStore = useUserSettingsStore();

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.fetchOnePetition(route.params.id);
}
</script>
