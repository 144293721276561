<template>
  <div
    v-on-click-outside="() => (isMenuOpen = false)"
    class="filter-multiples"
    :class="{ disabled: disabled, [mode]: true }"
  >
    <div class="select-box" @click="isMenuOpen = !isMenuOpen">
      <label>
        <slot name="label">Label</slot>
      </label>

      <div class="tools">
        <!-- selected marker with slot for custom badges -->
        <slot name="badge" :active-filters="activeFilters">
          <span v-if="activeFilters.length" class="nb-selected" :class="mode">
            {{ activeFilters.length }}
          </span>
        </slot>

        <div class="arrow">
          <i
            class="fa-solid"
            :class="isMenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen && !disabled" class="menu">
      <!-- SearchBar -->
      <SelectSearchBar
        v-if="hasSearch"
        v-model.trim="searchField"
        :mode="mode"
      />
      <!-- Selected items list -->
      <div v-if="activeFilters.length > 0" class="selected-list" @wheel.stop>
        <div
          v-for="(selectedItem, index) in activeFilters"
          :key="index"
          class="option"
          @click="uncheckFilter(getValue(selectedItem))"
        >
          <span class="check">
            <i class="fa-solid fa-square-check"></i>
          </span>
          <div class="text">
            {{ getLabel(selectedItem) }}
          </div>
        </div>
      </div>

      <hr v-if="activeFilters.length > 0" @wheel.prevent />

      <!-- Tip when no search -->
      <p v-if="searchField && searchedItems.length === 0" class="tip">
        Aucun résultat ne correspond à cette recherche
      </p>

      <!-- Option to select -->
      <div class="options-list">
        <div
          v-for="(item, index) in nonSelectedItems"
          :key="index"
          class="option"
          @click="checkFilter(item)"
        >
          <span class="check">
            <i class="fa-regular fa-square"></i>
          </span>
          <div class="text">
            {{ getLabel(item) }}
          </div>
        </div>
      </div>

      <!-- Reset -->
      <div class="reset">
        <button
          class="btn-reset btn-secondary"
          type="reset"
          :class="mode"
          @click="reset"
        >
          <i class="fa-solid fa-arrow-rotate-left"></i>
          <span class="label">Réinitialiser</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import SelectSearchBar from "@/components/reusable/atoms/SelectSearchBar.vue";
import { searchNormalized } from "@/utils/helper.utils";

const props = defineProps({
  filter: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  itemLabel: {
    type: String,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  activeFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  mode: {
    type: String,
    validator(value) {
      // The value must match one of these strings
      return ["purple", "blue", "topbar"].includes(value);
    },
    default: "purple",
  },
  hasSearch: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["resetFilter", "addFilterItem", "removeFilterItem"]);

const isMenuOpen = ref(false);
const searchField = ref("");

// Filtered items based on search query
const searchedItems = computed(() => {
  return props.items.filter((item) =>
    searchNormalized(
      props.itemLabel ? item[props.itemLabel] : item,
      searchField.value
    )
  );
});

// Non-selected items from the filtered list
const nonSelectedItems = computed(() => {
  return searchedItems.value.filter((item) => {
    const activeValues = props.itemValue
      ? props.activeFilters.map(getValue)
      : props.activeFilters;
    return !activeValues.includes(getValue(item));
  });
});

const getValue = (item) => (props.itemValue ? item[props.itemValue] : item);

const getLabel = (item) => (props.itemLabel ? item[props.itemLabel] : item);

const checkFilter = (item) => {
  emit("addFilterItem", { filter: props.filter, item: item });
};

const uncheckFilter = (item) => {
  emit("removeFilterItem", { filter: props.filter, item: item });
};

const reset = () => {
  searchField.value = "";
  emit("resetFilter", props.filter);
};

watch(
  () => props.disabled,
  () => {
    isMenuOpen.value = false;
  }
);
</script>

<style lang="scss" scoped>
/* Purple & Topbar styles
========================================================================== */
.purple,
.topbar {
  color: var(--clr-n8);
  .select-box {
    border-radius: 15px;
    background: white;
  }
  .menu {
    border-radius: 13px;
  }
  .nb-selected,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-p2);
  }
}

.topbar {
  .select-box {
    color: white;
    background: var(--clr-p8);
  }
  .menu {
    color: var(--clr-n8);
  }
}

/* Blue styles
========================================================================== */
.blue {
  color: var(--clr-s8);
  .select-box,
  .menu {
    border-radius: 4px;
    background: white;
  }
  .nb-selected,
  .btn-reset,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-s2);
  }
}

/* Whole component
========================================================================== */
.filter-multiples {
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  /* Input
========================================================================== */
  .select-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    height: 30px;
    box-shadow: 0 3px 5px #00000014;
    padding: 4px 15px;
    cursor: pointer;

    .tools {
      display: flex;
      gap: 8px;

      .nb-selected {
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 12px;
        min-width: 20px;
        height: 20px;
        line-height: initial;
        font-size: 14px;
      }
    }
  }

  &.disabled > .select-box {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

/* Popover Menu
========================================================================== */
.menu {
  position: absolute;
  z-index: 10;
  top: 33px;
  left: 0;
  box-shadow: 0 1px 6px #0000005d;
  max-height: 700px;
  min-width: 250px;
  width: 100%;
  padding: 5px;
  background: white;

  .selected-list {
    flex-shrink: 0;
    margin: 10px 0 0 8px;
    max-height: 120px;
    overflow-y: auto;
    overscroll-behavior: contain;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .options-list {
    margin: 10px 0 5px 8px;
    max-height: 343px;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;
  }
}

/* Individual items
========================================================================== */
.option {
  display: flex;
  line-height: 1.5;
  cursor: pointer;
  margin-right: 2px;
  padding: 0 4px;
  border-radius: 4px;

  .check {
    font-size: 1.063em;
  }

  .text {
    margin-left: 8px;
    text-transform: capitalize;
    overflow-wrap: anywhere;
  }

  &:hover {
    background-color: var(--clr-p1);
  }
}

.reset {
  display: flex;
  justify-content: flex-end;
  padding: 0 12px 4px 0;
}

.tip {
  margin: 4px 0 0 8px;
  padding: 2px;
  opacity: 0.6;
  font-style: italic;
}

.btn-reset {
  font-size: 0.9rem;

  .label {
    margin-left: 4px;
  }
}

/* Scrollbar
========================================================================== */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
}
</style>
