<template>
  <div class="template">
    <template v-if="userSettingsStore.hasPermission('SOCIETAL')">
      <PetitionsParams />
      <LoaderFullPage v-if="store.loading" />
      <PetitionsDatas v-else />
    </template>
    <template v-else>
      <PetitionFreemiumPopup />
    </template>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PetitionsParams from "@/components/petitions/organisms/PetitionsParams.vue";
import PetitionsDatas from "@/components/petitions/organisms/PetitionsDatas.vue";
import { usePetitionsStore } from "@/stores/petitions";
import { useUserSettingsStore } from "@/stores/user-settings";
import PetitionFreemiumPopup from "@/components/petitions/molecules/PetitionFreemiumPopup.vue";

const store = usePetitionsStore();
const router = useRouter();
const userSettingsStore = useUserSettingsStore();

store.isFollawedActive =
  router.currentRoute.value.fullPath.includes("follawed");

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.selectedFilters.from = 0;
  store.fetchPetitionsList();
}
</script>
