<template>
  <ScrollTop />
  <div class="content-wrapper">
    <div class="top-tweets">
      <TopPublications
        :is-loading="legiSocialMediaStore.isTotalFieldsLoading"
        :publication-count="
          legiSocialMediaStore.totalValues.total_publication_count
        "
        :total-share-count="legiSocialMediaStore.totalValues.total_share_count"
        :impressions-count="legiSocialMediaStore.totalValues.impression_count"
        @click-icon="() => router.push({ hash: '#posts-content' })"
      />
    </div>
    <div class="top-authors">
      <TopAuthors
        :is-loading="legiSocialMediaStore.isTotalFieldsLoading"
        :authors-count="legiSocialMediaStore.totalValues.authors_count"
        :authors-percent="authorsPercentOfPanel"
        @click-icon="() => router.push({ hash: '#posts-content' })"
      />
    </div>
    <div class="top-articles">
      <TopArticles
        :is-loading="legiSocialMediaStore.isTotalFieldsLoading"
        :articles-count="legiSocialMediaStore.totalValues.articles_count"
        @click-icon="() => router.push({ hash: '#articles-content' })"
      />
    </div>

    <div v-if="isPostsOrArticlesAvailable" class="timeline-tweets">
      <LegiWorkTimeCurve />
    </div>
    <!-- Stakeholder -->
    <div v-if="isPostsOrArticlesAvailable" class="stakeholder-graph">
      <div class="words-ranking">
        <GraphCurve
          :is-loading="legiSocialMediaStore.isStakeholderStatsLoading"
          :items="legiSocialMediaStore.stakeHoldersValues"
        />
      </div>
    </div>
    <!-- Levels Ranking -->
    <div v-if="isPostsOrArticlesAvailable" class="levels-ranking">
      <LevelsChart
        :is-loading="legiSocialMediaStore.isLevelsRankingLoading"
        :items="legiSocialMediaStore.levelsValues"
      />
    </div>

    <div v-if="isPostsOrArticlesAvailable" class="hemicycle-groups">
      <GroupsRanking
        :is-loading="legiSocialMediaStore.isGroupsLoading"
        :groups="legiSocialMediaStore.groupsRanking"
      />
    </div>

    <div
      v-if="isPostsOrArticlesAvailable"
      id="posts-content"
      class="card latest-posts"
    >
      <h4 class="title-medium-semibold latest-posts__title">
        Dernières publications
      </h4>
      <ul
        v-if="legiSocialMediaStore.latestPublications?.length > 0"
        class="latest__list"
      >
        <li
          v-for="post in legiSocialMediaStore.latestPublications"
          :key="post._id"
          class="latest__item"
        >
          <PublicationCard :post="post" />
        </li>
      </ul>
      <NoData v-else />
    </div>
    <div
      v-if="isPostsOrArticlesAvailable"
      id="articles-content"
      class="card latest-articles"
    >
      <div class="latest-articles__title">
        <h4 class="title-medium-semibold">Articles les plus partagés</h4>
        <i
          class="fa-brands fa-lg fa-square-x-twitter"
          :style="'color: var(--clr-twitter)'"
        />
      </div>
      <ul
        v-if="legiSocialMediaStore.latestArticles?.length > 0"
        class="latest__list"
      >
        <li
          v-for="article in legiSocialMediaStore.latestArticles"
          :key="article._id"
          class="latest__item"
        >
          <XArticleCard :article="article" :is-overview="true" />
        </li>
      </ul>
      <NoData v-else />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import GroupsRanking from "@/components/social-media/overview/molecules/GroupsRanking.vue";
import TopPublications from "@/components/social-media/overview/molecules/TopPublications.vue";
import TopAuthors from "@/components/social-media/overview/molecules/TopAuthors.vue";
import TopArticles from "@/components/social-media/overview/molecules/TopArticles.vue";
import LevelsChart from "@/components/social-media/overview/molecules/LevelsChart.vue";
import GraphCurve from "@/components/social-media/molecules/GraphCurve.vue";
import XArticleCard from "@/components/social-media/molecules/XArticleCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import LegiWorkTimeCurve from "@/components/legi-work/organisms/LegiWorkTimeCurve.vue";
import { useLegiSocialMediaStore } from "@/stores/legi-social-media";
import { useLegiWorkStore } from "@/stores/legi-work";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";

const router = useRouter();

const legiWorkStore = useLegiWorkStore();
const legiSocialMediaStore = useLegiSocialMediaStore();
legiSocialMediaStore.doc_ref = legiWorkStore.legiWorkZoom.ref;

const authorsPercentOfPanel = computed(() =>
  Math.round(
    (100 * legiSocialMediaStore.totalValues.authors_count) /
      legiSocialMediaStore.totalValues.authors_total
  )
);
const isPostsOrArticlesAvailable = computed(() => {
  return (
    legiSocialMediaStore.totalValues.total_publication_count > 0 ||
    legiSocialMediaStore.totalValues.articles_count > 0
  );
});

legiSocialMediaStore.fetchOverview();
</script>

<style scoped lang="scss">
.card {
  padding: 16px;
}
.content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

@container (width < 875px) {
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.timeline-tweets,
.hemicycle-groups {
  grid-column: span 6;
}

.top-tweets,
.stakeholder-graph,
.levels-ranking,
.words-ranking,
.latest-posts,
.latest-articles {
  grid-column: span 3;
}

.top-authors {
  grid-column: span 2;
}

.top-articles {
  grid-column: span 1;
}

.latest-posts {
  display: flex;
  flex-direction: column;
}

.latest__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.latest-articles__title {
  display: flex;
  align-items: center;
  gap: 4px;
}

.latest-posts__title,
.latest-articles__title {
  margin-bottom: 8px;
}
</style>
