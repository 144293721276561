<template>
  <div class="card">
    <div class="title-row">
      <div>
        <h4>
          {{
            scrutinData.title
              .replace(/^la motion de /, "Motion de ")
              .replace("\.$", ".")
          }}
        </h4>
        <p>Date : {{ getDateLongFormat(data493.date) }}</p>
        <p>
          <span v-if="scrutinData.result === 'rejeté'">
            La mention de censure n'a pas été adoptée
          </span>
          <span v-else>La mention de censure a été adoptée</span>
        </p>
      </div>
      <div class="pdf-link">
        <BaseLink :href="data493.decision_motion.texte_associe_url">
          <div class="pdf-link-column">
            <i class="fa-solid fa-file-pdf"></i>Motion de censure
          </div>
        </BaseLink>
      </div>
    </div>
  </div>

  <div v-if="listOfCensureAuthors.length > 0" class="card">
    <h4>Signataires</h4>
    <p>
      <span
        v-for="name in listOfCensureAuthors"
        :key="name"
        class="author-name"
        >{{ name }}</span
      >
    </p>
  </div>
</template>

<script setup>
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import { getDateLongFormat } from "@/utils/formatDate";
import { computed } from "vue";

const props = defineProps({
  scrutinData: { type: Object, required: true },
  data493: { type: Object, required: true },
});

const listOfCensureAuthors = computed(() =>
  props.data493.authors.map((a) => a.name)
);
</script>

<style scoped lang="scss">
.card {
  color: var(--clr-s8);
}

.title-row {
  display: flex;
  justify-content: space-between;

  .pdf-link {
    flex-shrink: 0;
    height: 100%;

    .pdf-link-column {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      color: var(--clr-link-default);

      i {
        font-size: 1.4rem;
      }
    }
  }
}

.author-name {
  text-transform: capitalize;
  &:not(:last-child)::after {
    content: " ; ";
  }
}

h4 {
  margin: 0;
}
</style>
