<template>
  <div class="card" :class="wrapperClass">
    <div class="main">
      <RouterLink v-if="titleLinkTo" :to="titleLinkTo">
        <i class="big-icon" :class="icon"></i>
      </RouterLink>
      <div class="label-col">
        <div class="big-value">{{ SINumberFormatter(value) }}</div>
        <div class="label">{{ label }}</div>
        <button
          v-if="Object.keys(subtypeValues).length > 0"
          class="toggle-see-more"
          @click="isOpen = !isOpen"
        >
          <i class="fa-solid fa-ellipsis"></i>
        </button>
      </div>
    </div>
    <div v-if="isOpen" class="more">
      <ul class="subtypes-list">
        <li v-for="subT in Object.keys(subtypeValues)" :key="subT">
          <div class="count">{{ subtypeValues[subT] }}</div>
          <div class="sub-type">{{ subT }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { SINumberFormatter } from "@/utils/helper.utils";

defineProps({
  label: { type: String, required: true },
  icon: { type: String, required: true },
  subtypeValues: { type: Object, default: () => ({}) },
  titleLinkTo: { type: String, default: "" },
  value: { type: Number, required: true },
  wrapperClass: { type: String, required: true },
});

const isOpen = ref(false);
</script>

<style scoped lang="scss">
.card {
  height: 100%;
}

.main {
  display: flex;
  justify-content: space-between;

  .big-icon {
    width: 65px;
    height: 65px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2.6rem;
    background-color: var(--active-color);
    color: white;
  }

  .label-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .label {
      color: var(--active-color);
    }
    .big-value {
      color: var(--active-color);
      font-size: 2rem;
    }

    .toggle-see-more {
      color: var(--active-color);
    }
  }
}

a .fa-questions,
a .fa-am {
  &:hover,
  &:active {
    opacity: 0.7;
  }
}
.subtypes-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
  column-gap: 10px;

  li {
    display: flex;
    gap: 10px;

    .count {
      color: var(--active-color);
    }
  }
}
</style>
