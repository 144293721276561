<template>
  <div v-if="store.loadingAdditionalInfo"><LoaderFullPage /></div>
  <ul v-else-if="store.zoomAdditionalInfo?.length > 0">
    <li
      v-for="addInfo in store.zoomAdditionalInfo"
      :key="addInfo.texte_associe"
    >
      <AdditionalInfoCard :additional-info="addInfo" />
    </li>
  </ul>
  <div v-else class="empty">
    <img
      src="@/assets/img/legislation/empty-states/infos-suppls-legi.svg"
      aria-hidden="true"
    />
    <p>Pas d'infos supplémentaires à afficher</p>
  </div>
</template>

<script setup>
import { useLegiWorkStore } from "@/stores/legi-work";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import AdditionalInfoCard from "@/components/legi-work/atoms/AdditionalInfoCard.vue";

const store = useLegiWorkStore();

store.loadAdditionalInfo();
</script>

<style scoped lang="scss">
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  li {
    text-align: center;
  }
}
img {
  max-width: 289px;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 15px;
  font-size: 1.3rem;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
}
</style>
