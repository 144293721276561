<template>
  <div class="collapse collapse-item" :class="{ 'is-active': active }">
    <div class="collapse-header" @click.prevent="active = !active">
      <slot name="title" :active="active"></slot>
      <transition name="rotate" mode="out-in">
        <i v-if="active" key="up" class="fa-solid fa-chevron-up"></i>
        <i v-else key="down" class="fa-solid fa-chevron-down"></i>
      </transition>
    </div>
    <div v-show="active" class="collapse-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  initialActive: { type: Boolean, default: true },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const active = ref(props.initialActive); // OK to lose reactivity because we only use the prop to initialize

function open() {
  active.value = true;
}

function close() {
  active.value = false;
}

defineExpose({ open, close });
</script>

<style lang="scss" scoped>
.collapse-header {
  display: inline-flex;
  align-items: center;
  margin: 10px 0 10px 0;
  font-weight: 600;
  cursor: pointer;

  width: 100%;

  .fa-solid {
    margin-left: 10px;
  }
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease-in-out;
}

.rotate-leave-to {
  transform: rotateZ(180deg);
}
</style>
