<template>
  <div class="card ministry-card">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <h4 class="ministry-name" v-html="processedMinistryName"></h4>
    <div class="ministers-wrapper">
      <MinisterCabinetCard
        v-for="cabinet in store.groupedSearchedMinistersByMinistries[
          ministryName
        ]"
        :key="cabinet._id"
        :cabinet="cabinet"
      />
    </div>
  </div>
</template>

<script setup>
import MinisterCabinetCard from "../molecules/MinisterCabinetCard.vue";
import { useCabinetsStore } from "@/stores/cabinets";
import { computed } from "vue";
import { generateHighlight } from "@/utils/helper.utils";

const store = useCabinetsStore();

const props = defineProps({
  ministryName: {
    type: String,
    required: true,
  },
});

const processedMinistryName = computed(() =>
  generateHighlight(
    store.searchCurrent,
    store.groupedSearchedMinistersByMinistries[props.ministryName][0].ministry
  )
);
</script>

<style lang="scss" scoped>
.ministers-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ministry-name {
  color: var(--clr-n8);
  margin: 0;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
}

@container (min-width: 1200px) {
  .ministers-wrapper {
    display: grid;
    gap: 8px 8px;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
