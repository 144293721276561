<template>
  <RouterRadioButtonsVue :options="routeOptions" />

  <RouterView />
</template>

<script setup>
import RouterRadioButtonsVue from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { RouterView, useRoute } from "vue-router";

const route = useRoute();
const { ref, stepIdx } = route.params;
const baseRoute = `/legislatives/${ref}/timeline/${stepIdx}/seance/`;

const routeOptions = [
  {
    label: "Texte entier",
    value: `${baseRoute}full-text`,
  },
  {
    label: "Amendements",
    value: `${baseRoute}amendments`,
  },
  {
    label: "Statistiques",
    value: `${baseRoute}stats`,
  },
];
</script>
