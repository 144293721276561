import dayjs from "dayjs";

export function getDateShortFormat(isoDate) {
  return dayjs(isoDate).format("DD/MM/YYYY");
}

export function getDateTimeShortFormat(isoDate) {
  return dayjs(isoDate).format("DD/MM/YYYY h:mm");
}

export function getDayTimeShortFormat(isoDate) {
  return dayjs(isoDate).format("DD/MM/YYYY HH:mm");
}

export function getDateTimeHourFormat(isoDate) {
  return dayjs(isoDate).format("D MMM YYYY HH:mm");
}

export function getDateLongFormat(isoDate) {
  return dayjs(isoDate).format("DD MMMM YYYY");
}

export function getDateTimeLongFormat(isoDate) {
  return dayjs(isoDate).format("dddd DD/MM/YYYY HH:mm");
}

export function getDayTimeFormat(isoDate) {
  return dayjs(isoDate).format("lll");
}

export function getHourTimeFormat(isoDate) {
  return dayjs(isoDate).format("HH:mm");
}

export function getMonthYearFormat(isoDate) {
  return dayjs(isoDate).format("MMM YYYY");
}
