<template>
  <div class="englobe-params">
    <FiltersMenu
      :active-filters="activeFilters"
      :is-loading="!store.filters"
      @remove-filter="store.removeFilterItem"
      @reset-all-filters="store.resetFilters"
    >
      <SearchAsYouTypeFilter
        v-if="!store.isFollawedActive"
        filter="author"
        :items="store.authors"
        item-label="name"
        item-value="_id"
        :active-filters="selectedFilters.author"
        :is-loading="store.isAuthorFilterLoading"
        mode="purple"
        @search="fetchSearchAuthors"
        @add-filter-item="store.addFilterItem"
        @remove-filter-item="store.removeFilterItem"
        @reset-filter="store.resetAuthorFilter"
      >
        Auteurs
      </SearchAsYouTypeFilter>
      <SelectMultipleCounter
        v-for="(value, key) in filtersNames"
        :key="key"
        :filter="key"
        :items="store.filters[key]"
        :active-filters="selectedFilters[key]"
        mode="purple"
        has-search
        @add-filter-item="store.addFilterItem"
        @remove-filter-item="store.removeFilterItem"
        @reset-filter="store.resetFilter"
      >
        {{ value }}
      </SelectMultipleCounter>
    </FiltersMenu>

    <div class="params-row request-and-check-all">
      <SwitchSlide :is-active="!store.bypassQuery" @toggle="toggleUseRequest">
        Utiliser la requête
      </SwitchSlide>
      <CheckBox
        :checked="isAllSelected"
        @change="
          () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
        "
      >
        Toutes les activités
      </CheckBox>
    </div>
    <div class="params-row">
      <div class="page-settings filters">
        <FilterCheckBox
          v-for="itemSocialMedia in itemsFiltersSocialMedia"
          :key="itemSocialMedia.label"
          :label="itemSocialMedia.label"
          :color="itemSocialMedia.color"
          :is-checked="indexesFilters.includes(itemSocialMedia.indexes)"
          @check="addFilter(itemSocialMedia.indexes)"
          @uncheck="removeFilter(itemSocialMedia.indexes)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useSocialMediaStatsStore } from "@/stores/social-media/statistics";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import { Platforms, PLATFORM_TYPES } from "@/utils/social360-platforms.js";

const store = useSocialMediaStatsStore();
const { selectedFilters, indexesFilters } = storeToRefs(store);

// All filters in wanted display order with their labels
const filtersNames = {
  group: "Groupe",
  major_stakeholder: "Grande Partie Prenante",
  stakeholder: "Partie Prenante",
  level: "Niveau",
  circo_name: "Localité",
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  selectedFilters.value.author?.forEach((filter) => {
    arr = [
      ...arr,
      {
        filter: "author",
        item: filter,
      },
    ];
  });
  return arr;
});

const fetchSearchAuthors = (nameSearch) => {
  if (nameSearch.length > 0) {
    store.getSearchAuthorsFilters(nameSearch);
  } else {
    store.resetSearchAuthorsFilters();
  }
};

const itemsFiltersSocialMedia = ref([
  {
    label: "𝕏",
    color: "var(--clr-twitter)",
    indexes: Platforms.TWITTER,
  },
  {
    label: "Linkedin",
    color: "var(--clr-linkedin)",
    indexes: Platforms.LINKEDIN,
  },
  {
    label: "Instagram",
    color: "var(--clr-instagram)",
    indexes: Platforms.INSTAGRAM,
  },
  {
    label: "Tiktok",
    color: "var(--clr-tiktok)",
    indexes: Platforms.TIKTOK,
  },
  {
    label: "Bluesky",
    color: "var(--clr-bluesky)",
    indexes: Platforms.BLUESKY,
  },
]);

const selectAllFilters = () => {
  indexesFilters.value = [...PLATFORM_TYPES];
  store.fetchOverview();
};

const addFilter = (filter) => {
  indexesFilters.value.push(filter);
  store.fetchOverview();
};

const removeFilter = (filter) => {
  indexesFilters.value = indexesFilters.value.filter((f) => f !== filter);
  store.fetchOverview();
};

const unselectAllFilters = () => {
  indexesFilters.value = [];
  store.fetchOverview();
};

const isAllSelected = computed(() => {
  return indexesFilters.value.length === Object.keys(Platforms)?.length;
});

const toggleUseRequest = () => {
  store.bypassQuery = !store.bypassQuery;
  store.fetchOverview();
};
</script>

<style lang="scss" scoped>
.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}
.filters {
  gap: 10px;
}

.filter {
  width: auto;
  gap: 40px;
}
</style>
