<template>
  <div class="template">
    <PrmParams />
    <LoaderFullPage v-if="store.loading" />
    <template v-else>
      <PrmDatas v-if="!isFollawedView" />
      <RouterView v-else />
    </template>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PrmParams from "@/components/prm/organisms/PrmParams.vue";
import PrmDatas from "@/components/prm/organisms/PrmDatas.vue";
import { usePrmStore } from "@/stores/prm.js";
import { computed } from "vue";

const store = usePrmStore();
const route = useRoute();

const isFollawedView = computed(() => route.fullPath.includes("follawed"));

if (route.fullPath.includes("regional")) {
  store.isRegional = true;
} else {
  store.isRegional = false;
}

if (route.fullPath.includes("follawed")) {
  store.isFollawedActive = true;

  // if the route contains news or activities (hence is the follawed/news or follawed/activities) their component will handle the fetching
  if (
    !route.fullPath.includes("news") &&
    !route.fullPath.includes("activities")
  ) {
    store.fetchPrmList();
  }
} else {
  store.isFollawedActive = false;
  store.filters = null;
  store.fetchPrmList();
}
</script>
