<template>
  <DigestsParams />
  <LoaderFullPage v-if="store.loading" />
  <DigestsListing />
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import DigestsParams from "@/components/alerting/organisms/DigestsParams.vue";
import DigestsListing from "@/components/alerting/organisms/DigestsListing.vue";
import { useDigestsStore } from "@/stores/digests.js";

const store = useDigestsStore();
store.readDigests();
</script>
