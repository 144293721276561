<template>
  <SocialsViewWrapper>
    <PublicationsParams />
    <LoaderFullPage v-if="store.loading" />
    <PublicationsData v-else />
  </SocialsViewWrapper>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import SocialsViewWrapper from "@/components/social-media/templates/SocialsViewWrapper.vue";
import PublicationsParams from "@/components/social-media/posts/organisms/PublicationsParams.vue";
import PublicationsData from "@/components/social-media/posts/organisms/PublicationsData.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useSocialMediaStore } from "@/stores/social-media/posts";
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useSocialMediaStore();
const userSettingsStore = useUserSettingsStore();
const router = useRouter();

store.isFollawedActive =
  router.currentRoute.value.fullPath.includes("follawed");

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.selectedFilters.from = 0;
  store.getPublicationsList(useRoute().query.author_id ?? null);
}
</script>
