<template>
  <div class="template">
    <ReportsParam />
    <LoaderFullPage v-if="store.loading" />
    <ReportsDatas v-else />
  </div>
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import ReportsParam from "@/components/legi-reports/templates/ReportsParams.vue";
import ReportsDatas from "@/components/legi-reports/templates/ReportsDatas.vue";
import { useLegiReportStore } from "@/stores/legi-reports";

const store = useLegiReportStore();

store.selectedFilters.from = 0;
store.fetchLegiReportsList();
</script>
