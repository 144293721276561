import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { httpRequest } from "@/utils/request.utils";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialJorfsFilters = {
  size: 20,
  from: 0,
  get_filters: true,
};

// Defining store
export const useJorfsStore = defineStore("jorfs", () => {
  const route = useRoute();
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);

  // Listing
  const items = ref([]);
  const item_count = ref(null);

  // Zoom
  const jorfZoom = ref(null);
  const previousJorfID = ref(null);
  const nextJorfID = ref(null);

  // filters
  const filters = ref(null);
  const selectedFilters = ref({ ...initialJorfsFilters });
  const isFollawedActive = ref(false);

  const fetchJorfsList = async () => {
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.JORFS];
    }

    try {
      const response = await httpRequest("post", "/jorfs", payload);

      if (response.data) {
        items.value = response.data.items;
        item_count.value = response.data.item_count;
        filters.value = response.data.filters;
      }
    } catch (err) {
      items.value = [];
      item_count.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOneJorf = async (jorfId) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", `/jorfs-zoom`, {
        _id: [jorfId],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      //* set the zoom and get the previous and next ones ID if all went well
      jorfZoom.value = response.data.items[0];
      const current = items.value.indexOf(
        items.value.find((item) => item._id === jorfZoom.value._id)
      );
      previousJorfID.value = items.value[current - 1]?._id;
      nextJorfID.value = items.value[current + 1]?._id;
    } catch (err) {
      //* set error if error
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };
  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });
  const resetFilters = () => {
    selectedFilters.value = { ...initialJorfsFilters };
  };

  const resetStore = () => {
    items.value = [];
    selectedFilters.value = { ...initialJorfsFilters };
    filters.value = null;
    loading.value = false;

    item_count.value = null;
  };

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchJorfsList();
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        (route.name === "Journal Officiel" ||
          route.name === "Journal Officiel - Follawed")
      ) {
        selectedFilters.value.from = 0;
        fetchJorfsList();
      }
    }
  );

  return {
    loading,
    items,
    item_count,
    jorfZoom,
    previousJorfID,
    nextJorfID,
    filters,
    selectedFilters,
    isFollawedActive,
    hasActiveFilters,
    fetchJorfsList,
    fetchOneJorf,
    resetFilters,
    resetStore,
    toggleFollawed,
  };
});
