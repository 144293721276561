<template>
  <div class="template">
    <LobbiesParams />
    <LoaderFullPage v-if="store.loading" />
    <template v-else>
      <LobbiesDatas v-if="!store.isFollawedActive" />
      <RouterView v-else />
    </template>
  </div>
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import LobbiesParams from "@/components/lobbies/organisms/LobbiesParams.vue";
import LobbiesDatas from "@/components/lobbies/organisms/LobbiesDatas.vue";
import { useLobbiesStore } from "@/stores/lobbies.js";
import { useRoute } from "vue-router";

const store = useLobbiesStore();
const route = useRoute();

store.selectedFilters.from = 0;
if (route.fullPath.includes("follawed")) {
  store.isFollawedActive = true;
} else {
  store.isFollawedActive = false;
  store.filters = null;
}
store.readLobbies();
</script>
