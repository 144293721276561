<template>
  <template v-if="store.items.length">
    <!-- Cards -->
    <div class="cards-wrapper">
      <PrmCard
        v-for="prospect in store.items"
        :key="prospect._id"
        :prospect="prospect"
      ></PrmCard>
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="store.itemCount"
      :key="`${store.itemCount}${store.selectedFilters.from}${store.selectedFilters.size}`"
      :total-items="store.itemCount"
      :current-page="store.selectedFilters.from"
      :items-limit="store.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-else-if="store.hasActiveFilters" />
  <NoDataFollawed v-else-if="store.isFollawedActive" />
  <NoData v-else />
</template>

<script setup>
import PrmCard from "../molecules/PrmCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";

import { usePrmStore } from "@/stores/prm";

const store = usePrmStore();

const changeCurrentPage = (from) => {
  store.selectedFilters.from = from;
  store.fetchPrmList();
};
</script>

<style lang="scss" scoped>
.cards-wrapper {
  container-type: normal;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 10px;
}
</style>
