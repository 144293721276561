<template>
  <ScrollTop />
  <div class="card">
    <template v-if="store.prmZoom.biographie">
      <h3>Historique des mandats</h3>
      <!-- eslint-disable vue/no-v-html -->
      <div class="biography typo-body-regular" v-html="biographyContent"></div>
      <!-- eslint-enable vue/no-v-html -->
    </template>

    <template v-else>
      <NoData />
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { usePrmStore } from "@/stores/prm";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import { marked } from "marked";

const store = usePrmStore();
const biographyContent = computed(() =>
  marked.parse(store.prmZoom.biographie || "")
);
</script>

<style lang="scss" scoped>
.card {
  padding: 15px 20px;
  height: fit-content;
  line-height: 21.82px;

  h3 {
    font-size: 1em;
  }
}

.biography {
  :deep(p) {
    margin: 1rem 0;
  }
}
</style>
