<template>
  <div
    class="card collapse collapse-item"
    :class="{ 'is-active': active, 'secondary-color': isSecondaryColor }"
  >
    <div class="collapse-header" @click.prevent="active = !active">
      <span class="title" :class="{ 'secondary-color': isSecondaryColor }"
        >Occurrences :</span
      >
      <div>
        <span>{{ active ? "Voir moins" : "Voir plus" }}</span>
        <transition name="rotate" mode="out-in">
          <i v-if="active" key="up" class="fa-solid fa-chevron-up"></i>
          <i v-else key="down" class="fa-solid fa-chevron-down"></i>
        </transition>
      </div>
    </div>
    <div v-show="active" class="collapse-content">
      <ul class="occurrences-list">
        <li v-for="[word, count] in listOfOccurrences" :key="word">
          <mark class="highlight skip-focus">{{ word }}</mark
          >: {{ count }} fois
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  occurrences: {
    type: Object,
    required: true,
  },
  isSecondaryColor: {
    type: Boolean,
    default: false,
  },
});

const active = ref(true);

const listOfOccurrences = computed(() =>
  // Keep the 10 words with most occurrences
  Object.entries(props.occurrences)
    .sort(([, val1], [, val2]) => val2 - val1)
    .filter((_, idx) => idx < 10)
);
</script>

<style lang="scss" scoped>
.collapse {
  background-color: var(--clr-n2);
  &.secondary-color {
    color: var(--clr-s8);
  }
}

.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;

  .title {
    font-weight: 400;
    color: var(--clr-p5);
    &.secondary-color {
      color: var(--clr-s8);
    }
  }

  .fa-solid {
    margin-left: 10px;
  }
}

.collapse-content {
  .occurrences-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease-in-out;
}

.rotate-leave-to {
  transform: rotateZ(180deg);
}
</style>
