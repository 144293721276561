<template>
  <AbstractCard
    color="var(--clr-service-legidoc)"
    icon="fa-kit fa-dossierlegi"
    follawed-name="follawed_legi_docs"
    :doc-id="legiWork._id"
    :target-url="linkUrl"
    has-follaw-button
    :idx="idx"
  >
    <template #header>
      Déposé le : {{ getDateShortFormat(legiWork.depot_initial) }}
    </template>
    <template #content>
      <LegiCardContent :legi-work="legiWork" />
    </template>
  </AbstractCard>
</template>
<script setup>
import { computed } from "vue";
import AbstractCard from "../../reusable/abstract/AbstractCorpusCard.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import LegiCardContent from "@/components/legi-work/molecules/LegiCardContent.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
const props = defineProps({
  legiWork: {
    type: Object,
    required: true,
  },
  idx: {
    type: Number,
    required: false,
    default: 0,
  },
});

const isFollawed = computed(() => {
  if (!userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]) {
    return false;
  }
  return userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]?.includes(
    props.legiWork._id
  );
});

const linkUrl = computed(() =>
  isFollawed.value
    ? `/legislatives/${props.legiWork.ref}/timeline`
    : `/legislatives/${props.legiWork.ref}/expose-motifs`
);
</script>
