<template>
  <!-- Stats components -->
  <div class="counter-stats-wrapper">
    <StatCounterCard
      :is-loading="questionsStore.isQuestionsStatLoading"
      :description="'Nombre de questions'"
      :count-number="questionsStore.questionsCount"
    />
    <StatCounterCard
      :is-loading="questionsStore.isQuestionsStatLoading"
      :description="'Nombre de Réponses'"
      :count-number="questionsStore.responsesCount"
    />
  </div>
  <div class="table-graph-wrapper">
    <!-- Table -->
    <table
      v-if="
        questionsStore.top3Ministries.length &&
        !questionsStore.isQuestionsStatLoading
      "
      class="card top3-ministry-table"
    >
      <thead>
        <tr>
          <th>Ministères les plus interrogés</th>
          <th>Nombre de questions</th>
          <th>Pourcentage de réponses</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(ministry, key, index) in questionsStore.top3Ministries"
          :key="index"
          class="top3-ministries-row"
        >
          <td>{{ key + 1 }} {{ ministry.ministry }}</td>
          <td>
            {{ ministry.question_count }}
          </td>
          <td>
            {{
              (
                (ministry.response_count / ministry.question_count) *
                100
              ).toFixed()
            }}
            %
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else-if="questionsStore.isQuestionsStatLoading"
      class="card spinner-wrapper"
    >
      <div class="spinner">
        <hollow-dots-spinner
          :animation-duration="1000"
          color="var(--clr-service-twitter)"
        />
      </div>
    </div>

    <NoData v-else />
    <!-- Chart -->
    <div class="card piechart-wrapper">
      <span v-if="!questionsStore.isQuestionsStatLoading"
        >Type de question</span
      >
      <div v-if="questionsStore.isQuestionsStatLoading" class="spinner">
        <hollow-dots-spinner
          :animation-duration="1000"
          color="var(--clr-service-twitter)"
        />
      </div>

      <PieChart
        v-else-if="
          pieChartData.labels.length > 0 && pieChartData.datasets.length > 0
        "
        :height="200"
        :chart-data="pieChartData"
        :options="pieChartOptions"
        :plugins="[ChartDataLabels]"
        class="piechart"
      />
      <div v-else>
        <div class="no-data-wrapper">
          <NoData />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { HollowDotsSpinner } from "epic-spinners";
import { PieChart } from "vue-chart-3";

import StatCounterCard from "../atoms/StatCounterCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useQuestionsStore } from "@/stores/questions";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const questionsStore = useQuestionsStore();
const totalQuestionsTypeCount = computed(() => {
  return questionsStore.questionsType.reduce((total, currentType) => {
    return total + currentType.question_count;
  }, 0);
});

const pieChartData = computed(() => {
  const data = questionsStore.questionsType;

  if (!data) {
    return {
      labels: [],
      datasets: [],
    };
  }
  const labels = data.map((element) =>
    capitalizeFirstLetter(element.question_type)
  );

  return {
    labels,
    datasets: [
      {
        backgroundColor: ["#6BD8BF", "#6973ED", "#BB39FF"],
        data: data.map((element) => element.question_count),
      },
    ],
  };
});

const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      formatter: (value) => {
        // Calculate the percentage
        const total = totalQuestionsTypeCount.value;
        const percent = ((value / total) * 100).toFixed();
        return percent > 5 ? `${percent}%` : "";
      },
      color: "white",
      font: {
        size: 12,
      },
    },
    legend: {
      position: "right",
      labels: {
        color: "#0A2E65",
        usePointStyle: true,
        font: {
          size: 12,
        },
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};
</script>
<style scoped lang="scss">
.top3-ministry-table {
  pointer-events: none;
}
.top3-ministry-table > thead > tr > th:nth-child(1) {
  text-align: left;
}
.top3-ministries-row > td:nth-child(2),
td:nth-child(3) {
  text-align: center;
}
.spinner-wrapper .spinner,
.piechart-wrapper .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
}

.counter-stats-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.table-graph-wrapper {
  display: grid;
  grid-template-columns: 2.02fr 1fr;
  gap: 11px;
  table {
    th,
    tr,
    td {
      color: var(--clr-s8);
      font-weight: 300;
      vertical-align: middle;
    }
    thead {
      border-bottom: 2px solid var(--clr-s1);
      th {
        padding: 1em 0 1em 1em;
      }
    }
    tbody > tr:nth-child(1),
    :nth-child(2) {
      border-bottom: 1px solid var(--clr-s1);
    }
    tbody td {
      padding: 1em 0 1em 1em;
      height: 4em;
    }
  }
}
.piechart-wrapper {
  font-weight: 300;
  color: var(--clr-s8);
  text-align: center;
}
.piechart {
  padding: 1.2em;
}
</style>
