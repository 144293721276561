<template>
  <button
    class="export-btn"
    :class="{ disabled: disabled }"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot></slot>
    <i
      v-if="userSettingsStore.hasPermission('BASIC')"
      :class="`fa-solid fa-${iconName} icon`"
    ></i>
    <PermissionLevelIcon
      v-else
      class="permission-indicator"
      permission-level="BASIC"
    />
  </button>
  <FreemiumUpgradePopup
    ref="lockedExportPopup"
    :closable="true"
    title="Export des données"
  >
    <p>Ce contenu est réservé aux abonnements payants.</p>
    <p>
      En activant celui-ci, vous pouvez exporter les données facilitant ainsi
      leur intégration dans vos rapports et analyses.
    </p>
  </FreemiumUpgradePopup>
</template>

<script setup>
import { ref } from "vue";
import PermissionLevelIcon from "@/components/general/atoms/PermissionLevelIcon.vue";
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";
import { useUserSettingsStore } from "@/stores/user-settings";

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["click"]);

const userSettingsStore = useUserSettingsStore();

const lockedExportPopup = ref();
const handleClick = () => {
  if (props.disabled) {
    return;
  }
  if (userSettingsStore.hasPermission("BASIC")) {
    emit("click");
  } else {
    lockedExportPopup.value.open();
  }
};
</script>

<style lang="scss" scoped>
.export-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: var(--shd-card);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 3px 10px;
  background: white;
  font-size: 0.938em;

  &:enabled {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--clr-p5);
    }

    &:active {
      background-color: var(--clr-p2);
    }
  }

  &:disabled {
    opacity: 0.65;
  }

  .icon {
    color: var(--clr-s7);
  }

  :deep(.permission-indicator) {
    width: 15px;
  }
}

p {
  margin: 0;
}
</style>
