<template>
  <div class="template">
    <LoaderFullPage v-if="questionStore.loading || prmStore.loading" />
    <template v-else>
      <BreadCrumbs :crumbs="crumbsArray" />
      <QuestionsZoomCard
        v-if="questionStore.questionZoom"
        :question="questionStore.questionZoom"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import BreadCrumbs from "@/components/reusable/atoms/BreadCrumbs.vue";
import QuestionsZoomCard from "@/components/questions/atoms/QuestionsZoomCard.vue";

import { usePrmStore } from "@/stores/prm";
import { useQuestionsStore } from "@/stores/questions";

const prmStore = usePrmStore();
const questionStore = useQuestionsStore();
const route = useRoute();

const crumbsArray = computed(() => [
  {
    text: "PRM",
    to: "/prm-national",
  },
  route.params._id === "follawed"
    ? {
        text: "Activités Follawed",
        to: "/prm-national/follawed/activities",
      }
    : {
        text: prmStore.prmZoom?.name ?? "Politique",
        to: `/prm-national/${route.params._id}/activities?`,
      },
  {
    text: "Question",
  },
]);

// Get the PRM to display the name in the breadcrumbs
if (
  route.params._id !== "follawed" &&
  prmStore.prmZoom?._id !== route.params._id
) {
  prmStore.fetchOnePersonnality(route.params._id);
}

questionStore.fetchOneQuestion(route.params.questionId);
</script>
