<template>
  <LineChart :chart-data="lineChartData" :options="options" />
</template>

<script setup>
import { computed } from "vue";
import { LineChart } from "vue-chart-3";
import dayjs from "dayjs";

const props = defineProps({
  signaturesHistory: {
    type: Array,
    required: true,
  },
});

const options = {
  responsive: true,
  plugins: {
    legend: { display: false },
  },
};

const lineChartData = computed(() => ({
  labels: props.signaturesHistory.map((element) =>
    dayjs(element.date).format("DD/MM/YYYY")
  ),
  datasets: [
    {
      label: "Signatures",
      indexAxis: "x",
      backgroundColor: "#6C0073",
      data: props.signaturesHistory.map((element) => element.signatures_number),
    },
  ],
}));
</script>
