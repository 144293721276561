<template>
  <button class="btn" title="Télécharger en Excel" @click="emit('onClick')">
    <i class="fa-solid fa-file-spreadsheet" />
  </button>
</template>

<script setup>
const emit = defineEmits(["onClick"]);
</script>

<style scoped lang="scss">
.btn {
  font-size: 1.5rem;
  color: var(--clr-s6);
  transition: scale 0.1s ease;

  &:hover {
    scale: 1.2;
  }
}
</style>
