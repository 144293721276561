<template>
  <div class="card">
    <div class="card-header">
      <div class="header-title">Nuage de mots</div>
      <div class="header-tools">
        <RadioButtons
          :options="
            Object.entries(datasetOptions).map(([key, val]) => ({
              label: val.label,
              value: key,
            }))
          "
          :active-option="activeDataset"
          @select-option="(value) => (activeDataset = value)"
        />
      </div>
    </div>

    <!-- Loader  -->
    <div v-if="props.isLoading">
      <div class="loading-wrapper">
        <hollow-dots-spinner
          :animation-duration="1000"
          :color="'var(--clr-p6)'"
        />
      </div>
    </div>
    <!-- Custom Legend -->
    <div
      v-else-if="Object.keys(wordcloudData).length > 0"
      class="wordcloud-wrapper"
    >
      <WordCloud
        :color="datasetOptions[activeDataset]?.color"
        :words="Object.entries(wordcloudData)"
        @click-word="(word) => $emit('clickWord', word)"
      />
    </div>
    <div v-else>
      <div class="no-data-wrapper">
        <NoData />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { HollowDotsSpinner } from "epic-spinners";

import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import WordCloud from "@/components/social-media/atoms/WordCloud.vue";

const props = defineProps({
  rankingValues: {
    type: Object,
    default: () => ({}),
  },
  isLoading: { type: Boolean, required: true },
});

const activeDataset = ref("top_words");

const wordcloudData = computed(() => {
  if (activeDataset.value === "all") {
    return {
      ...props.rankingValues.top_words,
      ...props.rankingValues.top_hashtags,
      ...props.rankingValues.top_mentions,
    };
  }

  if (activeDataset.value === "top_hashtags") {
    // Data cleanup:
    // Add # chars to hashtags if missing
    const hashtags = Object.keys(props.rankingValues.top_hashtags);
    const dataset = {};
    hashtags.forEach((hashtag) => {
      const h = hashtag.startsWith("#") ? hashtag : `#${hashtag}`;
      dataset[h] = hashtags[h];
    });
    return dataset;
  }

  return props.rankingValues[activeDataset.value] || {};
});

const datasetOptions = {
  top_words: { label: "Mots", color: "var(--clr-twitter-words)" },
  top_hashtags: { label: "Hashtags", color: "var(--clr-twitter-hashtag)" },
  top_mentions: { label: "Mentions", color: "var(--clr-twitter-mentions)" },
  all: { label: "Tout", color: "var(--clr-p4)" },
};

defineEmits(["clickWord"]);
</script>

<style scoped lang="scss">
.card-header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 8px 0;

  .header-title {
    font-size: 1.0625em;
    font-weight: 600;
    color: black;
  }
}

.wordcloud-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wrapper,
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
</style>
