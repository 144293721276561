<template>
  <li class="toc-subitem" @click="goToArticle(content.title)">
    {{ content.title || "Article sans titre" }}
    <span v-if="highlightCount > 0" class="toc-occurrences-count" />
  </li>

  <!-- If the item has sub-elements, display them recursively -->
  <ul v-if="hasSubItems" class="nested-subitem">
    <TableOfContentsSubItem
      v-for="(subItem, index) in content.subItems"
      :key="index"
      :content="subItem"
      :occurrences="occurrences"
    />
  </ul>
</template>

<script setup>
import { computed } from "vue";
import { goToArticle, hasHighlight } from "@/utils/browse-articles";

const props = defineProps({
  content: { type: Object, required: true },
  occurrences: { type: Object, required: true, default: () => ({}) },
});

const hasSubItems = computed(() => {
  return props.content.subItems && props.content.subItems.length > 0;
});

const highlightCount = computed(() => {
  return props.content.content ? hasHighlight(props.content.content) : 0;
});
</script>

<style lang="scss" scoped>
.toc-subitem {
  margin: 0 0 4px 32px;
  width: fit-content;
  list-style: square;
  &:hover {
    cursor: pointer;
    color: var(--clr-s7);
    text-decoration: underline;
  }
}

.toc-occurrences-count {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 8px;
  background-color: var(--clr-highlight);
}

.nested-subitem {
  padding-left: 16px;
}
</style>
