<template>
  <div class="stats-wrapper">
    <div class="card">
      <p class="card-title">Nombre d'amendements</p>
      <div v-if="store.isStatsLoading">
        <hollow-dots-spinner :animation-duration="1000" color="var(--clr-s6)" />
      </div>
      <p v-else class="card-number">
        {{
          !isNaN(store.amendmentsCount)
            ? formatNumbers(store.amendmentsCount)
            : "Aucune donnée"
        }}
      </p>
    </div>
    <div class="card">
      <p class="card-title">Nombre de dossiers touchés</p>
      <div v-if="store.isStatsLoading">
        <hollow-dots-spinner :animation-duration="1000" color="var(--clr-s6)" />
      </div>
      <p v-else class="card-number">
        {{
          !isNaN(store?.legidocCount)
            ? formatNumbers(store.legidocCount)
            : "Aucune donnée"
        }}
      </p>
    </div>
    <div class="card chart">
      <!-- <p class="card-title">Type d'action</p> -->
      <div v-if="store.isStatsLoading" class="spinner-wrapper">
        <hollow-dots-spinner :animation-duration="1000" color="var(--clr-s6)" />
      </div>
      <div v-else class="card-chart">
        <PieChart
          v-if="
            pieChartData.labels.length > 0 && pieChartData.datasets.length > 0
          "
          :chart-data="pieChartData"
          :options="pieChartOptions"
          class="piechart"
        />
        <NoData v-else />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { PieChart } from "vue-chart-3";
import { HollowDotsSpinner } from "epic-spinners";

import NoData from "@/components/reusable/atoms/NoData.vue";
import formatNumbers from "@/utils/formatNumbers";
import { useAmendmentsStore } from "@/stores/amendments";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const store = useAmendmentsStore();

//* Pie chart
const pieChartData = computed(() => {
  const data = Object.entries(store.actionTypesCount).map(([key, val]) => ({
    action_type: key,
    action_count: val,
  }));

  if (!data) {
    return {
      labels: [],
      datasets: [],
    };
  }

  let actionSum = 0;
  data.forEach((entry) => (actionSum += entry.action_count));

  const labels = data.map(
    (element) =>
      capitalizeFirstLetter(element.action_type) +
      " : " +
      ((element.action_count / actionSum) * 100).toFixed(2) +
      "%"
  );

  const TYPE_TO_COLOR_MAP = {
    // colors from the d3 color schemes
    // https://observablehq.com/@d3/color-schemes
    "insertions et compléments": "#4e79a7",
    substitutions: "#f28e2c",
    suppressions: "#e15759",
    "amendement d'appel": "#76b7b2",
    "articles additionnels": "#59a14f",
    "nouvelle rédaction": "#edc949",
    rétablir: "#af7aa1",
  };

  return {
    labels,
    datasets: [
      {
        backgroundColor: data.map(
          (element) => TYPE_TO_COLOR_MAP[element.action_type] || "grey"
        ),
        data: data.map((element) => element.action_count),
      },
    ],
  };
});

const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
      title: {
        display: true,
        text: "Type d'action",
        color: "#0a2e65",
        font: {
          family: "DM Sans",
          size: 17,
        },
      },
      labels: {
        color: "#0A2E65",
        usePointStyle: true,
        font: {
          family: "DM Sans",
          size: 10,
        },
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 10px;
  color: var(--clr-s8);
}
.card {
  display: flex;
  flex-direction: column;
  height: 200px;
  position: relative;
  justify-content: space-evenly;
  align-items: center;

  .card-title {
    font-size: 1.063em;
    margin: 0;
  }

  .card-number {
    font-size: 2em;
  }

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .card-chart {
    width: 100%;
    height: 100%;
  }
  .piechart {
    width: 100%;
    height: 100%;
  }
}
</style>
