<template>
  <div class="template">
    <LobbyZoomParams />
    <LoaderFullPage v-if="store.loading" />
    <LobbyZoomDatas v-else />
  </div>
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import LobbyZoomParams from "@/components/lobbies/organisms/LobbyZoomParams.vue";
import LobbyZoomDatas from "@/components/lobbies/organisms/LobbyZoomDatas.vue";
import { useLobbiesStore } from "@/stores/lobbies.js";
import { useRoute } from "vue-router";
const route = useRoute();
const store = useLobbiesStore();

if (store.lobbyZoom?.national_id !== route.params._id) {
  store.readOneLobby(route.params._id);
}
</script>
