<template>
  <PopupDialog ref="addPetitionDialog" title="Ajouter une pétition">
    <template #body>
      <p>Ajouter l'URL d'une pétition en provenance d'un de ces sites:</p>
      <ul>
        <li>Change.org</li>
        <li>MesOpinions</li>
        <li>Avaaz</li>
      </ul>
      <p>
        <i class="fa-regular fa-circle-info"></i> Les pétitions de l'Assemblée
        Nationale sont ajoutées automatiquement.
      </p>

      <div class="field" :class="{ error: state.url.isValid }">
        <label for="url">Url de la pétition *</label>
        <input v-model="state.url.value" type="text" name="url" />
      </div>

      <div v-if="state.error" class="error-message">
        <i :class="{ 'fa-solid fa-ban': state.error }"></i>
        {{ state.error }}
      </div>
    </template>

    <template #footer>
      <button class="btn-primary" @click="formValidation">Ajouter</button>
    </template>
  </PopupDialog>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useToast } from "vue-toastification";
import PopupDialog from "@/components/reusable/atoms/PopupDialog.vue";
import { urlRegex } from "@/utils/helper.utils";
import { usePetitionsStore } from "@/stores/petitions";

const petitionsStore = usePetitionsStore();
const toast = useToast();
const addPetitionDialog = ref(null);
const emit = defineEmits(["open", "close"]);

defineExpose({ openDialog, closeDialog });

const state = reactive({
  url: {
    value: null,
    isValid: false,
  },
  error: null,
});

function closeDialog() {
  state.error = "";
  addPetitionDialog.value?.close();
}
function openDialog() {
  state.url.value = "";
  state.error = "";
  addPetitionDialog.value?.open();
}
// Validate form before sending the request to the server
function formValidation() {
  if (!state.url.value) {
    state.error = "Veuillez renseigner l'url d'une pétition";
    return false;
  } else if (!urlRegex(state.url.value)) {
    state.error = "L'url de cette pétition n'est pas conforme";
    state.url.isValid = false;

    return false;
  } else {
    state.error = "";
    submit(state.url.value);
    return true;
  }
}
// Component method
async function submit(url) {
  try {
    const response = await petitionsStore.addPetition(url);
    if (response?.status === 201) {
      toast.success(
        "Petition ajoutée avec succès, nous procédons à la récupération des données. La pétition sera visible dans quelques minutes."
      );
      await petitionsStore.fetchOnePetition(response.data._id);
      emit("close");
    }
  } catch (error) {
    if (error.response.data.includes("deja disponible")) {
      state.error = "Cette pétition est déjà disponible";
    }
  } finally {
    petitionsStore.loading = false;
  }
}
</script>

<style scoped lang="scss">
p,
ul {
  margin: 0;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 5px;

  margin-top: 1rem;

  input[type="text"] {
    border: 1px solid var(--clr-p2);
    border-radius: 5px;
    margin: 0;
    padding: 4px 10px;
    background-color: var(--clr-n2);
  }
}

.error-message {
  color: var(--clr-e200);
  font-size: 0.9375em;
}
</style>
