<template>
  <div class="timeline-row">
    <ul>
      <li v-for="(step, idx) in timeline" :key="step.name">
        <RouterLink :to="urlForStep(idx)" class="step-column">
          <div :class="{ active: idx === activeStepIdx }">
            {{
              getDateShortFormat(
                step.date_depot || step.promulgation?.promulgation_date
              )
            }}
          </div>
          <div class="insti-pic">
            <InstitutionBadge
              :institution="step.institution"
              :is-active="idx === activeStepIdx"
            />
          </div>
          <div :class="{ active: idx === activeStepIdx }" class="step-title">
            {{ step.dossier_etape }}
          </div>
        </RouterLink>
      </li>
    </ul>
  </div>

  <div v-if="activeStepIdx !== null">
    <RouterRadioButtons :is-blue-style="true" :options="menuOptions" />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import InstitutionBadge from "./InstitutionBadge.vue";
import { getTimelineUrl } from "@/utils/legidoc-timeline-routing";

const props = defineProps({
  legiWorkRef: { type: String, required: true },
  timeline: {
    type: Array,
    required: true,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();

const activeStepIdx = ref(
  route.params.stepIdx ? parseInt(route.params.stepIdx) : null
);

const activeStep = computed(
  () =>
    typeof activeStepIdx.value === "number" &&
    props.timeline[activeStepIdx.value]
);

const baseRoute = `/legislatives/${props.legiWorkRef}/timeline`;

const menuOptions = computed(() => {
  const options = [];

  if (!activeStep.value) {
    return options;
  }

  if (activeStep.value.dossier_etape?.match(/dépot/i)) {
    options.push({
      label: "Texte",
      value: `${baseRoute}/${activeStepIdx.value}/texte-initial`,
    });
  }

  if (activeStep.value.dossier_etape?.match(/lecture/i)) {
    options.push(
      ...[
        {
          label: "Examen en commission",
          value: `${baseRoute}/${activeStepIdx.value}/commission/full-text`,
          activePattern: `${baseRoute}/${activeStepIdx.value}/commission`,
        },
        ...(activeStep.value.debats_seance.text_in
          ? [
              {
                label: "Discussion en séance publique",
                value: `${baseRoute}/${activeStepIdx.value}/seance/full-text`,
                activePattern: `${baseRoute}/${activeStepIdx.value}/seance`,
              },
            ]
          : []),
        ...(activeStep.value.debats_seance.scrutin_ref
          ? [
              {
                label: "Scrutin",
                value: `${baseRoute}/${activeStepIdx.value}/scrutin`,
              },
            ]
          : []),
        ...(activeStep.value.debats_seance.texte_associe
          ? [
              {
                label: "Texte soumis au scrutin",
                value: `${baseRoute}/${activeStepIdx.value}/texte-scrutin`,
              },
            ]
          : []),
      ]
    );
  }

  if (activeStep.value.dossier_etape?.match(/commission mixte paritaire/i)) {
    options.push(
      ...[
        {
          label: "Travaux",
          value: `${baseRoute}/${activeStepIdx.value}/cmp_travaux`,
        },
        {
          label: "Lectures du texte",
          value: `${baseRoute}/${activeStepIdx.value}/cmp_lecture`,
        },
      ]
    );
  }

  if (activeStep.value.dossier_etape?.match(/promulgation/i)) {
    options.push(
      ...[
        {
          label: "Texte Entier",
          value: `${baseRoute}/${activeStepIdx.value}/prom_texte`,
        },
        {
          label: "Décrets",
          value: `${baseRoute}/${activeStepIdx.value}/prom_decret`,
        },
      ]
    );
  }

  if (activeStep.value.engagement_responsabilite_49_3?.length > 0) {
    options.push({
      label: "49.3",
      value: `${baseRoute}/${activeStepIdx.value}/49_3`,
    });
  }
  return options;
});

function urlForStep(stepIdx) {
  return getTimelineUrl(baseRoute, props.timeline, stepIdx);
}
</script>

<style scoped lang="scss">
.timeline-row {
  ul {
    position: relative;

    display: flex;
    gap: 20px;

    list-style: none;
    padding: 0;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      border-top: 1px solid var(--clr-s8);
      // manually placed -- might need to be adjusted when design changes
      transform: translateY(46px);
      z-index: 1;
    }

    li {
      text-align: center;
      width: 150px;
      z-index: 2;

      .step-column {
        display: flex;
        flex-direction: column;
        gap: 10px;

        color: var(--clr-s8);
      }

      .step-title {
        font-weight: 600;
      }

      .insti-pic {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .active {
      font-weight: 700;
    }
  }
}
</style>
