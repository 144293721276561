import { ref } from "vue";
import { useShepherd } from "vue-shepherd";
import { waitForElement } from "../utils/wait-for-element";
import { sendProductTourStep } from "@/services/apiUsers";

const TOUR_STEP = Object.freeze({
  WELCOME: "WELCOME",
  SEARCH_FILTERS: "SEARCH_FILTERS",
  TRANSITION_SIDE_MENU: "TRANSITION_SIDE_MENU",
  LEGIDOC_FOLLAW: "LEGIDOC_FOLLAW",
  LEGIDOC_FILTERS: "LEGIDOC_FILTERS",
  SAVE_FILTERS: "SAVE_FILTERS",
  ALERTS: "ALERTS",
  BYE_BYE: "BYE_BYE",
});

export const configProductTour = (router) => {
  const alreadySendStep = ref([]);

  const tour = useShepherd({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "shepherd-theme-custom",
    },
  });

  const createProgressIndicator = (currentStep, totalSteps) => {
    const progress = document.createElement("span");
    progress.className = "shepherd-progress";
    progress.innerText = `étape ${currentStep + 1}/${totalSteps}`;
    return progress;
  };

  const addProgressIndicator = (footer, currentStep, totalSteps) => {
    const existingProgress = footer.querySelector(".shepherd-progress");
    const progress = createProgressIndicator(currentStep, totalSteps);

    if (existingProgress) {
      existingProgress.innerText = progress.innerText;
    } else {
      footer.insertBefore(
        progress,
        footer.querySelector(".shepherd-button:first-child")
      );
    }
  };

  const updateProgressIndicator = () => {
    const currentStep = tour.getCurrentStep();
    const currentStepElement = currentStep?.getElement();
    if (!currentStepElement) return;

    const footer = currentStepElement.querySelector(".shepherd-footer");
    if (!footer) return;

    const stepIndex = tour.steps.indexOf(currentStep);
    addProgressIndicator(footer, stepIndex, tour.steps.length);
  };

  const trackTourStep = async (step) => {
    if (!step) return;
    if (alreadySendStep.value.includes(step)) return;

    alreadySendStep.value.push(step);
    await sendProductTourStep(step);
  };
  tour.addStep({
    id: TOUR_STEP.WELCOME,
    arrow: false,
    attachTo: {
      element: "[data-product-tour='step-1']",
      on: "bottom",
    },
    title: `Bienvenue sur Follaw.sv, <br>
      outil de veille sociétale et institutionnelle !`,
    text: `
      <p class="product-tour__subtitle">Notre mission ?</p>
      <p class="product-tour-content-text--center">Démocratiser les affaires publiques en rendant accessible et facile l’accès aux informations institutionnelles.</p>
      <p class="product-tour-content-text--center">Merci de rejoindre la communauté !</p>
    `,
    buttons: [
      {
        text: "Suivant",
        classes: "shepherd-button-primary",
        action: () => {
          trackTourStep(TOUR_STEP.WELCOME);
          tour.next();
        },
      },
      {
        text: `<i class="fa-solid fa-xmark" />`,
        action: tour.complete,
        classes: "shepherd-button-cancel",
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });
  tour.addStep({
    id: TOUR_STEP.SEARCH_FILTERS,
    attachTo: {
      element: "[data-product-tour='step-2']",
      on: "bottom",
    },
    text: `
      <p class="product-tour__subtitle">Accédez facilement à toutes les informations pertinentes en quelques clics !</p>
      <span class="product-tour-content-text">La barre de filtre permet d’affiner vos recherches selon vos critères :</span>
      <ul class="product-tour-content-list">
        <li>Choisissez la temporalité qui vous convient pour vos recherches</li>
        <li>Choisissez parmi 750 thématiques paramétrées par notre équipe et prêtes à l’emploi</li>
        <li>Affinez la recherche selon vos propres critères</li>
      </ul>
    `,
    buttons: [
      {
        text: "Retour",
        classes: "shepherd-button-secondary",
        action: tour.back,
      },
      {
        text: "Suivant",
        classes: "shepherd-button-primary",
        action: () => {
          trackTourStep(TOUR_STEP.SEARCH_FILTERS);
          tour.next();
        },
      },
      {
        text: `<i class="fa-solid fa-xmark" />`,
        action: tour.complete,
        classes: "shepherd-button-cancel",
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });
  tour.addStep({
    id: TOUR_STEP.TRANSITION_SIDE_MENU,
    attachTo: {
      element: "[data-product-tour='step-3']",
      on: "left",
    },
    text: "Les différentes informations de veilles sont accessibles via ces sections. Pour commencer, nous allons explorer les dossiers législatifs récents.",
    buttons: [
      {
        text: "Retour",
        classes: "shepherd-button-secondary",
        action: tour.back,
      },
      {
        text: "Suivant",
        classes: "shepherd-button-primary",
        action: async () => {
          trackTourStep(TOUR_STEP.TRANSITION_SIDE_MENU);
          await router.push({
            path: "/legislatives",
            query: {
              search: undefined,
              thematics: undefined,
            },
          });
          tour.hide();
          await waitForElement("[data-product-tour='step-4']");
          tour.next();
        },
      },
      {
        text: `<i class="fa-solid fa-xmark" />`,
        action: tour.complete,
        classes: "shepherd-button-cancel",
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });
  tour.addStep({
    id: TOUR_STEP.LEGIDOC_FOLLAW,
    attachTo: { element: "[data-product-tour='step-4']", on: "left" },
    text: `
      <p class="product-tour__subtitle">Vous souhaitez marquer un élément en favori pour le suivre facilement ?</p>
      <span class="product-tour-content-text">Avec Follaw, vous pouvez :</span>
      <ul class="product-tour-content-list">
        <li>Conserver (et retrouver) facilement en favori les éléments souhaités</li>
        <li>Recevoir des alertes en cas de mise à jour sur ces éléments (Soyez immédiatement informé des nouvelles informations : réponses à une question, des mises à jour d'un dossier législatif, du sort d'un amendement, etc.)</li>
      </ul>
      <p>Vous pouvez ensuite naviguer efficacement entre les contenus qui vous intéressent et ceux que vous suivez de près avec les boutons "Follawed" et "Tous les matchs".</p>
    `,
    buttons: [
      {
        text: "Retour",
        classes: "shepherd-button-secondary",
        action: () => {
          router.push("/");
          tour.back();
        },
      },
      {
        text: "Suivant",
        classes: "shepherd-button-primary",
        action: () => {
          trackTourStep(TOUR_STEP.LEGIDOC_FOLLAW);
          tour.next();
        },
      },
      {
        text: `<i class="fa-solid fa-xmark" />`,
        action: tour.complete,
        classes: "shepherd-button-cancel",
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });

  tour.addStep({
    id: TOUR_STEP.LEGIDOC_FILTERS,
    attachTo: { element: "[data-product-tour='step-5']", on: "bottom" },
    text: "Pour chaque section, vous avez accès à des filtres dédiés et personnalisés en fonction de la section concernée. Ces filtres vous permettent d'affiner vos recherches et de trouver exactement l’information la plus pertinente. ",
    buttons: [
      {
        text: "Retour",
        classes: "shepherd-button-secondary",
        action: tour.back,
      },
      {
        text: "Suivant",
        classes: "shepherd-button-primary",
        action: () => {
          trackTourStep(TOUR_STEP.LEGIDOC_FILTERS);
          tour.next();
        },
      },
      {
        text: `<i class="fa-solid fa-xmark" />`,
        action: tour.complete,
        classes: "shepherd-button-cancel",
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });
  tour.addStep({
    id: TOUR_STEP.SAVE_FILTERS,
    attachTo: { element: "[data-product-tour='step-6']", on: "bottom" },

    text: `
      <p class="product-tour__subtitle">Souhaitez-vous personnaliser vos filtres pour chaque section ?</p>
      <p>Avec notre fonctionnalité de sauvegarde de filtres, vous pouvez affiner et personnaliser votre expérience sur Follaw.sv.</p>
      <p>Vos cinq derniers pré-réglages sont accessibles en un simple clic, vous permettant de retrouver rapidement vos configurations préférées et gagner un temps précieux.</p>
    `,
    buttons: [
      {
        text: "Retour",
        classes: "shepherd-button-secondary",
        action: tour.back,
      },
      {
        text: "Suivant",
        classes: "shepherd-button-primary",
        action: () => {
          trackTourStep(TOUR_STEP.SAVE_FILTERS);
          tour.next();
        },
      },
      {
        text: `<i class="fa-solid fa-xmark" />`,
        action: tour.complete,
        classes: "shepherd-button-cancel",
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });
  tour.addStep({
    id: TOUR_STEP.ALERTS,
    attachTo: { element: "[data-product-tour='step-7']", on: "bottom" },
    text: `
      <p class="product-tour__subtitle">Vous préférez que l'information vienne à vous ?</p>
      <p>Notre système d’alerte est la solution !</p>
      <span class="product-tour-content-text">Paramétrez vos alertes afin de vous faire gagner un temps précieux soit :</span>
      <ul class="product-tour-content-list">
        <li>via des alertes en temps réel pour vous tenir informé des dernières actualités institutionnelles ou sociétales</li>
        <li>via des récapitulatifs des informations sur le corpus de veille et la période souhaités</li>
      </ul>
      <p>Laissez l’outil travailler pour vous et restez en permanence informé !</p>
    `,
    buttons: [
      {
        text: "Retour",
        classes: "shepherd-button-secondary",
        action: tour.back,
      },
      {
        text: "Suivant",
        classes: "shepherd-button-primary",
        action: () => {
          trackTourStep(TOUR_STEP.ALERTS);
          tour.next();
        },
      },
      {
        text: `<i class="fa-solid fa-xmark" />`,
        action: tour.complete,
        classes: "shepherd-button-cancel",
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });
  tour.addStep({
    id: TOUR_STEP.BYE_BYE,
    attachTo: {
      element:
        import.meta.env.VITE_ENV === "production"
          ? "[aria-label='Ouvrir le chat']"
          : "[data-product-tour='step-7']",
      on: "right",
    },
    text: `
      <p class="product-tour__subtitle">Félicitations !</p>
      <p>
        Vous êtes désormais non seulement dans le mouvement de la modernisation des affaires publiques,
        mais vous êtes également au fait de toutes les fonctionnalités clés de la plateforme.
      </p>
        <p>Vous avez une question ? Une demande particulière ?<br>
        Vous pouvez contacter notre service client directement pour obtenir des éclaircissements sur une utilisation optimale,
        l'ajout de nouvelles fonctionnalités, ou toute autre demande similaire.
      </p>
    `,
    buttons: [
      {
        text: "Recommencer",
        classes: "shepherd-button-secondary",
        action: async () => {
          trackTourStep(TOUR_STEP.BYE_BYE);
          tour.cancel();
          await router.push({ path: "/" });
          tour.start();
        },
      },
      {
        text: "Terminer",
        classes: "shepherd-button-primary",
        action: () => {
          trackTourStep(TOUR_STEP.BYE_BYE);
          tour.complete();
        },
      },
    ],
    when: {
      show: () => updateProgressIndicator(),
    },
  });

  return tour;
};
