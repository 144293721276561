<template>
  <template v-if="legiWorkStore.items.length">
    <!-- Cards -->
    <div class="responsive-legi-works-wrapper">
      <LegiWorkCard
        v-for="(legiWork, idx) in legiWorkStore.items"
        :key="legiWork._id"
        :legi-work="legiWork"
        :idx="idx"
      ></LegiWorkCard>
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="legiWorkStore.item_count"
      :key="`${legiWorkStore.item_count}${legiWorkStore.selectedFilters.from}${legiWorkStore.selectedFilters.size}`"
      :total-items="legiWorkStore.item_count"
      :current-page="legiWorkStore.selectedFilters.from"
      :items-limit="legiWorkStore.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-else-if="legiWorkStore.hasActiveFilters" />
  <NoDataFollawed v-else-if="legiWorkStore.isFollawedActive" />
  <NoData v-else />
</template>

<script setup>
import LegiWorkCard from "../molecules/LegiWorkCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";

import { useLegiWorkStore } from "@/stores/legi-work";

const legiWorkStore = useLegiWorkStore();

const changeCurrentPage = (from) => {
  legiWorkStore.selectedFilters.from = from;
  legiWorkStore.fetchLegiWorkList();
};
</script>

<style scoped lang="scss">
.responsive-legi-works-wrapper {
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}
</style>
