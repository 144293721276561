<template>
  <div class="template">
    <LegiWorkListingParams />
    <LoaderFullPage v-if="store.loading" />
    <LegiWorkListingDatas v-else />
  </div>
</template>

<script setup>
import LegiWorkListingParams from "@/components/legi-work/organisms/LegiWorkListingParams.vue";
import LegiWorkListingDatas from "@/components/legi-work/organisms/LegiWorkListingDatas.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useLegiWorkStore } from "@/stores/legi-work.js";
import { useRouter } from "vue-router";

const store = useLegiWorkStore();
const router = useRouter();

store.isFollawedActive =
  router.currentRoute.value.fullPath.includes("follawed");

store.selectedFilters.from = 0;
store.fetchLegiWorkList();
</script>
