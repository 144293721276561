<template>
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <div class="col">
          <div class="row">
            <SelectMultipleCounter
              v-for="(value, key) in filtersNames"
              :key="key"
              :filter="key"
              :items="store.filters[key]"
              :active-filters="selectedFilters[key]"
              mode="purple"
              has-search
              @add-filter-item="addFilterItem"
              @remove-filter-item="removeFilterItem"
              @reset-filter="resetFilter"
            >
              {{ value }}
            </SelectMultipleCounter>
          </div>
          <div class="row">
            <template v-for="(value, key) in agendaFiltersNames" :key="key">
              <FilterSwitch
                v-if="key === 'agenda_guests'"
                label="Invités politiques"
                color="var(--clr-agenda-guests)"
                :is-checked="isInvitePolitiqueChecked"
                @check="addInvitePolitiqueFilter"
                @uncheck="removeInvitePolitiqueFilter"
              />
              <SelectMultipleAgenda
                v-else
                :filter="key"
                :items="store.agendaFilters[key]"
                :active-filters="selectedFilters?.[key]"
                :is-agenda-active="activeAgendas.includes(key)"
                @add-filter-item="addFilterItem"
                @remove-filter-item="removeFilterItem"
                @reset-filter="resetFilter"
                @activate-agenda="() => activateAgenda(key)"
                @deactivate-agenda="() => deactivateAgenda(key)"
              >
                {{ value }}
              </SelectMultipleAgenda>
            </template>
          </div>
        </div>
      </FiltersMenu>
    </div>

    <div class="params-row">
      <div class="display-and-time-wrapper">
        <RadioButtons
          :options="displayOptions"
          :active-option="store.activeDisplay"
          @select-option="store.activeDisplay = $event"
        />
        <RadioButtons
          :options="intervals"
          :active-option="store.activeInterval"
          @select-option="store.changeInterval($event)"
        />
        <TimeNavigationButton
          :start="store.selectedFilters.start"
          :end="store.selectedFilters.end"
          :range="store.activeInterval"
          @previous-time="store.previousTime()"
          @next-time="store.nextTime()"
        />
      </div>
    </div>

    <div class="params-row">
      <div class="page-settings">
        <SwitchSlide :is-active="!store.bypassQuery" @toggle="toggleUseRequest"
          >Utiliser la requête</SwitchSlide
        >
        <SelectAllBox
          :is-all-selected="isAllChecked"
          :is-some-selected="isAnyChecked"
          @select-all="selectAll"
          @unselect-all="unselectAll"
          >{{ selectedIds.length }} évènement{{
            selectedIds.length > 1 ? "s" : ""
          }}</SelectAllBox
        >
        <ExportButton
          icon-name="calendar-plus"
          :disabled="!isAnyChecked"
          @click="store.exportAsIcs()"
        >
          Exporter pour l'agenda
        </ExportButton>
        <ExportButton
          icon-name="file-spreadsheet"
          :disabled="!isAnyChecked"
          @click="exportAsExcel()"
        >
          Exporter en .xlsx
        </ExportButton>
        <ExportButton
          icon-name="envelope"
          :disabled="!isAnyChecked"
          @click="store.exportAsEmail()"
        >
          Recevoir par email
        </ExportButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";
import xlsx from "json-as-xlsx";

import FilterSwitch from "@/components/reusable/atoms/FilterSwitch.vue";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleAgenda from "@/components/reusable/molecules/SelectMultipleAgenda.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import SelectAllBox from "@/components/reusable/atoms/SelectAllBox.vue";
import TimeNavigationButton from "../atoms/TimeNavigationButton.vue";
import ExportButton from "@/components/reusable/atoms/ExportButton.vue";
import { TIME_INTERVALS } from "@/stores/agenda";
import { DISPLAY_OPTIONS } from "@/stores/agenda";
import {
  useAgendaStore,
  filtersNames,
  agendaFiltersNames,
} from "@/stores/agenda";

const store = useAgendaStore();

//* ------------------------------------------------- FILTERS -------------------------------------------------

const { selectedFilters, activeAgendas } = storeToRefs(store);

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  selectedFilters.value.agenda_names?.forEach((filter) => {
    arr = [...arr, { filter: "agenda_names", item: filter }];
  });
  return arr;
});

// Functions for normal filters

const addFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];
  targetFilters = [...targetFilters, event.item];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: targetFilters,
  };

  store.fetchEvents();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }

  store.fetchEvents();
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  store.fetchEvents();
};

function activateAgenda(agendaName) {
  activeAgendas.value.push(agendaName);
  store.fetchEvents();
}

function deactivateAgenda(agendaName) {
  activeAgendas.value = activeAgendas.value.filter((k) => k !== agendaName);
  store.fetchEvents();
}

const isInvitePolitiqueChecked = computed(() => {
  return activeAgendas.value.includes("agenda_guests") ?? false;
});

const addInvitePolitiqueFilter = () => {
  activeAgendas.value.push("agenda_guests");
  store.fetchEvents();
};

const removeInvitePolitiqueFilter = () => {
  activeAgendas.value = activeAgendas.value.filter(
    (k) => k !== "agenda_guests"
  );
  store.fetchEvents();
};

const resetAllFilters = () => {
  store.resetFilters();
  store.fetchEvents();
};

//* ------------------------------------------------- Radio buttons -------------------------------------------------

const intervals = [
  {
    label: "Mois",
    value: TIME_INTERVALS.MONTH,
  },
  {
    label: "Semaine",
    value: TIME_INTERVALS.WEEK,
  },
  {
    label: "Jour",
    value: TIME_INTERVALS.DAY,
  },
];

const displayOptions = [
  {
    label: "Vue liste",
    value: DISPLAY_OPTIONS.LIST,
  },
  {
    label: "Vue calendrier",
    value: DISPLAY_OPTIONS.CALENDAR,
  },
];

//* ------------------------------------------------- Use request & Export -------------------------------------------------

// Use request

const toggleUseRequest = () => {
  store.bypassQuery = !store.bypassQuery;
  store.fetchEvents();
};

// Selection

const selectedIds = computed(() => Object.keys(store.selectedEvents));

const isAllChecked = computed(() => {
  if (selectedIds.value.length === 0) {
    return false;
  }
  return store.items.every((evt) => selectedIds.value.includes(evt._id));
});

const isAnyChecked = computed(() => selectedIds.value.length > 0);

const selectAll = () => {
  store.items.forEach((event) => {
    if (!selectedIds.value.includes(event._id)) {
      store.selectedEvents = { ...store.selectedEvents, [event._id]: event };
    }
  });
};

const unselectAll = () => {
  selectedIds.value.forEach((key) => delete store.selectedEvents[key]);
};

const selectedEventsFormattedForExport = computed(() => {
  const sortedEvents = Object.values(store.selectedEvents).sort(
    (a, b) =>
      new Date(a.start) - new Date(b.start) || new Date(a.end) - new Date(b.end)
  );
  const formattedEvents = sortedEvents.map((event) => {
    event = { ...event };
    event.start = dayjs(event.start).format("DD/MM/YYYY HH:mm");
    event.end = dayjs(event.end).format("DD/MM/YYYY HH:mm");
    const participantsNames = event.participants.map((p) => p.name).toString();
    event.participants = participantsNames;
    return event;
  });
  return formattedEvents;
});

const exportAsExcel = () => {
  const dataAsJson = [
    {
      sheet: "Agenda",
      columns: [
        { label: "Titre", value: "title" },
        { label: "Début", value: "start" },
        { label: "Fin", value: "end" },
        { label: "Description", value: "description" },
        { label: "Lieu", value: "location" },
        { label: "Type", value: "type_" },
        { label: "Participants", value: "participants" },
        { label: "Nom agenda", value: "agenda.name" },
        { label: "Type agenda", value: "agenda.type_" },
        { label: "Propriétaire", value: "agenda.owner" },
        { label: "Url", value: "agenda.url" },
      ],
      content: selectedEventsFormattedForExport.value,
    },
  ];

  xlsx(dataAsJson, {
    fileName: "Agenda_export",
  });

  unselectAll();
};
</script>

<style lang="scss" scoped>
.top {
  align-items: baseline;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 6px;
}

.display-and-time-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
</style>
