import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

export const fetchInstitutionalGroups = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/v2/institutional-groups",
      payload
    );

    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchSearchInstitutionalGroups = async (name) => {
  try {
    const response = await httpRequest(
      "post",
      "/v2/institutional-groups/search_institutional-groups",
      {
        name,
      }
    );

    return response.data.institutional_groups;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchInstitutionalGroupsZoom = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/v2/institutional-groups/zoom",
      payload
    );

    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchInstitutionalGroupsActivities = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/v2/institutional-groups/zoom/activities",
      payload
    );

    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchInstitutionalGroupsPublications = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/v2/institutional-groups/zoom/publications",
      payload
    );

    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchInstitutionalGroupsAgenda = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/v2/institutional-groups/zoom/agenda",
      payload
    );

    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};

export const fetchInstitutionalGroupsPetitions = async (payload) => {
  try {
    const response = await httpRequest(
      "post",
      "/v2/institutional-groups/zoom/petitions",
      payload
    );

    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
};
