<template>
  <div
    class="title title-medium-semibold"
    @mouseenter="showDocLink = true"
    @mouseleave="showDocLink = false"
  >
    {{ amendment.legi_title }}
    <Transition name="fade">
      <div v-if="showDocLink" class="doc-link-hitbox">
        <div class="doc-link typo-body-regular">
          <RouterLink :to="`/legislatives/${amendment.doc_ref}/expose-motifs`"
            ><i class="fa-kit fa-dossierlegi"></i
            ><span>Voir le dossier législatif</span></RouterLink
          >
        </div>
      </div>
    </Transition>
  </div>

  <div class="statuses interface-small-regular">
    <AmendmentStatusTag :status="amendment.status" />
    <GenericTag
      v-if="shouldDisplayLongStatus"
      background-color="var(--clr-tag-status)"
      color="var(--clr-n8)"
      >{{ amendment.status_long }}</GenericTag
    >
  </div>

  <div class="author-and-infos-wrapper">
    <div v-if="amendment.author" class="author">
      <CardProfilePicture
        :picture-url="
          amendment.author?.photo || '/src/assets/img/legislation/gouv_fr.png'
        "
        :size="48"
      />
      <div v-if="amendment.author" class="author-infos">
        <span class="name">{{ amendment.author.name }}</span>
        <GroupTag
          v-if="amendment.author.group"
          :group-color="amendment.author.group.color"
        >
          {{ amendment.author.group.acronym }}
        </GroupTag>
        <div v-if="amendment.nb_cosignataires" class="title-small-regular">
          + {{ amendment.nb_cosignataires }} cosignataire{{
            amendment.nb_cosignataires > 1 ? "s" : ""
          }}
        </div>
      </div>
    </div>
    <ul class="infos title-medium-regular">
      <li class="title-medium-semibold">Amendment n° {{ amendment.number }}</li>
      <li>{{ amendment.title }}</li>
      <li>
        <i class="fa-solid fa-location-dot"></i>
        {{ getInstitutionsNames(amendment.institution) }}
        ({{ amendment.examined_by }})
      </li>
    </ul>
  </div>

  <div v-if="amendment.matched_words && amendment.matched_words.length > 0">
    <span class="matched-title title-medium-regular"
      >Mot{{ amendment.matched_words.length > 1 ? "s" : "" }} matché{{
        amendment.matched_words.length > 1 ? "s" : ""
      }}
      :
    </span>
    <!-- eslint-disable-next-line vue/no-v-html-->
    <span v-html="listOfWords" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import AmendmentStatusTag from "@/components/amendments/atoms/AmendmentStatusTag.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import { getInstitutionsNames } from "@/utils/institutions.utils";

const props = defineProps({
  amendment: {
    type: Object,
    required: true,
  },
});
const listOfWords = computed(() =>
  props.amendment.matched_words?.filter((_, idx) => idx < 10).join(", ")
);

const shouldDisplayLongStatus = computed(
  () =>
    props.amendment.status_long &&
    props.amendment.status_long !== props.amendment.status &&
    props.amendment.institution !== "Sénat"
);

const showDocLink = ref(false);
</script>

<style lang="scss" scoped>
.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title {
  position: relative;
  flex-grow: 1;

  &:hover {
    color: var(--clr-s7);
  }

  .doc-link-hitbox {
    position: absolute;
    z-index: 2;
    left: 75px;
    bottom: -33px;
    padding-top: 8px;

    .doc-link {
      position: relative;
      box-shadow: 0px 0px 7px 4px #00000014;
      border-radius: 4px;
      padding: 5px;
      color: var(--clr-link-default);
      background-color: white;

      &::after {
        content: "";
        position: absolute;
        border-width: 5px;
        border-style: solid;
        bottom: 100%;
        left: 8px;
        border-color: transparent transparent white transparent;
      }

      &:hover {
        text-decoration: underline;
      }

      span {
        margin-left: 10px;
      }
    }
  }
}

.statuses {
  display: flex;
  align-items: center;
  gap: 4px;

  .tag {
    text-transform: capitalize;
  }
}

.author-and-infos-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  .author {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-right: 1px solid var(--clr-s3);

    .author-infos {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .name {
        text-transform: capitalize;
      }
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 0;
    padding: 0;
    color: var(--clr-s7);
    list-style-type: none;
  }
}

.matched-title {
  color: var(--clr-s7);
}
</style>
