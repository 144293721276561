<template>
  <div class="template">
    <div class="englobe-params">
      <RouterLink class="back" to="/reports">
        <i class="fa-solid fa-chevron-left"></i> Retour à la liste
      </RouterLink>
    </div>

    <LoaderFullPage v-if="store.loading" />
    <div v-else-if="store.reportZoom" class="card">
      <div class="header">
        <div>{{ getDayTimeFormat(store.reportZoom.date_depot) }}</div>
        <SourceLink v-if="store.reportZoom.url" :href="store.reportZoom.url">
          Source
        </SourceLink>
      </div>
      <div class="title-row">
        <InstitutionBadge :institution="store.reportZoom.institution" />
        <div class="title-col">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="title" v-html="store.reportZoom.title"></div>
          <div class="report-type">{{ store.reportZoom.classification }}</div>
        </div>
      </div>
      <div class="body">
        <FullText
          :content="store.reportZoom.content"
          :occurrences="store.reportZoom.occurrences"
        />
      </div>
    </div>
    <div v-else>Impossible d'afficher ce rapport</div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useLegiReportStore } from "@/stores/legi-reports";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { getDayTimeFormat } from "@/utils/formatDate";
import InstitutionBadge from "@/components/legi-work/atoms/InstitutionBadge.vue";
import FullText from "@/components/legi-work/templates/FullText.vue";

const store = useLegiReportStore();
const route = useRoute();

store.fetchOneReport(route.params.docRef);
</script>

<style scoped lang="scss">
.header {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.title-row {
  display: flex;
  gap: 10px;

  margin-bottom: 10px;

  .title-col {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
      &::first-letter {
        text-transform: capitalize;
      }
    }

    .report-type {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report-content {
  color: var(--clr-n8);

  :deep(.personality) {
    padding-top: 10px;
    font-weight: 600;
  }
}
</style>
