<template>
  <button class="btn-close">
    <i class="fa-solid fa-xmark"></i>
  </button>
</template>

<style lang="scss" scoped>
.btn-close {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    background-color: var(--clr-p2);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
  }
  &:active {
    background-color: var(--clr-p1);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) inset;
  }
}
</style>
