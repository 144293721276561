<template>
  <div v-if="Object.entries(occurrences).length > 0" class="card box">
    <div class="header">
      <SwitchSlide :is-active="filterText" @toggle="emit('toggleFilter')"
        >Ne montrer que les occurrences</SwitchSlide
      >
    </div>
    <div class="box-main">
      <ul class="list">
        <li v-for="[key, value] in Object.entries(occurrences)" :key="key">
          <span class="highlight skip-focus occurrence-key">{{ key }}</span
          >: {{ value }} fois
        </li>
      </ul>

      <div class="controls">
        <button @click="moveToPrevFocus">
          <i class="fa-solid fa-chevron-up"></i></button
        ><button @click="moveToNextFocus">
          <i class="fa-solid fa-chevron-down"></i>
        </button>
      </div>
    </div>
  </div>
  <div v-else class="card box no-occurrences">
    Pas d'occurrences dans ce texte.
  </div>
</template>

<script setup>
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import { moveToNextFocus, moveToPrevFocus } from "@/utils/browse-occurrences";

defineProps({
  occurrences: { type: Object, required: true },
  filterText: { type: Boolean, default: false },
  showFilterText: { type: Boolean, default: false },
});

const emit = defineEmits(["toggleFilter"]);
</script>

<style scoped lang="scss">
@import "@/assets/sass/helpers/variables.scss";

.box {
  min-width: 300px;
  height: fit-content;
  max-height: 250px;
  padding: 16px;
  flex-shrink: 0;
  color: var(--clr-s8);
  overflow: auto;
  position: sticky;
  top: 10px;
}
.no-occurrences {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.box-main {
  display: flex;
}

ul.list {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-top: 7px;

    &:first-child {
      margin-top: 0;
    }
  }

  .occurrence-key {
    text-transform: capitalize;
  }
}

.controls {
  align-self: flex-start;
  width: 40px;

  display: flex;
  gap: 5px;
  justify-content: flex-end;
}
</style>
