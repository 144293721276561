<template>
  <div class="template">
    <LoaderFullPage v-if="store.loadingStats" />
    <template v-else>
      <LegiWorkOverviewDatas />
      <LegiWorkOverviewTrelloOfLegiDocs />
    </template>
  </div>
</template>

<script setup>
import LegiWorkOverviewDatas from "@/components/legi-work/organisms/LegiWorkOverviewDatas.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import LegiWorkOverviewTrelloOfLegiDocs from "@/components/legi-work/organisms/LegiWorkOverviewTrelloOfLegiDocs.vue";
import { useLegiOverviewStore } from "@/stores/legi-overview";
const store = useLegiOverviewStore();
store.fetchLegiOverview();
store.fetchDocSteps();
store.fetchCompteRendusCount();
</script>
