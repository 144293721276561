import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { defineStore, storeToRefs } from "pinia";
import { getIsoDateRange } from "@/utils/date.utils";
import { useUserStore } from "@/stores/user";
import { PLATFORM_TYPES } from "@/utils/social360-platforms.js";
import {
  fetchTotals,
  fetchSearchAuthors,
  fetchTimeCurve,
  fetchPublications,
  fetchGroupRanking,
  fetchRankings,
  fetchMajorStakeholderRanking,
  fetchLevelRanking,
} from "@/services/apiSocialMedia";
import { fetchArticles } from "@/services/apiArticles";
import { TIME_INTERVALS } from "@/constants/time-interval";

const initialPublicationsStatsFilters = {};
const initialTimeCurveTopPublicationsFilters = {
  sort_field: "like",
  sort_value: "desc",
  size: 10,
  get_filters: false,
  highlight: true,
};

const initialTwitterTimeCurveTopArticlesFilters = {
  sort_field: "number_of_tweets",
  sort_value: "desc",
  size: 10,
  get_filters: false,
  highlight: true,
};

export const useSocialMediaStatsStore = defineStore("statistics", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const indexesFilters = ref([...PLATFORM_TYPES]);
  const bypassQuery = ref(false);
  const authors = ref([]);
  const totalValues = ref({});
  const totalFilters = ref(null);
  const timeCurve = ref(undefined);
  const timeCurveInterval = ref(TIME_INTERVALS.DAY);
  const timeCurveTopDate = ref(undefined);
  const timeCurveTopPublications = ref([]);
  const timeCurveTopPublicationsCount = ref(0);
  const timeCurveTopArticles = ref([]);
  const timeCurveTopArticlesCount = ref(0);
  const stakeHoldersValues = ref({});
  const levelsValues = ref({});
  const groupsRanking = ref([]);
  const rankingValues = ref({});
  const filters = ref(null);
  const selectedFilters = ref({ ...initialPublicationsStatsFilters });
  const timeCurveTopPublicationsFilters = ref({
    ...initialTimeCurveTopPublicationsFilters,
  });
  const timeCurveTopArticlesFilters = ref({
    ...initialTwitterTimeCurveTopArticlesFilters,
  });
  const isTimeCurveLoading = ref(false);
  const isTimeCurvePublicationsLoading = ref(false);
  const isTotalFieldsLoading = ref(false);
  const isGroupsLoading = ref(false);
  const isRankingStatsLoading = ref(false);
  const isLevelsRankingLoading = ref(false);
  const isStakeholderStatsLoading = ref(false);
  const isAuthorFilterLoading = ref(false);
  const isArticlesLoading = ref(false);

  const createPayloadBase = () => {
    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
      indexes: indexesFilters.value,
    };

    // Convert the author filters to a list of IDs
    if (payload.author?.length > 0) {
      payload.author = payload.author.map((author) => author._id);
    }

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    return payload;
  };

  const getSearchAuthorsFilters = async (nameSearch) => {
    isAuthorFilterLoading.value = true;

    try {
      authors.value = await fetchSearchAuthors(nameSearch);
    } finally {
      isAuthorFilterLoading.value = false;
    }
  };

  const fetchTotalFields = async () => {
    isTotalFieldsLoading.value = true;

    const payload = { ...createPayloadBase(), get_filters: true };
    try {
      const response = await fetchTotals(payload);
      if (response) {
        totalValues.value = response.items;
        filters.value = response.filters;
      }
    } catch (error) {
      totalValues.value = {};
    } finally {
      isTotalFieldsLoading.value = false;
    }
  };

  const getTimeCurve = async () => {
    isTimeCurveLoading.value = true;

    const payload = {
      ...createPayloadBase(),
      interval: timeCurveInterval.value,
    };

    try {
      const data = await fetchTimeCurve(payload);
      if (data) {
        timeCurve.value = {
          x_timeline: data.x_timeline,
          tiktok_timeline: data.tiktok_timeline,
          bluesky_timeline: data.bluesky_timeline,
          instagram_timeline: data.instagram_timeline,
          linkedin_timeline: data.linkedin_timeline,
        };
      }
    } finally {
      isTimeCurveLoading.value = false;
    }
  };

  const getTimeCurvePublications = async () => {
    isTimeCurvePublicationsLoading.value = true;
    const date = timeCurveTopDate.value.date;
    const selectedPlatform = timeCurveTopDate.value?.platform;

    const dateRange = getIsoDateRange(date, timeCurveInterval.value);

    const payload = {
      ...createPayloadBase(),
      ...dateRange,
      ...timeCurveTopPublicationsFilters.value,
      indexes: selectedPlatform ? [selectedPlatform] : undefined,
    };

    if (userStore.sharedFilters.selected_keyword) {
      payload.filter = userStore.sharedFilters.selected_keyword;
    }

    try {
      const data = await fetchPublications(payload);

      if (data) {
        timeCurveTopPublications.value = data.items;
        timeCurveTopPublicationsCount.value = data.item_count;
      } else {
        timeCurveTopPublications.value = [];
        timeCurveTopPublicationsCount.value = null;
      }
    } finally {
      isTimeCurvePublicationsLoading.value = false;
    }
  };

  const getTimeCurveArticles = async () => {
    isArticlesLoading.value = true;

    const date = timeCurveTopDate.value.date;
    const selectedPlatform = timeCurveTopDate.value?.platform;

    const dateRange = getIsoDateRange(date, timeCurveInterval.value);

    const payload = {
      ...createPayloadBase(),
      ...dateRange,
      indexes: selectedPlatform ? [selectedPlatform] : undefined,
      ...timeCurveTopArticlesFilters.value,
    };

    if (userStore.sharedFilters.selected_keyword) {
      payload.filter = userStore.sharedFilters.selected_keyword;
    }

    try {
      const data = await fetchArticles(payload);

      if (data) {
        timeCurveTopArticles.value = data.items;
        timeCurveTopArticlesCount.value = data.item_count;
      } else {
        timeCurveTopArticles.value = [];
        timeCurveTopArticlesCount.value = 0;
      }
    } finally {
      isArticlesLoading.value = false;
    }
  };

  const fetchGroupsRanking = async () => {
    isGroupsLoading.value = true;

    const payload = createPayloadBase();

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const data = await fetchGroupRanking(payload);
      if (data) {
        groupsRanking.value = {
          publications: data.publications,
          authors: data.authors,
        };
      }
    } finally {
      isGroupsLoading.value = false;
    }
  };

  const fetchRankingStats = async () => {
    isRankingStatsLoading.value = true;

    const payload = createPayloadBase();

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const data = await fetchRankings(payload);
      if (data) {
        rankingValues.value = {
          top_domains: data.top_domains,
          top_hashtags: data.top_hashtags,
          top_words: data.top_words,
          top_mentions: data.top_mentions,
        };
      }
    } finally {
      isRankingStatsLoading.value = false;
    }
  };

  const fetchStakeholders = async () => {
    isStakeholderStatsLoading.value = true;

    const payload = createPayloadBase();

    try {
      const data = await fetchMajorStakeholderRanking(payload);
      if (data) {
        stakeHoldersValues.value = {
          publications: data.publications,
          authors: data.authors,
          classement: data.classement,
        };
      }
    } finally {
      isStakeholderStatsLoading.value = false;
    }
  };

  const fetchLevelsRanking = async () => {
    isLevelsRankingLoading.value = true;

    const payload = createPayloadBase();

    try {
      const data = await fetchLevelRanking(payload);

      if (data) {
        levelsValues.value = {
          publications: data.publications,
          authors: data.authors,
        };
      }
    } finally {
      isLevelsRankingLoading.value = false;
    }
  };

  const fetchOverview = () => {
    fetchTotalFields();
    getTimeCurve();
    fetchGroupsRanking();
    fetchRankingStats();
    fetchStakeholders();
    fetchLevelsRanking();
    if (timeCurveTopDate.value) {
      getTimeCurvePublications();
    }
  };

  const addFilterItem = (event) => {
    if (selectedFilters.value[event.filter]?.includes(event.item)) {
      // Filter is already added, so skip adding it
      return;
    }

    let targetFilters = selectedFilters.value[event.filter] ?? [];
    targetFilters = [...targetFilters, event.item];

    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };

    fetchOverview();
  };

  const removeFilterItem = (event) => {
    let targetFilters = selectedFilters.value[event.filter] ?? [];

    targetFilters = targetFilters.filter((el) => el !== event.item);

    if (targetFilters.length === 0) {
      delete selectedFilters.value[event.filter];
    } else {
      selectedFilters.value = {
        ...selectedFilters.value,
        [event.filter]: targetFilters,
      };
    }

    fetchOverview();
  };

  function handlePresetApplied(updatedFilters) {
    selectedFilters.value = { ...selectedFilters.value, ...updatedFilters };
    fetchOverview();
  }

  const resetSearchAuthorsFilters = () => {
    authors.value = [];
  };

  const resetFilter = (event) => {
    delete selectedFilters.value[event];
    fetchOverview();
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialPublicationsStatsFilters };
    fetchOverview();
  };

  const resetStore = () => {
    resetSearchAuthorsFilters();
    totalValues.value = {};
    filters.value = null;
    rankingValues.value = {};
    stakeHoldersValues.value = {};
    levelsValues.value = {};
    selectedFilters.value = { ...initialPublicationsStatsFilters };
    isTotalFieldsLoading.value = false;
    isStakeholderStatsLoading.value = false;
    isLevelsRankingLoading.value = false;
  };

  const route = useRoute();
  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval && route.name === "Overview") {
        selectedFilters.value.from = 0;
        fetchOverview();
      }
    }
  );

  // Trigger refetch of timecurve zoom data when parameters change
  watch([timeCurveTopDate], () => {
    if (timeCurveTopDate.value) {
      getTimeCurveArticles();
      getTimeCurvePublications();
    }
  });

  watch([timeCurveTopPublicationsFilters], () => {
    if (timeCurveTopDate.value) {
      getTimeCurvePublications();
    }
  });

  watch([timeCurveTopArticlesFilters], () => {
    if (timeCurveTopDate.value) {
      getTimeCurveArticles();
    }
  });

  return {
    authors,
    bypassQuery,
    resetSearchAuthorsFilters,
    totalFilters,
    addFilterItem,
    fetchLevelsRanking,
    fetchOverview,
    fetchStakeholders,
    getTimeCurve,
    filters,
    groupsRanking,
    levelsValues,
    rankingValues,
    removeFilterItem,
    handlePresetApplied,
    resetFilter,
    resetFilters,
    resetStore,
    selectedFilters,
    stakeHoldersValues,
    timeCurve,
    timeCurveInterval,
    timeCurveTopDate,
    timeCurveTopPublications,
    timeCurveTopPublicationsCount,
    timeCurveTopArticles,
    timeCurveTopArticlesCount,
    timeCurveTopPublicationsFilters,
    timeCurveTopArticlesFilters,
    totalValues,
    isAuthorFilterLoading,
    isGroupsLoading,
    isLevelsRankingLoading,
    isRankingStatsLoading,
    isStakeholderStatsLoading,
    isTimeCurveLoading,
    isTimeCurvePublicationsLoading,
    isTotalFieldsLoading,
    isArticlesLoading,
    getSearchAuthorsFilters,
    indexesFilters,
  };
});
