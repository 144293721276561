<template>
  <AbstractSocialCard
    :id="post._id"
    :author="post.author"
    :social-icon="{
      name: 'fa-square-x-twitter',
      color: 'var(--clr-twitter)',
    }"
    :handle="handleTwitter"
    :reposts="SINumberFormatter(post.reposts)"
    :likes="SINumberFormatter(post.like)"
    :comments="SINumberFormatter(post.comment)"
    :impressions="SINumberFormatter(post.impression)"
    :created-at="post.created_at"
    :last-update="post.last_update"
    :source-url="post.permalink"
    :is-overview="isOverview"
  >
    <template #content>
      <AbstractSocialCardContent :describe="tweetText" />

      <SourceLink
        v-for="url in tweetLinks"
        :key="url"
        :href="url"
        target="_blank"
      >
        {{ url }}
      </SourceLink>
    </template>
  </AbstractSocialCard>
</template>

<script setup>
import { computed } from "vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import AbstractSocialCard from "@/components/social-media//abstract/AbstractSocialCard.vue";
import AbstractSocialCardContent from "@/components/social-media//abstract/AbstractSocialCardContent.vue";
import { useHandle } from "@/composables/social-media/useHandle";
import { Platforms } from "@/utils/social360-platforms.js";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  isOverview: {
    type: Boolean,
    default: false,
  },
});

const handleTwitter = useHandle(
  props.post.author.x_information?.username,
  Platforms.TWITTER
);

const linksRegex = /(https:\/\/t.co\/[a-zA-Z0-9]{10})/g;

const tweetLinks = computed(() => props.post.text.match(linksRegex));
const tweetText = computed(() => props.post.text.replace(linksRegex, ""));
</script>
