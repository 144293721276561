<script setup>
import { useRouter } from "vue-router";
import { getTimelineUrl } from "@/utils/legidoc-timeline-routing";
import { useLegiWorkStore } from "@/stores/legi-work";

const store = useLegiWorkStore();
const router = useRouter();

// Redirect to last version of the text
const newUrl = getTimelineUrl(
  `/legislatives/${store.legiWorkZoom.ref}/timeline`,
  store.legiWorkZoom.timeline,
  store.legiWorkZoom.timeline.length - 1
);

router.replace(newUrl);
</script>
