import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import dayjs from "dayjs";
import { useToast } from "vue-toastification";

import { useUserStore } from "@/stores/user";
import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialAgendaFilters = {
  start: dayjs().startOf("week").format(),
  end: dayjs().endOf("week").format(),
  get_filters: true,
};

export const TIME_INTERVALS = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
};

export const DISPLAY_OPTIONS = {
  LIST: "list",
  CALENDAR: "calendar",
};

// All filters in wanted display order with their labels
export const filtersNames = {
  author: "Auteur",
  type_: "Type d'évènement",
  location: "Lieu de l'évènement",
};

export const agendaFiltersNames = {
  agenda_guests: "Invités politiques",
  agenda_government: "Gouvernement",
  agenda_sn: "Sénat",
  agenda_an: "Assemblée Nationale",
  agenda_others: "Autre",
};

const toast = useToast();

export const useAgendaStore = defineStore("agenda", () => {
  const userStore = useUserStore();
  const sharedFiltersAsPayload = storeToRefs(userStore).sharedFiltersAsPayload;
  const items = ref([]);
  const itemCount = ref(null);
  const bypassQuery = ref(false);
  const filters = ref({});
  const agendaFilters = ref({});
  const selectedFilters = ref({ ...initialAgendaFilters });
  const activeAgendas = ref([...Object.keys(agendaFiltersNames)]);
  const activeInterval = ref(TIME_INTERVALS.WEEK);
  const activeDisplay = ref(DISPLAY_OPTIONS.LIST);
  const selectedEvents = ref({});
  const loading = ref(false);

  const fetchEvents = async () => {
    loading.value = true;

    //* Create the payload from selectedFilters and from the topbar
    const payload = {
      // shared filters first so that the start and end from selected filters overrides the ones from the topbar
      ...sharedFiltersAsPayload.value,
      ...selectedFilters.value,
    };

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    // Explicitly set empty arrays for deactivated agendas
    Object.keys(agendaFiltersNames).forEach((agendaName) => {
      if (!activeAgendas.value.includes(agendaName)) {
        payload[agendaName] = [];
      }
    });

    // Update agenda_guests to be a boolean value
    if ("agenda_guests" in payload) {
      payload.agenda_guests = Array.isArray(
        payload.agenda_guests
          ? payload.agenda_guests.length
          : !!payload.agenda_guests
      );
    }

    try {
      const response = await httpRequest("post", `/events`, payload);
      if (response.data) {
        itemCount.value = response.data.item_count;
        // Converting the start and end date to Paris timezone
        items.value = response.data.items.map((item) => ({
          ...item,
          start: dayjs(item.start),
          end: dayjs(item.end),
        }));
        Object.keys(response.data.filters).forEach((filterKey) => {
          if (filterKey in agendaFiltersNames) {
            agendaFilters.value = {
              ...agendaFilters.value,
              [filterKey]: response.data.filters[filterKey],
            };
          }

          if (filterKey in filtersNames) {
            filters.value = {
              ...filters.value,
              [filterKey]: response.data.filters[filterKey],
            };
          }
        });
      }
    } catch (err) {
      itemCount.value = null;
      items.value = [];
      filters.value = null;
      agendaFilters.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const changeInterval = (to) => {
    switch (to) {
      case TIME_INTERVALS.DAY:
        activeInterval.value = TIME_INTERVALS.DAY;
        selectedFilters.value.start = dayjs().startOf("day").format(); // set to 12:00 am of the current day
        selectedFilters.value.end = dayjs().endOf("day").format(); // set to 23:59 pm of the current day
        break;
      case TIME_INTERVALS.MONTH:
        activeInterval.value = TIME_INTERVALS.MONTH;
        selectedFilters.value.start = dayjs().startOf("month").format(); // set to the first day of the current month
        selectedFilters.value.end = dayjs().endOf("month").format(); // set to the last day of the current month
        break;
      case TIME_INTERVALS.WEEK:
      default:
        activeInterval.value = TIME_INTERVALS.WEEK;
        selectedFilters.value.start = dayjs().startOf("week").format(); // set to the first day of the current week
        selectedFilters.value.end = dayjs().endOf("week").format(); // set to the last day of the current week
        break;
    }
    fetchEvents();
  };

  const previousTime = () => {
    switch (activeInterval.value) {
      case TIME_INTERVALS.DAY:
        selectedFilters.value.start = dayjs(selectedFilters.value.start)
          .subtract(1, "day")
          .startOf("day") // set to 12:00 am the previous day
          .format();
        selectedFilters.value.end = dayjs(selectedFilters.value.end)
          .subtract(1, "day")
          .endOf("day") // set to 23:59 pm the previous day
          .format();
        break;
      case TIME_INTERVALS.MONTH:
        selectedFilters.value.start = dayjs(selectedFilters.value.start)
          .subtract(1, "month")
          .startOf("month") // set to set to 12:00 the first day of the previous month
          .format();
        selectedFilters.value.end = dayjs(selectedFilters.value.end)
          .subtract(1, "month")
          .endOf("month") // set to 23:59 pm the last day of the previous month
          .format();
        break;
      case TIME_INTERVALS.WEEK:
      default:
        selectedFilters.value.start = dayjs(selectedFilters.value.start)
          .subtract(1, "week")
          .startOf("week") // set to the first day of the previous week
          .format();
        selectedFilters.value.end = dayjs(selectedFilters.value.end)
          .subtract(1, "week")
          .endOf("week") // set to the last day of the previous week
          .format();
        break;
    }
    fetchEvents();
  };

  const nextTime = () => {
    switch (activeInterval.value) {
      case TIME_INTERVALS.DAY:
        selectedFilters.value.start = dayjs(selectedFilters.value.start)
          .add(1, "day")
          .startOf("day") // set to 12:00 am the next day
          .format();
        selectedFilters.value.end = dayjs(selectedFilters.value.end)
          .add(1, "day")
          .endOf("day") // set to 23:59 pm the next day
          .format();
        break;
      case TIME_INTERVALS.MONTH:
        selectedFilters.value.start = dayjs(selectedFilters.value.start)
          .add(1, "month")
          .startOf("month") // set to 12:00 am the first day of the next month
          .format();
        selectedFilters.value.end = dayjs(selectedFilters.value.end)
          .add(1, "month")
          .endOf("month") // set to 23:59 pm to the last day of the next month
          .format();
        break;
      case TIME_INTERVALS.WEEK:
      default:
        selectedFilters.value.start = dayjs(selectedFilters.value.start)
          .add(1, "week")
          .startOf("week") // set to 12:00 am the first day of the next week
          .format();
        selectedFilters.value.end = dayjs(selectedFilters.value.end)
          .add(1, "week")
          .endOf("week") // set to 23:59 pm the last day of the previous week
          .format();
        break;
    }
    fetchEvents();
  };

  const exportAsIcs = async () => {
    loading.value = true;

    const payload = {
      _id: Object.keys(selectedEvents.value),
    };

    const dashboardName = userStore.dashboards.find(
      (d) => d._id === userStore.selectedDashboard
    ).name;

    try {
      const response = await httpRequest("post", `/events/export-ics`, payload);
      if (response.data) {
        const blob = new Blob([response.data.events], {
          type: "text/calendar",
        });
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = fileUrl;
        link.setAttribute("download", `${dashboardName}_évènements_export.ics`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);

        // Unselect events after export
        Object.keys(selectedEvents.value).forEach(
          (key) => delete selectedEvents.value[key]
        );
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  async function exportAsEmail() {
    loading.value = true;

    const payload = {
      _id: Object.keys(selectedEvents.value),
    };

    try {
      await httpRequest("post", "/events/export-email", payload);

      toast("Les événements vous ont été envoyés par email.");
      Object.keys(selectedEvents.value).forEach(
        (key) => delete selectedEvents.value[key]
      );
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  }

  const resetFilters = () => {
    selectedFilters.value = { ...initialAgendaFilters };
  };

  const resetStore = () => {
    items.value = [];
    filters.value = null;
    selectedFilters.value = { ...initialAgendaFilters };
    loading.value = false;
    itemCount.value = null;
  };

  return {
    activeAgendas,
    activeDisplay,
    activeInterval,
    agendaFilters,
    bypassQuery,
    changeInterval,
    exportAsEmail,
    exportAsIcs,
    fetchEvents,
    filters,
    itemCount,
    items,
    loading,
    nextTime,
    previousTime,
    resetFilters,
    resetStore,
    selectedEvents,
    selectedFilters,
  };
});
