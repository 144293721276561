<template>
  <SocialsViewWrapper>
    <OverviewParams />
    <LoaderFullPage v-if="store.loading" />
    <OverviewDatas v-else />
  </SocialsViewWrapper>
</template>

<script setup>
import SocialsViewWrapper from "@/components/social-media/templates/SocialsViewWrapper.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import OverviewParams from "@/components/social-media/overview/organisms/OverviewParams.vue";
import OverviewDatas from "@/components/social-media/overview/organisms/OverviewDatas.vue";
import { useSocialMediaStatsStore } from "@/stores/social-media/statistics";
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useSocialMediaStatsStore();
const userSettingsStore = useUserSettingsStore();

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.fetchOverview();
}
</script>
