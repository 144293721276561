<template>
  <div class="englobe-params">
    <div class="params-row">
      <BreadCrumbs :crumbs="crumbsArray" />
    </div>
    <div class="params-row">
      <RouterRadioButtons :options="textOptions" />
    </div>
    <LobbiesNewsParams v-if="route.fullPath.includes('news')" is-zoomed />
    <LobbiesActivitiesParams
      v-if="route.fullPath.includes('activities')"
      is-zoomed
    />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";
import BreadCrumbs from "@/components/reusable/atoms/BreadCrumbs.vue";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import LobbiesNewsParams from "./LobbiesNewsParams.vue";
import LobbiesActivitiesParams from "./LobbiesActivitiesParams.vue";
import side_menu from "@/utils/side_menu";
import { useLobbiesStore } from "@/stores/lobbies.js";

const store = useLobbiesStore();
const route = useRoute();

const crumbsArray = computed(() => [
  store.isFollawedActive
    ? {
        text: "Lobbies Follawed",
        to: { name: "Lobbies Follawed - Vos follaw" },
      }
    : {
        text: "Lobbies",
        to: { name: "Lobbies" },
      },
  {
    text: store.lobbyZoom?.name ?? "Lobby",
  },
]);

const routerOptions = side_menu
  .find((r) => r.name === "PRM")
  .secondary_tabs.find((tab) => tab.name === "Lobbies")
  .secondary_routes.find((r) => r.name === "Lobby Zoom").children;
const textOptions = routerOptions.map((r) => ({
  label: r.name.replace("Lobby Zoom - ", ""),
  value: `/lobbies/${route.params._id}/${r.path}`,
  exact: true,
}));
</script>
