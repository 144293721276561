<template>
  <BaseLink :href="url">
    <div class="card">
      <div class="header-row">
        <i class="fa-solid fa-file-pdf"></i>
        <span class="title">{{ additionalInfo.dossier_etape }}</span>
      </div>
      <div>Mis en ligne: {{ getDateShortFormat(additionalInfo.date) }}</div>
    </div>
  </BaseLink>
</template>

<script setup>
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import { computed } from "vue";

const props = defineProps({ additionalInfo: { type: Object, required: true } });

const url = computed(() => {
  if (props.additionalInfo.texte_associe_url.startsWith("/")) {
    // Quick fix: AN website gives us relative links, so we add them back
    return `https://www.assemblee-nationale.fr${props.additionalInfo.texte_associe_url}`;
  }

  return props.additionalInfo.texte_associe_url;
});
</script>

<style scoped lang="scss">
.card {
  color: var(--clr-n8);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  i {
    color: var(--clr-link-default);
    font-size: 1.5rem;
  }
}

.header-row {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 700;
  margin-left: 10px;
}
</style>
