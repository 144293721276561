<template>
  <div
    v-if="Object.keys(store.groupedSearchedMinistersByMinistries).length"
    class="cards-wrapper"
  >
    <MinistryCard
      v-for="ministryName in Object.keys(
        store.groupedSearchedMinistersByMinistries
      )"
      :key="ministryName"
      :ministry-name="ministryName"
    />
  </div>
  <div v-else class="card">
    <AbstractNoData>
      <template #message>
        Nous n’avons pas trouvé de ministre correspondant à votre recherche
      </template>
      <template #image>
        <img src="@/assets/img/empty_section.svg" aria-hidden="true" />
      </template>
    </AbstractNoData>
  </div>
</template>

<script setup>
import MinistryCard from "@/components/cabinets/organisms/MinistryCard.vue";
import { useCabinetsStore } from "@/stores/cabinets";
import AbstractNoData from "@/components/reusable/abstract/AbstractNoData.vue";

const store = useCabinetsStore();
</script>

<style lang="scss" scoped>
.cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
