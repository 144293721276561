<template>
  <VueCal
    :active-view="store.activeInterval"
    class="cal-wrapper vuecal--green-theme"
    :disable-views="['years', 'year']"
    :dblclick-to-navigate="false"
    events-on-month-view="short"
    events-on-day-view="short"
    :events="formattedEvents"
    :hide-title-bar="true"
    :hide-view-selector="true"
    locale="fr"
    :on-event-click="onEventClick"
    :selected-date="asVueCalFormat(store.selectedFilters.start)"
    :show-all-day-events="'short'"
    :overlaps-per-time-step="true"
    :time-from="8 * 60"
    :time-to="24 * 60"
  />
  <!-- Use dialog HTML element for the zoomed event
      The "on-click-outside" behavior is done by applying a @click on the whole element (including the backdrop),
      then disabling it on the `dialog-content` with stopPropagation()
  -->
  <dialog id="dialog-agenda-selected-event" @click="closeDialog()">
    <div
      v-if="dialogEvent"
      class="dialog-content"
      @click="(e) => e.stopPropagation()"
    >
      <EventCard
        :event="dialogEvent"
        :is-selected="isEventSelected(dialogEvent._id)"
        has-checkbox
        is-dialog
        @select-event="selectEvent"
        @unselect-event="unselectEvent"
        @close-dialog="closeDialog"
      />
    </div>
  </dialog>
</template>

<script setup>
import { computed, ref } from "vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import EventCard from "./EventCard.vue";
import { useAgendaStore } from "@/stores/agenda";
import dayjs from "dayjs";

const store = useAgendaStore();

const dialogEvent = ref(null);

const isEventSelected = (id) => Object.keys(store.selectedEvents).includes(id);

function eventTypeToIconName(type) {
  const map = {
    accueil: "fa-kit fa-sv-accueil",
    audioconférence: "fa-solid fa-phone",
    audition: "fa-kit fa-sv-audition",
    cérémonie: "fa-kit fa-sv-ceremonie",
    colloque: "fa-kit fa-sv-colloque",
    comité: "fa-kit fa-sv-comite",
    "conférence de presse": "fa-kit fa-sv-conferencepresse",
    "conseil (restreint) de défense et de sécurité nationale":
      "fa-solid fa-shield-halved",
    "conseil des ministres": "fa-kit fa-sv-conseilministres",
    déplacement: "fa-kit fa-sv-deplacement",
    interview: "fa-kit fa-sv-interview",
    "question au gouvernement": "fa-kit fa-sv-questiongou",
    "question d'actualité": "fa-solid fa-comments-question",
    rencontre: "fa-solid fa-handshake-simple",
    repas: "fa-solid fa-fork-knife",
    réunion: "fa-kit fa-sv-reunion",
    sommet: "fa-kit fa-sv-sommet",
    visioconférence: "fa-kit fa-sv-visioconference",
    autre: "fa-solid fa-calendar-day",
  };

  return map[type] || map.autre;
}

function asVueCalFormat(date) {
  return dayjs(date).format("YYYY-M-D HH:mm");
}

function getAgendaTypeClassName(event) {
  return event.agenda.type_?.replace(/\s+/g, "-");
}

const formattedEvents = computed(() =>
  store.items.map((e) => {
    // Determine the icon based on the event type and whether it's selected
    let iconName;
    if (isEventSelected(e._id)) {
      iconName = "fa-solid fa-square-check";
    } else {
      iconName = eventTypeToIconName(e.type_);
    }

    return {
      ...e,
      original_title: e.title,
      title: `<i class="${iconName}"></i> ${e.title}`,
      allDay: dayjs(e.end).diff(dayjs(e.start), "hour") > 7,
      content: e.description,
      class: `agenda--event ${getAgendaTypeClassName(e)} ${
        isEventSelected(e._id) ? "selected-event" : ""
      }`,
      start: asVueCalFormat(e.start),
      end: asVueCalFormat(e.end),
    };
  })
);

function onEventClick(event, e) {
  // removing the agenda title's icon on the event that is added on the agenda
  const eventWithoutIcon = {
    ...event,
    title: stripHtmlTags(event.title),
  };
  dialogEvent.value = eventWithoutIcon;
  document.getElementById("dialog-agenda-selected-event").showModal();
  e.stopPropagation();
}

function stripHtmlTags(str) {
  return str.replace(/<[^>]*>/g, "");
}
function closeDialog() {
  dialogEvent.value = null;
  document.getElementById("dialog-agenda-selected-event").close();
}

const selectEvent = (evt) => {
  store.selectedEvents = { ...store.selectedEvents, [evt._id]: evt };
};

const unselectEvent = (evt) => {
  delete store.selectedEvents[evt._id];
};
</script>

<style lang="scss" scoped>
// Following the recomandation of adding and setted height container on the VueCal component
.calendar-container {
  height: 100%;
}
.cal-wrapper {
  background-color: white;
  :deep(.agenda--event) {
    color: var(--clr-n8);
    background-color: white;
    text-align: left;
    font-size: 0.875em;
    padding: 5px 3px;
    border-left: 3px solid var(--clr-agenda-event-type);
    box-shadow: none;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: var(--clr-agenda-event-type);
      opacity: 0.05;
    }

    cursor: pointer;
    i {
      color: var(--clr-agenda-event-type);
    }
  }

  // Override Vue-Cal CSS classes here
  :deep(.vuecal__bg) {
    overflow: visible;
  }

  :deep(.vuecal__cell-date) {
    font-size: 1.25em;
    font-weight: 700;
    color: var(--clr-p5);
  }
  :deep(.vuecal__time-cell-label),
  :deep(.vuecal__all-day-text),
  :deep(.vuecal__all-day-text span) {
    color: var(--clr-n8);
    text-align: center;
  }
  :deep(.vuecal__event) {
    min-height: 37px;
  }

  :deep(.vuecal__cell:before) {
    border: 1px solid var(--clr-p2);
  }

  // See https://antoniandre.github.io/vue-cal/#css-notes
  :deep(.vuecal__cell) {
    min-height: 150px;
  }
  :deep(.vuecal__flex .weekday-label) {
    display: flex;
    flex-direction: column;
  }

  :deep(.vuecal__flex .weekday-label span:last-of-type) {
    font-size: 1.3333333333333333em;
    color: var(--clr-p5);
    font-weight: 700;
    order: 1;
    margin-top: 8px;
  }

  :deep(.vuecal__heading) {
    height: 100px;
  }
  :deep(.vuecal__flex .vuecal__heading) {
    border-left: 1px solid var(--clr-p2);
  }

  // To keep the date number on top of the Month view
  :deep(.vuecal__flex .vuecal__cell-content) {
    margin-top: 0.9375em;
    justify-content: flex-start;
  }
  :deep(.vuecal__time-column .vuecal__time-cell) {
    text-align: center;
    padding-right: 0;
  }
  :deep(.vuecal__time-column .vuecal__time-cell span:last-of-type) {
    position: relative;
    top: 13px;
  }
  :deep(.selected-event) {
    border: var(--clr-p7) 1px solid;
    border-top-left-radius: 4px;
    i {
      color: var(--clr-p7);
    }
  }
}

#dialog-agenda-selected-event {
  padding: 0;
  border: none;
  color: var(--clr-n8);
  background-color: white;

  .dialog-content {
    display: flex;
    max-width: 572px;
    background-color: white;

    > * {
      box-shadow: none;
    }
  }
}
</style>
