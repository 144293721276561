<template>
  <div class="template">
    <LoaderFullPage v-if="store.loading" />
    <template v-else>
      <SearchFieldCabinets v-model="store.searchCurrent" />
      <div class="wrapper">
        <div class="ministries-list">
          <MinistriesNavBar />
        </div>
        <div class="cabinets-list">
          <MinistriesList />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import SearchFieldCabinets from "@/components/cabinets/atoms/SearchFieldCabinets.vue";
import MinistriesNavBar from "@/components/cabinets/organisms/MinistriesNavBar.vue";
import { useCabinetsStore } from "@/stores/cabinets";
import MinistriesList from "@/components/cabinets/organisms/MinistriesList.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";

const store = useCabinetsStore();

store.fetchCabinetsList();
</script>

<style lang="scss" scoped>
.wrapper {
  grid-template-columns: 1fr;
}

.cabinets-list {
  height: 100%;
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.ministries-list {
  display: none;
}

@container (min-width: 900px) {
  .wrapper {
    height: calc(100vh - var(--top-menu-height) - 60px);
    display: grid;
    grid-template-columns: 380px minmax(auto, 750px);
    gap: 8px;
  }

  .ministries-list {
    display: contents;
  }
}

@container (min-width: 1200px) {
  .wrapper {
    grid-template-columns: 380px auto;
  }
}
</style>
