<template>
  <AbstractSocialCard
    :id="post._id"
    :author="post.author"
    :social-icon="{ name: 'fa-bluesky', color: 'var(--clr-bluesky)' }"
    :handle="handleBluesky"
    :reposts="SINumberFormatter(post.reposts)"
    :likes="SINumberFormatter(post.like)"
    :comments="SINumberFormatter(post.comment)"
    :created-at="post.created_at"
    :last-update="post.last_update"
    :source-url="post.permalink"
    :is-overview="isOverview"
  >
    <template #content>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="post.text" />
      <!-- eslint-enable-next-line vue/no-v-html -->
    </template>
  </AbstractSocialCard>
</template>
<script setup>
import { SINumberFormatter } from "@/utils/helper.utils.js";
import AbstractSocialCard from "@/components/social-media//abstract/AbstractSocialCard.vue";
import { useHandle } from "@/composables/social-media/useHandle";
import { Platforms } from "@/utils/social360-platforms";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  isOverview: {
    type: Boolean,
    default: false,
  },
  tooltipActivable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const handleBluesky = useHandle(
  props.post.author.bluesky_information?.username,
  Platforms.BLUESKY
);
</script>
