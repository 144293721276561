<template>
  <div class="user-menu">
    <button class="user-menu-trigger" @click="toggleMenu">
      <i class="fa-solid fa-user"></i>
    </button>
    <div
      v-if="isMenuOpen"
      v-on-click-outside="toggleMenu"
      class="user-menu-options"
    >
      <ul class="menu-list">
        <li class="menu-item">
          <RouterLink to="/user-settings/account" @click="toggleMenu">
            <i class="fa-solid fa-user"></i>
            Mon compte
          </RouterLink>
        </li>
        <li class="menu-item">
          <BaseLink href="https://follaw.crisp.help/fr/" @click="toggleMenu">
            <i class="fa-solid fa-question"></i>
            Centre d'aide
          </BaseLink>
        </li>
        <li class="menu-item" @click="logout()">
          <i class="fa-regular fa-arrow-right-from-bracket"></i>
          Me déconnecter
        </li>
      </ul>
    </div>
    <PopupDialog ref="confirmationDialog" title="Déconnexion">
      <template #body>
        <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
      </template>
      <template #footer>
        <button class="btn-delete" @click="confirmLogout()">
          Me déconnecter
        </button>
      </template>
    </PopupDialog>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import PopupDialog from "@/components/reusable/atoms/PopupDialog.vue";
import { useAuthStore } from "@/stores/auth";
import BaseLink from "../atoms/BaseLink.vue";

const authStore = useAuthStore();
const confirmationDialog = ref(null);

const isMenuOpen = ref(false);

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}

function logout() {
  isMenuOpen.value = false;
  confirmationDialog.value?.open();
}

function confirmLogout() {
  authStore.logoutRequest();
  confirmationDialog.value?.close();
}
</script>

<style lang="scss" scoped>
.user-menu {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .user-menu-trigger {
    border: #fff 2px solid;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: white;
    margin: 0 8px;

    i {
      font-size: 0.938em;
    }

    &:hover,
    &:focus {
      background-color: var(--clr-p5);
    }
    &:active {
      background-color: var(--clr-n8);
    }
  }

  .user-menu-options {
    position: absolute;
    top: 3.5em;
    right: -1em;
    width: 230px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    border-radius: 5px;
    padding: 8px;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.16);

    &:before {
      content: "";
      position: absolute;
      border: 0.75em solid;
      border-color: transparent transparent #fff transparent;
      right: 1.7em;
      top: -1.5rem;
    }

    .menu-list {
      list-style: none;
      padding: 0;
      margin: 0;

      display: flex;
      flex-direction: column;
      gap: 4px;

      .menu-item {
        cursor: pointer;
        border-radius: 8px;
        padding: 8px;

        a {
          color: var(--clr-n8);
        }

        i {
          margin-right: 8px;
        }

        &:last-child {
          color: var(--clr-e300);
        }

        &:hover {
          background-color: var(--clr-p1);
        }
      }
    }
  }
}
</style>
