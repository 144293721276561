import { ref } from "vue";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const toast = useToast();

function handleDigestCreationError(err) {
  if (
    err.response?.status === 403 &&
    err.response?.dataMsg === "Additional_query is too broad."
  ) {
    toast.error(
      "Le récapitulatif n'a pas été sauvegardé car la requête est trop large. Renseignez plus de mots clés ou de thématiques."
    );
  } else if (
    err.response?.status === 403 &&
    err.response?.dataMsg === "This alert has no filtering options."
  ) {
    toast.error(
      "Le récapitulatif n'a pas été sauvegardé car la requête est trop large. Renseignez plus de mots clés ou de thématiques."
    );
  } else {
    handleNetworkError(err);
  }
}

// Defining store
export const useDigestsStore = defineStore("digests", () => {
  const loading = ref(false);

  const digests = ref([]);
  const filters = ref({});

  // CREATE
  const createDigest = async (formData) => {
    loading.value = true;

    if (!formData) {
      return;
    }

    const payload = { ...formData };
    if (formData.saved_filter_id === "") {
      delete payload.saved_filter_id;
    }

    try {
      const response = await httpRequest("post", "/digests", payload);

      if (response.status === 200) {
        readDigests();
      }
    } catch (err) {
      handleDigestCreationError(err);
    } finally {
      loading.value = false;
    }
  };

  // READ
  const readDigests = async () => {
    loading.value = true;

    try {
      const response = await httpRequest("get", "/digests");

      if (response.data) {
        digests.value = response.data.items;
        filters.value = { ...response.data.filters };
      }
    } catch (err) {
      digests.value = [];
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  // UPDATE
  const updateDigest = async (id, updatedDigest) => {
    loading.value = true;

    const digest = digests.value.find((d) => d._id === id);

    const payload = {};

    for (const key in updatedDigest) {
      if (updatedDigest[key] !== digest[key]) {
        payload[key] = updatedDigest[key];
      }
    }

    if (updatedDigest.saved_filter_id === "") {
      payload.saved_filter_id = null;
    }

    if (Object.keys(payload).length === 0) {
      loading.value = false;
      return;
    }

    try {
      const response = await httpRequest("patch", `/digests/${id}`, payload);
      if (response.status === 200) {
        readDigests();
      }
    } catch (err) {
      handleDigestCreationError(err);
    } finally {
      loading.value = false;
    }
  };

  const toggleDigest = async (id) => {
    if (!id) {
      return;
    }

    const digest = digests.value.find((d) => d._id === id);

    try {
      const res = await httpRequest("patch", `/digests/${id}`, {
        active: digest.active === true ? false : true,
      });

      if (res.status === 200) {
        digest.active = !digest.active;
      }
    } catch (err) {
      handleNetworkError(err);
    }
  };

  // DELETE
  const deleteDigest = async (id) => {
    if (!id) {
      return;
    }

    const digest = digests.value.indexOf(
      digests.value.find((d) => d._id === id)
    );

    digests.value.splice(digest, 1);

    try {
      await httpRequest("delete", `/digests/${id}`);
    } catch (err) {
      handleNetworkError(err);
    }
  };

  return {
    loading,
    digests,
    filters,
    createDigest,
    readDigests,
    updateDigest,
    toggleDigest,
    deleteDigest,
  };
});
