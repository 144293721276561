<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          v-if="!store.isFollawedActive"
          filter="author"
          :items="store.authors"
          item-label="name"
          item-value="_id"
          :active-filters="selectedFilters.author"
          :is-loading="store.isAuthorFilterLoading"
          mode="purple"
          @search="fetchSearchAuthors"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetAuthorFilter"
        >
          Auteurs
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>

      <FollawedRadioButtons
        all-match-route="/legislatives"
        follawed-route="/legislatives/follawed"
      />
    </div>

    <div class="params-row">
      <SwitchSlide :is-active="!store.bypassQuery" @toggle="toggleUseRequest"
        >Utiliser la requête</SwitchSlide
      >
    </div>

    <div class="params-row">
      <TotalMatched :item-count="store.item_count" feminine />
      <SelectItemsLimit
        :items-limit="selectedFilters.size"
        @change-limit="changeLimit"
      >
        Textes
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";

import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

// All filters in wanted display order with their labels
const filtersNames = {
  current_institution: "Institution",
  nature: "Nature de texte",
  current_etape: "Étape",
};
const store = useLegiWorkStore();
const { selectedFilters } = storeToRefs(store);
const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchLegiWorkList();
};
const resetAllFilters = () => {
  store.resetFilters();
  fetchLegiWorkList();
};

const fetchLegiWorkList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchLegiWorkList();
};
const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];

  const itemToAdd = event.item;

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, itemToAdd],
  };
  fetchLegiWorkList();
};

const showScrollTop = computed(() => selectedFilters.value.size > 20);

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] || [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchLegiWorkList();
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  return arr;
});

const toggleUseRequest = () => {
  store.bypassQuery = !store.bypassQuery;
  store.fetchLegiWorkList();
};

const fetchSearchAuthors = (nameSearch) => {
  if (nameSearch.length > 0) {
    store.getSearchAuthorsFilters(nameSearch);
  } else {
    store.authors = [];
  }
};
const resetAuthorFilter = () => {
  store.authors = [];
  delete selectedFilters.value.author;
  fetchLegiWorkList();
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchLegiWorkList();
};
</script>
