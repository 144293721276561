<template>
  <FloatingBrowseOccurrences v-if="hasHighlights" />

  <LoaderFullPage v-if="loadingOjSenat" />
  <div v-else class="oj-senat">
    <div class="card">
      <div class="header-name">
        <h4>Ordre du jour des séances publique du Sénat</h4>
        <FollawButton
          :feature="FOLLAWED_FEATURES.ORDRE_DU_JOUR_SN"
          :element-id="ojSenatId"
        />
      </div>
      <p>Mise à jour: {{ getDayTimeFormat(ojSenatLastUpdate) }}</p>
      <p><BaseLink :href="ojSenatUrl">Source</BaseLink></p>
      <OccurrencesCollapse
        v-if="ojSenatOccurrences"
        :occurrences="ojSenatOccurrences"
      />
    </div>

    <div class="card typo-body-regular">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="ojSenat"></div>
    </div>
  </div>
</template>

<script setup>
// The OJ of the Senate is a special document
// Because of its significance, it is highlighted in its own section within the Follaw side-bar

// Note: this view's responsibility is limited to showing a single document,
// so we are including the store logic in the component setup code to keep it simple
// (If it gets too complex, we can always split the logic to its own store file)

import { computed, ref } from "vue";
import { storeToRefs } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { getDayTimeFormat } from "@/utils/formatDate";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import { useUserStore } from "@/stores/user";
import FloatingBrowseOccurrences from "@/components/reusable/atoms/FloatingBrowseOccurrences.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import OccurrencesCollapse from "@/components/reusable/atoms/OccurrencesCollapse.vue";
import { handleNetworkError } from "@/utils/handleNetworkError";
import { FOLLAWED_FEATURES } from "@/stores/user";

const userStore = useUserStore();

const loadingOjSenat = ref(true);
const ojSenatId = ref(null);
const ojSenat = ref(null);
const ojSenatLastUpdate = ref(null);
const ojSenatUrl = ref(null);
const ojSenatOccurrences = ref({});
const { sharedFiltersAsPayload: topbarPayload } = storeToRefs(userStore);

const hasHighlights = computed(() => ojSenat.value?.includes("mark class"));

async function loadOjSenat() {
  loadingOjSenat.value = true;

  try {
    const res = await httpRequest(
      "post",
      "/ordre-du-jour-sn",
      topbarPayload.value
    );
    ojSenatId.value = res.data._id;
    ojSenat.value = res.data.content;
    ojSenatLastUpdate.value = res.data.last_update;
    ojSenatUrl.value = res.data.url;
    ojSenatOccurrences.value = res.data.occurrences;
  } catch (err) {
    handleNetworkError(err);
  } finally {
    loadingOjSenat.value = false;
  }
}

loadOjSenat();
</script>

<style scoped lang="scss">
.oj-senat {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header-name {
  display: flex;
  justify-content: space-between;

  h4 {
    width: 80%;
  }
}
</style>
