<template>
  <BarChart
    :chart-data="chartData"
    :height="hasLegend ? 70 : 40"
    :options="chartOptions"
    :width="450"
  />
</template>

<script setup>
import { computed } from "vue";
import { BarChart } from "vue-chart-3";

const props = defineProps({
  data: { type: Object, required: true },
  hasLegend: { type: Boolean, default: false },
});

const AM_STATUS_COLOR_MAP = {
  adopté: "#00C726",
  refusé: "#ED2424",
  retiré: "#FC9B00",
  irrecevable: "#BC0000",
  "irrecevable 40": "#A800D0",
  tombé: "indianred",
  rejeté: "firebrick",
  // temp values to remove once finalized
  "en traitement": "bisque",
  "a discuter": "aquamarine",
  discuté: "darkseagreen",
};

const chartData = computed(() => ({
  labels: ["Amendements"],
  datasets: Object.entries(props.data).map(([status, count]) => ({
    label: status,
    backgroundColor: AM_STATUS_COLOR_MAP[status],
    data: [count],
  })),
}));

const chartOptions = computed(() => ({
  indexAxis: "y",
  plugins: {
    legend: props.hasLegend
      ? {
          position: "bottom",
          labels: {
            color: "black",
            usePointStyle: true,
          },
        }
      : { display: false },
  },
  scales: {
    x: {
      display: false,
      stacked: true,
    },
    y: {
      display: false,
      stacked: true,
    },
  },
}));
</script>
