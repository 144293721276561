<template>
  <div class="template">
    <QuestionsParams />
    <LoaderFullPage v-if="store.loading" />
    <template v-else>
      <QuestionsDatas v-if="isDataAvailable || store.isFollawedActive" />
      <NoData v-else />
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import QuestionsParams from "@/components/questions/organisms/QuestionsParams.vue";
import QuestionsDatas from "@/components/questions/organisms/QuestionsDatas.vue";
import { useQuestionsStore } from "@/stores/questions.js";

const store = useQuestionsStore();
const router = useRouter();

const isDataAvailable = computed(() => {
  return (
    store.items?.length > 0 ||
    store.top3Ministries?.length > 0 ||
    store.similarQuestions?.length > 0 ||
    store.questionsType?.length > 0
  );
});

store.isFollawedActive =
  router.currentRoute.value.fullPath.includes("follawed");

store.selectedFilters.from = 0;
store.fetchQuestionsStats();
store.fetchQuestionsList();
</script>
