import { ref } from "vue";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const toast = useToast();

function handleAlertCreationError(err) {
  if (
    err.response?.status === 403 &&
    err.response?.dataMsg === "Additional_query is too broad."
  ) {
    toast.error(
      "L'alerte n'a pas été sauvegardée car elle est trop large. Renseignez plus de mots clés ou de thématiques."
    );
  } else if (
    err.response?.status === 403 &&
    err.response?.dataMsg === "This alert has no filtering options."
  ) {
    toast.error(
      "L'alerte n'a pas été sauvegardée car elle est trop large. Renseignez plus de mots clés ou de thématiques."
    );
  } else {
    handleNetworkError(err);
  }
}

// Defining store
export const useAlertsStore = defineStore("alerts", () => {
  const loading = ref(false);

  const alerts = ref([]);
  const filters = ref([]);

  // CREATE
  const createAlert = async (formData) => {
    loading.value = true;

    if (!formData) {
      return;
    }

    const payload = { ...formData };
    if (formData.saved_filter_id === "") {
      delete payload.saved_filter_id;
    }

    try {
      const response = await httpRequest("post", "/alerts", payload);

      if (response.status === 200) {
        readAlerts();
      }
    } catch (err) {
      handleAlertCreationError(err);
    } finally {
      loading.value = false;
    }
  };

  // READ
  const readAlerts = async () => {
    loading.value = true;

    try {
      // Limit the fetching to 50 alerts
      // We don't expect users with a normal use to have more than 50 alerts
      // Putting this limitation is a soft limit and we might reconsider it based on user feedback
      const response = await httpRequest("get", "/alerts?size=50");

      if (response.data) {
        alerts.value = response.data.items;
        filters.value = response.data.filters;
      }
    } catch (err) {
      alerts.value = [];
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  // UPDATE
  const updateAlert = async (id, updatedAlert) => {
    loading.value = true;

    const alert = alerts.value.find((a) => a._id === id);

    const payload = {};

    for (const key in updatedAlert) {
      if (updatedAlert[key] !== alert[key]) {
        payload[key] = updatedAlert[key];
      }
    }

    if (updatedAlert.saved_filter_id === "") {
      payload.saved_filter_id = null;
    }

    if (Object.keys(payload).length === 0) {
      loading.value = false;
      return;
    }

    try {
      const response = await httpRequest("patch", `/alerts/${id}`, payload);
      if (response.status === 200) {
        readAlerts();
      }
    } catch (err) {
      handleAlertCreationError(err);
    } finally {
      loading.value = false;
    }
  };

  const toggleAlert = async (id) => {
    if (!id) {
      return;
    }

    const alert = alerts.value.find((a) => a._id === id);

    try {
      const res = await httpRequest("patch", `/alerts/${id}`, {
        active: alert.active === true ? false : true,
      });

      if (res.status === 200) {
        alert.active = !alert.active;
      }
    } catch (err) {
      handleNetworkError(err);
    }
  };

  // DELETE
  const deleteAlert = async (id) => {
    if (!id) {
      return;
    }

    const alert = alerts.value.indexOf(alerts.value.find((a) => a._id === id));

    alerts.value.splice(alert, 1);

    try {
      await httpRequest("delete", `/alerts/${id}`);
    } catch (err) {
      handleNetworkError(err);
    }
  };

  return {
    loading,
    alerts,
    filters,
    createAlert,
    readAlerts,
    updateAlert,
    toggleAlert,
    deleteAlert,
  };
});
