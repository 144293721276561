<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <h1 class="headline-small-regular">Nouveautés</h1>
      <FollawedRadioButtons all-match-route="/" follawed-route="/follawed" />
    </div>
    <div class="params-row justify-start">
      <CheckBox
        :checked="isAllSelected"
        @change="
          () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
        "
      >
        Toutes les activités
      </CheckBox>
    </div>
    <div class="params-row justify-start">
      <FilterCheckBox
        v-for="corpus in corpusFilters"
        :key="corpus.value"
        :label="corpus.label"
        :color="corpus.color"
        :item-count="store.itemsCounts[corpus.value]"
        :is-checked="store.selectedCorpuses.includes(corpus.value)"
        @check="addFilter(corpus.value)"
        @uncheck="removeFilter(corpus.value)"
      />
    </div>
    <div class="params-row">
      <SwitchSlide :is-active="!store.bypassQuery" @toggle="toggleUseRequest"
        >Utiliser la requête</SwitchSlide
      >
      <SelectItemsLimit
        :items-limit="basePayload.size"
        @change-limit="changeLimit"
      >
        Éléments
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useHomepageStore } from "@/stores/homepage";

const store = useHomepageStore();
const { basePayload } = storeToRefs(store);

const showScrollTop = computed(() => basePayload.value.size > 20);

const corpusFilters = [
  {
    label: "Dossiers Législatifs",
    color: "var(--clr-service-legidoc)",
    value: store.CORPUSES.LEGIDOCS,
  },
  {
    label: "JORF",
    color: "var(--clr-service-jorf)",
    value: store.CORPUSES.JORFS,
  },
  {
    label: "Amendements",
    color: "var(--clr-service-amendment)",
    value: store.CORPUSES.AMENDMENTS,
  },
  {
    label: "Questions",
    color: "var(--clr-service-question)",
    value: store.CORPUSES.QUESTIONS,
  },
  {
    label: "Comptes-rendus",
    color: "var(--clr-service-report)",
    value: store.CORPUSES.REPORTS,
  },
  {
    label: "Publications",
    color: "var(--clr-service-twitter)",
    value: store.CORPUSES.POSTS,
  },
  // <!--TODO : uncomment once back is fixed (receive news with type "article")
  // {
  //   label: "Articles",
  //   color: "var(--clr-service-article)",
  //   value: store.CORPUSES.ARTICLES,
  // },
  {
    label: "Nominations",
    color: "var(--clr-service-nominations)",
    value: store.CORPUSES.NOMINATIONS,
  },
  {
    label: "Agenda",
    color: "var(--clr-service-agenda)",
    value: store.CORPUSES.AGENDA,
  },
  {
    label: "Communiqués",
    color: "var(--clr-service-news)",
    value: store.CORPUSES.NEWS,
  },
];

const addFilter = (filter) => {
  store.selectedCorpuses.push(filter);
  fetchHomepage();
};

const removeFilter = (filter) => {
  store.selectedCorpuses = store.selectedCorpuses.filter((f) => f !== filter);
  fetchHomepage();
};

const selectAllFilters = () => {
  store.selectedCorpuses = Object.values(store.CORPUSES);
  fetchHomepage();
};

const unselectAllFilters = () => {
  store.selectedCorpuses = [];
  fetchHomepage();
};

const isAllSelected = computed(
  () => store.selectedCorpuses.length === Object.keys(store.CORPUSES)?.length
);

const toggleUseRequest = () => {
  store.bypassQuery = !store.bypassQuery;
  fetchHomepage();
};

const changeLimit = (size) => {
  basePayload.value.size = size;
  fetchHomepage();
};

const fetchHomepage = () => {
  basePayload.value.from = 0;
  store.fetchHomepage();
};
</script>

<style lang="scss" scoped>
.justify-start {
  justify-content: start;
}
</style>
