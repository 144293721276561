<template>
  <div class="englobe-datas">
    <LegiDocZoomHeader :legi-work="legiWorkZoom" />

    <div class="top-nav">
      <RouterRadioButtons :options="textOptions" />
    </div>

    <RouterView />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { RouterView } from "vue-router";
import { storeToRefs } from "pinia";

import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import side_menu from "@/utils/side_menu";
import LegiDocZoomHeader from "../molecules/LegiDocZoomHeader.vue";

const store = useLegiWorkStore();

const { legiWorkZoom } = storeToRefs(store);

const legiRef = computed(() => legiWorkZoom.value.ref);

// Build navbar options from router config
const routerOptions = side_menu
  .find((route) => route.name === "Travaux parlementaires")
  .secondary_tabs.find((tab) => tab.name === "Dossiers législatifs")
  .secondary_routes.find(
    (route) => route.path === "/legislatives/:ref"
  ).children;
const textOptions = routerOptions.map((route) => ({
  label: route.name.replace("Zoom Follawed Dossiers Législatifs - ", ""),
  value: `/legislatives/${legiRef.value}/${route.path}`,
}));
</script>

<style scoped lang="scss">
.englobe-datas {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.top-nav {
  display: flex;
  align-items: flex-start;
}
</style>
