<template>
  <TimelineAmendmentsParams />
  <LoaderFullPage v-if="amendmentsStore.loading" />
  <AmendmentsDatas v-else-if="amendmentsStore.items.length > 0" />
  <div v-else class="empty">
    <img
      src="@/assets/img/legislation/empty-states/no-amendments-datas.svg"
      aria-hidden="true"
    />
    <h4>Pas d'amendement à afficher</h4>
    <p>Aucun amendement n'a été proposé sur ce texte.</p>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useAmendmentsStore } from "@/stores/amendments";
import { useLegiWorkStore } from "@/stores/legi-work";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import AmendmentsDatas from "@/components/amendments/organisms/AmendmentsDatas.vue";
import TimelineAmendmentsParams from "@/components/legi-work/organisms/TimelineAmendmentsParams.vue";
import { onUnmounted } from "vue";

const route = useRoute();
const { stepIdx } = route.params;

const store = useLegiWorkStore();
const amendmentsStore = useAmendmentsStore();
const step = store.legiWorkZoom.timeline[stepIdx];

const isCommission = route.path.includes("/commission");

// Configure the store in legi-doc mode according to the route
amendmentsStore.selectedFilters.text_ref = isCommission
  ? [step.texte_associe]
  : [step.debats_seance.text_in];
amendmentsStore.selectedFilters.bypass_time_range = true;
amendmentsStore.selectedFilters.type = isCommission ? "commissions" : "session";

onUnmounted(() => amendmentsStore.resetFilters());

amendmentsStore.fetchAmendmentsList();
</script>
<style scoped lang="scss">
img {
  max-width: 271px;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  flex-direction: column;
  gap: 8px;
}
</style>
