<template>
  <div class="englobe-datas">
    <!-- Card -->
    <JorfZoomCard
      v-if="!jorfsStore.loading && jorfsStore.jorfZoom"
      :key="jorfsStore.jorfZoom._id"
      :jorf="jorfsStore.jorfZoom"
    />
  </div>
</template>

<script setup>
import { useJorfsStore } from "@/stores/jorfs";
import JorfZoomCard from "../molecules/JorfZoomCard.vue";

const jorfsStore = useJorfsStore();
</script>
