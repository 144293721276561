<template>
  <AuthMobileView v-if="isMobile" />
  <AuthDesktopView v-else />
</template>

<script setup>
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";
import AuthMobileView from "./auth/AuthMobileView.vue";
import AuthDesktopView from "./auth/AuthDesktopView.vue";

const { width } = useWindowSize();

const isMobile = computed(() => width.value < 1000);
</script>
