<template>
  <div class="template agenda-view-wrapper">
    <AgendaParams />
    <AgendaDatas />
  </div>
</template>

<script setup>
import AgendaParams from "@/components/agenda/templates/AgendaParams.vue";
import AgendaDatas from "@/components/agenda/templates/AgendaDatas.vue";
import { useAgendaStore } from "@/stores/agenda";

const store = useAgendaStore();

store.selectedFilters.from = 0;
store.fetchEvents();
</script>
