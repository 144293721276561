<template>
  <AbstractSelectMultiple
    :filter="filter"
    :items="items"
    :item-label="itemLabel"
    :item-value="itemValue"
    :active-filters="activeFilters"
    :mode="mode"
    :has-search="hasSearch"
    :disabled="disabled"
    @reset-filter="handleResetFilter"
    @add-filter-item="handleAddFilterItem"
    @remove-filter-item="handleRemoveFilterItem"
  >
    <template #label>
      <slot> </slot>
    </template>

    <template #badge>
      <span v-if="activeFilters.length" class="nb-selected" :class="mode">
        {{ activeFilters.length }}
      </span>
    </template>
  </AbstractSelectMultiple>
</template>

<script setup>
import AbstractSelectMultiple from "./AbstractSelectMultiple.vue";

defineProps({
  filter: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  itemLabel: {
    type: String,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  activeFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  mode: {
    type: String,
    validator(value) {
      // The value must match one of these strings
      return ["purple", "blue", "topbar"].includes(value);
    },
    default: "purple",
  },
  hasSearch: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["resetFilter", "addFilterItem", "removeFilterItem"]);

const handleResetFilter = (filter) => {
  emit("resetFilter", filter);
};

const handleAddFilterItem = (item) => {
  emit("addFilterItem", item);
};

const handleRemoveFilterItem = (item) => {
  emit("removeFilterItem", item);
};
</script>
<style lang="scss" scoped>
.purple,
.topbar {
  color: var(--clr-n8);
  .nb-selected,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-p2);
  }
}

/* Blue styles
========================================================================== */
.blue {
  .nb-selected,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-s2);
  }
}

/* Whole component
========================================================================== */
.filter-multiples {
  .select-box {
    .tools {
      .nb-selected {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 5px;
        border-radius: 12px;
        min-width: 20px;
        height: 20px;
        line-height: initial;
        font-size: 14px;
      }
    }
  }
}
</style>
