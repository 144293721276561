<template>
  <component :is="getPlatformComponent(post.data_type)" v-bind="props" />
</template>

<script setup>
import BlueskyCard from "./BlueskyCard.vue";
import InstagramCard from "./InstagramCard.vue";
import LinkedinCard from "./LinkedinCard.vue";
import TiktokCard from "./TiktokCard.vue";
import XCard from "./XCard.vue";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
});

const socialMediaCardComponents = {
  x: XCard,
  linkedin: LinkedinCard,
  instagram: InstagramCard,
  tiktok: TiktokCard,
  bluesky: BlueskyCard,
};

const getPlatformComponent = (dataType) => socialMediaCardComponents[dataType];
</script>
