<template>
  <section class="body">
    <div class="title-medium-semibold">{{ props.jorf.title }}</div>

    <div class="subtitle title-medium-semibold">{{ props.jorf.issuer }}</div>

    <div v-if="props.jorf.matched_words?.length > 0" class="content">
      <span class="subtitle title-medium-regular">Mots matchés : </span>
      <span data-testid="matched words">{{ listOfWords }}</span>
    </div>
  </section>
  <section class="footer">
    <SourceLink
      :href="`https://www.legifrance.gouv.fr/jorf/id/${props.jorf.jorftext_id}`"
      >{{ props.jorf.jorf_title || "Voir JORF" }}</SourceLink
    >
  </section>
</template>

<script setup>
import { computed } from "vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";

const props = defineProps({
  jorf: {
    type: Object,
    required: true,
  },
});

const listOfWords = computed(() =>
  props.jorf.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
}

.subtitle {
  color: var(--clr-s7);
}

.footer {
  display: flex;
  justify-content: flex-end;
}
</style>
