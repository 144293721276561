<template>
  <SocialsViewWrapper />
</template>

<script setup>
import { useRouter } from "vue-router";
import SocialsViewWrapper from "@/components/social-media/templates/SocialsViewWrapper.vue";
import { useUserSettingsStore } from "@/stores/user-settings";

const userSettingsStore = useUserSettingsStore();
const router = useRouter();

// If you have access but got on this page manually, redirect to the overview
if (userSettingsStore.hasPermission("SOCIETAL")) {
  router.push("/twitter-overview");
}
</script>
