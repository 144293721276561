<template>
  <div v-if="store.isActivitiesLoading">
    <LoaderFullPage />
  </div>

  <template v-else-if="store.lobbiesActivities?.length">
    <div class="activities-wrapper" :class="{ zoomed: !isFollawed }">
      <template
        v-for="item in store.lobbiesActivities"
        :key="item._id || item.id_twitter"
      >
        <PublicationCard
          v-if="Object.values(Platforms).includes(item.data_type)"
          :post="item"
        />
        <LobbyActivityCard v-else-if="item.type === 'lobby'" :activity="item" />
      </template>
    </div>
    <div class="pagination-wrapper">
      <PaginationElement
        v-if="totalCount"
        :key="`${totalCount}${store.selectedLobbiesActivitiesParams.from}${store.selectedLobbiesActivitiesParams.size}`"
        :total-items="totalCount"
        :current-page="store.selectedLobbiesActivitiesParams.from"
        :items-limit="store.selectedLobbiesActivitiesParams.size"
        @change-current-page="changeCurrentPage"
      />
    </div>
  </template>

  <div v-else class="card">
    <EmptySection :message="emptyMessage" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import LobbyActivityCard from "@/components/lobbies/atoms/LobbyActivityCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import EmptySection from "@/components/reusable/molecules/EmptySection.vue";
import { useLobbiesStore } from "@/stores/lobbies";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";
import { Platforms } from "@/utils/social360-platforms.js";

const store = useLobbiesStore();
const route = useRoute();

const isFollawed = ref(route.fullPath.includes("follawed") ? true : false);

const emptyMessage = computed(() => {
  return isFollawed.value
    ? "Vos organisations follawed n'ont pas d'activité."
    : "Cette organisation n'a pas d'activité.";
});

const totalCount = computed(() => {
  let count = 0;
  Object.values(store.activitiesCounts).forEach((c) => {
    count += c;
  });
  return count;
});

const changeCurrentPage = (from) => {
  store.selectedLobbiesActivitiesParams.from = from;
  isFollawed.value
    ? store.readFollawedActivities()
    : store.readOneLobbyActivities(route.params._id);
};
</script>

<style lang="scss" scoped>
.activities-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  &.zoomed {
    display: flex;
    flex-direction: column;
  }
}

.pagination-wrapper {
  grid-column: span 2;

  @container (max-width: 1100px) {
    grid-column: span 1;
  }
}
</style>
