<template>
  <ScrollTop v-if="store.groupZoom.composition.length > 0" />
  <div class="card">
    <CollapseSection v-for="role in rolesToDisplay" :key="role">
      <template #title
        >{{ capitalizeFirstLetter(role) }} ({{
          membersForRole(role).length
        }})</template
      >
      <template #content>
        <ul class="members-list">
          <li v-for="m in membersForRole(role)" :key="m._id">
            <MemberSmallCard :person="m" />
          </li>
        </ul>
      </template>
    </CollapseSection>
  </div>
</template>

<script setup>
import CollapseSection from "@/components/agenda/atoms/CollapseSection.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { computed } from "vue";
import MemberSmallCard from "../../components/institu-groups/molecules/MemberSmallCard.vue";
import { capitalizeFirstLetter } from "@/utils/helper.utils";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const store = useInstituGroupsStore();

// Distinguish unique roles from the members array
const rolesToDisplay = computed(() => {
  let roles = new Array(
    ...new Set(store.groupZoom.composition.map((member) => member.position))
  );

  // The roles are not known in advance, so we need a generic way to order them
  // => Use regexes to classify the roles
  // Then the desired order is : presidents, then vice-presidents, then secretaries, then members

  const presidentRegex = /pr.sident/i;
  const vicePresidentRegex = /vice.*pr.sident/i;
  const secretaryRegex = /secr.taire/i;
  const membersRegex = /membre/i;

  const presidentRoles = roles.filter(
    (r) => r.match(presidentRegex) && !r.match(vicePresidentRegex)
  );
  const vicePresidentRoles = roles.filter((r) => r.match(vicePresidentRegex));
  const secretaryRoles = roles.filter((r) => r.match(secretaryRegex));
  const memberRoles = roles.filter((r) => r.match(membersRegex));
  const otherRoles = roles.filter(
    (r) =>
      !r.match(presidentRegex) &&
      !r.match(vicePresidentRegex) &&
      !r.match(secretaryRegex) &&
      !r.match(membersRegex)
  );

  roles = [
    ...presidentRoles,
    ...vicePresidentRoles,
    ...secretaryRoles,
    ...otherRoles,
    ...memberRoles,
  ];

  return roles;
});

function membersForRole(role) {
  return store.groupZoom.composition.filter((m) => m.position === role);
}
</script>

<style scope lang="scss">
.members-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
</style>
