<template>
  <div class="items-limit" tabindex="0" @focusout="isMenuOpen = false">
    <label><slot>Dossiers</slot> par page&nbsp;:</label>
    <div class="select-box" @click="isMenuOpen = !isMenuOpen">
      <span class="selected">{{ props.itemsLimit }}</span>

      <span class="select-arrow">
        <!-- Open -->
        <i v-if="isMenuOpen" class="fa-solid fa-chevron-up"></i>
        <!-- Closed -->
        <i v-else class="fa-solid fa-chevron-down"></i>
      </span>
      <div v-if="isMenuOpen" class="menu">
        <div
          v-for="item in [10, 20, 50, 200]"
          :key="item"
          class="item"
          :class="{ active: item == props.itemsLimit }"
          @click="$emit('changeLimit', item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  itemsLimit: {
    type: Number,
    required: true,
  },
});
const isMenuOpen = ref(false);

defineEmits(["changeLimit"]);
</script>

<style lang="scss" scoped>
.items-limit {
  display: flex;
  width: fit-content;
  font-size: 0.938em;
  -webkit-user-select: none; // Safari
  user-select: none;

  .select-box {
    position: relative;
    vertical-align: bottom;
    display: inline-block;
    margin-left: 7px;
    box-shadow: 0 3px 5px #00000014;
    border-radius: 4px;
    width: 65px;
    max-height: 20px;
    padding: 2px 7px;
    background: white;
    cursor: pointer;
    z-index: 5;

    .select-arrow {
      position: absolute;
      top: 4px;
      right: 9px;
      font-size: 0.8em;
      pointer-events: none;

      .fa-chevron-down,
      .fa-chevron-up {
        color: var(--clr-p7);
      }
    }

    .menu {
      position: absolute;
      top: 22px;
      right: 0;
      box-shadow: 0 1px 6px #0000005d;
      border-radius: 4px;
      width: 100%;
      padding: 4px 0;
      overflow: hidden;
      background: white;

      .item {
        padding: 2px 0 0 4px;
        cursor: pointer;

        &:hover {
          background-color: var(--clr-p1);
        }

        &.active {
          background-color: var(--clr-p2);
        }
      }
    }
  }
}
</style>
