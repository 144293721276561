<template>
  <ScrollTop />
  <div v-if="store.loadingText"><LoaderFullPage /></div>
  <div v-else-if="!store.zoomedText">Pas de texte récupéré</div>
  <FullText
    v-else
    :content="store.zoomedText.content"
    :embed-amendments="store.zoomedTextAmendments"
    :occurrences="store.zoomedText.occurrences || {}"
  />
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

import { useLegiWorkStore } from "@/stores/legi-work";
import FullText from "@/components/legi-work/templates/FullText.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const store = useLegiWorkStore();
const route = useRoute();
const toast = useToast();

// Find text id based on the route (many routes use this component to render a full text)
const textId = computed(() => {
  const step = store.legiWorkZoom.timeline[route.params.stepIdx];
  if (
    route.path.includes("texte-initial") ||
    route.path.includes("commission")
  ) {
    return step.texte_associe;
  }

  if (route.path.includes("seance")) {
    return step.debats_seance.text_in;
  }

  if (route.path.includes("texte-scrutin")) {
    return step.debats_seance.texte_associe;
  }

  if (route.path.includes("cmp_lecture")) {
    const rapport = step.travaux_commission?.rapport?.find(
      (r) => r.texte_adopte
    );
    return rapport?.texte_adopte;
  }

  if (route.path.includes("prom_")) {
    return step.promulgation.texte_associe;
  }

  throw new Error(`Unrecognized path ${route.path}`);
});

const isCommission = route.path.includes("/commission");
if (textId.value) {
  store.loadZoomedText(textId.value, isCommission);
} else {
  toast.warning("Texte pas encore disponible");
}
</script>
