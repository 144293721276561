<template>
  <div class="card ranking-wrapper">
    <div class="rank-header">
      <h4 class="header-title">Groupes politiques</h4>
      <div class="header-tools">
        <RadioButtons
          :options="dataCategory"
          :active-option="activeCategory"
          @select-option="setActiveCategory"
        />
      </div>
    </div>
    <div v-if="props.isLoading">
      <div class="loading-wrapper">
        <hollow-dots-spinner
          :animation-duration="1000"
          :color="'var(--clr-p6)'"
        />
      </div>
    </div>

    <!-- Pie Chart component-->
    <div>
      <PieChart
        v-if="
          pieChartData.labels.length > 0 && pieChartData.datasets.length > 0
        "
        :height="200"
        :chart-data="pieChartData"
        :options="pieChartOptions"
      />
      <div v-else>
        <div class="no-data-wrapper">
          <NoData />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { HollowDotsSpinner } from "epic-spinners";
import { PieChart } from "vue-chart-3";

import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const props = defineProps({
  items: {
    type: Object,
    required: true,
  },
  isLoading: { type: Boolean, required: true },
});

const activeCategory = ref("total");

const totalItemsCount = computed(() => {
  return Object.values(props.items).reduce(
    (total, counts) => total + (counts[activeCategory.value] || 0),
    0
  );
});

const pieChartData = computed(() => {
  const data = props.items;

  if (!data) {
    return {
      labels: [],
      datasets: [],
    };
  }

  // Sort order based on active value
  const groupOrder = Object.keys(data).sort((g1, g2) => {
    const val1 = data[g1][activeCategory.value];
    const val2 = data[g2][activeCategory.value];
    return val2 - val1;
  });

  const labels = groupOrder.map(
    (groupAcronym) =>
      `${capitalizeFirstLetter(groupAcronym)} : ${(
        ((data[groupAcronym][activeCategory.value] || 0) /
          totalItemsCount.value) *
        100
      ).toFixed(2)}%`
  );

  return {
    labels,
    datasets: [
      {
        backgroundColor: groupOrder.map(
          (groupAcronym) => data[groupAcronym].color
        ),
        data: groupOrder.map(
          (groupAcronym) => data[groupAcronym][activeCategory.value]
        ),
      },
    ],
  };
});

const pieChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
      labels: {
        color: "black",
        usePointStyle: true,
        font: {
          size: 11,
        },
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

const dataCategory = [
  {
    label: "Activités",
    value: "total",
  },
  {
    label: "Amendements",
    value: "amendment",
  },
  {
    label: "Questions",
    value: "question",
  },
];

function setActiveCategory(newValue) {
  activeCategory.value = newValue;
}
</script>

<style scoped lang="scss">
.ranking-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .item-entry {
    width: 100%;

    display: flex;
    justify-content: space-between;
    font-size: 0.875em;
    color: black;
    padding: 8px 0;

    border-bottom: 1px solid var(--clr-p2);

    &:last-child {
      border-bottom: initial;
    }

    span {
      text-transform: capitalize;
    }
  }
}

.ranking-footer {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.rank-header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;
}

.loading-wrapper,
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
</style>
