import { createApp } from "vue";
import { createPinia } from "pinia";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Hotjar from "vue-hotjar";
import Toast, { POSITION } from "vue-toastification";
import { Chart, registerables } from "chart.js";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import * as Sentry from "@sentry/vue";
import { inject } from "@vercel/analytics";

import "@/assets/sass/override_toast.scss";
import "shepherd.js/dist/css/shepherd.css";

import App from "@/App.vue";
import router from "@/router";

import "./assets/sass/style.scss";

const IS_PRODUCTION = import.meta.env.VITE_ENV === "production";

// DayJS config
dayjs.extend(utc);
dayjs.locale("fr");
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

dayjs.extend(relativeTime, {
  rounding: Math.floor, // default is Math.round
});

// Toast config
const toastOptions = { position: POSITION.BOTTOM_CENTER };

Chart.register(...registerables);

const app = createApp(App)
  .use(createPinia())
  .use(Hotjar, {
    id: import.meta.env.VITE_HOTJAR_ID,
    isProduction: IS_PRODUCTION,
  })
  .use(router)
  .use(Toast, toastOptions);

Sentry.init({
  app,
  environment: import.meta.env.MODE || "development",
  dsn: import.meta.env.VITE_SENTRY_URL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
  // Control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://app.follaw.sv"],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%.
  replaysOnErrorSampleRate: 1.0, // Change the sample rate to 100% when sampling sessions where errors occur.
});

// Vercel Analytics
inject();

// Singleton config object, used to store global variables
window._fsv_config = {
  ACCESS_TOKEN_KEY: "follaw_access_token",
};

app.mount("#app");
