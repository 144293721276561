<template>
  <LoaderFullPage v-if="loading" />
  <template v-else-if="userSettingsStore.hasPermission('BASIC')">
    <div class="template">
      <div class="row">
        <h4>Mes enregistrements de filtres</h4>
        <button class="btn-primary" @click="openCreateDialog">
          <i
            class="fa-duotone fa-bookmark"
            style="
              --fa-primary-color: var(--clr-p1);
              --fa-secondary-color: var(--clr-p3);
            "
          ></i
          >Enregistrer ma sélection actuelle
        </button>
      </div>
      <div v-if="store.presets.length" class="row list">
        <FilterPresetsCard
          v-for="preset in store.presets.sort((a, b) =>
            a.name.localeCompare(b.name)
          )"
          :key="preset.id"
          :preset="preset"
          :thematics="userStore.thematics"
          @open-update="openUdpateDialog($event)"
          @open-delete="openDeleteDialog($event)"
          @apply="store.applyPreset(preset.id, $event)"
        />
      </div>
      <AbstractNoData v-else class="empty">
        <template #image>
          <img src="@/assets/img/empty_section.svg" aria-hidden="true" />
        </template>
        <template #title>Aucun filtre enregistré</template>
        <template #message>
          Enregistrez vos configurations de filtres pour pouvoir les
          ré-appliquer par la suite.
        </template>
      </AbstractNoData>
    </div>

    <PopupDialog
      ref="createModal"
      title="Sauvegarder ma sélection de filtres"
      @close="closeDialog"
    >
      <template #body>
        <AddEditPresetForm
          v-if="showForm"
          ref="createForm"
          @submit="createPreset($event)"
        />
      </template>
      <template #footer>
        <button class="btn-primary" @click="submitCreate">Sauvegarder</button>
      </template>
    </PopupDialog>

    <PopupDialog
      ref="updateModal"
      title="Éditer une sélection de filtres"
      @close="closeDialog"
    >
      <template #body>
        <AddEditPresetForm
          v-if="showForm"
          ref="updateForm"
          :targeted-preset="targetedPreset"
          @submit="updatePreset($event)"
        />
      </template>
      <template #footer>
        <button v-if="showForm" class="btn-primary" @click="submitUpdate">
          Éditer
        </button>
      </template>
    </PopupDialog>

    <PopupDialog
      ref="deleteModal"
      title="Supprimer une sélection de filtres"
      @close="closeDialog"
    >
      <template #body>
        <p class="modal-text">
          Êtes-vous sûr de vouloir supprimer la liste de filtres
        </p>
        <p class="modal-text">"{{ targetedPreset?.name }}" ?</p>
      </template>
      <template #footer>
        <button class="btn-delete" @click="deletePreset">Supprimer</button>
      </template>
    </PopupDialog>
  </template>
  <template v-else>
    <FreemiumUpgradePopup
      :closable="false"
      title="Enregistrement de vos recherches"
    >
      <p>Ce contenu est réservé aux abonnements payants.</p>
      <p>
        En activant celui-ci, vous pouvez sauvegarder tous vos critères de
        recherche pour retrouver facilement vos filtres et votre configuration.
      </p>
    </FreemiumUpgradePopup>
  </template>
</template>

<script setup>
import { ref, computed } from "vue";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import FilterPresetsCard from "@/components/general/molecules/FilterPresetsCard.vue";
import PopupDialog from "@/components/reusable/atoms/PopupDialog.vue";
import AddEditPresetForm from "@/components/general/molecules/AddEditPresetForm.vue";
import AbstractNoData from "@/components/reusable/abstract/AbstractNoData.vue";
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";

import { useFilterPresetsStore } from "@/stores/filter-presets";
import { useUserSettingsStore } from "@/stores/user-settings";
import { useUserStore } from "@/stores/user";

const store = useFilterPresetsStore();
const userSettingsStore = useUserSettingsStore();
const userStore = useUserStore();

const loading = computed(() => store.loading || userStore.loading);

// Modals
const showForm = ref(false);
const targetedPreset = ref(null);
const closeDialog = (modal) => {
  modal?.close();
  targetedPreset.value = null;
  showForm.value = false;
};

// CREATE
const createModal = ref();
const openCreateDialog = () => {
  showForm.value = true;
  createModal.value?.open();
};

const createForm = ref();
const submitCreate = () => {
  createForm.value?.form.requestSubmit();
};

const createPreset = (formData) => {
  if (createForm.value?.validate()) {
    store.createPreset(formData);
    closeDialog(createModal.value);
  }
};

// UPDATE
const updateModal = ref();
const openUdpateDialog = (preset) => {
  targetedPreset.value = preset;
  showForm.value = true;
  updateModal.value?.open();
};

const updateForm = ref();
const submitUpdate = () => {
  updateForm.value?.form.requestSubmit();
};

const updatePreset = (formData) => {
  if (updateForm.value?.validate()) {
    store.updatePreset(targetedPreset.value.id, formData);
    closeDialog(updateModal.value);
  }
};

// DELETE
const deleteModal = ref();
const openDeleteDialog = (preset) => {
  targetedPreset.value = preset;
  deleteModal.value?.open();
};
const deletePreset = () => {
  store.deletePreset(targetedPreset.value.id);
  closeDialog(deleteModal.value);
};
</script>

<style scoped>
p {
  margin: 0;
  line-height: var(--lh-normal);
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;

  &.list {
    justify-content: flex-start;
  }
}

.btn-primary i {
  margin-right: 10px;
}

.empty {
  flex: 1;
}
</style>
