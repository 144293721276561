<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink to="/news-releases" class="back">
        <i class="fa-solid fa-chevron-left"></i> Communiqués
      </RouterLink>
      <div
        v-if="store.previousNewsID || store.nextNewsID"
        class="previous-next-wrapper"
      >
        <!-- Next and Previous Items -->
        <RouterLink
          v-if="store.previousNewsID"
          :to="`/news-releases/${store.previousNewsID}`"
          class="btn-primary previous"
        >
          <i class="fa-solid fa-chevron-left" />Précédent
        </RouterLink>
        <RouterLink
          v-if="store.nextNewsID"
          :to="`/news-releases/${store.nextNewsID}`"
          class="btn-primary next"
        >
          Suivant<i class="fa-solid fa-chevron-right" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useNewsStore } from "@/stores/news";

const store = useNewsStore();
</script>

<style lang="scss" scoped>
.previous-next-wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}
</style>
