<template>
  <div class="wrapper">
    <div class="title">
      <h4>Navette parlementaire</h4>
      <SwitchSlide
        :is-active="store.docStepsFollawedOnly"
        @toggle="
          () => (store.docStepsFollawedOnly = !store.docStepsFollawedOnly)
        "
      />
      Follawed uniquement
    </div>
    <div v-if="store.loadingDocSteps">Chargement</div>
    <div v-else class="dashboard">
      <div
        v-for="status in Object.keys(store.docSteps)"
        :key="status"
        class="status-col card"
      >
        <div class="col-header">
          <h5>{{ status }}</h5>
          <div class="count-badge">{{ store.docSteps[status].length }}</div>
        </div>
        <ul>
          <li v-for="legiDoc in store.docSteps[status]" :key="legiDoc._id">
            <RouterLink :to="legiDocLinkUrl(legiDoc._id, legiDoc.ref)">
              <MiniLegiDocCard :legi-doc="legiDoc" />
            </RouterLink>
          </li>
        </ul>
      </div>
      <div v-if="Object.keys(store.docSteps).length === 0">
        Pas de dossiers législatifs en cours correspondant à votre requête.
      </div>
    </div>
  </div>
</template>

<script setup>
import { useLegiOverviewStore } from "@/stores/legi-overview";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import MiniLegiDocCard from "../molecules/MiniLegiDocCard.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";

function isFollawed(docId) {
  if (!userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]) {
    return false;
  }
  return userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]?.includes(docId);
}

function legiDocLinkUrl(docId, docRef) {
  return isFollawed(docId)
    ? `/legislatives/${docRef}/timeline`
    : `/legislatives/${docRef}/expose-motifs`;
}

const store = useLegiOverviewStore();
const userStore = useUserStore();
</script>

<style scoped lang="scss">
.dashboard {
  display: flex;
  gap: 5px;
  width: 100%;
  overflow-x: auto;
}

.status-col {
  padding: 5px;
  border-radius: 4px;
  width: 300px;
  height: fit-content;
  max-height: 500px;
  overflow-y: auto;

  .col-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-height: 85px;
    padding: 0 10px;

    h4 {
      margin-bottom: 10px 0;
    }

    .count-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      border-radius: 50%;
      width: 35px;
      height: 35px;

      background-color: var(--clr-p5);
      color: white;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;

    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;

  h3 {
    padding: 5px 0;
  }
}
</style>
