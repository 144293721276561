<template>
  <div class="template">
    <JorfsParams />
    <LoaderFullPage v-if="store.loading" />
    <JorfsDatas v-else />
  </div>
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import JorfsParams from "@/components/jorfs/organisms/JorfsParams.vue";
import JorfsDatas from "@/components/jorfs/organisms/JorfsDatas.vue";
import { useJorfsStore } from "@/stores/jorfs.js";
import { useRouter } from "vue-router";

const store = useJorfsStore();
const router = useRouter();

store.isFollawedActive =
  router.currentRoute.value.fullPath.includes("follawed");

store.selectedFilters.from = 0;
store.fetchJorfsList();
</script>
