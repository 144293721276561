<template>
  <div class="no-data">
    <div class="img">
      <slot name="image"></slot>
    </div>
    <h4 class="title">
      <slot name="title"></slot>
    </h4>

    <h6 class="title-large-regular">
      <slot name="message" />
    </h6>
  </div>
</template>

<style scoped lang="scss">
.no-data {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  color: var(--clr-n8);

  .img {
    margin-bottom: 20px;
    width: 30%;
  }
}
</style>
