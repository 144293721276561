<template>
  <div
    class="badge"
    :class="{ active: isActive }"
    :style="{ backgroundImage: `url(${url})` }"
  ></div>
</template>

<script setup>
import { computed } from "vue";

import AN_URL from "@/assets/img/legislation/assemblee.png";
import SN_URL from "@/assets/img/legislation/senat.png";
import LF_URL from "@/assets/img/legislation/legifrance.png";
import GOUV_URL from "@/assets/img/legislation/gouv_fr.png";
import CC_URL from "@/assets/img/legislation/Conseil_Constitutionnel.svg";

const props = defineProps({
  institution: { type: String, required: true },
  isActive: { type: Boolean, default: false },
});

const url = computed(() => {
  if (
    props.institution.match(/assembl.*?e nationale/i) ||
    props.institution === "AN"
  ) {
    return AN_URL;
  }

  if (props.institution.match(/s.*?nat/i) || props.institution === "SN") {
    return SN_URL;
  }
  if (props.institution.match(/l.*?gifrance/i)) {
    return LF_URL;
  }

  if (props.institution.match(/constitutionnel/i)) {
    return CC_URL;
  }

  const TODO_INSTITUTIONS = [
    "Commission Mixte Paritaire",
    "Journal Officiel",
    "Conseil Constitutionnel",
  ];
  if (TODO_INSTITUTIONS.includes(props.institution)) {
    return GOUV_URL;
  }

  return GOUV_URL;
});
</script>

<style scoped lang="scss">
.badge {
  width: 35px;
  height: 35px;
  border: 2px solid var(--clr-s2);
  border-radius: 50%;

  background-size: 35px;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;

  flex-shrink: 0; // Prevent the badge to shrink in flex contexts
}

.active {
  border: 2px solid var(--clr-s8);
}
</style>
