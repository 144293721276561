<template>
  <div class="template">
    <InstituGroupsParams />
    <LoaderFullPage v-if="store.loading" />
    <template v-else-if="store.groups.length > 0">
      <table class="institu-groups-list">
        <thead>
          <tr>
            <th
              class="clickable"
              :class="{
                active: isSortActive('name'),
              }"
              @click="() => changeSortOrder('name')"
            >
              Nom
              <i
                :class="`fa-solid fa-${
                  isSortActive('name') ? 'sort-down' : 'sort'
                }`"
              ></i>
            </th>
            <th>Type</th>
            <th class="centered">Institution</th>
            <th
              class="centered clickable"
              :class="{ active: isSortActive('number_of_members') }"
              @click="() => changeSortOrder('number_of_members')"
            >
              Membres
              <i
                :class="`fa-solid fa-${
                  isSortActive('number_of_members') ? 'sort-down' : 'sort'
                }`"
              ></i>
            </th>
            <th
              class="clickable"
              :class="{ active: isSortActive('updated_at') }"
              @click="() => changeSortOrder('updated_at')"
            >
              Dernière MaJ

              <i
                :class="`fa-solid fa-${
                  isSortActive('updated_at') ? 'sort-down' : 'sort'
                }`"
              ></i>
            </th>
            <th class="centered">Follaw</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="o in store.groups" :key="o._id">
            <td class="elem-name">
              <RouterLink :to="`/organes/${o._id}/composition`">
                {{ o.name }}</RouterLink
              >
            </td>
            <td class="group-type">{{ o.group_type }}</td>
            <td class="institution">
              <InstitutionBadge :institution="o.institution" />
            </td>
            <td class="nb-members">
              {{ o.number_of_members }}
            </td>
            <td>{{ getDateShortFormat(o.updated_at) }}</td>
            <td class="follaw">
              <FollawButton
                :element-id="o._id"
                :feature="FOLLAWED_FEATURES.COMMISSION"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <PaginationElement
        v-if="store.groupsCount"
        :key="`${store.groupsCount}${store.selectedFilters.from}${store.selectedFilters.size}`"
        :total-items="store.groupsCount"
        :current-page="store.selectedFilters.from"
        :items-limit="store.selectedFilters.size"
        @change-current-page="changeCurrentPage"
      />
    </template>
    <NoData v-else-if="store.hasActiveFilters" />
    <NoDataFollawed v-else-if="store.isFollawedActive" />
    <NoData v-else />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import InstituGroupsParams from "@/components/institu-groups/templates/InstituGroupsParams.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { FOLLAWED_FEATURES } from "@/stores/user";
import NoData from "@/components/reusable/atoms/NoData.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import InstitutionBadge from "@/components/legi-work/atoms/InstitutionBadge.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";

const store = useInstituGroupsStore();
const router = useRouter();

function changeCurrentPage(from) {
  store.selectedFilters.from = from;
  store.fetchInstituGroupsList();
}

function isSortActive(sortField) {
  return store.selectedFilters.sort_field === sortField;
}

function changeSortOrder(newSortOrder) {
  store.selectedFilters.sort_field = newSortOrder;
  store.selectedFilters.sort_order = newSortOrder === "name" ? "asc" : "desc";
  store.selectedFilters.from = 0;
  store.fetchInstituGroupsList();
}

store.isFollawedActive =
  router.currentRoute.value.fullPath.includes("follawed");

store.selectedFilters.from = 0;
store.fetchInstituGroupsList();
</script>

<style scoped lang="scss">
.institu-groups-list {
  tbody {
    tr {
      td {
        &.elem-name {
          max-width: 400px;
        }
        &.group-type {
          text-transform: capitalize;
        }

        &.nb-members {
          text-align: center;
        }

        &.institution,
        &.follaw {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
