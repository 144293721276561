import axios from "axios";

/**
 * Fetches TikTok thumbnail URL from the provided permalink.
 * https://developers.tiktok.com/doc/embed-videos
 * @param {string} permalink - The permalink of the TikTok video.
 * @returns {Promise<string>} - A promise that resolves to the thumbnail URL.
 * @throws {Error} - If there is a network error during the API call.
 */
export const fetchTikTokThumbnail = async (permalink) => {
  const cleanPermalink = permalink.split("?")[0];
  const apiUrl = `https://www.tiktok.com/oembed?url=${cleanPermalink}`;

  try {
    const response = await axios.get(apiUrl);
    return response.data.thumbnail_url;
  } catch (err) {
    if (err.code === "ERR_NETWORK") return null;
    throw err;
  }
};
