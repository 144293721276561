<template>
  <button
    :id="id"
    class="follaw-button"
    :class="{ follawed: isFollawed }"
    :data-product-tour="id === 'legiwork-follaw-btn-0' ? 'step-4' : null"
    @click.prevent.stop="clickButton"
  ></button>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();

const props = defineProps({
  id: {
    type: String,
    required: false,
    default: null,
  },
  elementId: {
    type: String,
    required: true,
  },
  feature: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["afterClick"]);

const isFollawed = computed(() => {
  if (!userStore.follawed[props.feature]) {
    return false;
  }
  return userStore.follawed[props.feature]?.includes(props.elementId);
});

function clickButton() {
  userStore.toggleFollawed(props.feature, props.elementId);
  emit("afterClick");
}
</script>

<style lang="scss" scoped>
.follaw-button {
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 30px;
  background: url("@/assets/img/follaw-buttons/btn-follaw-default.svg")
    no-repeat;
  background-size: auto;
  background-position-x: -8px;
  background-position-y: -4px;
  transition: 0.2s;

  &:hover,
  &:active {
    background: url("@/assets/img/follaw-buttons/btn-follaw-default-hover.svg")
      no-repeat;
    background-size: auto;
    background-position-x: -8px;
    background-position-y: -4px;
  }

  &.follawed {
    background: url("@/assets/img/follaw-buttons/btn-follaw-selected.svg")
      no-repeat;
    background-size: auto;
    background-position-x: -8px;
    background-position-y: -4px;

    &:hover,
    &:active {
      background: url("@/assets/img/follaw-buttons/btn-follaw-selected-hover.svg")
        no-repeat;
      background-size: auto;
      background-position-x: -8px;
      background-position-y: -4px;
    }
  }
}
</style>
