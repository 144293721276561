<template>
  <div class="template">
    <NewsParams />
    <LoaderFullPage v-if="store.loading" />
    <NewsDatas v-else />
  </div>
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import NewsParams from "@/components/news/organisms/NewsParams.vue";
import NewsDatas from "@/components/news/organisms/NewsDatas.vue";
import { useNewsStore } from "@/stores/news";

const store = useNewsStore();

store.selectedFilters.from = 0;
store.fetchNewsList();
</script>
