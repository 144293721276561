<template>
  <div class="card">
    <section class="head">
      <div class="post-date">
        {{ getDateShortFormat(amendment.published_at) }}
      </div>
      <div
        v-if="
          amendment.updated_at &&
          amendment.updated_at !== amendment.published_at
        "
        class="update-date"
      >
        Dernière MàJ :
        {{ getDateShortFormat(amendment.updated_at) }}
      </div>
      <SourceLink :href="absoluteSource">Source</SourceLink>
      <AmendmentActionTypeTag
        v-if="amendment.action"
        :action-type="amendment.action"
      />
    </section>

    <section class="body">
      <div class="title-follaw-wrapper">
        <div class="title">
          <div class="icon">
            <i class="fa-kit fa-xl fa-am"></i>
          </div>
          <div
            class="legi-title"
            @mouseenter="showDocLink = true"
            @mouseleave="showDocLink = false"
          >
            {{ amendment.legi_title }}
            <Transition name="fade">
              <div v-if="showDocLink" class="doc-link-hitbox">
                <div class="doc-link">
                  <RouterLink
                    :to="`/legislatives/${amendment.doc_ref}/expose-motifs`"
                    ><i class="fa-kit fa-dossierlegi"></i
                    ><span>Voir le dossier législatif</span></RouterLink
                  >
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <div class="follaw-button">
          <FollawButton
            :element-id="amendment._id"
            :feature="FOLLAWED_FEATURES.AMENDMENTS"
          />
        </div>
      </div>

      <div class="statuses-wrapper">
        <div class="status">
          <AmendmentStatusTag :status="amendment.status" />
        </div>
        <div class="status-long">
          <GenericTag v-if="amendment.status_long" background-color="#E7ECF4">{{
            amendment.status_long
          }}</GenericTag>
        </div>
      </div>

      <div class="author-and-infos-wrapper">
        <div class="author">
          <CardProfilePicture
            :picture-url="
              amendment.author?.photo ||
              '/src/assets/img/legislation/gouv_fr.png'
            "
            class="picture"
            :size="48"
          />
          <div v-if="amendment.author" class="author-infos">
            <span class="author-name">{{ amendment.author.name }}</span>
            <PrmLink :to="`/prm-national/${amendment.author._id}`" />
            <GroupTag
              v-if="amendment.author.group"
              :background-color="amendment.author.group.color"
            >
              {{ amendment.author.group.acronym }}
            </GroupTag>
            <div v-if="amendment.nb_cosignataires">
              + {{ amendment.nb_cosignataires }} cosignataire{{
                amendment.nb_cosignataires > 1 ? "s" : ""
              }}
            </div>
          </div>
        </div>
        <ul class="infos">
          <li>{{ amendment.title }}</li>
          <li>
            <i class="fa-solid fa-location-dot"></i>
            {{ getInstitutionsNames(amendment.institution) }}
          </li>
          <li>Amendement N° {{ amendment.number }}</li>
        </ul>
      </div>

      <div class="content">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="
            props.amendment.matched_words &&
            props.amendment.matched_words.length > 0
          "
          class="matched-words"
        >
          <h5 class="matched-title">
            Mot{{
              props.amendment.matched_words.length > 1 ? "s" : ""
            }}
            matché{{ props.amendment.matched_words.length > 1 ? "s" : "" }}
            :
          </h5>
          <p
            class="matched-words-list capitalize-letter"
            v-html="listOfWords"
          ></p>
        </div>

        <div class="responsive-row">
          <div class="summary">
            <h5>Exposé sommaire :</h5>
            <div
              class="summary-content typo-body-regular"
              v-html="amendment.expose_sommaire"
            />
          </div>

          <div class="dispositif">
            <h5>Amendement :</h5>
            <div
              class="dispositif-content typo-body-regular"
              v-html="amendment.dispositif"
            />
          </div>
        </div>

        <div v-if="amendment.original_text">
          <h5>Article original</h5>
          <p
            class="simulated-text-content typo-body-regular"
            v-html="amendment.original_text"
          ></p>
        </div>

        <div v-if="amendment.simulated_text">
          <h5>
            <i class="fa-light fa-robot"></i> Simulation de l'Article amendé par
            IA (BETA)
          </h5>
          <p
            class="simulated-text-content typo-body-regular"
            v-html="amendment.simulated_text"
          ></p>
        </div>

        <AmendmentArticleCollapse
          v-if="amendment.article_content"
          class="article"
          :article="amendment.article_content"
        />

        <ListOfDeputies
          :deputies-list="amendment.co_signataires"
          title="Co-signataires"
        />

        <p v-if="amendment.co_signataires.length > 0">
          Pourcentage de co-signataires du même groupe que l'auteur :
          {{ authorGroupPercentage }}% -
          <span class="amendment-type"
            >Amendement de
            {{ authorGroupPercentage < 100 ? "coalition" : "groupe" }}</span
          >
        </p>
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import AmendmentStatusTag from "../atoms/AmendmentStatusTag.vue";
import AmendmentActionTypeTag from "@/components/reusable/atoms/AmendmentActionTypeTag.vue";
import AmendmentArticleCollapse from "../atoms/AmendmentArticleCollapse.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import { FOLLAWED_FEATURES } from "@/stores/user";
import { getInstitutionsNames } from "@/utils/institutions.utils";
import ListOfDeputies from "@/components/reusable/molecules/ListOfDeputies.vue";
import PrmLink from "@/components/social-media/atoms/PrmLink.vue";
const props = defineProps({
  amendment: {
    type: Object,
    required: true,
  },
});

const authorGroupPercentage = computed(() => {
  if (
    !props.amendment.author ||
    !props.amendment.author.group ||
    !props.amendment.co_signataires
  ) {
    return 0;
  }

  const authorGroup = props.amendment.author.group.acronym;
  // Remove non db cosigners from the computation
  // In some cases, a personnality is not in our db yet, so we only know it's Parliamentary ID and not it's group.
  // To avoid such personnalities to distort our result, we exclude them from the calculation
  const cosignersWithKnownGroup = props.amendment.co_signataires.filter(
    (cosigner) => cosigner.group
  );
  const cosignersFromAuthorGroup = cosignersWithKnownGroup.filter(
    (cosigner) => cosigner.group.acronym === authorGroup
  );

  if (cosignersWithKnownGroup.length === 0) {
    return 0;
  }

  const percentage =
    (cosignersFromAuthorGroup.length / cosignersWithKnownGroup.length) * 100;
  return Math.round(percentage);
});

const listOfWords = computed(() =>
  props.amendment.matched_words?.filter((_, idx) => idx < 10).join(", ")
);

// Some links are scraped as relative links
// Workaround: force links to be absolute
const absoluteSource = computed(() =>
  props.amendment.source.match(/^https?:\/\//)
    ? props.amendment.source
    : `//${props.amendment.source}`
);

const showDocLink = ref(false);
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  color: var(--clr-n8);
}

.head {
  border-bottom: 1px solid var(--clr-service-amendment);
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.938em;

  .update-date {
    width: 100px;
  }
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title-follaw-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .title {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 50%;
      max-width: 32px;
      min-width: 32px;
      height: 32px;
      color: white;
      background-color: var(--clr-service-amendment);
    }

    .legi-title {
      position: relative;
      flex-grow: 1;
      font-weight: 600;

      &:hover {
        color: var(--clr-s7);
      }

      .doc-link-hitbox {
        position: absolute;
        z-index: 2;
        left: 75px;
        bottom: -33px;
        padding-top: 10px;

        .doc-link {
          position: relative;
          box-shadow: 0px 0px 7px 4px #00000014;
          border-radius: 4px;
          padding: 5px;
          color: var(--clr-link-default);
          background-color: white;
          font-weight: 400;

          &::after {
            content: "";
            position: absolute;
            border-width: 5px;
            border-style: solid;
            bottom: 100%;
            left: 8px;
            border-color: transparent transparent white transparent;
          }

          &:hover {
            text-decoration: underline;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.statuses-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 0.938em;
  font-weight: 700;
  .tag {
    text-transform: capitalize;
  }
}
.responsive-row {
  display: grid;
  gap: 25px;
  grid-template-columns: 1fr 1fr;

  @container (width < 1100px) {
    grid-template-columns: 1fr;
  }
}

.author-and-infos-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;

  .author {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.938em;
    border-right: 1px solid var(--clr-s3);

    .author-infos {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .author-name {
        text-transform: capitalize;
      }
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding-left: 10px;
    line-height: 20.46px;
    font-weight: 300;
    color: var(--clr-s7);
    list-style-type: none;
  }
}
.capitalize {
  text-transform: capitalize;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    margin: 0;
    padding: 0;
  }

  h5 {
    margin: 10px 0 7px 0;
    font-size: 1rem;
  }

  .summary {
    line-height: normal;
  }

  .matched-words,
  .summary-content,
  .dispositif-content {
    p,
    :deep(p) {
      margin: 0;
    }
  }
  .matched-words {
    .matched-words-list {
      display: block;
    }
  }

  .article {
    margin-top: 10px;
  }

  .amendment-type {
    font-weight: bold;
  }
}

.simulated-text-content {
  font-weight: 300;
  p {
    margin: 0;
  }
}
</style>
