<template>
  <div class="stakeholders-preview-zoom">
    <div
      v-if="storeStakeholder.isZoomTimeCurveLoading"
      class="stakeholders-preview-zoom__loader"
    >
      <LoaderCircle />
    </div>

    <div v-else-if="isZoomTimeCurveEmpty()" class="card">
      <EmptyChart />
    </div>

    <StakeholdersZoomTimeCurve v-else />
  </div>
</template>

<script setup>
import StakeholdersZoomTimeCurve from "@/components/social-media/stakeholders/organisms/StakeholdersZoomTimeCurve.vue";
import EmptyChart from "@/components/reusable/molecules/EmptyChart.vue";
import LoaderCircle from "@/components/reusable/atoms/LoaderCircle.vue";

import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import { useUserSettingsStore } from "@/stores/user-settings";

const userSettingsStore = useUserSettingsStore();
const storeStakeholder = useStakeholdersStore();

const isZoomTimeCurveEmpty = () =>
  storeStakeholder.zoomTimeCurve &&
  Object.values(storeStakeholder.zoomTimeCurve).every(
    (value) => value === null
  );

if (userSettingsStore.hasPermission("SOCIETAL")) {
  storeStakeholder.getZoomedAuthorTimeCurve();
}
</script>

<style scoped lang="scss">
.stakeholders-preview-zoom {
  display: grid;
  gap: 8px;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
