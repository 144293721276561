<template>
  <Teleport :to="teleportTarget">
    <div v-show="isVisible" class="buttons-group">
      <button title="Revenir en haut de la page" @click="scrollToTop">
        <i class="fa-solid fa-chevrons-up"></i>
      </button>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, toRef, onMounted } from "vue";
import { useEventListener } from "@vueuse/core";

const isVisible = ref(false);

const props = defineProps({
  teleportTarget: {
    type: String,
    default: "#app",
  },
  scrollWatchTarget: {
    type: String,
    default: "#app-body",
  },
});

const target = toRef(props, "scrollWatchTarget");
const appBody = document.getElementById(target.value.substring(1));

function updateVisibility() {
  isVisible.value = appBody ? appBody.scrollTop > 20 : false;
}

function scrollToTop() {
  if (appBody) {
    appBody.scrollTo({ top: 0, behavior: "smooth" });
  }
}

onMounted(() => {
  if (appBody) {
    useEventListener(appBody, "scroll", updateVisibility);
  }
});
</script>

<style scoped lang="scss">
.buttons-group button {
  position: fixed;
  bottom: 20px;
  right: 100px;

  border-radius: 41px;
  background: var(--clr-p5);
  color: white;

  padding: 6px 10px;
  align-items: center;

  &:hover,
  &:focus {
    background: var(--clr-p4);
    transform: translateY(-2px);
  }
  &:active {
    background: var(--clr-p4);
  }
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  z-index: 999;
}
</style>
