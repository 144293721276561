import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

export const sendProductTourStep = async (payload) => {
  try {
    const response = await httpRequest("patch", "/users", {
      tour_step_viewed: payload,
    });
    return response.data;
  } catch (error) {
    handleNetworkError(error);
  }
};
