<template>
  <div class="template">
    <BreadCrumbs :crumbs="crumbsArray" />
    <LoaderFullPage v-if="store.isQuestionLoading" />
    <QuestionsZoomCard v-else-if="store.question" :question="store.question" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import BreadCrumbs from "@/components/reusable/atoms/BreadCrumbs.vue";
import QuestionsZoomCard from "@/components/questions/atoms/QuestionsZoomCard.vue";

import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();
const route = useRoute();

const crumbsArray = computed(() => [
  {
    text: "Lobbies",
    to: { name: "Lobbies" },
  },
  route.params._id === "follawed"
    ? {
        text: "Actualités Follawed",
        to: { name: "Lobbies Follawed - Leurs Actualités" },
      }
    : {
        text: store.lobbyZoom?.name ?? "Lobby",
        to: {
          name: "Lobby Zoom - Actualités",
          params: { _id: route.params._id },
        },
      },
  {
    text: "Question",
  },
]);

// Get the lobby to display the name in the breadcrumbs
if (
  route.params._id !== "follawed" &&
  store.lobbyZoom?.national_id !== route.params._id
) {
  store.readOneLobby(route.params._id);
}

store.fetchOneQuestion(route.params.questionId);
</script>
