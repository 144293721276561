<template>
  <div class="card" :class="wrapperClass">
    <div class="main">
      <RouterLink v-if="title1LinkTo" :to="title1LinkTo">
        <i class="big-icon" :class="icon"></i>
      </RouterLink>

      <div class="label-col">
        <div class="big-value">{{ SINumberFormatter(value) }}</div>

        <div class="label">{{ label }}</div>
        <button class="toggle-see-more" @click="isOpen = !isOpen">
          <i class="fa-solid fa-ellipsis"></i>
        </button>
      </div>
      <div class="label-col">
        <div class="big-value">{{ SINumberFormatter(value2) }}</div>
        <RouterLink v-if="title2LinkTo" :to="title2LinkTo" class="label">
          {{ label2 }}
        </RouterLink>
        <div v-else class="label">{{ label2 }}</div>
        <button class="toggle-see-more" @click="isOpen = !isOpen">
          <i class="fa-solid fa-ellipsis"></i>
        </button>
      </div>
    </div>
    <div v-if="isOpen" class="more">
      <ul class="subtypes-list">
        <li v-for="subT in sortedSubtypeValues" :key="subT">
          <div class="count">{{ subtypeValues[subT] }}</div>
          <div class="sub-type">{{ subT }}</div>
        </li>
      </ul>
      <ul class="subtypes-list">
        <li v-for="subT in sortedSubtypeValues2" :key="subT">
          <div class="count">{{ subtypeValues2[subT] }}</div>
          <div class="sub-type">{{ subT }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

import { SINumberFormatter } from "@/utils/helper.utils";

const props = defineProps({
  label: { type: String, required: true },
  label2: { type: String, required: true },
  icon: { type: String, required: true },
  wrapperClass: { type: String, required: true },
  value: { type: Number, required: true },
  value2: { type: Number, required: true },
  subtypeValues: { type: Object, default: () => ({}) },
  subtypeValues2: { type: Object, default: () => ({}) },
  title1LinkTo: { type: String, default: "" },
  title2LinkTo: { type: String, default: "" },
});

const sortedSubtypeValues = computed(() => {
  return Object.keys(props.subtypeValues).sort(
    (a, b) => props.subtypeValues[b] - props.subtypeValues[a]
  );
});

const sortedSubtypeValues2 = computed(() => {
  return Object.keys(props.subtypeValues2).sort(
    (a, b) => props.subtypeValues2[b] - props.subtypeValues2[a]
  );
});

const isOpen = ref(false);
</script>

<style scoped lang="scss">
.main {
  display: flex;
  justify-content: space-between;

  .big-icon {
    width: 65px;
    height: 65px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2.6rem;
    background-color: var(--active-color);
    color: white;
  }

  .label-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .label {
      color: var(--active-color);
    }
    .big-value {
      color: var(--active-color);
      font-size: 2rem;
    }

    .toggle-see-more {
      color: var(--active-color);
    }
  }
}

.more {
  display: flex;
}

a .fa-dossierlegi {
  &:hover {
    background-color: #6278f7;
  }
  &:active {
    background-color: #2646ff;
  }
}

.subtypes-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
  column-gap: 10px;

  li {
    display: flex;
    gap: 10px;

    .count {
      color: var(--active-color);
    }
  }
}
</style>
