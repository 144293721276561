export const PRESET_FEATURES = {
  AGENDA: "agenda", // Agenda
  JORFS: "jorf", // Journal Officiel
  PETITIONS: "petitions", // Pétitions
  NEWS_NEWS: "communiques", // Actualités - Communiqués
  NEWS_NOMINATIONS: "nomination", // Actualités - Nominations
  LEGI_LEGIWORKS: "dossier_legislative", // Travaux parlementaires - Dossiers législatifs
  LEGI_AMENDMENTS: "amendements", // Travaux parlementaires - Amendements
  LEGI_QUESTIONS: "questions", // Travaux parlementaires - Questions
  LEGI_REPORTS: "comptes_rendus_rapports", // Travaux parlementaires - Comptes-rendus et rapports
  LEGI_INSTITU_GROUPS: "commission_organes", // Travaux parlementaires - Commissions et autres organes
  PRM_NATIONAL: "prm_national", // PRM - National & Régional, départemental & local
  PRM_REGIONAL: "prm_regional", // PRM - National & Régional, départemental & local
  PRM_LOBBIES: "lobbies", // PRM - Lobbies
  SM_OVERVIEW: "sm_overview", // Social media - Overview
  SM_TWEETS: "sm_tweets", // Social media - Tweets
  SM_STAKEHOLDERS: "sm_parties_prenantes", // Social media - Parties prenantes
  SM_ARTICLES: "sm_articles", // Social media - Articles
};

export const features = {
  [PRESET_FEATURES.AGENDA]: {
    name: "Agenda",
    filters: {
      author: "Auteur",
      type_: "Type d'évènement",
      location: "Lieu de l'évènement",
    },
    additionnal_filters: {
      agenda_guests: "Invités politiques",
      agenda_government: "Gouvernement",
      agenda_sn: "Sénat",
      agenda_an: "Assemblée Nationale",
      agenda_others: "Autre",
    },
    active_filters: ["activeAgendas", "selectedFilters"],
    url: "/events",
  },
  [PRESET_FEATURES.JORFS]: {
    name: "Journal Officiel",
    filters: {
      issuer: "Émetteur",
      nature: "Nature de texte",
    },
    active_filters: ["selectedFilters"],
    url: "/jorfs",
  },
  [PRESET_FEATURES.PETITIONS]: {
    name: "Pétitions",
    filters: {
      origin: "Origine",
      status: "Statut",
    },
    active_filters: ["selectedFilters"],
    url: "/read-petitions",
  },
  [PRESET_FEATURES.NEWS_NEWS]: {
    name: "Communiqués",
    label: "Actualités - Communiqués",
    filters: { domain: "Sources" },
    active_filters: ["selectedFilters"],
    url: "/news",
  },
  [PRESET_FEATURES.NEWS_NOMINATIONS]: {
    name: "Nominations",
    label: "Actualités - Nominations",
    filters: { themes: "Catégories" },
    active_filters: ["selectedFilters"],
    url: "/nominations",
  },
  [PRESET_FEATURES.LEGI_LEGIWORKS]: {
    name: "Dossiers législatifs",
    label: "Travaux parlementaires - Dossiers législatifs",
    filters: {
      current_institution: "Institution",
      nature: "Nature de texte",
      current_etape: "Étape",
    },
    active_filters: ["selectedFilters"],
    url: "/legi-docs",
    sayt_url: "/legi-doc/search_authors",
  },
  [PRESET_FEATURES.LEGI_AMENDMENTS]: {
    name: "Amendements",
    label: "Travaux parlementaires - Amendements",
    filters: {
      status: "Statut",
      institution: "Lieu de dépôt",
      action: "Type d'action",
      examined_by: "Examinateur",
      group: "Groupe",
    },
    active_filters: ["selectedFilters"],
    url: "/amendments",
    sayt_url: "/amendments/search_authors",
  },
  [PRESET_FEATURES.LEGI_QUESTIONS]: {
    name: "Questions",
    label: "Travaux parlementaires - Questions",
    filters: {
      status: "Statut",
      type_question: "Type de question",
      circo_name: "Localité",
      group: "Groupe",
      ministry_responsible: "Ministère attributaire",
      institution: "Institution",
    },
    active_filters: ["selectedFilters"],
    url: "/questions",
    sayt_url: "/question/search_authors",
  },
  [PRESET_FEATURES.LEGI_REPORTS]: {
    name: "Comptes-rendus et rapports",
    label: "Travaux parlementaires - Comptes-rendus et rapports",
    filters: {
      institution: "Institution",
      type_: "Type",
      classification: "Classification",
    },
    active_filters: ["selectedFilters"],
    url: "/legi-compte-rendus-and-rapports",
  },
  [PRESET_FEATURES.LEGI_INSTITU_GROUPS]: {
    name: "Commissions et autres organes",
    label: "Travaux parlementaires - Commissions et autres organes",
    filters: {
      institution: "Institution",
      group_type: "Type d'organe",
    },
    active_filters: ["selectedFilters"],
    url: "/v2/institutional-groups",
  },
  [PRESET_FEATURES.PRM_NATIONAL]: {
    name: "PRM National",
    label: "PRM - National",
    filters: {
      circo_name: "Circonscription",
      group: "Groupe",
      party: "Parti",
      stakeholder: "Partie prenante",
    },
    active_filters: ["selectedRegionalFilters", "selectedNationalFilters"],
    url: "/v2/prm/listing",
    sayt_url: "/v2/prm/search_authors",
  },
  [PRESET_FEATURES.PRM_REGIONAL]: {
    name: "PRM Régional",
    label: "PRM - Régional, départemental & local",
    filters: {
      circo_name: "Circonscription",
      group: "Groupe",
      level: "Niveau",
      party: "Parti",
      stakeholder: "Partie prenante",
    },
    active_filters: ["selectedFilters"],
    url: "/v2/prm/listing",
    additionalPayload: { regional: true },
    sayt_url: "/v2/prm/search_authors",
  },
  [PRESET_FEATURES.PRM_LOBBIES]: {
    name: "Lobbies",
    label: "PRM - Lobbies",
    filters: {
      type: "Type d'organisation",
      city: "Localité",
      levels: "Niveau",
      categories: "Secteur",
    },
    active_filters: ["selectedFilters"],
    url: "/v2/lobbies/listing",
    sayt_url: "/v2/lobbies/search_lobbies",
  },
  [PRESET_FEATURES.SM_OVERVIEW]: {
    name: "Réseaux sociaux overview",
    label: "Social media - Overview",
    filters: {
      group: "Groupe",
      major_stakeholder: "Grande Partie Prenante",
      stakeholder: "Partie Prenante",
      level: "Niveau",
      circo_name: "Localité",
    },
    active_filters: ["selectedFilters"],
    url: "/social-media/totals",
  },
  [PRESET_FEATURES.SM_TWEETS]: {
    name: "Réseaux sociaux publications",
    label: "Social media - Publications",
    filters: {
      group: "Groupe",
      major_stakeholder: "Grande Partie Prenante",
      stakeholder: "Partie Prenante",
      level: "Niveau",
      circo_name: "Localité",
    },
    active_filters: ["selectedFilters"],
    url: "/tweets",
    sayt_url: "/social-media/search_authors",
  },
  [PRESET_FEATURES.SM_STAKEHOLDERS]: {
    name: "Réseaux sociaux parties prenantes",
    label: "Social media - Parties prenantes",
    filters: {
      group: "Groupe",
      major_stakeholder: "Grande Partie Prenante",
      stakeholder: "Partie Prenante",
      level: "Niveau",
      circo_name: "Localité",
    },
    active_filters: ["selectedFilters"],
    url: "social-media/authors",
  },
  [PRESET_FEATURES.SM_ARTICLES]: {
    name: "Réseaux sociaux articles",
    label: "Social media - Articles",
    filters: {
      group: "Groupe",
      major_stakeholder: "Grande Partie Prenante",
      stakeholder: "Partie Prenante",
      level: "Niveau",
      circo_name: "Localité",
    },
    active_filters: ["selectedFilters"],
    url: "/social-media/links",
  },
};
