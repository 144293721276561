import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import { TIME_INTERVALS } from "@/constants/time-interval";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import {
  fetchAuthors,
  fetchPoliticalMap,
  fetchPublications,
  fetchSocietalMap,
  fetchTimeCurve,
} from "@/services/apiSocialMedia";
import { Platforms, PLATFORM_TYPES } from "@/utils/social360-platforms";
import { fetchArticles } from "@/services/apiArticles";
import { getIsoDateRange } from "@/utils/date.utils";

const initialStakeholdersFilters = {
  from: 0, //* Page number
  size: 20, //* Number of items per page
  get_filters: true, //* Retrieve available filters
  sort_field: "number_of_publications", //* Sort field
  sort_order: "desc", //* Sort order
  highlight: true, //* Retrieve highligted text based on querries/dashboard
};

const initialZoomPublicationsFilters = {
  sort_field: "like",
  sort_order: "desc",
  size: 20,
  from: 0,
};

const initialZoomMentionsFilters = {
  sort_field: "like",
  sort_order: "desc",
  size: 20,
  from: 0,
};

const initialTimeCurveTopArticlesFilters = {
  sort_field: "number_of_retweets",
  sort_order: "desc",
  size: 10,
  get_filters: false,
  highlight: true,
};

const initialTimeCurveTopPublicationsFilters = {
  sort_field: "like",
  sort_order: "desc",
  size: 10,
  get_filters: false,
  highlight: true,
};

export const useStakeholdersStore = defineStore("stakeholders", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const zoomPublicationsIndexes = ref([...PLATFORM_TYPES]);

  // Stakeholders list
  const authorsStakeholder = ref([]);
  const authorZoom = ref(null);
  const authorsCountStakeholder = ref(null);

  const bypassQuery = ref(false);

  const zoomMentions = ref([]);
  const zoomMentionsCount = ref(null);
  const zoomMentionsFilters = ref({ ...initialZoomMentionsFilters });
  const zoomPublications = ref(null);
  const zoomPublicationsCount = ref([]);
  const zoomPublicationsFilters = ref({ ...initialZoomPublicationsFilters });

  const zoomTimeCurve = ref(undefined);
  const zoomTimeCurveInterval = ref(TIME_INTERVALS.DAY);
  const zoomTimeCurveTopDate = ref(null);
  const zoomTimeCurveTopPublicationsFilters = ref({
    ...initialTimeCurveTopPublicationsFilters,
  });
  const zoomTimeCurveTopArticlesFilters = ref({
    ...initialTimeCurveTopArticlesFilters,
  });
  const zoomTimeCurveTopArticles = ref([]);
  const zoomTimeCurveTopArticlesCount = ref(null);
  const zoomTimeCurveTopPublications = ref([]);
  const zoomTimeCurveTopPublicationsCount = ref(null);

  const filters = ref(null);
  const selectedFilters = ref({ ...initialStakeholdersFilters });
  const isFollawedActive = ref(false);

  const loading = ref(false);
  const isPublicationsLoading = ref(false);
  const isAuthorLoading = ref(false);
  const isRelatedMentionsLoading = ref(false);
  const isSocialMediaPublicationsLoading = ref(false);
  const isSocialMediaAuthorLoading = ref(false);
  const isArticlesLoading = ref(false);
  const isZoomTimeCurveLoading = ref(false);
  const isTimeCurveZoomPublicationsLoading = ref(false);

  // Mappings
  const politicalMap = ref({});
  const societalMap = ref({});

  const getAuthorZoom = async (id) => {
    isAuthorLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      author: [id],
      from: 0,
      size: 1,
    };

    try {
      const response = await fetchAuthors(payload);

      if (response) {
        authorZoom.value = response.items[0];
      }
    } finally {
      isAuthorLoading.value = false;
    }
  };
  const getPublicationsList = async (author_id = null) => {
    isPublicationsLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      ...zoomPublicationsFilters.value,
      indexes: zoomPublicationsIndexes.value,
      author: [author_id],
    };

    try {
      const response = await fetchPublications(payload);

      if (response) {
        zoomPublicationsCount.value = response.item_count;
        zoomPublications.value = response.items;
      }
    } finally {
      isPublicationsLoading.value = false;
    }
  };

  const getMentionsOfPublications = async (handle) => {
    isRelatedMentionsLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      ...zoomMentionsFilters.value,
      mentions: [handle],
      indexes: [Platforms.TWITTER],
    };

    try {
      const response = await fetchPublications(payload);

      if (response) {
        zoomMentionsCount.value = response.item_count;
        zoomMentions.value = response.items;
      }
    } finally {
      isRelatedMentionsLoading.value = false;
    }
  };

  const getZoomedAuthorTimeCurve = async () => {
    isZoomTimeCurveLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      start: dayjs().subtract(1, "year"),
      indexes: PLATFORM_TYPES,
      interval: zoomTimeCurveInterval.value,
      author: [authorZoom.value._id],
    };

    try {
      const response = await fetchTimeCurve(payload);

      if (response) {
        zoomTimeCurve.value = {
          x_timeline: response.x_timeline,
          tiktok_timeline: response.tiktok_timeline,
          bluesky_timeline: response.bluesky_timeline,
          instagram_timeline: response.instagram_timeline,
          linkedin_timeline: response.linkedin_timeline,
        };
      }
    } finally {
      isZoomTimeCurveLoading.value = false;
    }
  };

  const getTimeCurvePublications = async (author_id) => {
    if (!author_id) {
      throw new Error("Cannot fetch publications without author id");
    }
    isTimeCurveZoomPublicationsLoading.value = true;

    const date = zoomTimeCurveTopDate.value.date;
    const selectedPlatform = zoomTimeCurveTopDate.value?.platform;

    const dateRange = getIsoDateRange(date, zoomTimeCurveInterval.value);

    const payload = {
      ...sharedFiltersAsPayload.value,
      ...selectedFilters.value,
      ...dateRange,
      ...zoomTimeCurveTopPublicationsFilters.value,
      indexes: selectedPlatform ? [selectedPlatform] : undefined,
      author: [author_id],
    };

    if (userStore.sharedFilters.selected_keyword) {
      payload.filter = userStore.sharedFilters.selected_keyword;
    }
    try {
      const response = await fetchPublications(payload);

      zoomTimeCurveTopPublications.value = response.items;
      zoomTimeCurveTopPublicationsCount.value = response.item_count;
    } catch (error) {
      zoomTimeCurveTopPublications.value = [];
      zoomTimeCurveTopPublicationsCount.value = null;
      throw error;
    } finally {
      isTimeCurveZoomPublicationsLoading.value = false;
    }
  };

  const fetchTopArticles = async () => {
    isArticlesLoading.value = true;

    const date = zoomTimeCurveTopDate.value.date;
    const selectedPlatform = zoomTimeCurveTopDate.value?.platform;

    const dateRange = getIsoDateRange(date, zoomTimeCurveInterval.value);

    const payload = {
      ...sharedFiltersAsPayload.value,
      ...dateRange,
      indexes: selectedPlatform ? [selectedPlatform] : undefined,
      ...zoomTimeCurveTopArticlesFilters.value,
    };

    if (userStore.sharedFilters.selected_keyword) {
      payload.filter = userStore.sharedFilters.selected_keyword;
    }

    try {
      const response = await fetchArticles(payload);

      if (response) {
        zoomTimeCurveTopArticles.value = response.items;
        zoomTimeCurveTopArticlesCount.value = response.item_count;
      } else {
        zoomTimeCurveTopArticles.value = [];
        zoomTimeCurveTopArticlesCount.value = null;
      }
    } finally {
      isArticlesLoading.value = false;
    }
  };

  const getAuthorsStakeholdersList = async (forcedFilters = {}) => {
    loading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      ...selectedFilters.value,
      ...forcedFilters,
    };

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload.author = userStore.follawed[FOLLAWED_FEATURES.TWEET_AUTHORS];
    }

    try {
      const data = await fetchAuthors(payload);
      if (data) {
        authorsCountStakeholder.value = data.item_count;
        authorsStakeholder.value = data.items;
        filters.value = data.filters;
      }
    } finally {
      loading.value = false;
    }
  };

  const fetchPoliticalMapping = async () => {
    politicalMap.value = {};

    // Fetch all authors matching the query with one unbounded request
    const payload = {
      ...sharedFiltersAsPayload.value,
      ...selectedFilters.value,
      get_filters: true,
    };
    const data = await fetchPoliticalMap(payload);
    filters.value = data.filters;
    politicalMap.value = data.items;
  };

  const fetchSocietalMapping = async () => {
    societalMap.value = {};

    // Fetch all authors matching the query with one unbounded request
    const payload = {
      ...sharedFiltersAsPayload.value,
      ...selectedFilters.value,
      get_filters: true,
    };
    const data = await fetchSocietalMap(payload);
    filters.value = data.filters;
    societalMap.value = data.items;
  };

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    getAuthorsStakeholdersList();
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialStakeholdersFilters };
  };

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const resetStore = () => {
    authorsStakeholder.value = [];
    filters.value = null;
    selectedFilters.value = { ...initialStakeholdersFilters };
    loading.value = false;

    authorsCountStakeholder.value = null;
  };

  const route = useRoute();
  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        switch (route.name) {
          case "Parties Prenantes - Ranking List":
          case "Parties Prenantes - Follawed":
            selectedFilters.value.from = 0;
            getAuthorsStakeholdersList();
            break;
          case "Parties Prenantes - Cartographie Politique":
            fetchPoliticalMapping();
            break;
          case "Parties Prenantes - Cartographie Sociétale":
            fetchSocietalMapping();
            break;
          case "Zoom Partie Prenante - Publications":
            selectedFilters.value.from = 0;
            getPublicationsList(route.params._id);
            break;
          case "Zoom Partie Prenante - Mentions":
            selectedFilters.value.from = 0;
            getMentionsOfPublications(authorZoom.value.x_information?.username);
            break;
        }
      }
    }
  );

  // Fetch again the time curve data when a filter changes

  watch([zoomTimeCurveTopDate], () => {
    if (zoomTimeCurveTopDate.value) {
      getTimeCurvePublications(authorZoom.value._id);
      fetchTopArticles();
    }
  });

  watch([zoomTimeCurveTopPublicationsFilters], () => {
    if (zoomTimeCurveTopDate.value) {
      getTimeCurvePublications(authorZoom.value._id);
    }
  });

  watch([zoomTimeCurveTopArticlesFilters], () => {
    if (zoomTimeCurveTopDate.value) {
      fetchTopArticles();
    }
  });

  return {
    authorsCountStakeholder,
    authorsStakeholder,
    authorZoom,
    bypassQuery,
    fetchPoliticalMapping,
    fetchSocietalMapping,
    filters,
    getAuthorsStakeholdersList,
    getAuthorZoom,
    getMentionsOfPublications,
    getPublicationsList,
    getTimeCurvePublications,
    getZoomedAuthorTimeCurve,
    hasActiveFilters,
    isAuthorLoading,
    isFollawedActive,
    isPublicationsLoading,
    isRelatedMentionsLoading,
    isSocialMediaAuthorLoading,
    isSocialMediaPublicationsLoading,
    isZoomTimeCurveLoading,
    loading,
    politicalMap,
    resetFilters,
    resetStore,
    selectedFilters,
    societalMap,
    toggleFollawed,
    zoomMentions,
    zoomMentionsCount,
    zoomMentionsFilters,
    zoomPublications,
    zoomPublicationsCount,
    zoomPublicationsFilters,
    zoomPublicationsIndexes,
    zoomTimeCurve,
    zoomTimeCurveInterval,
    zoomTimeCurveTopArticles,
    zoomTimeCurveTopArticlesCount,
    zoomTimeCurveTopArticlesFilters,
    zoomTimeCurveTopDate,
    zoomTimeCurveTopPublications,
    zoomTimeCurveTopPublicationsCount,
    zoomTimeCurveTopPublicationsFilters,
  };
});
