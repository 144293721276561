import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialPayload = {
  size: 20,
  from: 0,
};

// Defining store
export const useHomepageStore = defineStore("homepage", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const CORPUSES = {
    AMENDMENTS: "amendment",
    // ARTICLES: "article", //TODO : uncomment once back is fixed (receive news with type "article")
    AGENDA: "event",
    JORFS: "jorf",
    LEGIDOCS: "legi_doc",
    NEWS: "news",
    NOMINATIONS: "nomination",
    POSTS: "publication",
    QUESTIONS: "question",
    REPORTS: "compte_rendu",
  };
  const countLoading = ref(false);
  const itemsCounts = ref([]);

  const loading = ref(false);
  const items = ref([]);
  const totalCount = ref(0);

  const basePayload = ref({ ...initialPayload });
  const selectedCorpuses = ref([
    CORPUSES.LEGIDOCS,
    CORPUSES.JORFS,
    CORPUSES.AMENDMENTS,
    CORPUSES.QUESTIONS,
    CORPUSES.REPORTS,
    CORPUSES.POSTS,
    // CORPUSES.ARTICLES, //TODO : uncomment once back is fixed (receive news with type "article")
    CORPUSES.NOMINATIONS,
    CORPUSES.AGENDA,
    CORPUSES.NEWS,
  ]);

  const isFollawedActive = ref(false);
  const bypassQuery = ref(false);

  const getCorpusesFollawedElements = () => {
    const arr = [];

    selectedCorpuses.value.forEach((corpus) => {
      if (corpus === "news") {
        arr.push(userStore.follawed[`follawed_news`]);
      } else {
        arr.push(userStore.follawed[`follawed_${corpus}s`]);
      }
    });

    return arr.filter((element) => !!element);
  };

  // Get homepage corpuses and individual counts from backend
  const fetchHomepageCounts = async () => {
    countLoading.value = true;

    const payload = {
      ...basePayload.value,
      ...sharedFiltersAsPayload.value,
    };

    if (isFollawedActive.value) {
      payload._id = [];

      getCorpusesFollawedElements().forEach((list) => {
        payload._id.push(...list);
      });

      delete payload.start;
      delete payload.end;
    }

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    try {
      const countResponse = await httpRequest(
        "post",
        "/overview-totals",
        payload
      );
      if (countResponse.data) {
        itemsCounts.value = countResponse.data;
      }
    } catch (err) {
      itemsCounts.value = [];
      handleNetworkError(err);
    } finally {
      countLoading.value = false;
    }
  };

  // Get homepage items from backend
  const fetchHomepageList = async () => {
    loading.value = true;

    const payload = {
      ...basePayload.value,
      ...sharedFiltersAsPayload.value,
      data_types: selectedCorpuses.value,
    };

    if (isFollawedActive.value) {
      payload._id = [];

      getCorpusesFollawedElements().forEach((list) => {
        payload._id.push(...list);
      });

      delete payload.start;
      delete payload.end;
    }

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    try {
      const listResponse = await httpRequest("post", "/read-overview", payload);

      if (listResponse.data) {
        items.value = listResponse.data.items;
        totalCount.value = listResponse.data.item_count;
      }
    } catch (err) {
      items.value = [];
      totalCount.value = 0;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchHomepage = () => {
    fetchHomepageCounts();
    fetchHomepageList();
  };

  const route = useRoute();
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        route.name.includes("Centre de contrôle")
      ) {
        basePayload.value.from = 0;
        fetchHomepage();
      }
    }
  );

  return {
    CORPUSES,
    basePayload,
    countLoading,
    itemsCounts,
    loading,
    items,
    totalCount,
    selectedCorpuses,
    isFollawedActive,
    bypassQuery,
    fetchHomepage,
  };
});
