import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { httpRequest } from "@/utils/request.utils";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import { useRoute } from "vue-router";
import { handleNetworkError } from "@/utils/handleNetworkError";
import * as LobbyService from "@/services/apiLobbies";

const initialLobbiesFilters = {
  size: 20,
  from: 0,
  sort: { number_of_publications: "desc" },
  get_filters: true,
};

const initialLobbiesSubParams = {
  size: 20,
  from: 0,
};

export const NEWS_FILTERS = {
  PUBLICATIONS: "publications",
  ARTICLES: "articles",
  QUESTIONS: "questions",
  EVENTS: "events",
  REPORTS: "compte-rendus",
};

export const ACTIVITIES_FILTERS = {
  LOBBYING: "lobbies",
  PUBLICATIONS: "publications",
};

// Defining store
export const useLobbiesStore = defineStore("lobbies", () => {
  const route = useRoute();
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);

  // Listing
  const items = ref([]);
  const itemCount = ref(null);

  // Zoom
  const lobbyZoom = ref(null);
  const prevZoomUrlQuery = ref(null);
  const isLatestNewsLoading = ref(false);
  const lobbyZoomLatestNews = ref(null);
  const isChartLoading = ref(false);
  const activityChartDatas = ref(null);
  const publicationsChartDatas = ref(null);

  // News
  const isNewsLoading = ref(false);
  const bypassQueryNews = ref(false);
  const lobbiesNews = ref(null);
  const selectedLobbiesNewsParams = ref(initialLobbiesSubParams);
  const lobbiesNewsFilters = ref([
    NEWS_FILTERS.PUBLICATIONS,
    NEWS_FILTERS.ARTICLES,
    NEWS_FILTERS.QUESTIONS,
    NEWS_FILTERS.EVENTS,
    NEWS_FILTERS.REPORTS,
  ]);
  const newsCounts = ref(null);
  const searchQuery = ref(null);

  // Question
  const isQuestionLoading = ref(false);
  const question = ref(null);

  // Report
  const isReportLoading = ref(false);
  const report = ref(null);

  // Activities
  const isActivitiesLoading = ref(false);
  const bypassQueryActivities = ref(false);
  const lobbiesActivities = ref(null);
  const selectedLobbiesActivitiesParams = ref(initialLobbiesSubParams);
  const lobbiesActivitiesFilters = ref([
    ACTIVITIES_FILTERS.LOBBYING,
    ACTIVITIES_FILTERS.PUBLICATIONS,
  ]);
  const activitiesCounts = ref(null);

  // filters
  const filters = ref(null);
  const selectedFilters = ref({ ...initialLobbiesFilters });
  const isCompanyFilterLoading = ref(false);
  const companies = ref([]);
  const isFollawedActive = ref(false);
  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const readLobbies = async () => {
    loading.value = true;

    //* Make the payload using the filters from the feature and those from the topbar
    let payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* Adapt the sorter format to the backend
    delete payload.sort;
    payload.sort_field = Object.keys(selectedFilters.value.sort)[0];
    payload.sort_order = Object.values(selectedFilters.value.sort)[0];

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload.national_id = userStore.follawed[FOLLAWED_FEATURES.LOBBIES];

      //* Get follawed filters
      delete payload.get_filters;
      payload.get_follawed_filters = true;
    } else {
      //* If there is author filters selected map them to only their ids
      if (payload.companies?.length > 0) {
        payload.companies = payload.companies.map(
          (company) => company.national_id
        );
      }

      //* Replace the companies field by the national_id field
      payload = {
        ...payload,
        national_id: payload.companies,
      };
      delete payload.companies;
    }

    try {
      const data = await LobbyService.fetchLobbiesListing(payload);

      items.value = data.items;
      itemCount.value = data.item_count;
      filters.value = data.filters;
    } finally {
      loading.value = false;
    }
  };

  const readFollawedNews = async () => {
    isNewsLoading.value = true;
    newsCounts.value = null;
    lobbiesNews.value = null;
    searchQuery.value = null;

    const payload = {
      ...selectedFilters.value,
      ...selectedLobbiesNewsParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesNewsFilters.value,
      national_id: userStore.follawed[FOLLAWED_FEATURES.LOBBIES],
      highlight: true,
      get_follawed_filters: true,
    };

    if (bypassQueryNews.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const data = await LobbyService.fetchFollawedNews(payload);
      newsCounts.value = {
        publicationsCount: data.publications_count,
        articlesCount: data.article_count,
        eventsCount: data.event_count,
        questionsCount: data.question_count,
        reportsCount: data.compte_rendu_count,
      };
      lobbiesNews.value = data.items;
      searchQuery.value = data.query_names;
    } finally {
      isNewsLoading.value = false;
    }
  };

  const readFollawedActivities = async () => {
    isActivitiesLoading.value = true;
    lobbiesActivities.value = null;
    activitiesCounts.value = null;

    const payload = {
      ...selectedFilters.value,
      ...selectedLobbiesActivitiesParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesActivitiesFilters.value,
      national_id: userStore.follawed[FOLLAWED_FEATURES.LOBBIES],
      highlight: true,
      get_follawed_filters: true,
    };

    if (bypassQueryActivities.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const data = await LobbyService.fetchFollawedActivities(payload);
      lobbiesActivities.value = data.items;
      activitiesCounts.value = {
        lobbiesCount: data.lobbies_count,
        publicationsCount: data.publications_count,
      };
    } finally {
      isActivitiesLoading.value = false;
    }
  };

  const readOneLobby = async (lobbyId) => {
    loading.value = true;
    lobbyZoom.value = null;

    // This ref can be used to check if the query has changed in the zoom views
    prevZoomUrlQuery.value = JSON.stringify(route.query);

    try {
      lobbyZoom.value = await LobbyService.fetchOneLobby(lobbyId, {
        ...sharedFiltersAsPayload.value,
      });
    } finally {
      loading.value = false;
    }
  };

  const fetchOneQuestion = async (questionId) => {
    isQuestionLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      _id: [questionId],
      highlight: true,
    };

    if (payload.filter || searchQuery.value) {
      payload.filter = payload.filter
        ? (payload.filter += searchQuery.value ?? "")
        : searchQuery.value;
    }

    try {
      const response = await httpRequest("post", `/question-zoom`, payload);
      question.value = response.data.items[0];
    } catch (err) {
      handleNetworkError(err);
    }

    isQuestionLoading.value = false;
  };

  const fetchOneReport = async (docRef) => {
    isReportLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      ref: [docRef],
      highlight: true,
    };

    if (payload.filter || searchQuery.value) {
      payload.filter = payload.filter
        ? (payload.filter += searchQuery.value ?? "")
        : searchQuery.value;
    }

    try {
      const response = await httpRequest(
        "post",
        "/legi-compte-rendus-zoom",
        payload
      );
      report.value = response.data;
    } catch (err) {
      handleNetworkError(err);
    }

    isReportLoading.value = false;
  };

  const readOneLobbyNews = async (lobbyId) => {
    isNewsLoading.value = true;

    const payload = {
      ...selectedLobbiesNewsParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesNewsFilters.value,
      highlight: true,
    };

    if (bypassQueryNews.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const data = await LobbyService.fetchOneLobbyNews(lobbyId, payload);
      newsCounts.value = {
        publicationsCount: data.publications_count,
        articlesCount: data.article_count,
        eventsCount: data.event_count,
        questionsCount: data.question_count,
        reportsCount: data.compte_rendu_count,
      };
      lobbiesNews.value = data.items;
      searchQuery.value = data.query_names;
    } finally {
      isNewsLoading.value = false;
    }
  };

  const readOneLobbyLatestNews = async (lobbyId) => {
    isLatestNewsLoading.value = true;
    lobbyZoomLatestNews.value = null;

    try {
      lobbyZoomLatestNews.value =
        await LobbyService.fetchOneLobbyLatestNews(lobbyId);
    } finally {
      isLatestNewsLoading.value = false;
    }
  };

  const readOneLobbyTimeline = async (lobbyId) => {
    isChartLoading.value = true;

    try {
      const data = await LobbyService.fetchOneLobbyTimeline(lobbyId);
      activityChartDatas.value = data.lobbies_timeline;
      publicationsChartDatas.value = Object.values(data.publication_timeline);
    } finally {
      isChartLoading.value = false;
    }
  };

  const readOneLobbyActivities = async (lobbyId) => {
    isActivitiesLoading.value = true;

    const payload = {
      ...selectedLobbiesActivitiesParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesActivitiesFilters.value,
      highlight: true,
    };

    if (bypassQueryActivities.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const data = await LobbyService.fetchOneLobbyActivities(lobbyId, payload);
      activitiesCounts.value = {
        lobbiesCount: data.lobbies_count,
        publicationsCount: data.publications_count,
      };
      lobbiesActivities.value = data.items;
    } finally {
      isActivitiesLoading.value = false;
    }
  };

  const getCompanyFilters = async (name) => {
    isCompanyFilterLoading.value = true;
    companies.value = [];

    try {
      companies.value = await LobbyService.searchLobbiesByName(name);
    } finally {
      isCompanyFilterLoading.value = false;
    }
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialLobbiesFilters };
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        switch (route.name) {
          case "Lobby Zoom - Actualités":
            selectedFilters.value.from = 0;
            readOneLobbyNews(route.params._id);
            break;
          case "Lobby Zoom - Activités":
            selectedFilters.value.from = 0;
            readOneLobbyActivities(route.params._id);
            break;
          case "Lobbies Follawed - Leurs Actualités":
            selectedFilters.value.from = 0;
            readFollawedNews();
            break;
          case "Lobbies Follawed - Leurs activités":
            selectedFilters.value.from = 0;
            readFollawedActivities();
            break;
          case "Lobbies Follawed - Vos follaw":
          case "Lobbies":
            selectedFilters.value.from = 0;
            readLobbies();
            break;
        }
      }
    }
  );

  return {
    activitiesCounts,
    activityChartDatas,
    bypassQueryActivities,
    bypassQueryNews,
    companies,
    fetchOneQuestion,
    fetchOneReport,
    filters,
    getCompanyFilters,
    hasActiveFilters,
    isActivitiesLoading,
    isChartLoading,
    isCompanyFilterLoading,
    isFollawedActive,
    isLatestNewsLoading,
    isNewsLoading,
    isQuestionLoading,
    isReportLoading,
    itemCount,
    items,
    loading,
    lobbiesActivities,
    lobbiesActivitiesFilters,
    lobbiesNews,
    lobbiesNewsFilters,
    lobbyZoom,
    lobbyZoomLatestNews,
    newsCounts,
    prevZoomUrlQuery,
    publicationsChartDatas,
    question,
    readFollawedActivities,
    readFollawedNews,
    readLobbies,
    readOneLobby,
    readOneLobbyActivities,
    readOneLobbyLatestNews,
    readOneLobbyNews,
    readOneLobbyTimeline,
    report,
    resetFilters,
    searchQuery,
    selectedFilters,
    selectedLobbiesActivitiesParams,
    selectedLobbiesNewsParams,
  };
});
