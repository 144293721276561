<template>
  <span v-if="props.data || props.data === 0">{{ props.data }}</span>
  <span v-else class="missing-item-data">Données absentes</span>
</template>

<script setup>
const props = defineProps({
  data: {
    type: [String, Number],
    required: false,
    default: "",
  },
});
</script>

<style lang="scss" scoped>
.missing-item-data {
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
}
</style>
