<template>
  <div v-on-click-outside="() => (isMenuOpen = false)" class="wrapper">
    <div class="select-box" @click="isMenuOpen = !isMenuOpen">
      Thématiques
      <div class="tools">
        <span class="nb-selected" :class="{ hidden: badgeValue === 0 }">
          {{ badgeValue }}
        </span>

        <div class="arrow">
          <i
            class="fa-solid"
            :class="isMenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen" class="menu">
      <h6 class="title">Thématiques et sous-thématiques</h6>
      <DiscoveryOptions
        v-bind="$props"
        @add-thematic="$emit('addThematic', $event)"
        @add-subthematic="$emit('addSubthematic', $event)"
        @remove-thematic="$emit('removeThematic', $event)"
        @remove-subthematic="$emit('removeSubthematic', $event)"
        @reset="$emit('resetAll')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import DiscoveryOptions from "@/components/topbar/molecules/DiscoveryOptions.vue";

const props = defineProps({
  thematics: {
    type: Array,
    default: () => [],
  },
  activeThematics: {
    type: Array,
    default: () => [],
  },
  activeSubthematics: {
    type: Array,
    default: () => [],
  },
});

defineEmits([
  "addThematic",
  "addSubthematic",
  "removeThematic",
  "removeSubthematic",
  "resetAll",
]);

const isMenuOpen = ref(false);

const badgeValue = computed(
  () => props.activeThematics.length + props.activeSubthematics.length
);
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  user-select: none;
}

.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  border-radius: 15px;
  color: white;
  background: var(--clr-p8);
  box-shadow: 0 3px 5px #00000014;

  height: 30px;
  padding: 4px 15px;
  cursor: pointer;

  .tools {
    display: flex;
    gap: 8px;

    .nb-selected {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      min-width: 20px;
      height: 20px;
      font-size: var(--size-small);
      background-color: var(--clr-p2);
      color: var(--clr-n8);

      &.hidden {
        // keep it in the layout so that it does not shift when the badge appears
        opacity: 0;
      }
    }
  }
}

.menu {
  position: absolute;
  z-index: 10;
  right: -50%;

  box-shadow: 0 1px 6px #0000005d;
  max-height: 200px;
  width: 400px;
  padding: 8px 16px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--clr-n8);
  border-radius: 13px;
  background-color: white;
}

@media screen and (min-height: 500px) {
  .menu {
    max-height: 400px;
  }
}

@media screen and (min-height: 600px) {
  .menu {
    max-height: 500px;
  }
}

@media screen and (min-height: 700px) {
  .menu {
    max-height: 600px;
  }
}
</style>
