<template>
  <RouterRadioButtonsVue :options="routeOptions" />

  <RouterView />
</template>

<script setup>
import RouterRadioButtonsVue from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import { RouterView, useRoute } from "vue-router";

const store = useLegiWorkStore();
const route = useRoute();
const { ref, stepIdx } = route.params;
const baseRoute = `/legislatives/${ref}/timeline/${stepIdx}/49_3/`;

const data =
  store.legiWorkZoom.timeline[stepIdx].engagement_responsabilite_49_3;

const routeOptions = data.map((entry, idx) => {
  if (entry.dossier_etape.match(/motion de censure/i)) {
    return {
      label: entry.dossier_etape,
      value: `${baseRoute}${idx}/scrutin`,
    };
  }

  if (entry.dossier_etape.match(/déclaration/i)) {
    return {
      label: entry.dossier_etape,
      value: `${baseRoute}${idx}/declaration`,
    };
  }
});
</script>
