<template>
  <AbstractSocialCard
    :id="post._id"
    :author="post.author"
    :social-icon="{ name: 'fa-tiktok', color: 'var(--clr-tiktok)' }"
    :handle="handleTiktok"
    :reposts="SINumberFormatter(post.reposts)"
    :likes="SINumberFormatter(post.like)"
    :comments="SINumberFormatter(post.comment)"
    :views="SINumberFormatter(post.impression)"
    :created-at="post.created_at"
    :last-update="post.last_update"
    :source-url="post.permalink"
    :is-overview="isOverview"
  >
    <template #content>
      <AbstractSocialCardContent :describe="post.text" />
    </template>

    <template v-if="post.permalink" #media>
      <div class="tiktok__image-wrapper">
        <LoaderCircle v-if="isLoading" />
        <p v-else-if="!thumbnailUrl" class="title-small-semibold error-message">
          Impossible de récupérer la miniature
        </p>
        <img v-else :src="thumbnailUrl" alt="Image du post TikTok" />
      </div>
    </template>
  </AbstractSocialCard>
</template>

<script setup>
import { ref } from "vue";
import AbstractSocialCard from "@/components/social-media//abstract/AbstractSocialCard.vue";
import AbstractSocialCardContent from "@/components/social-media//abstract/AbstractSocialCardContent.vue";
import LoaderCircle from "@/components/reusable/atoms/LoaderCircle.vue";
import { useHandle } from "@/composables/social-media/useHandle";
import { Platforms } from "@/utils/social360-platforms.js";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { handleNetworkError } from "@/utils/handleNetworkError";
import { fetchTikTokThumbnail } from "@/services/apiExternal";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  isOverview: {
    type: Boolean,
    default: false,
  },
  tooltipActivable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const handleTiktok = useHandle(
  props.post.author.tiktok_information?.username,
  Platforms.TIKTOK
);

const thumbnailUrl = ref("");
const isLoading = ref(true);

const getTikTokThumbnail = async () => {
  try {
    thumbnailUrl.value = await fetchTikTokThumbnail(props.post.permalink);
  } catch (err) {
    handleNetworkError(err);
  } finally {
    isLoading.value = false;
  }
};

getTikTokThumbnail();
</script>

<style lang="scss" scoped>
.tiktok__image-wrapper {
  display: flex;
  width: 217px;
  height: 217px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.tiktok__image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.error-message {
  text-align: center;
}
</style>
