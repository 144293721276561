<template>
  <div class="content-wrapper">
    <div class="top-tweets">
      <TopPublications
        :is-loading="statsStore.isTotalFieldsLoading"
        :publication-count="statsStore.totalValues.total_publication_count"
        :total-share-count="statsStore.totalValues.total_share_count"
        :impressions-count="statsStore.totalValues.impression_count"
        @click-icon="() => router.push('/posts')"
      />
    </div>
    <div class="top-authors">
      <TopAuthors
        :is-loading="statsStore.isTotalFieldsLoading"
        :authors-count="statsStore.totalValues.authors_count"
        :authors-percent="authorsPercentOfPanel"
        @click-icon="() => router.push('/stakeholders/ranking')"
      />
    </div>
    <div class="top-articles">
      <TopArticles
        :is-loading="statsStore.isTotalFieldsLoading"
        :articles-count="statsStore.totalValues.articles_count"
        @click-icon="() => router.push('articles')"
      />
    </div>

    <div class="timeline-tweets">
      <OverviewTimeCurve />
    </div>
    <!-- Stakeholder -->
    <div class="stakeholder-graph">
      <div class="words-ranking">
        <GraphCurve
          :is-loading="statsStore.isStakeholderStatsLoading"
          :items="statsStore.stakeHoldersValues"
        />
      </div>
    </div>
    <!-- Levels Ranking -->
    <div class="levels-ranking">
      <LevelsChart
        :is-loading="statsStore.isLevelsRankingLoading"
        :items="statsStore.levelsValues"
      />
    </div>

    <div class="hemicycle-groups">
      <GroupsRanking
        :is-loading="statsStore.isGroupsLoading"
        :groups="statsStore.groupsRanking"
      />
    </div>

    <div class="domains-ranking">
      <GenericRanking
        color="var(--clr-service-article)"
        icon="fa-kit fa-sv-articles"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_domains"
        name="Domaines"
        @click-item="goToExternalUrl"
      />
    </div>
    <div class="hashtag-ranking">
      <GenericRanking
        color="var(--clr-twitter-hashtag)"
        icon="fa-solid fa-hashtag"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_hashtags"
        name="Hashtags"
        @click-item="goToPosts(`#${$event}`)"
      />
    </div>
    <div class="words-ranking">
      <GenericRanking
        color="var(--clr-twitter-words)"
        icon="fa-kit fa-sv-mots"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_words"
        name="Mots"
        @click-item="goToPosts"
      />
    </div>
    <div class="mentions-ranking">
      <GenericRanking
        color="var(--clr-twitter-mentions)"
        icon="fa-solid fa-at"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_mentions"
        name="Mentions"
        @click-item="goToPosts"
      />
    </div>

    <div class="wordcloud">
      <WordCloud
        :is-loading="statsStore.isLevelsRankingLoading"
        :ranking-values="statsStore.rankingValues"
        @click-word="goToPosts"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import GroupsRanking from "@/components/social-media/overview/molecules/GroupsRanking.vue";
import TopPublications from "@/components/social-media/overview/molecules/TopPublications.vue";
import TopAuthors from "@/components/social-media/overview/molecules/TopAuthors.vue";
import TopArticles from "@/components/social-media/overview/molecules/TopArticles.vue";
import GenericRanking from "@/components/social-media/overview/molecules/GenericRanking.vue";
import GraphCurve from "@/components/social-media/molecules/GraphCurve.vue";
import LevelsChart from "@/components/social-media/overview/molecules/LevelsChart.vue";
import WordCloud from "@/components/social-media/overview/molecules/WordCloud.vue";
import { useUserStore } from "@/stores/user";
import { useSocialMediaStatsStore } from "@/stores/social-media/statistics";
import { useSocialMediaStore } from "@/stores/social-media/posts";
import OverviewTimeCurve from "@/components/social-media/overview/organisms/OverviewTimeCurve.vue";

const router = useRouter();
const route = useRoute();

const userStore = useUserStore();
const postsStore = useSocialMediaStore();
const statsStore = useSocialMediaStatsStore();

const authorsPercentOfPanel = computed(() =>
  Math.round(
    (100 * statsStore.totalValues.authors_count) /
      statsStore.totalValues.total_author_count
  )
);

const goToExternalUrl = (url) => {
  if (!url.includes("//")) {
    url = `//${url}`;
  }
  window.open(url);
};

const goToPosts = (word) => {
  userStore.searchBoxCurrentValue = word;
  userStore.confirmSearchBox();
  postsStore.getPublicationsList().then(() => {
    router.push({
      name: "Publications",
      query: {
        ...route.query,
        search: word,
      },
    });
  });
};
</script>

<style scoped lang="scss">
.content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

@container (width < 875px) {
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.timeline-tweets,
.hemicycle-groups,
.wordcloud {
  grid-column: span 6;
}

.top-tweets,
.stakeholder-graph,
.levels-ranking,
.domains-ranking,
.hashtag-ranking,
.words-ranking,
.mentions-ranking {
  grid-column: span 3;
}

.top-authors {
  grid-column: span 2;
}

.top-articles {
  grid-column: span 1;
}
</style>
