import axios, { isAxiosError } from "axios";
import * as Sentry from "@sentry/vue";

axios.defaults.baseURL = import.meta.env.VITE_VUE_APP_API_URL;

export async function httpRequest(method, url, data, responseType = undefined) {
  const config = {
    method: method,
    url: url,
    headers: {
      Authorization: localStorage?.getItem(window._fsv_config.ACCESS_TOKEN_KEY),
    },
    data: data,
    responseType,
  };
  try {
    return await axios(config);
  } catch (error) {
    if (!isAxiosError(error)) {
      Sentry.captureMessage("Unexpected non-Axios Error during a request");
      Sentry.captureException(error);
    }

    if (!error.response) {
      if (isAxiosError(error)) {
        Sentry.captureMessage("Unexpected Axios Error with no response");
      }

      // fake the error format for the rest of the function
      error.response = {};
    }

    if (typeof error.response.data === "string") {
      try {
        // Parse errors in the RFC 9457 format { "detail": "error message" }
        error.response.dataMsg = JSON.parse(error.response.data)?.detail;
      } catch (err) {
        error.response.dataMsg = error.response.data;
      }
    } else if (typeof error.response.data === "object") {
      error.response.dataMsg =
        error.response.data.detail || JSON.stringify(error.response.data);
    } else {
      error.response.dataMsg = "Unknown error";
    }

    throw error;
  }
}
