<template>
  <template v-if="store.items.length">
    <!-- Listing -->
    <div class="responsive-cards-wrapper">
      <AmendmentCard
        v-for="amendment in store.items"
        :key="amendment._id"
        :amendment="amendment"
        :to="`/amendments/${amendment._id}`"
      />
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="store.item_count"
      :key="`${store.item_count}${store.selectedFilters.from}${store.selectedFilters.size}`"
      :total-items="store.item_count"
      :current-page="store.selectedFilters.from"
      :items-limit="store.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-else-if="store.hasActiveFilters" />
  <NoDataFollawed v-else-if="store.isFollawedActive" />
  <NoData v-else />
</template>

<script setup>
import AmendmentCard from "../molecules/AmendmentCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";

import { useAmendmentsStore } from "@/stores/amendments.js";

const store = useAmendmentsStore();

const changeCurrentPage = (from) => {
  store.selectedFilters.from = from;
  store.fetchAmendmentsList();
};
</script>
