<template>
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>

      <FollawedRadioButtons
        all-match-route="/petitions"
        follawed-route="/petitions/follawed"
      />
    </div>

    <!-- Actions container : Add petition -->
    <div class="params-row">
      <SwitchSlide
        v-if="!store.isFollawedActive"
        :is-active="!store.bypassQuery"
        @toggle="toggleUseRequest"
      >
        Utiliser la requête
      </SwitchSlide>

      <button class="btn-primary" @click="openCreatePetition">
        + Ajouter une pétition
      </button>
    </div>

    <!-- Creation Pop up -->
    <CreatePetition ref="createPetitionComponent" @close="handleClose" />

    <div class="params-row">
      <TotalMatched :item-count="store.item_count" feminine />

      <SelectItemsLimit
        :items-limit="state.selectedFilters.size"
        @change-limit="changeLimit"
      >
        Pétitions
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { storeToRefs } from "pinia";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import CreatePetition from "@/components/petitions/molecules/CreatePetition.vue";
import { usePetitionsStore } from "@/stores/petitions";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";

const store = usePetitionsStore();
const { selectedFilters } = storeToRefs(store);
const createPetitionComponent = ref(null);

// All filters in wanted display order with their labels
const filtersNames = {
  origin: "Origine",
  status: "Statut",
};

const state = reactive({
  item_count: computed(() => store.item_count),
  selectedFilters: computed(() => store.selectedFilters),
  origin: computed(() => store.filters?.origin),
  originFilters: computed(() => store.selectedFilters.origin),
});

function openCreatePetition() {
  createPetitionComponent.value.openDialog();
}

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });

  return arr;
});

function handleClose() {
  createPetitionComponent.value.closeDialog();
}

// add Filter Items
const addFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];
  targetFilters = [...targetFilters, event.item];
  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: targetFilters,
  };
  fetchPetitions();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }

  fetchPetitions();
};

// Change the limit of items per pages
async function changeLimit(size) {
  selectedFilters.value.size = size;
  await store.fetchPetitionsList();
}

function toggleUseRequest() {
  store.bypassQuery = !store.bypassQuery;
  fetchPetitions();
}

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchPetitions();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchPetitions();
};

const fetchPetitions = async () => {
  selectedFilters.value.from = 0;
  await store.fetchPetitionsList();
};
</script>

<style scoped lang="scss">
.btn-primary {
  margin-left: auto;
}
</style>
