export const TIME_INTERVALS = {
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
  HOUR: "hour",
};

export const TIME_INTERVALS_LATEST = {
  LAST_24H: "last_24h",
  LAST_WEEK: "last_week",
  LAST_MONTH: "last_month",
  LAST_YEAR: "last_year",
  LEGI_15: "legislature_15",
  LEGI_16: "legislature_16",
  LEGI_17: "legislature_17",
  FREE: "free",
};
