const LABEL_COLORS = {
  lobbies: { background: "#E48B34", text: "white" },
  "médias & journalistes": { background: "#9C7FDD", text: "white" },
  vip: { background: "#ECCB45", text: "black" },
  politiques: { background: "#4FAFD5", text: "white" },
  institutions: { background: "#DE339C", text: "white" },
  events: { background: "#63D562", text: "black" },
};

export default function getMajorStakeholderColor(ms) {
  return (
    LABEL_COLORS[ms.toLowerCase()] || {
      background: "lightgray",
      color: "black",
    }
  );
}
