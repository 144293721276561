<template>
  <RadioButtons
    :active-option="activeValue"
    :is-blue-style="isBlueStyle"
    :options="options"
    @select-option="selectOption"
  />
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import RadioButtons from "../atoms/RadioButtons.vue";

const props = defineProps({
  options: {
    type: Array,
    // format of element:
    // - label : Text to display
    // - value : Link router URL
    // - activePattern : Pattern to match to display link as active
    required: true,
  },
  isBlueStyle: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const activeValue = computed(() => {
  function normalizeUrl(url) {
    // Remove trailing slash
    return url.replace(/\/$/, "");
  }

  const route = router.currentRoute.value;
  let matchedOption;

  matchedOption = props.options.find((o) =>
    o.exact
      ? route.path === o.value
      : normalizeUrl(route.fullPath).includes(
          normalizeUrl(o.activePattern || o.value)
        )
  );

  if (!matchedOption && route.name) {
    matchedOption = props.options.find((o) =>
      route.name.toLowerCase().includes(o.label.toLowerCase())
    );
  }

  return matchedOption?.value || null;
});

function selectOption(newValue) {
  router.push(newValue);
}
</script>
