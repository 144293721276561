export function getSortedNonEmptyColumns(map, columnHeaders, metric) {
  return (
    columnHeaders
      ?.filter((columnName) => map[columnName]?.length > 0)
      .sort((column1, column2) => {
        //
        const deltaGroup =
          getHeighest(map[column2], metric) - getHeighest(map[column1], metric);

        if (deltaGroup !== 0) {
          return deltaGroup;
        }

        // In case of ties, return the column with most elements first
        return column2.length - column1.length;
      }) || []
  );
}

function getHeighest(column, metric) {
  return column.sort(
    (author1, author2) => author2[metric] - author1[metric]
  )[0][metric];
}
