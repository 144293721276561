<template>
  <div class="card ranking-wrapper">
    <div class="rank-header">
      <div class="header-icon"><i :class="props.icon" /></div>
      <div class="header-title">
        Classement <span>{{ props.name }}</span>
      </div>

      <div class="header-export">
        <DownloadButton @click="downloadAsExcel" />
      </div>
    </div>
    <div v-if="$props.isLoading">
      <div class="loading-wrapper">
        <hollow-dots-spinner :animation-duration="1000" :color="props.color" />
      </div>
    </div>
    <div v-else>
      <ul v-if="Object.keys(props.items).length > 0" class="item-list">
        <li
          v-for="item in itemsDescendingOrder"
          :key="item[0]"
          class="item-entry"
        >
          <button class="ranking-link" @click="$emit('clickItem', item[0])">
            {{ item[0] }}
          </button>
          <span>{{ item[1] }} mention{{ item[1] > 0 ? "s" : null }}</span>
        </li>
      </ul>
      <div v-else>
        <div class="no-data-wrapper">
          <NoData />
        </div>
      </div>
    </div>
    <div class="ranking-footer">
      <PaginationElement
        v-if="
          !props.isLoading && Object.keys(props.items).length > ITEMS_PER_PAGE
        "
        :total-items="Object.keys(items).length"
        :items-limit="ITEMS_PER_PAGE"
        :current-page="currentPage"
        @change-current-page="changeCurrentPage"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import xlsx from "json-as-xlsx";
import { HollowDotsSpinner } from "epic-spinners";

import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import DownloadButton from "@/components/reusable/atoms/DownloadButton.vue";

const ITEMS_PER_PAGE = 7;

const currentPage = ref(0);

const props = defineProps({
  color: {
    type: String,
    required: true,
  },
  icon: { type: String, required: true },
  items: {
    type: Object,
    default: () => ({}),
  },
  isLoading: { type: Boolean, required: true },
  name: {
    type: String,
    required: true,
  },
});

defineEmits(["clickItem"]);

const itemsDescendingOrder = computed(() =>
  Object.entries(props.items)
    .sort(([, val1], [, val2]) => val2 - val1)
    .slice(
      ITEMS_PER_PAGE * currentPage.value,
      ITEMS_PER_PAGE * currentPage.value + ITEMS_PER_PAGE
    )
);

function changeCurrentPage(newPage) {
  currentPage.value = newPage;
}

function downloadAsExcel() {
  const dataAsJson = [
    {
      sheet: props.name,
      columns: [
        { label: props.name, value: "key" },
        { label: "Nombre", value: "val" },
      ],
      content: Object.entries(props.items).map(([key, val]) => ({ key, val })),
    },
  ];

  xlsx(dataAsJson, {
    fileName: props.name,
  });
}
</script>

<style scoped lang="scss">
.ranking-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rank-header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;

  padding: 8px 0;
  border-bottom: 3px solid v-bind("props.color");

  .header-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: v-bind("props.color");
    color: white;
    font-size: 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-title {
    font-size: 1rem;
    font-weight: bold;
  }

  .header-export {
    flex-grow: 1;
    text-align: right;
  }
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .item-entry {
    width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 8px 0;

    border-bottom: 1px solid var(--clr-p2);
    &:last-child {
      border-bottom: initial;
    }
  }
}

.ranking-footer {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.loading-wrapper,
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px;
}

.ranking-link {
  text-decoration: none;
  font-style: normal;
  color: var(--clr-n8);
}

.ranking-link:hover {
  text-decoration: underline;
  color: var(--clr-link-default);
}
</style>
