<template>
  <template v-if="store.zoom">
    <!-- Listing -->
    <AmendmentZoomCard
      :key="store.zoom._id"
      :amendment="store.zoom"
    ></AmendmentZoomCard>
  </template>
  <template v-else>
    <NoData />
  </template>
</template>

<script setup>
import AmendmentZoomCard from "../molecules/AmendmentZoomCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";

import { useAmendmentsStore } from "@/stores/amendments.js";

const store = useAmendmentsStore();
</script>
