<template>
  <div class="englobe-datas">
    <!-- Card -->
    <QuestionsZoomCard
      v-if="!store.loading && store.questionZoom"
      :key="store.questionZoom._id"
      :question="store.questionZoom"
    />
  </div>
</template>

<script setup>
import { useQuestionsStore } from "@/stores/questions";
import QuestionsZoomCard from "@/components/questions/atoms/QuestionsZoomCard.vue";

const store = useQuestionsStore();
</script>
