<template>
  <label class="switch">
    <input
      type="checkbox"
      :checked="checked"
      @change.stop="() => emit('change')"
    />
    <span class="slider round"></span>
  </label>
</template>

<script setup>
defineProps({
  checked: {
    type: Boolean,
    required: true,
  },
  size: {
    type: String,
    default: "20px",
  },
  color: {
    type: String,
    default: "var(--clr-p3)",
  },
});

const emit = defineEmits(["change"]);
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  --switch-size: v-bind(size);
  --switch-color: v-bind(color);

  position: relative;
  display: inline-block;
  width: calc(var(--switch-size) * 2);
  height: calc(var(--switch-size));
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--clr-n6);
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(var(--switch-size) - 8px);
  width: calc(var(--switch-size) - 8px);
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: transform 0.1s ease;
}

input:checked + .slider {
  background-color: var(--switch-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--switch-color);
}

input:checked + .slider:before {
  transform: translateX(var(--switch-size));
}

/* Rounded sliders */
.slider.round {
  border-radius: 100vh;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
