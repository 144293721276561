<template>
  <div class="template">
    <LoaderFullPage v-if="amStore.loading || prmStore.loaging" />
    <template v-else>
      <BreadCrumbs :crumbs="crumbsArray" />
      <AmendmentZoomCard v-if="amStore.zoom" :amendment="amStore.zoom" />
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import BreadCrumbs from "@/components/reusable/atoms/BreadCrumbs.vue";
import AmendmentZoomCard from "@/components/amendments/molecules/AmendmentZoomCard.vue";

import { usePrmStore } from "@/stores/prm";
import { useAmendmentsStore } from "@/stores/amendments";

const prmStore = usePrmStore();
const amStore = useAmendmentsStore();
const route = useRoute();

const crumbsArray = computed(() => [
  {
    text: "PRM",
    to: "/prm-national",
  },
  route.params._id === "follawed"
    ? {
        text: "Activités Follawed",
        to: "/prm-national/follawed/activities",
      }
    : {
        text: prmStore.prmZoom?.name ?? "Politique",
        to: `/prm-national/${route.params._id}/activities?`,
      },
  {
    text: "Amendement",
  },
]);

// Get the PRM to display the name in the breadcrumbs
if (
  route.params._id !== "follawed" &&
  prmStore.prmZoom?._id !== route.params._id
) {
  prmStore.fetchOnePersonnality(route.params._id);
}

amStore.fetchOneAmendment(route.params.amId);
</script>
