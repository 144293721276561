<template>
  <LoaderFullPage v-if="store.loadingDecrets" />
  <NoData v-else-if="store.zoomDecrets.length === 0" />
  <ul v-else class="decrets-list">
    <li v-for="d in store.zoomDecrets" :key="d._id">
      <JorfCard :jorf="d" />
    </li>
  </ul>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useLegiWorkStore } from "@/stores/legi-work";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import JorfCard from "@/components/jorfs/atoms/JorfCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";

const route = useRoute();
const { stepIdx } = route.params;

const store = useLegiWorkStore();

const step = store.legiWorkZoom.timeline[stepIdx];
const nor_ref = step.promulgation.nor_ref;

store.loadDecrets(nor_ref);
</script>

<style scoped lang="scss">
ul.decrets-list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  li {
    max-width: 350px;
  }
}
</style>
