<template>
  <AbstractCard
    color="var(--clr-service-report)"
    icon="fa-kit fa-cr"
    follawed-name="follawed_compte_rendus"
    :doc-id="report._id"
    :target-url="routingUrl"
    :has-follaw-button="hasFollawButton"
  >
    <template #header>
      {{ getDayTimeFormat(report.created_at) }}
    </template>

    <template #content>
      <ReportCardContent :report="report" />
    </template>
  </AbstractCard>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import AbstractCard from "../../reusable/abstract/AbstractCorpusCard.vue";
import ReportCardContent from "@/components/legi-reports/molecules/ReportCardContent.vue";
import { getDayTimeFormat } from "@/utils/formatDate";

const props = defineProps({
  report: {
    type: Object,
    required: true,
  },
  hasFollawButton: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();

const routingUrl = computed(() => {
  let baseRoute = "";

  if (route.fullPath.includes("lobbies")) {
    baseRoute = "/lobbies";
    if (route.fullPath.includes("follawed")) {
      baseRoute += "/follawed";
    } else {
      baseRoute += `/${route.params._id}`;
    }
  }

  if (props.report.data_type === "legi_rapport") {
    baseRoute += "/reports";
  } else {
    baseRoute += "/comptes-rendus";
  }

  return `${baseRoute}/${encodeURIComponent(props.report.ref)}`;
});
</script>
