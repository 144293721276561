<template>
  <i class="icon fa-regular fa-magnifying-glass" />
  <input
    v-model.trim="model"
    type="search"
    placeholder="Chercher par nom ou par ministère"
  />
  <div v-if="model.length > 0" class="clear-btn">
    <button
      @mouseenter="() => (isResetHovered = true)"
      @mouseleave="() => (isResetHovered = false)"
      @click="resetCurrentValue"
    >
      <i
        :class="`fa-${isResetHovered ? 'solid' : 'light'} fa-arrow-rotate-left`"
      />
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";

const model = defineModel({ type: String, required: true });

const isResetHovered = ref(false);

const resetCurrentValue = () => {
  model.value = "";
  isResetHovered.value = false;
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid var(--clr-n3);
  width: 380px;
  height: 32px;
  padding-left: 42px;
  border-radius: 4px;
}

.icon {
  position: absolute;
  left: 36px;
  top: 27px;
  width: 18.2px;
  height: 18.18px;
  fill: var(--clr-n7);
}

.clear-btn {
  position: absolute;
  left: 370px;
  top: 22px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 25px;

    font-size: 0.8rem;
    color: black;

    background-color: white;
    border-radius: 50%;

    &:focus {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) inset;
    }
  }
}
</style>
