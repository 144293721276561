<template>
  <div class="wrapper">
    <div class="header-title">Niveau de bruit</div>
    <div class="graph-row">
      <img class="bars" :src="svgUrl" />
      {{ description }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import NOISE_LEVEL_LOW from "@/assets/img/tweet_noise_level/low.svg";
import NOISE_LEVEL_MEDIUM_LOW from "@/assets/img/tweet_noise_level/medium_low.svg";
import NOISE_LEVEL_MEDIUM_HIGH from "@/assets/img/tweet_noise_level/medium_high.svg";
import NOISE_LEVEL_HIGH from "@/assets/img/tweet_noise_level/high.svg";

const props = defineProps({ level: { type: Number, required: true } });

const svgUrl = computed(
  () =>
    [
      NOISE_LEVEL_LOW,
      NOISE_LEVEL_MEDIUM_LOW,
      NOISE_LEVEL_MEDIUM_HIGH,
      NOISE_LEVEL_HIGH,
    ][props.level]
);

const description = computed(
  () =>
    [
      "Ce dossier fait partie des dossiers législatif peu discutés.",
      "Ce dossier fait partie des dossiers législatif faiblement discutés.",
      "Ce dossier fait partie des dossiers législatif très discutés.",
      "Ce dossier est très discuté, il fait partie des dossiers législatif chauds.",
    ][props.level]
);
</script>

<style scoped lang="scss">
.header-title {
  text-align: center;
}

.graph-row {
  display: flex;
  align-items: center;
  gap: 15px;
}
.bars {
  width: 125px;
}
</style>
