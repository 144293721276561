import { Platforms } from "@/utils/social360-platforms";

export function useHandle(handle, platform) {
  if (!Object.values(Platforms).includes(platform)) {
    throw new Error(
      `Platform "${platform}" is not valid. Please use one of the following: ${Object.values(Platforms).join(", ")}`
    );
  }

  if (!handle) {
    return {
      label: "",
      url: "",
    };
  }

  let label = handle;

  const urlHandle = {
    [Platforms.TWITTER]: `https://twitter.com/${handle}`,
    [Platforms.LINKEDIN]: (() => {
      const isCompany = handle.startsWith("company/");
      label = handle.replace(/(company|in)\//, "");
      return `https://linkedin.com/${isCompany ? "company" : "in"}/${label}`;
    })(),
    [Platforms.INSTAGRAM]: `https://instagram.com/${handle}`,
    [Platforms.TIKTOK]: `https://www.tiktok.com/@${handle}`,
    [Platforms.BLUESKY]: `https://bsky.app/profile/${handle}`,
  };

  const url = urlHandle[platform] || "";

  return { label, url };
}

/**
 * Returns the correctly formatted LinkedIn handle.
 * @param {Object} author - The author object containing LinkedIn information.
 * @returns {string} The formatted LinkedIn handle, or an empty string if no username is provided.
 *---
 * @example
 * // For an individual
 * getLinkedinHandle({ linkedin_information: { username: 'john-doe', individual: true } });
 * // Returns: "in/john-doe"
 *
 * @example
 * // For a company
 * getLinkedinHandle({ linkedin_information: { username: 'tech-company', individual: false } });
 * // Returns: "company/tech-company"
 *
 */
export function getLinkedinHandle(author) {
  if (!author || !author.linkedin_information?.username) {
    return "";
  }

  const handlePrefix = author.linkedin_information?.individual
    ? "in"
    : "company";

  return `${handlePrefix}/${author.linkedin_information?.username}`;
}
