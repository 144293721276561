<template>
  <div class="error">
    <img
      src="@/assets/img/Forbidden.svg"
      alt="error 403 forbidden"
      class="error-img"
    />
    <div class="error-title">Accès refusé</div>
    <div class="error-message">
      Désolé, vous ne pouvez pas accéder à cette page car elle est réservée aux
      utilisateurs abonnés à une veille privée.
      <template v-if="userSettingsStore.hasPermission('BASIC')">
        Vous pouvez explorer d'autres données disponibles en choisissant une
        autre veille dans le menu.
      </template>
      <template v-else>
        Les veilles personnalisées sont des fonctionnalités des abonnements
        payants. Pour obtenir un plan payant veuillez consulter nos abonnements.
      </template>
      Si vous pensez qu'il s'agit d'une erreur, veuillez
      <a @click="askAboutForbiddenDashboard">nous contacter</a>.
    </div>
    <div class="error-action">
      <!-- TODO : Add conditions back once the comparison is made available on commercial site -->
      <!-- v-if="userSettingsStore.hasPermission('BASIC')" -->
      <button class="btn-primary" @click="refreshWithSavedDashboard">
        Réinitialiser ma veille
      </button>

      <!-- <BaseLink v-else href="https://www.follaw.sv/" class="btn-primary">
        Comparer les abonnements
      </BaseLink> -->
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
// import BaseLink from "@/components/general/atoms/BaseLink.vue";
import { useUserSettingsStore } from "@/stores/user-settings";
import { useUserStore } from "@/stores/user";

const userSettingsStore = useUserSettingsStore();
const userStore = useUserStore();

const router = useRouter();
const route = useRoute();

const refreshWithSavedDashboard = () => {
  router.push({
    path: route.query.accessTo,
    query: {
      dashboard: userStore.selectedDashboard,
    },
  });
};

const askAboutForbiddenDashboard = () => {
  // Use the Crisp API to automatically start a conversation with the Sales team
  window.$crisp.push(["do", "chat:open"]);

  window.$crisp.push([
    "set",
    "message:text",
    [
      `Bonjour, j'ai accédé à un lien vers la veille ${route.query.withDashboard} mais il semble que mon compte n'y ait pas accès. Pouvez-vous m'aider à comprendre la situation ?`,
    ],
  ]);
};
</script>

<style scoped lang="scss">
.error {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  height: 100%;
  margin: 0 auto;

  .error-img {
    margin-bottom: 20px;
    width: 33%;
    height: 33%;
  }

  .error-title {
    font-size: 1.313em;
    font-weight: bold;
  }

  .error-message {
    text-align: center;

    &.bold {
      font-size: 1.125em;
      font-weight: 600;
    }
  }

  .error-action {
    margin-top: 10px;
  }
}
</style>
