import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { useStorage } from "@vueuse/core";
import { useToast } from "vue-toastification";

import { httpRequest } from "@/utils/request.utils";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const toast = useToast();

export const useAuthStore = defineStore("auth", () => {
  const userStore = useUserStore();

  const userID = useStorage("userID", null);
  const hasCheckedToken = ref(false);
  const hasValidToken = ref(false);

  const loginRequest = async (data) => {
    try {
      const response = await axios.post("/login", data);

      if (response.status === 202) {
        return {
          data: response.data,
          statusCode: response.status,
        };
      }

      if (response.status === 200) {
        const accessToken = "Bearer " + response.data.access_token;
        localStorage?.setItem("follaw_access_token", accessToken);
        userStore.getUserAndFilters();
        hasValidToken.value = true;
      }
    } catch (error) {
      hasValidToken.value = false;

      if (
        error.response.status === 404 &&
        error.response.data.detail.match(/user not found/i)
      ) {
        toast.error("Pas de compte associé à cet email.");
      } else {
        handleNetworkError(error);
      }
    }
  };

  async function checkCurrentToken() {
    try {
      const response = await httpRequest("get", "/");
      hasValidToken.value = response.status === 200;
    } catch (error) {
      hasValidToken.value = false;

      if (
        error?.response?.status === 401 &&
        error?.response?.dataMsg?.match(/invalid token/i)
      ) {
        // Notify user and do not throw the error further
        toast.error("Votre session a expirée, veuillez vous reconnecter.");
      } else if (
        error?.response?.status === 403 &&
        error?.response?.dataMsg?.match(/not authenticated/i)
      ) {
        // Notify user and do not throw the error further
        toast.info(
          "Veuillez vous connecter ou vous créer un compte pour accéder à la plateforme"
        );
      } else {
        handleNetworkError(error);
      }
    } finally {
      hasCheckedToken.value = true;
    }
  }

  const createTokenWithDigicode = async (payload) => {
    try {
      const response = await httpRequest("patch", "/login", payload);
      if (response.status === 200) {
        const accessToken = "Bearer " + response.data.access_token;
        localStorage?.setItem("follaw_access_token", accessToken);
        userID.value = response.data.user;
        userStore.getUserAndFilters();
        hasValidToken.value = true;
      }
    } catch (error) {
      hasValidToken.value = false;

      if (error.response.status >= 400) {
        if (error.response.status === 422) {
          // Invalid code or email
          toast.error(
            "Le lien que vous avez suivi n'est pas valide. Veuiller réessayer."
          );
        } else {
          toast.error(error.response.data?.detail || "Erreur inconnue");
          return { error: error.response.data?.detail || "Erreur inconnue" };
        }
      } else {
        return { error: error.response };
      }
    } finally {
      hasCheckedToken.value = true;
    }
  };

  const refreshTokenBeforeExpiration = async () => {
    try {
      const response = await httpRequest("patch", "/login", {
        extend_token: true,
      });
      if (response.status === 200) {
        const accessToken = "Bearer " + response.data.access_token;
        localStorage?.setItem("follaw_access_token", accessToken);
      }
    } catch (error) {
      hasValidToken.value = false;
    }
  };

  const logoutRequest = async () => {
    const response = await httpRequest("post", "/logout");
    if (response.status === 200) {
      userID.value = null;
      hasValidToken.value = false;
      window.CRISP_TOKEN_ID = null; // 1. Clear the crisp token value
      window.$crisp.push(["do", "session:reset"]); // 2. Unbind the current crisp session
      window.$crisp.push(["do", "chat:hide"]); // 3. Hide the chatbox
      router.push("/");
    }
  };

  return {
    userID,
    hasCheckedToken,
    hasValidToken,
    loginRequest,
    checkCurrentToken,
    createTokenWithDigicode,
    refreshTokenBeforeExpiration,
    logoutRequest,
  };
});
