import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { defineStore, storeToRefs } from "pinia";
import { httpRequest } from "@/utils/request.utils";
import { useUserStore } from "@/stores/user";
import { handleNetworkError } from "@/utils/handleNetworkError";
import { fetchArticle } from "@/services/apiSocialMedia";

const initialTwitterArticlesFilters = {
  from: 0, //* Page number
  size: 20, //* Number of items per page
  sort_field: "number_of_tweets", //* Primary sorter of the list
  sort_order: "desc", //* Sort order
  get_filters: true, //* Retrieve available filters
  highlight: true, //* Retrieve highligted text based on querries/dashboard
};

export const useTwitterArticlesStore = defineStore("articles", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const items = ref([]);
  const item_count = ref(null);
  const filters = ref(null);
  const selectedFilters = ref({ ...initialTwitterArticlesFilters });
  const loading = ref(false);

  const relatedTweets = ref([]);
  const isPublicationsLoading = ref(false);

  const fetchTwitterArticlesList = async () => {
    loading.value = true;

    item_count.value = null;
    items.value = [];

    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const data = await fetchArticle(payload);
      if (data) {
        item_count.value = data.item_count;
        items.value = data.items;
        filters.value = data.filters;
      }
    } finally {
      loading.value = false;
    }
  };

  async function fetchRelatedTweetsOfArticle(articleId) {
    isPublicationsLoading.value = true;

    const payload = {
      indexes: ["x"],
      links_id: [articleId],
      get_filters: false,
      highlight: true,
      ...sharedFiltersAsPayload.value,
    };

    try {
      const response = await httpRequest(
        "post",
        `/social-media/publications`,
        payload
      );
      if (response.data) {
        relatedTweets.value = response.data.items;
      }
    } catch (err) {
      handleNetworkError(err);
    }

    isPublicationsLoading.value = false;
  }

  const resetFilters = () => {
    selectedFilters.value = { ...initialTwitterArticlesFilters };
  };

  const resetStore = () => {
    items.value = [];
    filters.value = null;
    selectedFilters.value = { ...initialTwitterArticlesFilters };
    loading.value = false;

    item_count.value = null;
  };

  const route = useRoute();
  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval && route.name === "Articles") {
        selectedFilters.value.from = 0;
        fetchTwitterArticlesList();
      }
    }
  );

  return {
    items,
    item_count,
    filters,
    selectedFilters,
    isPublicationsLoading,
    relatedTweets,
    loading,
    fetchRelatedTweetsOfArticle,
    fetchTwitterArticlesList,
    resetFilters,
    resetStore,
  };
});
