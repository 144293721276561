<template>
  <GenericTooltip>
    <template #trigger>
      <GenericTag
        :color="getStatusColor(status).text"
        :background-color="getStatusColor(status).background"
      >
        <div class="capitalize-letter">
          {{ status }}
        </div>
      </GenericTag>
    </template>
    <template #text>{{ getStatusTooltip(status) }}</template>
  </GenericTooltip>
</template>

<script setup>
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import GenericTooltip from "@/components/reusable/atoms/GenericTooltip.vue";
import { AMENDMENTS_STATUS_COLORS } from "@/utils/amendments.js";

defineProps({
  status: {
    type: String,
    required: true,
  },
});

const STATUS_TOOLTIPS = {
  "à discuter": "L'amendement n'a pas encore été examiné",
  discuté: "L'amendement a été examiné, mais son sort n'est pas encore connu",
  "en recevabilité":
    "L'amendement respecte les règles légales, mais son sort n'est pas encore connu",
  "en traitement": "L'amendement est en cours de traitement",
  irrecevable:
    "L'amendement ne respecte pas les critères de recevabilité financière, législative ou procédurière",
  "irrecevable 40":
    "L'amemdement aurait pour conséquence une diminution des ressources ou une augmentation des dépenses",
  retiré:
    "L'amendement a été retiré car il existe déjà ou parce que son retrait permet l'accélération des débats",
  effacé: "L'amendement a été effacé",
  adopté: "L'amendement a été adopté à la majorité",
  tombé:
    "L'amendement est tombé car il est similaire à un autre où parce que l'article cité a été supprimé",
  rejeté: "L'amendement a été rejeté ",
  "non soutenu":
    "L'amendement n'a pas été soutenu publiquement par l'élu qui l'a soumis",
};

const getStatusColor = (status) => {
  return (
    AMENDMENTS_STATUS_COLORS[status.toLowerCase()] || {
      background: "#E7ECF4",
      color: "black",
    }
  );
};

const getStatusTooltip = (status) => {
  return (
    STATUS_TOOLTIPS[status.toLowerCase()] || "Explication concernant le statut"
  );
};
</script>
