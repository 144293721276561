import { handleNetworkError } from "@/utils/handleNetworkError";
import { httpRequest } from "@/utils/request.utils";

export async function fetchLobbiesListing(payload) {
  try {
    const response = await httpRequest("post", "/v2/lobbies/listing", payload);
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function fetchOneLobby(lobbyId, payload) {
  try {
    const response = await httpRequest(
      "post",
      `/v2/lobbies/zoom/${lobbyId}`,
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function fetchOneLobbyNews(lobbyId, payload) {
  try {
    const response = await httpRequest(
      "post",
      `/v2/lobbies/zoom/${lobbyId}/news`,
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function fetchOneLobbyTimeline(lobbyId) {
  try {
    const response = await httpRequest(
      "get",
      `/v2/lobbies/zoom/${lobbyId}/timeline`
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function fetchOneLobbyLatestNews(lobbyId) {
  try {
    const response = await httpRequest(
      "get",
      `/v2/lobbies/zoom/${lobbyId}/general-news`
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function fetchOneLobbyActivities(lobbyId, payload) {
  try {
    const response = await httpRequest(
      "post",
      `/v2/lobbies/zoom/${lobbyId}/activities`,
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function fetchFollawedActivities(payload) {
  try {
    const response = await httpRequest(
      "post",
      `/v2/lobbies/followed-activities`,
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function fetchFollawedNews(payload) {
  try {
    const response = await httpRequest(
      "post",
      `/v2/lobbies/followed-news`,
      payload
    );
    return response.data;
  } catch (err) {
    handleNetworkError(err);
  }
}

export async function searchLobbiesByName(name) {
  try {
    const response = await httpRequest("post", "/v2/lobbies/search_lobbies", {
      name,
    });
    return response.data.lobbies;
  } catch (err) {
    handleNetworkError(err);
  }
}
