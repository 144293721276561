<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          v-if="!store.isFollawedActive"
          filter="author"
          :items="store.authors"
          item-label="name"
          item-value="_id"
          :active-filters="selectedFilters.author"
          :is-loading="store.isAuthorFilterLoading"
          mode="purple"
          @search="fetchSearchAuthors"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetAuthorFilter"
        >
          Auteurs
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>

      <FollawedRadioButtons
        all-match-route="/questions"
        follawed-route="/questions/follawed"
      />
    </div>

    <!--Stats-->
    <QuestionsStats v-if="!store.isFollawedActive && statsAvailable" />

    <div class="params-row">
      <TotalMatched :item-count="store.item_count" feminine />

      <div class="page-settings">
        <div class="export-wrapper">
          <ExportButton
            :disabled="store.loadingExcel"
            icon-name="file-spreadsheet"
            @click="store.downloadExcel()"
          >
            <span v-if="store.loadingExcel">(En cours)</span>
            <span v-else>Exporter en .xlsx</span>
          </ExportButton>
        </div>

        <SortBy
          :items="sortersNames"
          :selected-sort-item="currentSortLabel"
          @change-sort="handleSortChange"
        />
        <SelectItemsLimit
          :items-limit="selectedFilters.size"
          @change-limit="changeLimit"
        >
          Questions
        </SelectItemsLimit>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";
import QuestionsStats from "@/components/questions/organisms/QuestionsStats.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import ExportButton from "@/components/reusable/atoms/ExportButton.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useQuestionsStore } from "@/stores/questions";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

// All filters in wanted display order with their labels
const filtersNames = {
  status: "Statut",
  type_question: "Type de question",
  circo_name: "Localité",
  group: "Groupe",
  ministry_responsible: "Ministère attributaire",
  institution: "Institution",
};

const sortersNames = {
  created_at: "Date de publication",
  closed_at: "Date de réponse",
};
const store = useQuestionsStore();
const { selectedFilters } = storeToRefs(store);

const currentSortField = ref(
  selectedFilters.value?.sort_field ?? sortersNames.created_at
);

const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      selectedFilters.value.sort_field = currentSortField;
      fetchQuestionsList();
    }
  }
};

const fetchQuestionsList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchQuestionsList();
  await store.fetchQuestionsStats();
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchQuestionsList();
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  selectedFilters.value.author?.forEach((filter) => {
    arr = [
      ...arr,
      {
        filter: "author",
        item: filter,
      },
    ];
  });
  return arr;
});

const showScrollTop = computed(() => selectedFilters.value.size > 20);

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] ?? [];
  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchQuestionsList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];
  const itemToRemove = event.item;
  targetFilters = targetFilters.filter((el) => el !== itemToRemove);
  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchQuestionsList();
};

const fetchSearchAuthors = (nameSearch) => {
  if (nameSearch.length > 0) {
    store.getSearchAuthorsFilters(nameSearch);
  } else {
    store.authors = [];
  }
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchQuestionsList();
};

const resetAuthorFilter = () => {
  store.authors = [];
  delete selectedFilters.value.author;
  fetchQuestionsList();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchQuestionsList();
};

// Stats
const statsAvailable = computed(() => {
  return (
    store.top3Ministries?.length > 0 ||
    store.similarQuestions?.length > 0 ||
    store.questionsType?.length > 0
  );
});
</script>
