<template>
  <template v-if="nominationsStore.items.length">
    <!-- Cards -->
    <div class="responsive-cards-wrapper">
      <NominationCard
        v-for="nomination in nominationsStore.items"
        :key="nomination._id"
        :nomination="nomination"
      ></NominationCard>
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="nominationsStore.item_count"
      :key="`${nominationsStore.item_count}${nominationsStore.selectedFilters.from}${nominationsStore.selectedFilters.size}`"
      :total-items="nominationsStore.item_count"
      :current-page="nominationsStore.selectedFilters.from"
      :items-limit="nominationsStore.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-else />
</template>

<script setup>
import NominationCard from "@/components/nominations/atoms/NominationCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";

import { useNominationsStore } from "@/stores/nominations";

const nominationsStore = useNominationsStore();

const changeCurrentPage = (from) => {
  nominationsStore.selectedFilters.from = from;
  nominationsStore.fetchNominationsList();
};
</script>
