import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { defineStore, storeToRefs } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialPrmNationalFilters = {
  size: 20,
  from: 0,
  sort: { number_of_activities: "desc" },
  get_filters: true,
};

const initialPrmRegionalFilters = {
  size: 20,
  from: 0,
  sort: { number_of_publications: "desc" },
  get_filters: true,
};

const initialPrmNewsParams = {
  size: 20,
  from: 0,
  sort: { created_at: "desc" },
};

const initialPrmActivitiesParams = {
  size: 20,
  from: 0,
};

export const ACTIVITIES_CHART_OPTIONS = {
  YEAR: "year",
  MONTH: "month",
};

export const NEWS_FILTERS = {
  PUBLICATIONS: "publications",
  ARTICLES: "articles",
};

export const ACTIVITIES_FILTERS = {
  PUBLICATIONS: "publications",
  LEGIDOCS: "legi-doc",
  AMENDMENTS: "amendment",
  QUESTIONS: "question",
  CR: "compte-rendu",
};

// Defining store
export const usePrmStore = defineStore("prm", () => {
  const route = useRoute();
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);

  // Listing
  const isRegional = ref(false);
  const items = ref([]);
  const itemCount = ref(null);

  // |_ News
  const isNewsLoading = ref(false);
  const bypassQueryNews = ref(false);
  const prmNewsPublications = ref(null);
  const prmNewsLinks = ref(null);
  const regionalNewsFilters = ref([
    NEWS_FILTERS.PUBLICATIONS,
    NEWS_FILTERS.ARTICLES,
  ]);
  const nationalNewsFilters = ref([
    NEWS_FILTERS.PUBLICATIONS,
    NEWS_FILTERS.ARTICLES,
  ]);
  const prmNewsFilters = computed({
    get() {
      return isRegional.value
        ? regionalNewsFilters.value
        : nationalNewsFilters.value;
    },
    set(newValue) {
      isRegional.value
        ? (regionalNewsFilters.value = newValue)
        : (nationalNewsFilters.value = newValue);
    },
  });
  const selectedRegionalNewsParams = ref(initialPrmNewsParams);
  const selectedNationalNewsParams = ref(initialPrmNewsParams);
  const selectedPrmNewsParams = computed({
    get() {
      return isRegional.value
        ? selectedRegionalNewsParams.value
        : selectedNationalNewsParams.value;
    },
    set(newValue) {
      isRegional.value
        ? (selectedRegionalNewsParams.value = newValue)
        : (selectedNationalNewsParams.value = newValue);
    },
  });
  const publicationsCount = ref(null);
  const linkCount = ref(null);

  // |_ Activities
  const isActivitiesLoading = ref(false);
  const bypassQueryActivities = ref(false);
  const prmActivities = ref(null);
  const regionalActivitiesFilters = ref([
    ACTIVITIES_FILTERS.PUBLICATIONS,
    ACTIVITIES_FILTERS.LEGIDOCS,
    ACTIVITIES_FILTERS.AMENDMENTS,
    ACTIVITIES_FILTERS.QUESTIONS,
    ACTIVITIES_FILTERS.CR,
  ]);
  const nationalActivitiesFilters = ref([
    ACTIVITIES_FILTERS.PUBLICATIONS,
    ACTIVITIES_FILTERS.LEGIDOCS,
    ACTIVITIES_FILTERS.AMENDMENTS,
    ACTIVITIES_FILTERS.QUESTIONS,
    ACTIVITIES_FILTERS.CR,
  ]);
  const prmActivitiesFilters = computed({
    get() {
      return isRegional.value
        ? regionalActivitiesFilters.value
        : nationalActivitiesFilters.value;
    },
    set(newValue) {
      isRegional.value
        ? (regionalActivitiesFilters.value = newValue)
        : (nationalActivitiesFilters.value = newValue);
    },
  });
  const selectedRegionalActivitiesParams = ref({
    ...initialPrmActivitiesParams,
  });
  const selectedNationalActivitiesParams = ref({
    ...initialPrmActivitiesParams,
  });
  const selectedPrmActivitiesParams = computed({
    get() {
      return isRegional.value
        ? selectedRegionalActivitiesParams.value
        : selectedNationalActivitiesParams.value;
    },
    set(newValue) {
      isRegional.value
        ? (selectedRegionalActivitiesParams.value = newValue)
        : (selectedNationalActivitiesParams.value = newValue);
    },
  });
  const indexCount = ref(null);

  // Zoom
  const prmZoom = ref(null);
  const prevZoomUrlQuery = ref(null);

  // |_ Zoom Charts
  const activitiesActiveOption = ref(ACTIVITIES_CHART_OPTIONS.YEAR);
  const isActivitiesChartLoading = ref(false);
  const activitiesChart = ref({});
  const isTopThematicsLoading = ref(false);
  const topThematics = ref([]);

  // filters
  const filters = ref(null);
  const selectedNationalFilters = ref({ ...initialPrmNationalFilters });
  const selectedRegionalFilters = ref({ ...initialPrmRegionalFilters });
  const selectedFilters = computed({
    get() {
      return isRegional.value
        ? selectedRegionalFilters.value
        : selectedNationalFilters.value;
    },
    set(newValue) {
      isRegional.value
        ? (selectedRegionalFilters.value = newValue)
        : (selectedNationalFilters.value = newValue);
    },
  });
  const authors = ref([]); // searched authors
  const isAuthorFilterLoading = ref(false);
  const isFollawedActive = ref(false);

  const fetchPrmList = async () => {
    loading.value = true;

    //* Make the payload using the filters from the feature and those from the topbar
    let payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
      regional: isRegional.value,
    };

    //* If there is author filters selected map them to only their ids
    if (payload.authors?.length > 0) {
      payload.authors = payload.authors.map((author) => author._id);
    }

    //* Adapt the sorter format to the backend
    delete payload.sort;
    payload.sort_field = Object.keys(selectedFilters.value.sort)[0];
    payload.sort_order = Object.values(selectedFilters.value.sort)[0];

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.PRM];

      //* Get follawed filters
      delete payload.get_filters;
      payload.get_follawed_filters = true;
    } else {
      //* Replace the authors filters by the _id field
      payload = {
        ...payload,
        _id: payload.authors,
      };
      delete payload.authors;
    }

    try {
      const response = await httpRequest("post", "/v2/prm/listing", payload);

      if (response.data) {
        items.value = response.data.items;
        itemCount.value = response.data.item_count;
        filters.value = response.data.filters;
      }
    } catch (err) {
      items.value = [];
      itemCount.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchFollawedPrmNews = async () => {
    loading.value = true;

    const payload = {
      ...selectedFilters.value,
      ...selectedPrmNewsParams.value,
      ...sharedFiltersAsPayload.value,
      regional: isRegional.value,
      indexes: prmNewsFilters.value,
      _id: userStore.follawed[FOLLAWED_FEATURES.PRM],
      highlight: true,
    };

    if (bypassQueryNews.value) {
      delete payload.dashboard_id;
    }

    //* Adapt the sorter format to the backend
    delete payload.sort;
    payload.sort_field = Object.keys(selectedPrmNewsParams.value.sort)[0];
    payload.sort_order = Object.values(selectedPrmNewsParams.value.sort)[0];

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/v2/prm/followed-news`,
        payload
      );
      prmNewsPublications.value = response.data.publications;
      prmNewsLinks.value = response.data.links;
      publicationsCount.value = response.data.publications_count;
      linkCount.value = response.data.link_count;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchFollawedPrmActivities = async () => {
    loading.value = true;

    const payload = {
      ...selectedFilters.value,
      ...selectedPrmActivitiesParams.value,
      ...sharedFiltersAsPayload.value,
      regional: isRegional.value,
      indexes: prmActivitiesFilters.value,
      _id: userStore.follawed[FOLLAWED_FEATURES.PRM],
      highlight: true,
    };

    if (bypassQueryActivities.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/prm/followed-activities`,
        payload
      );
      prmActivities.value = response.data.items;
      indexCount.value = response.data.index_count;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOnePersonnality = async (prmId) => {
    loading.value = true;

    // This ref can be used to check if the query has changed in the zoom views
    prevZoomUrlQuery.value = JSON.stringify(route.query);

    try {
      const response = await httpRequest("post", `/v2/prm/zoom/${prmId}`, {
        ...sharedFiltersAsPayload.value,
      });

      prmZoom.value = response.data;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOnePersonnalityTemporality = async (prmId) => {
    isActivitiesChartLoading.value = true;

    try {
      const response = await httpRequest(
        "post",
        `/v2/prm/zoom/${prmId}/temporal-activity`,
        {
          ...sharedFiltersAsPayload.value,
          interval: activitiesActiveOption.value,
        }
      );
      activitiesChart.value = response.data.timelines;
    } catch (err) {
      handleNetworkError(err);
    }

    isActivitiesChartLoading.value = false;
  };

  const fetchOnePersonnalityTopThematics = async (prmId) => {
    isTopThematicsLoading.value = true;

    try {
      const response = await httpRequest(
        "get",
        `/v2/prm/zoom/${prmId}/top-thematics`
      );
      topThematics.value = Object.entries(response.data.thematics).map(
        ([key, value]) => {
          return {
            label: key,
            counts: value,
          };
        }
      );
    } catch (err) {
      handleNetworkError(err);
    }

    isTopThematicsLoading.value = false;
  };

  const fetchOnePersonnalityNews = async (prmId) => {
    isNewsLoading.value = true;

    const payload = {
      ...selectedPrmNewsParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: prmNewsFilters.value,
    };

    if (bypassQueryActivities.value) {
      delete payload.dashboard_id;
    }

    //* Adapt the sorter format to the backend
    delete payload.sort;
    payload.sort_field = Object.keys(selectedPrmNewsParams.value.sort)[0];
    payload.sort_order = Object.values(selectedPrmNewsParams.value.sort)[0];

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/v2/prm/zoom/${prmId}/news`,
        payload
      );
      publicationsCount.value = response.data.publications_count;
      linkCount.value = response.data.link_count;
      prmNewsPublications.value = response.data.publications;
      prmNewsLinks.value = response.data.links;
    } catch (err) {
      handleNetworkError(err);
    }
    isNewsLoading.value = false;
  };

  const fetchOnePersonnalityActivities = async (prmId) => {
    isActivitiesLoading.value = true;

    const payload = {
      ...selectedPrmActivitiesParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: prmActivitiesFilters.value,
      highlight: true,
    };

    if (bypassQueryActivities.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/v2/prm/zoom/${prmId}/activities`,
        payload
      );
      indexCount.value = response.data.index_count;
      prmActivities.value = response.data.items;
    } catch (err) {
      handleNetworkError(err);
    }
    isActivitiesLoading.value = false;
  };

  const getSearchAuthorsFilters = async (name) => {
    isAuthorFilterLoading.value = true;

    try {
      const response = await httpRequest("post", "/v2/prm/search_authors", {
        name: name,
        regional: isRegional.value,
      });
      authors.value = response.data.personalities;
    } catch (err) {
      handleNetworkError(err);
    }
    isAuthorFilterLoading.value = false;
  };

  const resetFilters = () => {
    selectedFilters.value = isRegional.value
      ? { ...initialPrmRegionalFilters }
      : { ...initialPrmNationalFilters };
  };

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchPrmList();
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        switch (route.name) {
          case "PRM National Zoom - Actualités":
          case "PRM Régional Zoom - Actualités":
            selectedFilters.value.from = 0;
            fetchOnePersonnalityNews(route.params._id);
            break;
          case "PRM National Zoom - Activités":
          case "PRM Régional Zoom - Activités":
            selectedFilters.value.from = 0;
            fetchOnePersonnalityActivities(route.params._id);
            break;
          case "PRM National Follawed - Leurs actualités":
          case "PRM Régional Follawed - Leurs actualités":
            selectedFilters.value.from = 0;
            fetchFollawedPrmNews();
            break;
          case "PRM National Follawed - Leurs activités":
          case "PRM Régional Follawed - Leurs activités":
            selectedFilters.value.from = 0;
            fetchFollawedPrmActivities();
            break;
          case "National":
          case "PRM National Follawed - Vos follaw":
          case "Régional, départemental & local":
          case "PRM Régional Follawed - Vos follaw":
            selectedFilters.value.from = 0;
            fetchPrmList();
            break;
        }
      }
    }
  );

  return {
    loading,
    isRegional,
    items,
    itemCount,
    prmZoom,
    prevZoomUrlQuery,
    activitiesActiveOption,
    isActivitiesChartLoading,
    activitiesChart,
    isTopThematicsLoading,
    topThematics,
    isNewsLoading,
    bypassQueryNews,
    prmNewsPublications,
    prmNewsLinks,
    selectedPrmNewsParams,
    prmNewsFilters,
    publicationsCount,
    linkCount,
    isActivitiesLoading,
    bypassQueryActivities,
    prmActivities,
    selectedPrmActivitiesParams,
    prmActivitiesFilters,
    indexCount,
    filters,
    selectedRegionalFilters,
    selectedNationalFilters,
    selectedFilters,
    authors,
    isAuthorFilterLoading,
    isFollawedActive,
    hasActiveFilters,
    fetchPrmList,
    fetchFollawedPrmNews,
    fetchFollawedPrmActivities,
    fetchOnePersonnality,
    fetchOnePersonnalityTopThematics,
    fetchOnePersonnalityTemporality,
    fetchOnePersonnalityNews,
    fetchOnePersonnalityActivities,
    toggleFollawed,
    getSearchAuthorsFilters,
    resetFilters,
  };
});
