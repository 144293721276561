<template>
  <LoaderFullPage v-if="stakeholdersStore.loading" />
  <template v-else>
    <div v-if="stakeholdersStore.authorsStakeholder.length">
      <div class="stakeholders-ranking__wrapper">
        <!-- Cards -->
        <AuthorCard
          v-for="author in stakeholdersStore.authorsStakeholder"
          :key="author._id"
          :author="author"
        />
      </div>
      <!-- Pagination -->
      <PaginationElement
        v-if="stakeholdersStore.authorsCountStakeholder"
        :key="`${stakeholdersStore.authorsCountStakeholder}${stakeholdersStore.selectedFilters.from}${stakeholdersStore.selectedFilters.size}`"
        :total-items="stakeholdersStore.authorsCountStakeholder"
        :current-page="stakeholdersStore.selectedFilters.from"
        :items-limit="stakeholdersStore.selectedFilters.size"
        @change-current-page="changeCurrentPage"
      />
    </div>
    <EmptySection
      v-else-if="stakeholdersStore.hasActiveFilters"
      message="Nous n’avons pas trouvé de partie prenante correspondant à votre requête."
    />
    <NoDataFollawed
      v-else-if="
        !stakeholdersStore.loading &&
        stakeholdersStore.authorsCountStakeholder === 0 &&
        stakeholdersStore.isFollawedActive
      "
    />
    <EmptySection
      v-else
      message="Nous n’avons pas trouvé de partie prenante correspondant à votre requête. "
    />
  </template>
</template>

<script setup>
import { useRoute } from "vue-router";

import AuthorCard from "@/components/social-media/molecules/AuthorCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import EmptySection from "@/components/reusable/molecules/EmptySection.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import { useUserSettingsStore } from "@/stores/user-settings";

const stakeholdersStore = useStakeholdersStore();
const userSettingsStore = useUserSettingsStore();
const route = useRoute();

const changeCurrentPage = async (from) => {
  stakeholdersStore.selectedFilters.from = from;
  await stakeholdersStore.getAuthorsStakeholdersList();
};

stakeholdersStore.isFollawedActive = route.fullPath.includes("follawed");

if (userSettingsStore.hasPermission("SOCIETAL")) {
  stakeholdersStore.selectedFilters.from = 0;
  stakeholdersStore.getAuthorsStakeholdersList();
}
</script>

<style scoped lang="scss">
.stakeholders-ranking__wrapper {
  display: grid;
  grid-gap: 10px;
  flex-grow: 3;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
</style>
