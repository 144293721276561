<template>
  <StepTimeline
    :legi-work-ref="legiWorkZoom.ref"
    :clickable="true"
    :timeline="legiWorkZoom.timeline"
  />

  <RouterView />
</template>

<script setup>
import { storeToRefs } from "pinia";
import StepTimeline from "../atoms/StepTimeline.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import { RouterView } from "vue-router";

const store = useLegiWorkStore();
const { legiWorkZoom } = storeToRefs(store);
</script>
