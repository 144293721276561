<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="store.loading"
        :disabled="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          v-if="!store.isFollawedActive"
          filter="companies"
          :items="store.companies"
          item-label="name"
          item-value="national_id"
          :active-filters="selectedFilters.companies"
          :is-loading="store.isCompanyFilterLoading"
          mode="purple"
          @search="fetchCompanies"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetCompanyFilter"
        >
          Organisations
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key] ?? []"
          :active-filters="selectedFilters[key]"
          mode="purple"
          :has-search="store.filters[key]?.length > 10"
          :disabled="!store.filters[key] || !store.filters[key].length"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>

      <FollawedRadioButtons
        all-match-route="/lobbies"
        follawed-route="/lobbies/follawed"
      />
    </div>

    <div v-if="store.isFollawedActive && store.items.length" class="params-row">
      <RouterRadioButtons :options="textOptions" />
    </div>

    <LobbiesNewsParams v-if="route.fullPath.includes('news')" />
    <LobbiesActivitiesParams
      v-else-if="route.fullPath.includes('activities')"
    />

    <div v-else class="params-row">
      <TotalMatched :item-count="store.itemCount" />
      <div class="page-settings">
        <SortBy
          :items="sortersNames"
          :selected-sort-item="currentSortLabel"
          @change-sort="handleSortChange"
        />
        <SelectItemsLimit
          :items-limit="selectedFilters.size"
          @change-limit="changeLimit"
        >
          Lobbies
        </SelectItemsLimit>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import LobbiesNewsParams from "./LobbiesNewsParams.vue";
import LobbiesActivitiesParams from "./LobbiesActivitiesParams.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import side_menu from "@/utils/side_menu";
import { useLobbiesStore } from "@/stores/lobbies.js";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";

const store = useLobbiesStore();
const { selectedFilters } = storeToRefs(store);
const route = useRoute();

// All filters in wanted display order with their labels
const filtersNames = {
  type: "Type d'organisation",
  city: "Localité",
  levels: "Niveau",
  categories: "Secteur",
};

// All sorters in wanted display order with their labels
const sortersNames = {
  expenses: "Dépenses",
  money: "CA",
  number_of_employees: "Employés",
  number_of_publications: "Publications",
};

const showScrollTop = computed(() => selectedFilters.value.size > 20);

// Build follawed navbar options from router config
const routerOptions = side_menu
  .find((r) => r.name === "PRM")
  .secondary_tabs.find((tab) => tab.name === "Lobbies").children;
const textOptions = routerOptions.map((r) => ({
  label: r.name.replace("Lobbies Follawed - ", ""),
  value: `/lobbies/${r.path}`,
  exact: true,
}));

// Sort
const currentSortField = ref(
  Object.keys(selectedFilters.value.sort)[0] ?? "expenses"
);

const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      selectedFilters.value.sort = { [currentSortField.value]: "desc" };
      fetchLobbiesList();
    }
  }
};

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchLobbiesList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] || [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchLobbiesList();
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  selectedFilters.value.companies?.forEach((filter) => {
    arr = [
      ...arr,
      {
        filter: "companies",
        item: filter,
      },
    ];
  });
  return arr;
});

const fetchCompanies = (event) => {
  if (event.length > 0) {
    store.getCompanyFilters(event);
  } else {
    store.companies = [];
  }
};

const resetCompanyFilter = () => {
  store.companies = [];
  delete selectedFilters.value.companies;
  fetchLobbiesList();
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchLobbiesList();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchLobbiesList();
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchLobbiesList();
};

const fetchLobbiesList = async () => {
  selectedFilters.value.from = 0;
  await store.readLobbies();
  if (route.path.includes("/news")) {
    await store.readFollawedNews();
  }
  if (route.path.includes("/activities")) {
    await store.readFollawedActivities();
  }
};
</script>

<style lang="scss" scoped>
.params-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
}

.legislature {
  margin: 0;
}
</style>
