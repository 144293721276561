<template>
  <div class="content-wrapper">
    <div class="overview-questions">
      <BigStatCounter
        label="Questions"
        icon="fa-kit fa-questions"
        title-link-to="/questions"
        wrapper-class="active-color-questions"
        :value="store.stats.question_count"
        :subtype-values="store.stats.question_status_count"
      />
    </div>
    <div class="overview-amendments">
      <BigStatCounter
        label="Amendements"
        icon="fa-kit fa-am"
        title-link-to="/amendments"
        wrapper-class="active-color-amendments"
        :value="store.stats.amendment_count"
        :subtype-values="store.stats.amendment_status_count"
      />
    </div>
    <div class="overview-compte-rendus">
      <BigStatCounter
        label="Compte-Rendus"
        icon="fa-kit fa-cr"
        title-link-to="/reports"
        wrapper-class="active-color-reports"
        :value="store.compteRendusCount"
      />
    </div>
    <div class="overview-laws">
      <BigStatDoubleCounter
        label="Projets de loi"
        label2="Propositions de loi"
        icon="fa-kit fa-dossierlegi"
        title1-link-to="/legislatives"
        title2-link-to="/legislatives"
        wrapper-class="active-color-laws"
        :value="store.stats.legi_proposition_count"
        :value2="store.stats.legi_project_count"
        :subtype-values="store.stats.legi_proposition_status_count"
        :subtype-values2="store.stats.legi_project_status_count"
      />
    </div>
    <div class="overview-questions-ranking">
      <OverviewQuestionRanking
        :ranking="store.stats.most_asked_ministries || {}"
      />
    </div>
    <div class="overview-groups">
      <OverviewGroupsGraph
        :items="store.stats.activities_per_group"
        :is-loading="false"
      />
    </div>
  </div>
</template>

<script setup>
import BigStatCounter from "../atoms/BigStatCounter.vue";
import BigStatDoubleCounter from "../atoms/BigStatDoubleCounter.vue";
import OverviewQuestionRanking from "../atoms/OverviewQuestionRanking.vue";
import OverviewGroupsGraph from "../molecules/OverviewGroupsGraph.vue";

import { useLegiOverviewStore } from "@/stores/legi-overview";

const store = useLegiOverviewStore();
</script>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @container (width > 875px) {
    display: grid;
    grid-template:
      "question amendements compte-rendus"
      "laws laws laws"
      "questions-ranking groups groups";
  }

  @container (width > 1200px) {
    grid-template:
      "question amendements compte-rendus laws laws"
      "questions-ranking questions-ranking groups groups groups";
  }
}

.overview-questions {
  grid-area: question;
}

.overview-amendments {
  grid-area: amendements;
}

.overview-compte-rendus {
  grid-area: compte-rendus;
}

.overview-laws {
  grid-area: laws;
}

.overview-questions-ranking {
  grid-area: questions-ranking;
}

.overview-groups {
  grid-area: groups;
}

.fa-question {
  &:hover {
    background-color: var(--color-questions);
  }
}
</style>
