<template>
  <AbstractSocialCard
    :id="post._id"
    :author="post.author"
    :social-icon="{ name: 'fa-linkedin', color: 'var(--clr-linkedin)' }"
    :handle="handleLinkedin"
    :reposts="SINumberFormatter(post.reposts)"
    :likes="SINumberFormatter(post.like)"
    :comments="SINumberFormatter(post.comment)"
    :created-at="post.created_at"
    :last-update="post.last_update"
    :source-url="post.permalink"
    :is-overview="isOverview"
  >
    <template #content>
      <AbstractSocialCardContent :describe="post.text" />
    </template>
  </AbstractSocialCard>
</template>

<script setup>
import { SINumberFormatter } from "@/utils/helper.utils.js";
import AbstractSocialCard from "@/components/social-media/abstract/AbstractSocialCard.vue";
import AbstractSocialCardContent from "@/components/social-media/abstract/AbstractSocialCardContent.vue";
import {
  getLinkedinHandle,
  useHandle,
} from "@/composables/social-media/useHandle";
import { Platforms } from "@/utils/social360-platforms.js";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  isOverview: {
    type: Boolean,
    default: false,
  },
  tooltipActivable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const handleLinkedin = useHandle(
  getLinkedinHandle(props.post.author),
  Platforms.LINKEDIN
);
</script>
