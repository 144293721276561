<template>
  <div class="template">
    <NewsZoomParams />
    <LoaderFullPage v-if="store.loading" />
    <NewsZoomDatas v-else />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import NewsZoomParams from "@/components/news/organisms/NewsZoomParams.vue";
import NewsZoomDatas from "@/components/news/organisms/NewsZoomDatas.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useNewsStore } from "@/stores/news";

const store = useNewsStore();
const route = useRoute();

store.fetchOneNews(route.params._id);
</script>
