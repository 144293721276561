<template>
  <div class="card">
    <div>{{ mission.name }}</div>
    <div class="flex-spacer"></div>
    <div class="footer">
      <GenericTag
        color="white"
        :background-color="mission.status === 'ONGOING' ? '#0049B9' : '#0A2E65'"
      >
        <span class="status">{{ statusReadable }}</span>
      </GenericTag>
      <div><RouterLink :to="url">Voir la mission</RouterLink></div>
    </div>
  </div>
</template>

<script setup>
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({ mission: { type: Object, required: true } });
const route = useRoute();

const STATUS_MAP = {
  ONGOING: "En cours",
  FINISHED: "Terminée",
};

const statusReadable = computed(
  () => STATUS_MAP[props.mission.status] || "Inconnu"
);

const url = computed(
  () => `/organes/${route.params._id}/mission/${props.mission._id}/composition`
);
</script>

<style scoped lang="scss">
.card {
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-spacer {
  flex-grow: 1;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
