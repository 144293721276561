<template>
  <div class="template">
    <AmendmentsParams />
    <LoaderFullPage v-if="store.loading" />
    <AmendmentsDatas v-else />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import AmendmentsParams from "@/components/amendments/organisms/AmendmentsParams.vue";
import AmendmentsDatas from "@/components/amendments/organisms/AmendmentsDatas.vue";
import { useAmendmentsStore } from "@/stores/amendments.js";

const store = useAmendmentsStore();
const router = useRouter();

store.isFollawedActive =
  router.currentRoute.value.fullPath.includes("follawed");

store.selectedFilters.from = 0;
store.fetchAmendmentsStats();
store.fetchAmendmentsList();
</script>
