<template>
  <div class="title">
    Synthèse des votes <span class="emphasis">{{ positionName }}</span> par
    groupe
  </div>

  <PieChart
    v-if="hasVotes"
    :chart-data="chartData"
    :height="350"
    :options="chartOptions"
  />
  <div v-else class="empty">Pas de votes {{ positionName }}</div>
</template>

<script setup>
import { computed } from "vue";
import { PieChart } from "vue-chart-3";

const props = defineProps({
  scrutin: { type: Object, required: true },
  position: { type: String, required: true },
});

const positionName = computed(
  () =>
    ({
      voted_for: "POUR",
      voted_against: "CONTRE",
      voted_abstention: "ABSTENTION",
    })[props.position] || "INCONNU"
);

const groupMap = computed(() =>
  props.scrutin.details_by_group.map((g) => ({
    name: g.group.acronym,
    color: g.group.color,
    value: g[props.position],
  }))
);

const hasVotes = computed(() => {
  let count = 0;
  groupMap.value.forEach((g) => (count += g.value));
  return count > 0;
});

const chartData = computed(() => ({
  labels: groupMap.value.map((g) => g.name),
  datasets: [
    {
      backgroundColor: groupMap.value.map((g) => g.color),
      data: groupMap.value.map((g) => g.value),
    },
  ],
}));

const chartOptions = {
  plugins: {
    datalabels: { color: "white", font: { size: 12 } },
    legend: {
      position: "bottom",
      labels: {
        color: "#0A2E65",
        usePointStyle: true,
        font: {
          size: 12,
        },
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  text-align: center;
  font-weight: 400;
  padding-bottom: 10px;
}

.emphasis {
  font-weight: 700;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
