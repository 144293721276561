<template>
  <div class="template">
    <RouterRadioButtons :options="navigationOptions" />
    <RouterView />
  </div>
</template>

<script setup>
import { RouterView } from "vue-router";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";

const navigationOptions = [
  { label: "Feuille verte", value: "feuille-verte" },
  { label: "Ordre du jour sénat", value: "senat" },
];
</script>
