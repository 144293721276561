import { ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { httpRequest } from "@/utils/request.utils";
import { FOLLAWED_FEATURES, useUserStore } from "@/stores/user";
import { handleNetworkError } from "@/utils/handleNetworkError";

const userStore = useUserStore();
const {
  loading: topbarLoading,
  sharedFiltersAsPayload: sharedFiltersAsPayload,
  selectedInterval: topbarInterval,
} = storeToRefs(userStore);

// Defining store
export const useLegiOverviewStore = defineStore("legi-overview", () => {
  const loadingStats = ref(false);
  const loadingDocSteps = ref(false);
  const loadingCompteRendus = ref(false);

  const stats = ref({});
  const docSteps = ref({});
  const compteRendusCount = ref(0);

  const docStepsFollawedOnly = ref(true);

  const fetchLegiOverview = async () => {
    loadingStats.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...sharedFiltersAsPayload.value,
    };

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest("post", "/legi-overview", payload);

      stats.value = response.data;
    } catch (err) {
      stats.value = {};
      handleNetworkError(err);
    }
    loadingStats.value = false;
  };

  const fetchDocSteps = async () => {
    loadingDocSteps.value = true;

    const payload = docStepsFollawedOnly.value
      ? {
          _id: userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS],
          ...sharedFiltersAsPayload.value,
        }
      : { ...sharedFiltersAsPayload.value };

    try {
      const response = await httpRequest("post", `/legi-doc-steps`, payload);

      docSteps.value = response.data;
    } catch (err) {
      docSteps.value = {};
      handleNetworkError(err);
    } finally {
      loadingDocSteps.value = false;
    }
  };

  const fetchCompteRendusCount = async () => {
    loadingCompteRendus.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      size: 0,
    };

    try {
      const response = await httpRequest(
        "post",
        `/legi-compte-rendus-and-rapports`,
        payload
      );

      compteRendusCount.value = response.data.item_count;
    } catch (err) {
      compteRendusCount.value = 0;
      handleNetworkError(err);
    } finally {
      loadingCompteRendus.value = false;
    }
  };

  const resetStore = () => {
    stats.value = {};
    docSteps.value = {};
    loadingStats.value = false;
    loadingDocSteps.value = false;
  };

  const route = useRoute();
  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        route.name === "Overview parlementaire"
      ) {
        fetchLegiOverview();
        fetchDocSteps();
      }
    }
  );

  watch([docStepsFollawedOnly], fetchDocSteps);

  return {
    compteRendusCount,
    loadingStats,
    loadingDocSteps,
    loadingCompteRendus,
    stats,
    docSteps,
    docStepsFollawedOnly,
    fetchDocSteps,
    fetchLegiOverview,
    fetchCompteRendusCount,
    resetStore,
  };
});
