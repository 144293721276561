<template>
  <!-- eslint-disable vue/no-v-html -->
  <p v-if="describe.length > MAX_DESCRIPTION_LENGTH && !showMore">
    <span v-html="describe.slice(0, MAX_DESCRIPTION_LENGTH)"></span>
    <span class="show-more" @click="showMore = true"
      >... Afficher la suite</span
    >
  </p>
  <p v-else-if="describe.length > MAX_DESCRIPTION_LENGTH && showMore">
    <span v-html="describe"></span>
    <span class="show-more" @click="showMore = false"> Réduire</span>
  </p>
  <p v-else v-html="describe"></p>
  <!-- eslint-enable -->
</template>

<script setup>
import { ref } from "vue";
const MAX_DESCRIPTION_LENGTH = 400;

defineProps({
  describe: {
    type: String,
    required: true,
  },
});

const showMore = ref(false);
</script>

<style lang="scss" scoped>
:deep(.show-more) {
  color: var(--clr-s8);
  background-color: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
