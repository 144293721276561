<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SelectMultipleCounter
          filter="themes"
          :items="store.filters.themes"
          :active-filters="selectedFilters.themes"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          Catégories
        </SelectMultipleCounter>
      </FiltersMenu>
    </div>

    <div class="params-row">
      <TotalMatched :item-count="store.item_count" feminine />
      <SelectItemsLimit
        :items-limit="selectedFilters.size"
        @change-limit="changeLimit"
      >
        Nominations
      </SelectItemsLimit>
    </div>
    <div class="params-row">
      <SwitchSlide :is-active="!store.bypassQuery" @toggle="toggleUseRequest"
        >Utiliser la requête</SwitchSlide
      >
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useNominationsStore } from "@/stores/nominations";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const store = useNominationsStore();
const { selectedFilters } = storeToRefs(store);

const activeFilters = computed(() => {
  let arr = [];
  // Repeat for each filtering property (ex : domain, status, etc.)
  selectedFilters.value?.themes?.forEach((filter) => {
    arr = [...arr, { filter: "themes", item: filter }];
  });
  return arr;
});

const showScrollTop = computed(() => selectedFilters.value.size > 20);

const addFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];
  targetFilters = [...targetFilters, event.item];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: targetFilters,
  };

  fetchNominationsList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }

  fetchNominationsList();
};
const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchNominationsList();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchNominationsList();
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchNominationsList();
};

function toggleUseRequest() {
  store.bypassQuery = !store.bypassQuery;
  selectedFilters.value.from = 0;
  store.fetchNominationsList();
}

const fetchNominationsList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchNominationsList();
};
</script>
