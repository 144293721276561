<template>
  <div class="card lobby-chart">
    <div class="head">
      <h6 class="title">Evolution des moyens et activités</h6>
      <div class="tools">
        <RadioButtons
          :active-option="activeValue"
          :options="options"
          @select-option="selectOption"
        />
      </div>
    </div>
    <div class="body">
      <div v-if="store.isChartLoading" class="loading-wrapper">
        <hollow-dots-spinner :animation-duration="1000" color="var(--clr-p4)" />
      </div>
      <LineChart
        v-else-if="activeValue === 'activity' && store.activityChartDatas"
        :options="activityChartOptions"
        :chart-data="activityChartData"
      />
      <BarChart
        v-else-if="
          activeValue === 'publications' && store.publicationsChartDatas.length
        "
        :options="publicationsChartOptions"
        :chart-data="publicationsChartData"
      />
      <EmptyChart v-else />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { HollowDotsSpinner } from "epic-spinners";
import { LineChart, BarChart } from "vue-chart-3";
import dayjs from "dayjs";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import EmptyChart from "@/components/reusable/molecules/EmptyChart.vue";
import { useLobbiesStore } from "@/stores/lobbies";
import { getCSSVariable } from "@/utils/helper.utils.js";

const store = useLobbiesStore();
const route = useRoute();
store.readOneLobbyTimeline(route.params._id);

const activeValue = ref("activity");
const options = ref([
  { label: "Activité", value: "activity" },
  { label: "Publications", value: "publications" },
]);
const selectOption = (newValue) => {
  activeValue.value = newValue;
};

// Activities chart (line)
const activityChartData = computed(() => {
  return {
    labels: store.activityChartDatas
      .map((data) => dayjs(data.start).year())
      .sort(),
    datasets: [
      {
        label: "Activités",
        data: store.activityChartDatas.map((data) => data.number_of_activities),
        borderColor: getCSSVariable("--clr-chart-1"),
        backgroundColor: getCSSVariable("--clr-chart-1"),
      },
      {
        label: "Employés",
        data: store.activityChartDatas.map(
          (data) => data.number_of_employees || 0
        ),
        borderColor: getCSSVariable("--clr-chart-3"),
        backgroundColor: getCSSVariable("--clr-chart-3"),
      },
    ],
  };
});

const activityChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        pointStyle: "rect",
      },
    },
    tooltip: {
      callbacks: {
        title: function (context) {
          const titles = store.activityChartDatas
            .map((data) => data.start)
            .sort()
            .map((date) => dayjs(date).format("DD-MM-YYYY"));
          return titles[context[0].dataIndex] || context[0].dataIndex;
        },
      },
    },
  },
};

// Publications chart (bar)
const barChartDatasets = computed(() => {
  const arr = [];
  const colors = [
    getCSSVariable("--clr-chart-2"),
    getCSSVariable("--clr-chart-3"),
    getCSSVariable("--clr-chart-1"),
  ];
  store.publicationsChartDatas.forEach((data, i) => {
    const publicationsCount =
      (data.x_publications || 0) +
      (data.instagram_publications || 0) +
      (data.tiktok_publications || 0) +
      (data.linkedin_publications || 0) +
      (data.bluesky_publications || 0);
    arr.push({
      label: data.year,
      data: [publicationsCount],
      borderColor: colors[i % colors.length],
      backgroundColor: colors[i % colors.length],
    });
  });
  return arr;
});

const publicationsChartData = computed(() => {
  return {
    labels: ["Publications postées"],
    datasets: barChartDatasets.value,
  };
});

const publicationsChartOptions = {
  responsive: true,
  categoryPercentage: 0.33,
  plugins: {
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        pointStyle: "rect",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.lobby-chart {
  display: flex;
  flex-direction: column;
  min-height: 450px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    .title {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
  }
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
