const institutionsMapping = {
  SN: "Sénat",
  AN: "Assemblée nationale",
  GV: "Gouvernement",
};
const reverseInstitutionMappings = {};
for (const [key, value] of Object.entries(institutionsMapping)) {
  reverseInstitutionMappings[value] = key;
}
function getInstitutionsNames(institution) {
  return institutionsMapping[institution] || institution;
}

function reverseInstitutionsNames(institution) {
  return reverseInstitutionMappings[institution] || institution;
}

export { getInstitutionsNames, reverseInstitutionsNames };
