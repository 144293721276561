export default function loadCrisp() {
  // Setup code provided by Crisp
  // https://help.crisp.chat/en/article/how-do-i-add-a-live-chat-to-my-website-10wcj3l/
  // and modified by Follaw

  if (!import.meta.env.VITE_CRISP_ID) {
    return;
  }

  window.$crisp = [];
  window.CRISP_WEBSITE_ID = import.meta.env.VITE_CRISP_ID;
  (function () {
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
}
