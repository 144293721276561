import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import {
  getInstitutionsNames,
  reverseInstitutionsNames,
} from "@/utils/institutions.utils";
import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialQuestionsFilters = {
  size: 20,
  from: 0,
  sort_field: "created_at", //* Sort field
  sort_order: "desc", //* Sort order
  get_filters: true,
};

// Defining store
export const useQuestionsStore = defineStore("questions", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);
  const loadingExcel = ref(false);

  // Stats
  const questionsCount = ref(null);
  const responsesCount = ref(null);
  const questionsType = ref([]);
  const top3Ministries = ref([]);
  const similarQuestions = ref(null);
  const isQuestionsStatLoading = ref(false);

  // Listing
  const items = ref([]);
  const item_count = ref(null);

  // Zoom
  const questionZoom = ref(null);
  const previousQuestionID = ref(null);
  const nextQuestionID = ref(null);

  // filters
  const selectedFilters = ref({ ...initialQuestionsFilters });
  const filters = ref(null);
  const isFollawedActive = ref(false);
  const authors = ref([]);
  const isAuthorFilterLoading = ref(false);

  const createPayloadBase = () => {
    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* Convert back the "institution name" filter if it exists
    if (payload.institution) {
      payload.institution = payload.institution.map((institution) =>
        reverseInstitutionsNames(institution)
      );
    }

    //* If there is author filters selected map them to only their ids
    if (payload.author?.length > 0) {
      payload.author = payload.author.map((author) => author._id);
    }

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.QUESTIONS];
    }
    return payload;
  };

  const fetchQuestionsStats = async () => {
    isQuestionsStatLoading.value = true;
    const payload = { ...createPayloadBase() };

    try {
      const response = await httpRequest("post", `/question-stats`, payload);
      if (response.data) {
        questionsCount.value = response.data.question_count;
        responsesCount.value = response.data.response_count;
        questionsType.value = response.data.question_types;
        top3Ministries.value = response.data.top_3_ministries;
        similarQuestions.value = response.data.similar_questions;
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      isQuestionsStatLoading.value = false;
    }
  };

  const fetchQuestionsList = async () => {
    loading.value = true;

    const payload = { ...createPayloadBase(), highlight: true };

    try {
      const response = await httpRequest("post", "/questions", payload);

      if (response.data) {
        items.value = response.data.items.map((q) => ({
          ...q,
          // Fix instution formatting
          institution: getInstitutionsNames(q.institution),
        }));
        item_count.value = response.data.item_count;
        filters.value = response.data.filters;
        // Convert institutions to more readable format
        filters.value.institution =
          filters.value.institution.map(getInstitutionsNames);
      }
    } catch (err) {
      items.value = [];
      item_count.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOneQuestion = async (questionId) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", `/question-zoom`, {
        ...sharedFiltersAsPayload.value,
        _id: [questionId],
        highlight: true,
      });

      //* set the zoom
      questionZoom.value = response.data.items[0];
      // Fix institution formatting
      questionZoom.value.institution = getInstitutionsNames(
        questionZoom.value.institution
      );
      // get the previous and next ones ID
      const current = items.value.indexOf(
        items.value.find((item) => item._id === questionZoom.value._id)
      );
      previousQuestionID.value = items.value[current - 1]?._id;
      nextQuestionID.value = items.value[current + 1]?._id;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const downloadExcel = async () => {
    loadingExcel.value = true;

    const payload = { ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        "/questions-export",
        payload,
        "blob"
      );

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      link.download = "questions.xlsx";
      link.click();
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loadingExcel.value = false;
    }
  };

  const getSearchAuthorsFilters = async (name) => {
    isAuthorFilterLoading.value = true;

    try {
      const response = await httpRequest("post", "/question/search_authors", {
        name: name,
      });
      authors.value = response.data.personalities;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      isAuthorFilterLoading.value = false;
    }
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialQuestionsFilters };
  };

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const resetStore = () => {
    questionsCount.value = null;
    responsesCount.value = null;
    questionsType.value = [];
    top3Ministries.value = [];
    similarQuestions.value = null;
    isQuestionsStatLoading.value = false;
    items.value = [];
    selectedFilters.value = { ...initialQuestionsFilters };
    filters.value = null;
    loading.value = false;
    item_count.value = null;
  };

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchQuestionsStats();
    fetchQuestionsList();
  };

  const route = useRoute();
  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        (route.name === "Questions" || route.name === "Questions - Follawed")
      ) {
        selectedFilters.value.from = 0;
        fetchQuestionsStats();
        fetchQuestionsList();
      }
    }
  );

  return {
    authors,
    filters,
    hasActiveFilters,
    isAuthorFilterLoading,
    isFollawedActive,
    isQuestionsStatLoading,
    item_count,
    items,
    loading,
    loadingExcel,
    nextQuestionID,
    previousQuestionID,
    questionsCount,
    questionsType,
    questionZoom,
    responsesCount,
    selectedFilters,
    similarQuestions,
    top3Ministries,
    downloadExcel,
    fetchOneQuestion,
    fetchQuestionsList,
    fetchQuestionsStats,
    getSearchAuthorsFilters,
    resetFilters,
    resetStore,
    toggleFollawed,
  };
});
