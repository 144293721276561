export default [
  {
    name: "Centre de contrôle",
    icon_class: "fa-home",
    path: "/",
    component: () => import("../views/HomeView.vue"),
    children: [
      {
        name: "Centre de contrôle - Follawed",
        path: "follawed",
        component: () => import("../views/HomeView.vue"),
      },
    ],
  },
  {
    name: "Agenda",
    icon_class: "fa-calendar-lines",
    path: "/agendas",
    component: () => import("../views/side_menu_views/AgendasView.vue"),
  },
  {
    name: "Journal Officiel",
    icon_class: "fa-newspaper",
    path: "/jorfs",
    component: () => import("../views/side_menu_views/JorfsView.vue"),
    secondary_routes: [
      {
        name: "Journal Officiel - Follawed",
        path: "/jorfs/follawed",
        component: () => import("../views/side_menu_views/JorfsView.vue"),
      },
      {
        name: "Zoom Journal Officiel",
        path: "/jorfs/:_id",
        component: () => import("../views/side_menu_views/JorfZoomView.vue"),
      },
    ],
  },
  {
    name: "Pétitions",
    icon_class: "fa-file-signature",
    path: "/petitions",
    component: () => import("../views/side_menu_views/PetitionsView.vue"),
    meta: {
      permission_level: "SOCIETAL",
    },
    secondary_routes: [
      {
        name: "Pétitions - Follawed",
        path: "/petitions/follawed",
        component: () => import("../views/side_menu_views/PetitionsView.vue"),
      },
      {
        name: "Zoom petitions",
        path: "/petitions/:id",
        component: () =>
          import("../views/side_menu_views/PetitionsZoomView.vue"),
      },
    ],
  },
  {
    name: "Actualités",
    icon_class: "fa-clock",
    secondary_tabs: [
      {
        name: "Communiqués",
        path: "/news-releases",
        component: () => import("../views/side_menu_views/NewsView.vue"),
        secondary_routes: [
          {
            name: "Zoom Communiqués",
            path: "/news-releases/:_id",
            component: () =>
              import("../views/side_menu_views/NewsZoomView.vue"),
          },
        ],
      },
      {
        name: "Nominations",
        path: "/nominations",
        component: () => import("../views/side_menu_views/NominationsView.vue"),
        meta: { permission_level: "SOCIETAL" },
      },
      {
        name: "Ordre du jour",
        path: "/ordre-du-jour/",
        component: () => import("../views/side_menu_views/OrdreDuJourView.vue"),
        children: [
          {
            name: "Default Ordre du jour",
            path: "",
            redirect: "/ordre-du-jour/feuille-verte",
          },
          {
            path: "feuille-verte",
            component: () =>
              import("../views/ordre-du-jour/FeuilleVerteView.vue"),
          },
          {
            path: "senat",
            component: () => import("../views/ordre-du-jour/OJSenatView.vue"),
          },
        ],
      },
      {
        name: "Le Direct",
        path: "/live",
        component: () => import("../views/side_menu_views/EnDirect.vue"),
        children: [
          {
            name: "Default Le Direct",
            path: "",
            redirect: "/live/eliasse",
          },
          {
            path: "eliasse",
            component: () => import("../views/en-direct/EliasseView.vue"),
          },
          {
            path: "en-seance",
            component: () =>
              import("../views/en-direct/SenateEnSeanceView.vue"),
          },
        ],
      },
      {
        name: "Conseil des Ministres",
        path: "/conseil-ministres",
        component: () =>
          import("../views/side_menu_views/ConseilMinistres.vue"),
      },
    ],
  },
  {
    name: "Travaux parlementaires",
    icon_class: "fa-landmark",
    secondary_tabs: [
      {
        name: "Overview parlementaire",
        path: "/parliamentary-overview",
        component: () =>
          import("../views/side_menu_views/ParliamentaryOverviewView.vue"),
      },
      {
        name: "Dossiers législatifs",
        path: "/legislatives",
        component: () => import("../views/side_menu_views/LegiWorkView.vue"),
        secondary_routes: [
          {
            path: "/legislatives/follawed",
            component: () =>
              import("../views/side_menu_views/LegiWorkView.vue"),
          },
          {
            path: "/legislatives/:ref",
            component: () =>
              import("../views/side_menu_views/LegiWorkZoomView.vue"),
            children: [
              {
                path: "global",
                name: "Zoom Follawed Dossiers Législatifs - Informations globales",
                component: () =>
                  import("@/views/legi-work/LegiGlobalInfos.vue"),
              },
              {
                path: "timeline",
                name: "Zoom Follawed Dossiers Législatifs - Navette parlementaire",
                component: () =>
                  import(
                    "@/components/legi-work/molecules/FollawedTimeline.vue"
                  ),
                children: [
                  {
                    name: "Zoom Follawed Dossiers Législatifs - Redirect navette to last step",
                    path: "",
                    component: () =>
                      import(
                        "@/components/legi-work/atoms/RedirectToLastStep.vue"
                      ),
                  },
                  {
                    path: ":stepIdx",
                    component: () =>
                      import("@/components/reusable/atoms/EmptyView.vue"),
                  },
                  {
                    path: ":stepIdx/texte-initial",
                    component: () =>
                      import("@/views/legi-work/TimelineFullText.vue"),
                  },
                  {
                    path: ":stepIdx/commission",
                    component: () =>
                      import("@/views/legi-work/TimelineCommission.vue"),
                    children: [
                      {
                        path: "full-text",
                        component: () =>
                          import("@/views/legi-work/TimelineFullText.vue"),
                      },
                      {
                        path: "amendments",
                        component: () =>
                          import("@/views/legi-work/TimelineAmendments.vue"),
                      },
                      {
                        path: "stats",
                        component: () =>
                          import("@/views/legi-work/TimelineLectureStats.vue"),
                      },
                      {
                        path: "commissions",
                        component: () =>
                          import(
                            "@/views/legi-work/TimelineCommissionsList.vue"
                          ),
                      },
                    ],
                  },
                  {
                    path: ":stepIdx/seance",
                    component: () =>
                      import("@/views/legi-work/TimelinePublicSeance.vue"),
                    children: [
                      {
                        path: "full-text",
                        component: () =>
                          import("@/views/legi-work/TimelineFullText.vue"),
                      },
                      {
                        path: "amendments",
                        component: () =>
                          import("@/views/legi-work/TimelineAmendments.vue"),
                      },
                      {
                        path: "stats",
                        component: () =>
                          import("@/views/legi-work/TimelineLectureStats.vue"),
                      },
                    ],
                  },
                  {
                    path: ":stepIdx/scrutin",
                    component: () =>
                      import("@/views/legi-work/ZoomScrutin.vue"),
                  },
                  {
                    path: ":stepIdx/texte-scrutin",
                    component: () =>
                      import("@/views/legi-work/TimelineFullText.vue"),
                  },
                  {
                    path: ":stepIdx/cmp_travaux",
                    component: () =>
                      import("@/views/legi-work/TimelineCmpGeneralInfos.vue"),
                  },
                  {
                    path: ":stepIdx/cmp_lecture",
                    component: () =>
                      import("@/views/legi-work/TimelineFullText.vue"),
                  },
                  {
                    path: ":stepIdx/49_3",
                    component: () =>
                      import("@/views/legi-work/TimelineQuaranteNeufTrois.vue"),
                    children: [
                      {
                        path: ":data493idx/declaration",
                        component: () =>
                          import(
                            "@/views/legi-work/TimelineQuaranteNeufTroisDeclaration.vue"
                          ),
                      },
                      {
                        path: ":data493idx/scrutin",
                        component: () =>
                          import("@/views/legi-work/ZoomScrutin.vue"),
                      },
                    ],
                  },
                  {
                    path: ":stepIdx/conseil-consti",
                    component: () =>
                      import("@/views/legi-work/TimelineConseilConsti.vue"),
                  },
                  {
                    path: ":stepIdx/prom_texte",
                    component: () =>
                      import("@/views/legi-work/TimelineFullText.vue"),
                  },
                  {
                    path: ":stepIdx/prom_decret",
                    component: () =>
                      import("@/views/legi-work/TimelineDecrets.vue"),
                  },
                ],
              },
              {
                path: "agenda-crs",
                name: "Zoom Follawed Dossiers Législatifs - Agenda et comptes-rendus",
                component: () => import("@/views/legi-work/ZoomAgendaCr.vue"),
              },
              {
                path: "social-media",
                name: "Zoom Follawed Dossiers Législatifs - Social media",
                component: () =>
                  import("@/views/legi-work/ZoomSocialMedia.vue"),
              },
              {
                path: "expose-motifs",
                name: "Zoom Follawed Dossiers Législatifs - Exposé des motifs",
                component: () =>
                  import("@/views/legi-work/ZoomExposeMotifs.vue"),
              },
              {
                path: "infos-suppls",
                name: "Zoom Follawed Dossiers Législatifs - Infos supplémentaires",
                component: () =>
                  import("@/views/legi-work/ZoomAdditionalInfos.vue"),
              },
            ],
          },
        ],
      },
      {
        name: "Amendements",
        path: "/amendments",
        component: () => import("../views/side_menu_views/AmendmentsView.vue"),
        secondary_routes: [
          {
            name: "Amendements - Follawed",
            path: "/amendments/follawed",
            component: () =>
              import("../views/side_menu_views/AmendmentsView.vue"),
          },
          {
            name: "Zoom amendements",
            path: "/amendments/:id",
            component: () =>
              import("../views/side_menu_views/AmendmentsZoomView.vue"),
          },
        ],
      },
      {
        name: "Questions",
        path: "/questions",
        component: () => import("../views/side_menu_views/QuestionsView.vue"),
        secondary_routes: [
          {
            name: "Questions - Follawed",
            path: "/questions/follawed",
            component: () =>
              import("../views/side_menu_views/QuestionsView.vue"),
          },
          {
            name: "Zoom question",
            path: "/questions/:_id",
            component: () =>
              import("../views/side_menu_views/QuestionsZoomView.vue"),
          },
        ],
      },
      {
        name: "Comptes-rendus et rapports",
        path: "/reports",
        component: () => import("../views/side_menu_views/ReportsView.vue"),
        secondary_routes: [
          {
            name: "Zoom Comptes-rendus",
            path: "/comptes-rendus/:docRef",
            component: () =>
              import("../views/side_menu_views/ReportCrZoomView.vue"),
          },
          {
            name: "Zoom rapport",
            path: "/reports/:docRef",
            component: () =>
              import("../views/side_menu_views/ReportZoomView.vue"),
          },
        ],
      },
      {
        name: "Commissions et autres organes",
        path: "/organes",
        component: () => import("../views/side_menu_views/CommissionsView.vue"),
        secondary_routes: [
          {
            name: "Commissions et autres organes - Follawed",
            path: "/organes/follawed",
            component: () =>
              import("../views/side_menu_views/CommissionsView.vue"),
          },
          {
            name: "Zoom Commission ou autre organe",
            path: "/organes/:_id",
            component: () =>
              import("../views/side_menu_views/CommissionZoomView.vue"),
            children: [
              {
                name: "Zoom Commission - Composition",
                path: "composition",
                component: () =>
                  import("../views/institutional-groups/CompositionView.vue"),
              },
              {
                name: "Zoom Commission - Activités",
                path: "activites",
                component: () =>
                  import("../views/institutional-groups/LastActivities.vue"),
              },
              {
                name: "Zoom Commission - Publications",
                path: "publications",
                component: () =>
                  import("../views/institutional-groups/PublicationsView.vue"),
              },
              {
                name: "Zoom Commission - Missions",
                path: "missions",
                component: () =>
                  import("../views/institutional-groups/MissionsView.vue"),
              },
              {
                path: "agenda",
                component: () =>
                  import("../views/institutional-groups/AgendaView.vue"),
              },
              {
                path: "petitions",
                component: () =>
                  import("../views/institutional-groups/PetitionsView.vue"),
              },
            ],
          },
          {
            name: "Zoom Mission d'une Commission",
            path: "/organes/:_id/mission/:missionId",
            component: () =>
              import("../views/institutional-groups/MissionZoomView.vue"),
            children: [
              {
                name: "Zoom Mission - Composition",
                path: "composition",
                component: () =>
                  import("../views/institutional-groups/CompositionView.vue"),
              },
              {
                name: "Zoom Mission - Activités",
                path: "activites",
                component: () =>
                  import("../views/institutional-groups/LastActivities.vue"),
              },
              {
                name: "Zoom Mission - Publications",
                path: "publications",
                component: () =>
                  import("../views/institutional-groups/PublicationsView.vue"),
              },
              {
                path: "agenda",
                component: () =>
                  import("../views/institutional-groups/AgendaView.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "PRM",
    icon_class: "fa-users",
    secondary_tabs: [
      // Routing can sometimes cause invalid routes, redirect gracefully
      { path: "/prm-national/activities", redirect: "/prm-national" },
      { path: "/prm-national/news", redirect: "/prm-national" },
      {
        name: "National",
        path: "/prm-national",
        component: () => import("../views/side_menu_views/PrmView.vue"),
        children: [
          {
            name: "PRM National Follawed - Vos follaw",
            path: "follawed",
            component: () => import("@/components/prm/organisms/PrmDatas.vue"),
          },
          {
            name: "PRM National Follawed - Leurs actualités",
            path: "follawed/news",
            component: () => import("@/views/prm/PrmNews.vue"),
          },
          {
            name: "PRM National Follawed - Leurs activités",
            path: "follawed/activities",
            component: () => import("@/views/prm/PrmActivities.vue"),
          },
        ],
        secondary_routes: [
          {
            name: "PRM National Zoom",
            path: "/prm-national/:_id",
            component: () => import("../views/side_menu_views/PrmZoomView.vue"),
            children: [
              {
                name: "PRM National Zoom - Informations générales",
                path: "",
                component: () => import("@/views/prm/PrmZoomCharts.vue"),
              },
              {
                name: "PRM National Zoom - Biographie",
                path: "biography",
                component: () => import("@/views/prm/PrmZoomBio.vue"),
              },
              {
                name: "PRM National Zoom - Actualités",
                path: "news",
                component: () => import("@/views/prm/PrmNews.vue"),
              },
              {
                name: "PRM National Zoom - Activités",
                path: "activities",
                component: () => import("@/views/prm/PrmActivities.vue"),
              },
              {
                name: "PRM National Zoom - Contact",
                path: "contact",
                component: () => import("@/views/prm/PrmZoomContact.vue"),
              },
            ],
          },
          {
            name: "Zoom Question d'un PRM",
            path: "/prm-national/:_id/question/:questionId",
            component: () =>
              import("@/views/side_menu_views/PrmQuestionZoomView.vue"),
          },
          {
            name: "Zoom Amendement d'un PRM",
            path: "/prm-national/:_id/amendment/:amId",
            component: () =>
              import("@/views/side_menu_views/PrmAmendmentZoomView.vue"),
          },
        ],
      },
      // Routing can sometimes cause invalid routes, redirect gracefully
      { path: "/prm-regional/activities", redirect: "/prm-regional" },
      { path: "/prm-regional/news", redirect: "/prm-regional" },
      {
        name: "Régional, départemental & local",
        path: "/prm-regional",
        component: () => import("../views/side_menu_views/PrmView.vue"),
        children: [
          {
            name: "PRM Régional Follawed - Vos follaw",
            path: "follawed",
            component: () => import("@/components/prm/organisms/PrmDatas.vue"),
          },
          {
            name: "PRM Régional Follawed - Leurs actualités",
            path: "follawed/news",
            component: () => import("@/views/prm/PrmNews.vue"),
          },
          {
            name: "PRM Régional Follawed - Leurs activités",
            path: "follawed/activities",
            component: () => import("@/views/prm/PrmActivities.vue"),
          },
        ],
        secondary_routes: [
          {
            name: "PRM Régional Zoom",
            path: "/prm-regional/:_id",
            component: () => import("../views/side_menu_views/PrmZoomView.vue"),
            children: [
              {
                name: "PRM Régional Zoom - Informations générales",
                path: "",
                component: () => import("@/views/prm/PrmZoomCharts.vue"),
              },
              {
                name: "PRM Régional Zoom - Biographie",
                path: "biography",
                component: () => import("@/views/prm/PrmZoomBio.vue"),
              },
              {
                name: "PRM Régional Zoom - Actualités",
                path: "news",
                component: () => import("@/views/prm/PrmNews.vue"),
              },
              {
                name: "PRM Régional Zoom - Activités",
                path: "activities",
                component: () => import("@/views/prm/PrmActivities.vue"),
              },
              {
                name: "PRM Régional Zoom - Contact",
                path: "contact",
                component: () => import("@/views/prm/PrmZoomContact.vue"),
              },
            ],
          },
        ],
      },
      // Routing can sometimes cause invalid routes, redirect gracefully
      { path: "/lobbies/activities", redirect: "/lobbies" },
      { path: "/lobbies/news", redirect: "/lobbies" },
      {
        name: "Lobbies",
        path: "/lobbies",
        component: () => import("../views/side_menu_views/LobbiesView.vue"),
        children: [
          {
            name: "Lobbies Follawed - Vos follaw",
            path: "follawed",
            component: () =>
              import("@/components/lobbies/organisms/LobbiesDatas.vue"),
          },
          {
            name: "Lobbies Follawed - Leurs Actualités",
            path: "follawed/news",
            component: () => import("@/views/lobbies/LobbyNews.vue"),
          },
          {
            name: "Lobbies Follawed - Leurs activités",
            path: "follawed/activities",
            component: () => import("@/views/lobbies/LobbyActivities.vue"),
          },
        ],
        secondary_routes: [
          {
            name: "Lobby Zoom",
            path: "/lobbies/:_id",
            component: () =>
              import("../views/side_menu_views/LobbyZoomView.vue"),
            children: [
              {
                name: "Lobby Zoom - Informations Globales",
                path: "",
                component: () => import("@/views/lobbies/LobbyZoomGeneral.vue"),
              },
              {
                name: "Lobby Zoom - Actualités",
                path: "news",
                component: () => import("@/views/lobbies/LobbyNews.vue"),
              },
              {
                name: "Lobby Zoom - Activités",
                path: "activities",
                component: () => import("@/views/lobbies/LobbyActivities.vue"),
              },
              {
                name: "Lobby Zoom - Clients",
                path: "clients",
                component: () => import("@/views/lobbies/LobbyZoomClients.vue"),
              },
              {
                name: "Lobby Zoom - Dirigeants et personnel",
                path: "staff",
                component: () => import("@/views/lobbies/LobbyZoomStaff.vue"),
              },
            ],
          },
          {
            name: "Zoom Question d'un Lobby",
            path: "/lobbies/:_id/question/:questionId",
            component: () =>
              import("../views/side_menu_views/LobbyQuestionZoomView.vue"),
          },
          {
            name: "Zoom Compte-rendu d'un Lobby",
            path: "/lobbies/:_id/comptes-rendus/:docRef",
            component: () =>
              import("../views/side_menu_views/LobbyReportCrZoomView.vue"),
          },
        ],
      },
      {
        name: "Cabinets",
        path: "/cabinets",
        component: () => import("../views/side_menu_views/CabinetsView.vue"),
        secondary_routes: [
          {
            name: "Cabinets Zoom",
            path: "/cabinets/:_id",
            component: () =>
              import("../views/side_menu_views/CabinetsZoomView.vue"),
          },
        ],
      },
    ],
  },
  {
    name: "Social Media",
    icon_class: "fa-chart-network",
    meta: { permission_level: "SOCIETAL" },
    locked_route: {
      name: "Réseaux vérouillés",
      path: "/locked-socials",
      component: () => import("../views/side_menu_views/LockedSocials.vue"),
    },
    secondary_tabs: [
      {
        name: "Overview",
        display_name: "Overview",
        path: "/overview",
        component: () =>
          import("../views/side_menu_views/SocialMediaOverviewView.vue"),
      },
      {
        name: "Publications",
        path: "/posts",
        component: () =>
          import("../views/side_menu_views/SocialMediaPostsView.vue"),
        secondary_routes: [
          {
            name: "Publications - Follawed",
            path: "/posts/follawed",
            component: () =>
              import("../views/side_menu_views/SocialMediaPostsView.vue"),
          },
        ],
      },
      {
        name: "Parties Prenantes",
        path: "/stakeholders",
        component: () =>
          import("../views/side_menu_views/SocialMediaStakeholdersView.vue"),
        secondary_routes: [
          {
            name: "Zoom Partie Prenante",
            path: "/stakeholders/:_id",
            component: () =>
              import(
                "@/views/social-media/stakeholders/StakeholdersZoomView.vue"
              ),
            children: [
              {
                name: "Zoom Partie Prenante - Aperçu",
                path: "preview",
                component: () =>
                  import(
                    "@/views/social-media/stakeholders/PreviewZoomView.vue"
                  ),
              },
              {
                name: "Zoom Partie Prenante - Publications",
                path: "publications",
                component: () =>
                  import(
                    "@/views/social-media/stakeholders/PublicationsZoomView.vue"
                  ),
              },
              {
                name: "Zoom Partie Prenante - Mentions",
                path: "mentions",
                component: () =>
                  import(
                    "@/views/social-media/stakeholders/MentionsZoomView.vue"
                  ),
              },
            ],
          },
        ],
        children: [
          {
            name: "Parties Prenantes - Default",
            path: "",
            redirect: "/stakeholders/ranking",
          },
          {
            name: "Parties Prenantes - Ranking",
            path: "ranking",
            component: () =>
              import(
                "@/views/social-media/stakeholders/StakeholdersRankingView.vue"
              ),
            children: [
              {
                path: "",
                name: "Parties Prenantes - Ranking List",
                component: () =>
                  import(
                    "@/views/social-media/stakeholders/StakeholdersRankingView.vue"
                  ),
              },
              {
                name: "Parties Prenantes - Follawed",
                path: "follawed",
                component: () =>
                  import(
                    "@/views/social-media/stakeholders/StakeholdersRankingView.vue"
                  ),
              },
            ],
          },
          {
            name: "Parties Prenantes - Cartographie Politique",
            path: "political",
            component: () =>
              import(
                "@/views/social-media/stakeholders/StakeholdersPoliticalView.vue"
              ),
          },
          {
            name: "Parties Prenantes - Cartographie Sociétale",
            path: "social",
            component: () =>
              import(
                "@/views/social-media/stakeholders/StakeholdersSocialView.vue"
              ),
          },
        ],
      },
      {
        name: "Articles",
        path: "/articles",
        component: () =>
          import("@/views/side_menu_views/SocialMediaArticlesView.vue"),
      },
    ],
  },
];
