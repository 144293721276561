<template>
  <div class="author" @click="clickAuthor">
    <i class="fa-kit fa-sv-author-pen author-icon"></i>
    Auteur
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  author: {
    type: Object,
    required: true,
  },
});

const clickAuthor = () => {
  router.push({ path: `/stakeholders/${props.author._id}/preview` });
};
</script>

<style lang="scss" scoped>
.author {
  display: flex;
  flex-direction: row;
  gap: 2px;
  color: #6800d0;
  font-weight: normal;

  i {
    z-index: 1;
    font-size: 19px;
    border-radius: 50%;
  }

  &:hover {
    color: #ae5eff;
    cursor: pointer;
  }
}
</style>
