<template>
  <form ref="addOrEditForm" @submit.prevent="$emit('submit', formData)">
    <FormInput
      v-model.trim="formData.name"
      label="Nom de mon enregistrement"
      name="preset-name"
      type="text"
      :error="
        !isFormValid && !formData.name
          ? 'Veuillez renseigner le nom de votre sélection.'
          : null
      "
      required
    />

    <h6>Filtres appliqués :</h6>
    <RadioButtons
      :options="displayOptions"
      :active-option="activeDisplay"
      @select-option="activeDisplay = $event"
    />

    <p>
      {{
        activeDisplay === DISPLAY_OPTIONS.GENERAL
          ? "Ces filtres s'appliquent à toutes les pages"
          : "Ces filtres s'appliquent uniquement aux pages énoncées"
      }}
    </p>
    <template v-if="activeDisplay === DISPLAY_OPTIONS.GENERAL">
      <CollapseSection start-closed>
        <template #title>
          <div class="title-and-badge">
            <p class="collapse-title">Filtre temporel</p>
            <span class="collapse-badge date">{{ intervalLabel }}</span>
          </div>
        </template>
        <template #content>
          <TimerangeOptions
            :interval-preset="formData.filter.selected_time_range"
            :start-date="formData.filter.start"
            :end-date="formData.filter.end"
            @select-time="selectTime($event)"
            @set-start="formData.filter.start = $event"
            @set-end="formData.filter.end = $event"
          />
          <button
            type="button"
            class="btn-primary btn-date"
            :disabled="!isCustomIntervalValid"
            @click="selectTime(TIME_INTERVALS.FREE)"
          >
            Filtrer la date
          </button>
        </template>
      </CollapseSection>
      <CollapseSection start-closed>
        <template #title>
          <div class="title-and-badge">
            <p class="collapse-title">Thématiques</p>
            <span v-show="discoveryBadgeValue > 0" class="collapse-badge">
              {{ discoveryBadgeValue }}
            </span>
          </div>
        </template>
        <template #content>
          <div class="thematics">
            <DiscoveryOptions
              :thematics="userStore.thematics"
              :active-thematics="selectedThematics"
              :active-subthematics="selectedSubthematics"
              @add-thematic="addSelectedFilter('thematics', $event)"
              @add-subthematic="addSelectedFilter('subthematics', $event)"
              @remove-thematic="removeSelectedFilter('thematics', $event)"
              @remove-subthematic="removeSelectedFilter('subthematics', $event)"
              @reset="resetDiscover"
            />
          </div>
        </template>
      </CollapseSection>
      <FormTextarea
        v-model.trim="formData.filter.search"
        label="Requête personnalisée"
        name="preset-additional-query"
      />
    </template>
    <template v-else>
      <CollapseSection
        v-for="(featureValue, featureKey) in features"
        :key="featureKey"
        start-closed
        @open="getFilters(featureKey)"
      >
        <template #title>
          <div class="title-and-badge">
            <p class="collapse-title">
              {{ featureValue.label ?? featureValue.name }}
            </p>
            <span v-show="getBadgeValue(featureKey) > 0" class="collapse-badge">
              {{ getBadgeValue(featureKey) }}
            </span>
          </div>
        </template>
        <template #content>
          <div v-if="store.featuresLoading.includes(featureKey)" class="loader">
            <HollowDotsSpinner
              :animation-duration="1000"
              color="var(--clr-p6)"
            />
          </div>
          <div
            v-else-if="Object.keys(store.featuresFilters).includes(featureKey)"
            class="feature-filters"
          >
            <SelectMultipleCounter
              v-for="(value, key) in featureValue.filters"
              :key="key"
              :filter="key"
              :items="store.featuresFilters[featureKey][key]"
              :active-filters="formData.filter?.[featureKey]?.[key] ?? []"
              mode="purple"
              has-search
              @add-filter-item="addFeatureFilter(featureKey, key, $event)"
              @remove-filter-item="removeFeatureFilter(featureKey, key, $event)"
              @reset-filter="resetFeatureFilter(featureKey, key)"
            >
              {{ value }}
            </SelectMultipleCounter>
          </div>
          <!-- TODO : Add a message when no filters are available -->
          <div v-else>Eh</div>
        </template>
      </CollapseSection>
    </template>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import dayjs from "dayjs";
import { HollowDotsSpinner } from "epic-spinners";
import FormInput from "@/components/reusable/atoms/FormInput.vue";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import CollapseSection from "@/components/reusable/atoms/CollapseSection.vue";
import DiscoveryOptions from "@/components/topbar/molecules/DiscoveryOptions.vue";
import TimerangeOptions from "@/components/general/atoms/TimerangeOptions.vue";
import FormTextarea from "@/components/reusable/atoms/FormTextarea.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import {
  getEndDateFromPreset,
  getStartDateFromPreset,
} from "@/utils/date.utils";
import { useFilterPresetsStore } from "@/stores/filter-presets";
import { TIME_INTERVALS, useUserStore } from "@/stores/user";
import { features } from "@/utils/features";

const store = useFilterPresetsStore();
const userStore = useUserStore();

const props = defineProps({
  targetedPreset: { type: Object, default: null },
});

defineEmits(["submit"]);

const formData = ref({
  name: "",
  filter: {
    selected_time_range: userStore.selectedInterval.selected_time_range ?? "",
    start:
      userStore.selectedInterval.selected_from_date?.split("T")[0] ??
      getStartDateFromPreset(userStore.selectedInterval.selected_time_range),
    end:
      userStore.selectedInterval.selected_to_date?.split("T")[0] ??
      getEndDateFromPreset(userStore.selectedInterval.selected_time_range),
    thematics: [...userStore.sharedFilters.selected_thematics],
    subthematics: [...userStore.sharedFilters.selected_subthematics],
    search: userStore.sharedFilters.selected_keyword ?? "",
  },
});
const isFormValid = ref(true);

const DISPLAY_OPTIONS = {
  GENERAL: "generalFilters",
  PAGE: "pageFilters",
};

const activeDisplay = ref(DISPLAY_OPTIONS.GENERAL);
const displayOptions = [
  {
    label: "Filtres généraux",
    value: DISPLAY_OPTIONS.GENERAL,
  },
  {
    label: "Filtres par page",
    value: DISPLAY_OPTIONS.PAGE,
  },
];

// TIMERANGE
const isCustomIntervalValid = computed(
  () =>
    !!formData.value.filter.start &&
    !!formData.value.filter.end &&
    dayjs(formData.value.filter.start) < dayjs(formData.value.filter.end)
);
const intervalLabel = computed(() => {
  switch (formData.value.filter.selected_time_range) {
    case TIME_INTERVALS.LAST_24H:
      return "< 24h";
    case TIME_INTERVALS.LAST_WEEK:
      return "< 1 semaine";
    case TIME_INTERVALS.LAST_MONTH:
      return "< 1 mois";
    case TIME_INTERVALS.LAST_YEAR:
      return "< 1 an";
    case TIME_INTERVALS.LEGI_15:
      return "XVe legi.";
    case TIME_INTERVALS.LEGI_16:
      return "XVIe legi.";
    case TIME_INTERVALS.LEGI_17:
      return "XVIIe legi.";
    case TIME_INTERVALS.FREE:
      if (isCustomIntervalValid.value) {
        return `${dayjs(formData.value.filter.start).format("DD/MM/YY")} - ${dayjs(
          formData.value.filter.end
        ).format("DD/MM/YY")}`;
      }
    // We want to fall through if the condition is not fullfilled
    // eslint-disable-next-line no-fallthrough
    default:
      return "Période";
  }
});

const selectTime = (option) => {
  if (option !== TIME_INTERVALS.FREE || isCustomIntervalValid.value) {
    if (option !== TIME_INTERVALS.FREE) {
      formData.value.filter.start = getStartDateFromPreset(option);
      formData.value.filter.end = getEndDateFromPreset(option);
    }
    formData.value.filter.selected_time_range = option;
  }
};

// DISCOVERY
// Theses selected* objects are built for the needs of using the id and the name
// in order to display them on the selected filters list
const selectedThematics = computed(() =>
  formData.value.filter.thematics.map((themId) =>
    userStore.thematics.find((t) => t._id === parseInt(themId))
  )
);

const allSubthematics = computed(() => {
  return userStore.thematics.reduce((acc, them) => {
    return [...acc, ...them.subthematics];
  }, []);
});

const selectedSubthematics = computed(() =>
  formData.value.filter.subthematics.map((subThemId) =>
    allSubthematics.value.find((t) => t._id === parseInt(subThemId))
  )
);

const discoveryBadgeValue = computed(
  () => selectedThematics.value.length + selectedSubthematics.value.length
);

const addSelectedFilter = (filter, event) => {
  const newId = event.item._id;
  if (!formData.value.filter[filter].includes(newId)) {
    formData.value.filter[filter].push(newId);
  }
};

const removeSelectedFilter = (filter, event) => {
  const idToRemove = event.item;
  formData.value.filter[filter] = formData.value.filter[filter].filter(
    (id) => id !== idToRemove
  );
};

const resetDiscover = () => {
  formData.value.filter.thematics = [];
  formData.value.filter.subthematics = [];
};

// FEATURES
const getFilters = (feature) => {
  if (!store.featuresFilters[feature]) {
    store.getFeatureFilters(feature);
  }
};

const addFeatureFilter = (featureKey, filterKey, event) => {
  const itemToAdd = event.item;
  const targetFilters = formData.value.filter?.[featureKey]?.[filterKey] || [];

  formData.value.filter = {
    ...formData.value.filter,
    [featureKey]: {
      ...formData.value.filter[featureKey],
      [filterKey]: [...targetFilters, itemToAdd],
    },
  };
};

const removeFeatureFilter = (featureKey, filterKey, event) => {
  const itemToRemove = event.item;
  const targetFilters = formData.value.filter?.[featureKey]?.[filterKey] || [];

  formData.value.filter[featureKey][filterKey] = targetFilters.filter(
    (item) => item !== itemToRemove
  );
  if (formData.value.filter[featureKey][filterKey].length === 0) {
    delete formData.value.filter[featureKey][filterKey];
  }
  if (!Object.keys(formData.value.filter[featureKey]).length) {
    delete formData.value.filter[featureKey];
  }
};

const resetFeatureFilter = (featureKey, filterKey) => {
  formData.value.filter[featureKey][filterKey] = [];
};

const getBadgeValue = (featureKey) => {
  return (
    formData.value.filter?.[featureKey] &&
    Object.values(formData.value.filter[featureKey])
      .filter((value) => value !== null)
      .reduce((acc, curr) => acc + curr.length, 0)
  );
};

const validate = () => {
  isFormValid.value = !!formData.value.name;
  return isFormValid.value;
};

onMounted(() => {
  if (props.targetedPreset) {
    formData.value.name = props.targetedPreset.name;
    formData.value.filter = { ...props.targetedPreset.filter };
  } else {
    const activeFilters = Object.fromEntries(
      Object.entries(store.activeFiltersByFeature).filter(
        // The [,value] syntax is because the entries are needed to recompose the object after filtering but we don't need to use the key in the callback
        ([, value]) => Object.keys(value).length
      )
    );

    Object.entries(activeFilters).forEach(([key, value]) => {
      formData.value.filter[key] = value;
    });
  }
});
const addOrEditForm = ref(null);

defineExpose({
  form: addOrEditForm,
  validate,
});
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  line-height: var(--lh-normal);
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 570px;
}

.title-and-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;

  .collapse-title {
    margin: 0;
    font-weight: var(--fw-semibold);
    line-height: var(--lh-normal);
  }

  .collapse-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 20px;
    height: 20px;
    font-size: var(--size-small);
    font-weight: var(--fw-regular);
    background-color: var(--clr-p5);
    color: #fff;

    &.date {
      padding: 8px;
    }
  }
}
.btn-date {
  float: right;
}
.thematics {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.feature-filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 8px;
}
</style>
