<template>
  <LoaderFullPage v-if="store.loadingScrutin" />
  <template v-else-if="!scrutinRef">
    <div class="empty">
      <img
        src="@/assets/img/legislation/empty-states/no-scrutin-datas.svg"
        aria-hidden="true"
      />
      <p>Pas de scrutin à afficher</p>
    </div>
  </template>

  <template v-else>
    <MotionCensureScrutinHeader
      v-if="is49_3"
      :scrutin-data="store.zoomScrutin"
      :data493="data493"
    />

    <div class="scrutin-wrapper">
      <div class="card summary-graph">
        <ScrutinSyntheseGraph :scrutin="store.zoomScrutin" />
      </div>
      <div class="card summary-graph">
        <ScrutinPositionGraph
          :scrutin="store.zoomScrutin"
          position="voted_for"
        />
      </div>
      <div class="card summary-graph">
        <ScrutinPositionGraph
          :scrutin="store.zoomScrutin"
          position="voted_against"
        />
      </div>
      <div class="card summary-graph">
        <ScrutinPositionGraph
          :scrutin="store.zoomScrutin"
          position="voted_abstention"
        />
      </div>

      <div
        v-for="gData in store.zoomScrutin.details_by_group"
        :key="gData.group._id"
        class="group"
      >
        <ScrutinGroupCard :group-data="gData" />
      </div>
    </div>
  </template>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { useLegiWorkStore } from "@/stores/legi-work";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import ScrutinSyntheseGraph from "@/components/legi-work/atoms/ScrutinSyntheseGraph.vue";
import ScrutinPositionGraph from "@/components/legi-work/atoms/ScrutinPositionGraph.vue";
import ScrutinGroupCard from "@/components/legi-work/molecules/ScrutinGroupCard.vue";
import MotionCensureScrutinHeader from "@/components/legi-work/atoms/MotionCensureScrutinHeader.vue";

const store = useLegiWorkStore();
const route = useRoute();

const { legiWorkZoom } = storeToRefs(store);

const is49_3 = computed(() => route.path.match(/49_3/));

const data493 = computed(() => {
  if (!is49_3.value) {
    return {};
  }

  const { stepIdx, data493idx } = route.params;
  const step = legiWorkZoom.value.timeline[stepIdx];
  return step.engagement_responsabilite_49_3[data493idx];
});

const scrutinRef = computed(() => {
  if (is49_3.value) {
    return data493.value.decision_motion.scrutin_ref;
  }

  const { stepIdx } = route.params;
  const step = legiWorkZoom.value.timeline[stepIdx];
  return step.debats_seance.scrutin_ref || "";
});

if (scrutinRef.value) {
  store.loadScrutin(scrutinRef.value);
}
</script>

<style scoped lang="scss">
img {
  max-width: 241.84px;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  font-size: 1.3rem;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
  small {
    font-size: 1em;
  }
}
.scrutin-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;

  color: var(--clr-s8);
}

.graph-summary {
  grid-column: span 1;
}

.group {
  grid-column: span 2;
}
</style>
