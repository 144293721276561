<template>
  <LoaderFullPage v-if="Object.keys(societalMap).length === 0" />
  <NoData v-else-if="!orderedNonEmptyMs?.length" />
  <div v-else class="columns-wrapper">
    <MappingColumn
      v-for="msName in orderedNonEmptyMs"
      :key="msName"
      :column-data="societalMap[msName]"
      :metric="store.selectedFilters.sort_field"
      :show-group-tags="true"
    >
      <template #header>
        <GenericTag
          :background-color="getMajorStakeholderColor(msName).background"
          :color="getMajorStakeholderColor(msName).text"
          ><span class="header-tag-title">{{ msName }}</span></GenericTag
        >
      </template>
    </MappingColumn>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import NoData from "@/components/reusable/atoms/NoData.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import MappingColumn from "@/components/social-media/stakeholders/molecules/MappingColumn.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import { getSortedNonEmptyColumns } from "@/utils/stakeholder_mapping";
import getMajorStakeholderColor from "@/utils/major-stakeholders-colors";
import { useStakeholdersStore } from "@/stores/social-media/stakeholders";

const store = useStakeholdersStore();
const { filters, societalMap } = storeToRefs(store);

const orderedNonEmptyMs = computed(() =>
  getSortedNonEmptyColumns(
    societalMap.value,
    filters.value.major_stakeholder,
    store.selectedFilters.sort_field
  )
);

store.fetchSocietalMapping();
</script>

<style scoped lang="scss">
$bodyHeaderHeight: 102px;

.columns-wrapper {
  display: flex;
  gap: 5px;

  width: 100%;
  height: calc(100vh - var(--top-menu-height) - $bodyHeaderHeight - 28px);
  overflow-x: auto;
}

.header-tag-title {
  text-transform: capitalize;
}
</style>
