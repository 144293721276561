<template>
  <div class="stakeholders-publications-zoom">
    <div
      v-if="storeStakeholder.isPublicationsLoading"
      class="stakeholders-publications-zoom__loader"
    >
      <LoaderCircle />
    </div>

    <template v-else>
      <PublicationCard
        v-for="post in storeStakeholder.zoomPublications"
        :key="post.id"
        :post="post"
      />

      <PaginationElement
        v-if="storeStakeholder.zoomPublicationsCount > 0"
        :total-items="storeStakeholder.zoomPublicationsCount"
        :current-page="storeStakeholder.zoomPublicationsFilters.from"
        :items-limit="storeStakeholder.zoomPublicationsFilters.size"
        @change-current-page="changeCurrentPage"
      />
    </template>
    <div
      v-if="isPublicationEmpty() && !storeStakeholder.isPublicationsLoading"
      class="card"
    >
      <EmptySection
        message="Cette partie prenante n’a pas de publications correspondant à votre requête."
      />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderCircle from "@/components/reusable/atoms/LoaderCircle.vue";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";
import EmptySection from "@/components/reusable/molecules/EmptySection.vue";

import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import { useUserSettingsStore } from "@/stores/user-settings";

const storeStakeholder = useStakeholdersStore();
const userSettingsStore = useUserSettingsStore();
const route = useRoute();

const isPublicationEmpty = () => {
  const publications = storeStakeholder?.zoomPublications ?? {};
  return Object.values(publications).every((value) => value === null);
};

function changeCurrentPage(from) {
  storeStakeholder.zoomPublicationsFilters.from = from;
  storeStakeholder.getPublicationsList(route.params._id);
}

if (userSettingsStore.hasPermission("SOCIETAL")) {
  storeStakeholder.zoomPublicationsFilters.from = 0;
  storeStakeholder.getPublicationsList(route.params._id);
}
</script>

<style scoped lang="scss">
.stakeholders-publications-zoom {
  display: grid;
  gap: 8px;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
