import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { FOLLAWED_FEATURES, useUserStore } from "@/stores/user";
import {
  getInstitutionsNames,
  reverseInstitutionsNames,
} from "@/utils/institutions.utils";
import {
  fetchInstitutionalGroups,
  fetchInstitutionalGroupsActivities,
  fetchInstitutionalGroupsAgenda,
  fetchInstitutionalGroupsPetitions,
  fetchInstitutionalGroupsPublications,
  fetchInstitutionalGroupsZoom,
  fetchSearchInstitutionalGroups,
} from "@/services/apiInstitutionalGroups";

const initialInstituGroupsFilters = {
  size: 20,
  from: 0,
  get_filters: true,
  sort_field: "number_of_members",
  sort_order: "desc",
};

export const ACTIVITIES_FILTERS = {
  LEGIDOCS: "legi-doc",
  AMENDMENTS: "amendment",
  QUESTIONS: "question",
};

// Defining store
export const useInstituGroupsStore = defineStore("institu-groups", () => {
  const route = useRoute();
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);
  const isFollawedActive = ref(false);

  const groups = ref([]);
  const selectedFilters = ref({ ...initialInstituGroupsFilters });
  const filters = ref(null);
  const searchedInstituGroups = ref([]);
  const groupsCount = ref(0);
  const isInstitutionalGroupsFilterLoading = ref(false);

  const groupZoom = ref(null);
  const missionZoom = ref(null);
  const prevZoomUrlQuery = ref(null);

  // Activities
  const loadingActivities = ref(false);
  const bypassQuery = ref(false);
  const activities = ref([]);
  const activitiesCount = ref(0);
  const activitiesFilters = ref({
    from: 0,
    size: 20,
    indexes: Object.values(ACTIVITIES_FILTERS),
  });

  // Publications
  const loadingPublications = ref(false);
  const publications = ref([]);
  const publicationsCount = ref(0);
  const publicationsFilters = ref({ from: 0, size: 20 });

  // Missions (children elements of commissions)
  const loadingMissions = ref(false);
  const missions = ref([]);
  const missionsCount = ref(0);
  const missionsFilters = ref({ from: 0, size: 100 });

  // Agenda
  const loadingEvents = ref(false);
  const events = ref([]);
  const eventsCount = ref(0);
  const eventsFilters = ref({
    from: 0,
    size: 20,
  });

  // Petitions
  const loadingPetitions = ref(false);
  const petitions = ref([]);
  const petitionsCount = ref(0);
  const petitionsFilters = ref({
    from: 0,
    size: 20,
    sort: [{ published_at: "desc" }],
  });

  async function fetchInstituGroupsList() {
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    // Reverse institution names in filters to backend format
    if (payload.institution) {
      payload.institution = payload.institution.map((institution) =>
        reverseInstitutionsNames(institution)
      );
    }

    // Replace institional_groups by the _id filter
    if (payload.institutional_groups) {
      payload._id = payload.institutional_groups.map((g) => g._id);
      delete payload.institutional_groups;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.COMMISSION];
    }

    try {
      const data = await fetchInstitutionalGroups(payload);

      if (data) {
        groups.value = data.items.map((r) => ({
          ...r,
          // Translate instution to human-readable
          institution: getInstitutionsNames(r.institution),
        }));
        groupsCount.value = data.item_count;
        filters.value = data.filters;
        // Convert institutions to more readable format
        filters.value.institution =
          filters.value.institution.map(getInstitutionsNames);
      }
    } catch (err) {
      groups.value = [];
      groupsCount.value = null;
      throw err;
    } finally {
      loading.value = false;
    }
  }

  async function fetchOneGroup(groupId) {
    loading.value = true;

    // This ref can be used to check if the query has changed in the zoom views
    prevZoomUrlQuery.value = JSON.stringify(route.query);

    try {
      const data = await fetchInstitutionalGroupsZoom({
        _id: [groupId],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      groupZoom.value = data;
      // institution to human-readable format
      groupZoom.value.institution = getInstitutionsNames(
        groupZoom.value.institution
      );
    } finally {
      loading.value = false;
    }
  }

  async function fetchActivities(groupId) {
    loadingActivities.value = true;

    const payload = {
      _id: [groupId],
      ...sharedFiltersAsPayload.value,
      ...activitiesFilters.value,
    };

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    if (!payload.indexes || payload.indexes.length === 0) {
      payload.indexes = Object.values(ACTIVITIES_FILTERS);
    }

    try {
      const data = await fetchInstitutionalGroupsActivities(payload);

      activities.value = data.items;
      activitiesCount.value = data.item_count;
    } finally {
      loadingActivities.value = false;
    }
  }

  async function fetchPublications(groupId) {
    loadingPublications.value = true;

    try {
      const data = await fetchInstitutionalGroupsPublications({
        _id: [groupId],
        ...sharedFiltersAsPayload.value,
        ...publicationsFilters.value,
      });

      publications.value = data.items;
      publicationsCount.value = data.item_count;
    } finally {
      loadingPublications.value = false;
    }
  }

  async function fetchMissions() {
    loadingMissions.value = true;

    try {
      const data = await fetchInstitutionalGroups({
        parent_ref: [groupZoom.value.ref],
        ...sharedFiltersAsPayload.value,
        ...missionsFilters.value,
      });

      missions.value = data.items;
      missionsCount.value = data.item_count;
    } finally {
      loadingMissions.value = false;
    }
  }

  async function fetchAgenda(groupRef) {
    loadingEvents.value = true;

    try {
      const data = await fetchInstitutionalGroupsAgenda({
        organe_ref: [groupRef],
        ...sharedFiltersAsPayload.value,
        ...eventsFilters.value,
      });

      events.value = data.items;
      eventsCount.value = data.item_count;
    } finally {
      loadingEvents.value = false;
    }
  }

  async function fetchPetitions(groupId) {
    loadingPetitions.value = true;

    try {
      const data = await fetchInstitutionalGroupsPetitions({
        _id: [groupId],
        ...sharedFiltersAsPayload.value,
        ...petitionsFilters.value,
      });

      petitions.value = data.items;
      petitionsCount.value = data.item_count;
    } finally {
      loadingPetitions.value = false;
    }
  }

  const getInstitutionalGroupsFilters = async (name) => {
    isInstitutionalGroupsFilterLoading.value = true;
    try {
      const data = await fetchSearchInstitutionalGroups(name);
      searchedInstituGroups.value = data;
    } finally {
      isInstitutionalGroupsFilterLoading.value = false;
    }
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialInstituGroupsFilters };
  };

  const resetStore = () => {
    groups.value = [];
    selectedFilters.value = { ...initialInstituGroupsFilters };
    filters.value = null;
    loading.value = false;
    groupsCount.value = 0;
  };

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchInstituGroupsList();
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        switch (route.name) {
          case "Commissions et autres organes":
          case "Commissions et autres organes - Follawed":
            selectedFilters.value.from = 0;
            fetchInstituGroupsList();
            break;
          case "Zoom Commission - Composition":
          case "Zoom Mission - Composition":
            fetchOneGroup(route.params._id);
            break;
          case "Zoom Commission - Activités":
          case "Zoom Mission - Activités":
            activitiesFilters.value.from = 0;
            fetchActivities(route.params._id);
            break;
          case "Zoom Commission - Publications":
          case "Zoom Mission - Publications":
            publicationsFilters.value.from = 0;
            fetchPublications(route.params._id);
            break;
        }
      }
    }
  );

  return {
    activities,
    activitiesCount,
    bypassQuery,
    activitiesFilters,
    fetchActivities,
    fetchInstituGroupsList,
    fetchOneGroup,
    fetchPublications,
    fetchMissions,
    fetchPetitions,
    fetchAgenda,
    filters,
    getInstitutionalGroupsFilters,
    groups,
    groupsCount,
    groupZoom,
    isFollawedActive,
    isInstitutionalGroupsFilterLoading,
    hasActiveFilters,
    loading,
    loadingActivities,
    loadingEvents,
    loadingPublications,
    loadingMissions,
    loadingPetitions,
    missions,
    missionsCount,
    missionsFilters,
    missionZoom,
    prevZoomUrlQuery,
    petitions,
    petitionsCount,
    petitionsFilters,
    events,
    eventsCount,
    eventsFilters,
    resetFilters,
    resetStore,
    searchedInstituGroups,
    selectedFilters,
    toggleFollawed,
    publications,
    publicationsCount,
    publicationsFilters,
  };
});
