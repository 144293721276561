export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

// Email validation with regex
export function validateEmail(email) {
  const atIndex = email.indexOf("@");
  const dotIndex = email.indexOf(".");
  return atIndex > 0 && dotIndex > 0;
}

// URL Regex
export function urlRegex(url) {
  return url.match(
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
  );
}

// Formating numbers to rounded metrics following International System of Units
export const SINumberFormatter = (num, digits = 0) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const item = lookup
    .slice()
    .reverse()
    .find((elem) => num >= elem.value);
  if (!item) {
    return "0";
  }
  const formattedNum = (num / item.value).toFixed(digits);
  return formattedNum + item.symbol;
};

// Determine if text should be white or black based on background (from follaw 1)
export function getTextColorForBackground(color) {
  if (!color) {
    return "black";
  }

  var r, g, b, hsp;
  if (color.match(/^rgb/)) {
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  if (hsp > 127.5) {
    return "black";
  } else {
    return "white";
  }
}
export function capitalizeFirstLetter(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function normalizeForSearch(str) {
  return (
    str
      // Remove accents
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      // force lowercase
      .toLowerCase()
  );
}

export function searchNormalized(str, pattern) {
  return normalizeForSearch(str).includes(normalizeForSearch(pattern));
}

export function getCSSVariable(varName) {
  return getComputedStyle(document.body).getPropertyValue(varName);
}

export function generateHighlight(search, text) {
  const searchRegex = new RegExp(search.replace(/[^\w\sÀ-ÖØ-öØ-ÿ]/g, ""), "gi");

  if (search === "") {
    return text;
  }

  return text.replace(
    searchRegex,
    (match) => `<mark class="highlight">${match}</mark>`
  );
}
