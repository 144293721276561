<template>
  <template v-for="(value, key) in TIME_INTERVALS" :key="key">
    <div
      v-if="value !== TIME_INTERVALS.FREE"
      class="item"
      :class="{ active: intervalPreset === TIME_INTERVALS[key] }"
      @click="$emit('selectTime', TIME_INTERVALS[key])"
    >
      <span class="item-text">{{ getLabel(value) }}</span>
    </div>
    <!-- custom interval -->
    <div v-else class="custom-selector">
      <span :class="{ active: intervalPreset === TIME_INTERVALS.FREE }"
        >Date :</span
      >
      <div class="inputs">
        <div class="input">
          <label for="start-date">Début</label>
          <input
            :value="startDate"
            name="start-date"
            type="date"
            :max="endDate || today"
            @input="$emit('setStart', $event.target.value)"
          />
        </div>
        <div class="input">
          <label for="end-date">Fin</label>
          <input
            :value="endDate"
            name="end-date"
            type="date"
            :min="startDate"
            :max="today"
            @input="$emit('setEnd', $event.target.value)"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import { TIME_INTERVALS } from "@/stores/user";

const props = defineProps({
  intervalPreset: { type: String, required: true },
  startDate: { type: String, default: "" },
  endDate: { type: String, default: "" },
});

defineEmits(["selectTime", "setStart", "setEnd"]);

const today = computed(() => dayjs().format("YYYY-MM-DD"));

const isCustomIntervalValid = computed(
  () =>
    !!props.startDate &&
    !!props.endDate &&
    dayjs(props.startDate) < dayjs(props.endDate)
);
const getLabel = (period) => {
  switch (period) {
    case TIME_INTERVALS.LAST_24H:
      return "Dernières 24h";
    case TIME_INTERVALS.LAST_WEEK:
      return "Dernière semaine";
    case TIME_INTERVALS.LAST_MONTH:
      return "Dernier mois";
    case TIME_INTERVALS.LAST_YEAR:
      return "Dernière année";
    case TIME_INTERVALS.LEGI_15:
      return "15e législature";
    case TIME_INTERVALS.LEGI_16:
      return "16e législature";
    case TIME_INTERVALS.LEGI_17:
      return "17e législature";
    case TIME_INTERVALS.FREE:
      if (isCustomIntervalValid.value) {
        return `${dayjs(props.startDate).format("DD/MM/YY")} - ${dayjs(
          props.endDate
        ).format("DD/MM/YY")}`;
      }
    // We want to fall through if the condition is not fullfilled
    // eslint-disable-next-line no-fallthrough
    default:
      return "Période";
  }
};
</script>

<style lang="scss" scoped>
.item {
  height: 27px;
  padding: 0 4px 0 15px;
  line-height: 27px;
  cursor: pointer;

  &.active,
  &.active:hover {
    background-color: var(--clr-p2);
  }

  &:hover {
    background-color: var(--clr-p1);
  }
}

.custom-selector {
  border-top: 2px solid var(--clr-p5);
  margin-top: 3px;
  padding: 10px;

  .active {
    background-color: var(--clr-p2);
  }

  .inputs {
    display: flex;
    gap: 8px;
    font-size: 0.938em;

    .input {
      width: calc(50% - 4px);
    }

    label {
      display: block;
      transform: translate(4px, 9px);
      width: fit-content;
      padding: 0 2px;
      background: white;
    }

    input[type="date"] {
      background: transparent;
      border: 1px solid var(--clr-p9);
      border-radius: 7px;
      color: var(--clr-n8);
      padding: 9px 0px 5px 5px;
      height: 36px;
      width: 100%;
      cursor: pointer;
    }
  }
}
</style>
