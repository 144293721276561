<template>
  <li>
    <button class="toc-header-button" @click="$emit('toggle', item.title)">
      {{ item.title || "Titre manquant" }}
      <i v-if="hasSubItems" class="fa-solid chevron" :class="chevronClass"></i>
    </button>

    <ul v-show="!isCollapsed" class="toc-group">
      <li
        v-if="item.title"
        class="toc-heading"
        @click="goToArticle(item.title)"
      >
        {{ item.title || "Titre manquant" }}
      </li>

      <template v-for="(subItem, index) in filteredSubItems" :key="index">
        <TableOfContentsSubItem :content="subItem" :occurrences="occurrences" />
      </template>
    </ul>
  </li>
</template>

<script setup>
import { computed } from "vue";
import TableOfContentsSubItem from "@/components/legi-work/molecules/TableOfContentsSubItem.vue";
import { goToArticle } from "@/utils/browse-articles";

const props = defineProps({
  item: { type: Object, required: true },
  occurrences: { type: Object, required: true },
  isCollapsed: { type: Boolean, required: true },
});

defineEmits(["toggle"]);

const hasSubItems = computed(() => {
  return props.item.article && props.item.article.length > 0;
});

const filteredSubItems = computed(() => {
  return props.item.article
    ? props.item.article.filter((subItem) => subItem.title)
    : [];
});

const chevronClass = computed(() => {
  return props.isCollapsed ? "fa-chevron-down" : "fa-chevron-up";
});
</script>

<style lang="scss" scoped>
.toc-header-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;

  &:hover {
    color: var(--clr-s7);
    background-color: var(--clr-s2);
  }
}

.toc-group {
  padding-left: 16px;
}

.toc-heading {
  margin: 0 0 8px 8px;
  color: var(--clr-s8);
  list-style: none;
  width: fit-content;

  &:hover {
    cursor: pointer;
    color: var(--clr-s7);
    text-decoration: underline;
  }
}
</style>
