<template>
  <LoaderFullPage v-if="store.loadingScrutin" />
  <ul v-else-if="store.zoomReunions.length > 0" class="reunion-list">
    <li v-for="reunion in store.zoomReunions" :key="reunion.ref">
      <ReunionCard :reunion="reunion" />
    </li>
  </ul>
  <NoData v-else />
</template>

<script setup>
import { useLegiWorkStore } from "@/stores/legi-work";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import ReunionCard from "@/components/legi-work/atoms/ReunionCard.vue";

const store = useLegiWorkStore();

store.loadReunions();
</script>

<style scoped lang="scss">
ul.reunion-list {
  list-style: none;
  margin: 0;
  padding: 0;

  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 10px;
}
</style>
