import { reactive, ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { reactiveOmit } from "@vueuse/core";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { httpRequest } from "@/utils/request.utils";
import { Platforms } from "@/utils/social360-platforms.js";
import { getIsoDateRange } from "@/utils/date.utils";

const userStore = useUserStore();
const {
  loading: topbarLoading,
  sharedFiltersAsPayload: sharedFiltersAsPayload,
  selectedInterval: topbarInterval,
} = storeToRefs(userStore);

const initialStatsFilters = {};
const initialTimeCurveTopPublicationsFilters = {
  sort_field: "like",
  sort_order: "desc",
  size: 10,
  from: 0,
  get_filters: false,
  highlight: true,
};

const initialTwitterTimeCurveTopArticlesFilters = {
  sort_field: "number_of_tweets",
  sort_order: "desc",
  size: 10,
  from: 0,
  get_filters: false,
  highlight: true,
};

export const TIMELINE_INTERVALS = {
  MONTH: "month",
  DAY: "day",
  HOUR: "hour",
};

export const useLegiSocialMediaStore = defineStore("legi-social-media", () => {
  const doc_ref = ref(null); // Needs to be initialized with the store

  const indexesFilters = ref([
    Platforms.TWITTER,
    Platforms.TIKTOK,
    Platforms.BLUESKY,
    Platforms.INSTAGRAM,
    Platforms.LINKEDIN,
  ]);
  const totalValues = ref({});
  const timeCurve = ref({});
  const timeCurveInterval = ref(TIMELINE_INTERVALS.DAY);
  const timeCurveTopDate = ref(null);
  const timeCurveTopPublications = ref([]);
  const timeCurveTopPublicationsCount = ref(null);
  const timeCurveTopArticles = ref([]);
  const timeCurveTopArticlesCount = ref(null);
  const stakeHoldersValues = ref({});
  const latestPublications = ref([]);
  const latestPublicationsCount = ref(null);
  const latestArticles = ref([]);
  const latestArticlesCount = ref(null);
  const levelsValues = ref({});
  const groupsRanking = ref({});
  const rankingValues = ref({});
  const filters = ref(null);
  const selectedFilters = ref({ ...initialStatsFilters });
  const timeCurveTopPublicationsFilters = ref({
    ...initialTimeCurveTopPublicationsFilters,
  });
  const timeCurveTopArticlesFilters = ref({
    ...initialTwitterTimeCurveTopArticlesFilters,
  });
  const isStakeholderStatsLoading = ref(false);
  const isTimeCurveLoading = ref(false);
  const isTimeCurvePublicationsLoading = ref(false);
  const isTotalFieldsLoading = ref(false);
  const isGroupsLoading = ref(false);
  const isRankingStatsLoading = ref(false);
  const isLevelsRankingLoading = ref(false);
  const isArticlesListLoading = ref(false);
  const isLatestPublicationsLoading = ref(false);
  const isLatestArticlesLoading = ref(false);

  const createPayloadBase = () => {
    const remainingSharedFilters = reactiveOmit(
      reactive(sharedFiltersAsPayload.value),
      ["dashboard_id", "start", "end"]
    );

    const payload = {
      ...selectedFilters.value,
      ...remainingSharedFilters,
      indexes: indexesFilters.value,
    };

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    return payload;
  };

  const fetchTotalFields = async () => {
    isTotalFieldsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/totals`,
        payload
      );
      if (response.data) {
        totalValues.value = response.data.items;
        filters.value = response.data.filters;
      }
    } catch (error) {
      totalValues.value = [];
      filters.value = null;
    }

    isTotalFieldsLoading.value = false;
  };

  const fetchTimeCurve = async () => {
    isTimeCurveLoading.value = true;

    const payload = {
      doc_ref: doc_ref.value,
      interval: timeCurveInterval.value,
      ...createPayloadBase(),
    };

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/time_curve`,
        payload
      );
      if (response.data) {
        timeCurve.value = {
          x_timeline: response.data.x_timeline,
          tiktok_timeline: response.data.tiktok_timeline,
          bluesky_timeline: response.data.bluesky_timeline,
          instagram_timeline: response.data.instagram_timeline,
          linkedin_timeline: response.data.linkedin_timeline,
        };
      }
    } catch (error) {
      timeCurve.value = {};
    }

    isTimeCurveLoading.value = false;
  };

  const getLatestPublications = async () => {
    isLatestPublicationsLoading.value = true;

    const payload = {
      ...createPayloadBase(),
      doc_ref: doc_ref.value,
      size: 20,
      from: 0,
    };

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/publications`,
        payload
      );

      if (response.data) {
        latestPublications.value = response.data.items;
        latestPublicationsCount.value = response.data.item_count;
      }
    } finally {
      isLatestPublicationsLoading.value = false;
    }
  };

  const getTimeCurvePublications = async () => {
    isTimeCurvePublicationsLoading.value = true;

    const date = timeCurveTopDate.value.date;
    const selectedPlatform = timeCurveTopDate.value?.platform;

    const dateRange = getIsoDateRange(date, timeCurveInterval.value);

    const payload = {
      ...createPayloadBase(),
      ...dateRange,
      ...timeCurveTopPublicationsFilters.value,
      indexes: selectedPlatform ? [selectedPlatform] : undefined,
      doc_ref: doc_ref.value,
    };

    if (userStore.sharedFilters.selected_keyword) {
      payload.filter = userStore.sharedFilters.selected_keyword;
    }

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/publications`,
        payload
      );

      if (response.data) {
        timeCurveTopPublications.value = response.data.items;
        timeCurveTopPublicationsCount.value = response.data.item_count;
      }
    } finally {
      isTimeCurvePublicationsLoading.value = false;
    }
  };

  const getLatestArticles = async () => {
    isLatestArticlesLoading.value = true;

    const payload = {
      doc_ref: doc_ref.value,
      size: 20,
      from: 0,
    };

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/links`,
        payload
      );

      if (response.data) {
        latestArticles.value = response.data.items;
        latestArticlesCount.value = response.data.item_count;
      }
    } finally {
      isLatestArticlesLoading.value = false;
    }
  };

  const getTimeCurveArticles = async () => {
    isArticlesListLoading.value = true;

    const date = timeCurveTopDate.value.date;
    const dateRange = getIsoDateRange(date, timeCurveInterval.value);

    const payload = {
      doc_ref: doc_ref.value,
      ...createPayloadBase(),
      ...dateRange,
      ...timeCurveTopArticlesFilters.value,
    };

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/links`,
        payload
      );

      if (response.data) {
        timeCurveTopArticles.value = response.data.items;
        timeCurveTopArticlesCount.value = response.data.item_count;
      }
    } finally {
      isArticlesListLoading.value = false;
    }
  };

  const resetTimeCurveTopsFilters = () => {
    timeCurveTopPublicationsFilters.value = {
      ...initialTimeCurveTopPublicationsFilters,
    };
    timeCurveTopArticlesFilters.value = {
      ...initialTwitterTimeCurveTopArticlesFilters,
    };
  };

  const fetchGroupsRanking = async () => {
    isGroupsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/group_ranking`,
        payload
      );
      if (response.data) {
        groupsRanking.value = {
          publications: response.data.publications,
          authors: response.data.authors,
        };
      }
    } catch (error) {
      groupsRanking.value = {};
    }

    isGroupsLoading.value = false;
  };

  const fetchRankingStats = async () => {
    isRankingStatsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/rankings`,
        payload
      );
      if (response.data) {
        rankingValues.value = {
          top_domains: response.data.top_domains,
          top_hashtags: response.data.top_hashtags,
          top_words: response.data.top_words,
          top_mentions: response.data.top_mentions,
        };
      }
    } catch (error) {
      rankingValues.value = [];
    }

    isRankingStatsLoading.value = false;
  };

  const fetchStakeholders = async () => {
    isStakeholderStatsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/major_stakeholder_ranking`,
        payload
      );

      if (response.data) {
        stakeHoldersValues.value = {
          publications: response.data.publications,
          authors: response.data.authors,
          classement: response.data.classement,
        };
      }
    } catch (error) {
      stakeHoldersValues.value = [];
    } finally {
      isStakeholderStatsLoading.value = false;
    }
  };

  const fetchLevelsRanking = async () => {
    isLevelsRankingLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        `/v2/legi-social-media/level_ranking`,
        payload
      );

      if (response.data) {
        levelsValues.value = {
          publications: response.data.publications,
          authors: response.data.authors,
        };
      }
    } catch (error) {
      levelsValues.value = {};
    } finally {
      isLevelsRankingLoading.value = false;
    }
  };

  const fetchOverview = () => {
    fetchTotalFields();
    fetchTimeCurve();
    fetchGroupsRanking();
    fetchRankingStats();
    fetchStakeholders();
    fetchLevelsRanking();
    if (timeCurveTopDate.value) {
      getTimeCurvePublications();
    }
    getLatestPublications();
    getLatestArticles();
  };

  const addFilterItem = (event) => {
    if (selectedFilters.value[event.filter]?.includes(event.item)) {
      // Filter is already added, so skip adding it
      return;
    }

    let targetFilters = selectedFilters.value[event.filter] ?? [];
    targetFilters = [...targetFilters, event.item];

    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };

    fetchOverview();
  };

  const removeFilterItem = (event) => {
    let targetFilters = selectedFilters.value[event.filter] ?? [];

    targetFilters = targetFilters.filter((el) => el !== event.item);

    if (targetFilters.length === 0) {
      delete selectedFilters.value[event.filter];
    } else {
      selectedFilters.value = {
        ...selectedFilters.value,
        [event.filter]: targetFilters,
      };
    }

    fetchOverview();
  };

  const resetFilter = (event) => {
    delete selectedFilters.value[event];
    fetchOverview();
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialStatsFilters };
    fetchOverview();
  };

  const resetStore = () => {
    totalValues.value = {};
    rankingValues.value = {};
    stakeHoldersValues.value = {};
    filters.value = null;
    levelsValues.value = {};
    selectedFilters.value = { ...initialStatsFilters };
    isTotalFieldsLoading.value = false;
    isStakeholderStatsLoading.value = false;
    isLevelsRankingLoading.value = false;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        router.currentRoute.value.name?.includes("Social Media")
      ) {
        selectedFilters.value.from = 0;
        fetchOverview();
      }
    }
  );

  // Trigger refetch of timecurve zoom data when parameters change
  watch([timeCurveTopDate], () => {
    if (timeCurveTopDate.value) {
      getTimeCurvePublications();
      getTimeCurveArticles();
    }
  });

  watch([timeCurveTopPublicationsFilters], () => {
    if (timeCurveTopDate.value) {
      getTimeCurvePublications();
    }
  });

  watch([timeCurveTopArticlesFilters], () => {
    if (timeCurveTopDate.value) {
      getTimeCurveArticles();
    }
  });

  return {
    addFilterItem,
    doc_ref,
    fetchLevelsRanking,
    fetchOverview,
    fetchStakeholders,
    fetchTimeCurve,
    filters,
    groupsRanking,
    indexesFilters,
    isArticlesListLoading,
    isLatestArticlesLoading,
    isGroupsLoading,
    isLevelsRankingLoading,
    isRankingStatsLoading,
    isStakeholderStatsLoading,
    isTimeCurveLoading,
    isTimeCurvePublicationsLoading,
    isTotalFieldsLoading,
    isLatestPublicationsLoading,
    latestArticles,
    latestArticlesCount,
    latestPublications,
    latestPublicationsCount,
    levelsValues,
    rankingValues,
    removeFilterItem,
    resetFilter,
    resetFilters,
    resetStore,
    resetTimeCurveTopsFilters,
    selectedFilters,
    stakeHoldersValues,
    timeCurve,
    timeCurveInterval,
    timeCurveTopArticles,
    timeCurveTopArticlesCount,
    timeCurveTopArticlesFilters,
    timeCurveTopDate,
    timeCurveTopPublications,
    timeCurveTopPublicationsCount,
    timeCurveTopPublicationsFilters,
    totalValues,
  };
});
