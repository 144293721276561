<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink to="/questions" class="back">
        <i class="fa-solid fa-chevron-left"></i> Retour à la liste
      </RouterLink>
      <div
        v-if="store.previousQuestionID || store.nextQuestionID"
        class="previous-next-wrapper"
      >
        <RouterLink
          v-if="store.previousQuestionID"
          :to="`/questions/${store.previousQuestionID}`"
          class="btn-primary previous"
        >
          <i class="fa-solid fa-chevron-left" />Précédente
        </RouterLink>
        <RouterLink
          v-if="store.nextQuestionID"
          :to="`/questions/${store.nextQuestionID}`"
          class="btn-primary next"
        >
          Suivante<i class="fa-solid fa-chevron-right" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useQuestionsStore } from "@/stores/questions";

const store = useQuestionsStore();
</script>
