import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useToast } from "vue-toastification";
import { pick } from "lodash";

import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const FOLLAWUPS_SERVICES_VALUES = [
  "amendment",
  "legislation",
  "question",
  "petition",
  "legislation-amendment",
  "legislation-rapport",
  "ordre_du_jour",
  "feuille_verte",
  "prm_birthday",
];

export const NO_NOTIFICATION = "NO_NOTIFICATION";

const toast = useToast();

export const useAlertSettingsStore = defineStore("alert-settings", () => {
  const isFetchLoading = ref(false);

  const settings = ref({
    _id: "",
    alert_notification_type: "email",
    digest_notification_type: "email",
    followup_notification_type: "email",
    telegram_channel_id: "",
    slack_webhook: "",
    msteams_webhook: "",
    followup_services: FOLLAWUPS_SERVICES_VALUES,
    petition_percentage: 0,
    days_before_prm_birthday: 0,
  });
  const isChannelLoading = ref(false);
  const isFollawupConfigLoading = ref(false);

  async function fetchAlertSettings() {
    isFetchLoading.value = true;

    try {
      const response = await httpRequest("get", "alert-settings");

      if (response.data) {
        const data = response.data;
        settings.value = {
          ...settings.value,
          ...data,
        };

        // For followups, the NO_NOTIFICATION value only exists in the front-end
        // For the back-end, it is represented by an empty followup_services array
        if (settings.value.followup_services.length <= 0) {
          settings.value.followup_notification_type = NO_NOTIFICATION;
        }
      } else {
        toast.error("Impossible de récupérer les paramètres de notifications");
        throw new Error(
          "Impossible de récupérer les paramètres de notifications"
        );
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      isFetchLoading.value = false;
    }
  }

  function updateAlertSettings(payload) {
    return httpRequest("patch", "alert-settings", payload);
  }

  async function patchChannelConfig() {
    isChannelLoading.value = true;

    const payload = pick(settings.value, [
      "alert_notification_type",
      "digest_notification_type",
      "followup_notification_type",
      "telegram_channel_id",
      "slack_webhook",
      "msteams_webhook",
      "followup_services",
    ]);

    if (payload.followup_notification_type === NO_NOTIFICATION) {
      payload.followup_notification_type = "email";
      payload.followup_services = [];
    } else {
      payload.followup_services = FOLLAWUPS_SERVICES_VALUES;
      settings.value.followup_services = FOLLAWUPS_SERVICES_VALUES;
    }

    try {
      await updateAlertSettings(payload);
    } catch (err) {
      handleNetworkError(err);
    } finally {
      isChannelLoading.value = false;
    }
  }

  async function patchFollawUpsConfig() {
    isFollawupConfigLoading.value = true;

    const payload = pick(settings.value, [
      "petition_percentage",
      "days_before_prm_birthday",
    ]);

    try {
      await updateAlertSettings(payload);
    } catch (err) {
      handleNetworkError(err);
    } finally {
      isFollawupConfigLoading.value = false;
    }
  }

  function hasFollowupService(followup) {
    return settings.value.followup_services.includes(followup);
  }

  async function toggleFollowupService(followup) {
    isFollawupConfigLoading.value = true;

    if (settings.value.followup_services.includes(followup)) {
      settings.value.followup_services =
        settings.value.followup_services.filter(
          (service) => service !== followup
        );
    } else {
      settings.value.followup_services.push(followup);
    }

    const payload = { followup_services: settings.value.followup_services };

    try {
      await updateAlertSettings(payload);
    } catch (err) {
      handleNetworkError(err);
    } finally {
      isFollawupConfigLoading.value = false;
    }
  }

  watch(
    settings,
    (newSettings) => {
      if (!newSettings) {
        return;
      }

      // Check if the config is still valid
      // Basically, we check that selected services have a configuration attribute correctly set
      // Otherwise, reset it to a default valid value

      const CHANNEL_ATTRIBUTE_MAPPING = {
        telegram: "telegram_channel_id",
        slack: "slack_webhook",
        msteams: "msteams_webhook",
      };

      for (const channel of Object.keys(CHANNEL_ATTRIBUTE_MAPPING)) {
        for (const notifType of ["followup", "digest", "alert"]) {
          if (
            newSettings[`${notifType}_notification_type`] === channel &&
            newSettings[CHANNEL_ATTRIBUTE_MAPPING[channel]] === ""
          ) {
            newSettings[`${notifType}_notification_type`] = "email";
          }
        }
      }
    },
    { deep: true }
  );

  return {
    toggleFollowupService,
    isChannelLoading,
    fetchAlertSettings,
    hasFollowupService,
    isFetchLoading,
    isFollawupConfigLoading,
    patchChannelConfig,
    patchFollawUpsConfig,
    settings,
  };
});
