<template>
  <div class="template">
    <template v-if="userSettingsStore.hasPermission('BASIC')">
      <RouterRadioButtons :options="textOptions" />
      <RouterView />
    </template>
    <template v-else>
      <FreemiumUpgradePopup :closable="false" title="Alertes et récapitulatifs">
        <p>Ce contenu est réservé aux abonnements payants.</p>
        <p>
          En activant celui-ci, vous pouvez recevoir des notifications (Slack,
          Telegram, Teams, Email) sur tous les corpus ou recevoir un résumé
          journalier ou hebdomadaire sur votre veille.
        </p>
      </FreemiumUpgradePopup>
    </template>
  </div>
</template>

<script setup>
import { RouterView, useRouter } from "vue-router";

import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { useUserSettingsStore } from "@/stores/user-settings";
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";

const router = useRouter();
const userSettingsStore = useUserSettingsStore();

// Build tabs navbar options from router config
const routerOptions = router
  .getRoutes()
  .find((route) => route.name === "Alerting").children;

const iconMapping = {
  "Alerting - Alertes": "fa-regular fa-bell",
  "Alerting - Récapitulatifs": "fa-regular fa-alarm-clock",
};
const textOptions = routerOptions.map((route) => {
  const iconClass = iconMapping[route.name];
  return {
    label: route.name.replace("Alerting - ", ""),
    value: `/alerting/${route.path}`,
    icon: iconClass,
  };
});
</script>

<style scoped>
p {
  margin: 0;
}
</style>
