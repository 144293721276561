<template>
  <NoData v-if="commissionsData.length === 0" />
  <ul v-else class="list">
    <li v-for="com in commissionsData" :key="com.organe_ref">
      <CommissionCard :commission="com" :start-open="onlyOneCom" />
    </li>
  </ul>
</template>

<script setup>
import CommissionCard from "@/components/legi-work/molecules/CommissionCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const { stepIdx } = route.params;

const store = useLegiWorkStore();

const commissionsData = store.legiWorkZoom.timeline[stepIdx].travaux_commission;

const onlyOneCom = computed(() => commissionsData?.length === 1);
</script>

<style scoped lang="scss">
ul.list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
