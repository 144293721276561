<template>
  <template v-if="store.items.length">
    <!-- Cards -->
    <div class="responsive-cards-wrapper" style="--min-card-size: 500px">
      <HomepageCard
        v-for="item in store.items"
        :key="item.document._id"
        :item="item"
      />
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="store.totalCount"
      :key="`${store.totalCount}${store.basePayload.from}${store.basePayload.size}`"
      :total-items="store.totalCount"
      :current-page="store.basePayload.from"
      :items-limit="store.basePayload.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoDataFollawed v-else-if="store.isFollawedActive" />
  <NoData v-else />
</template>

<script setup>
import HomepageCard from "../molecules/HomepageCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";

import { useHomepageStore } from "@/stores/homepage";

const store = useHomepageStore();

const changeCurrentPage = (from) => {
  store.basePayload.from = from;
  store.fetchHomepage();
};
</script>
