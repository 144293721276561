<template>
  <Component
    :is="closable ? 'dialog' : 'div'"
    ref="dialog"
    class="popup-dialog"
    :class="{ inline: !closable }"
    @click="closeDialog"
  >
    <div class="dialog-content" @click="(e) => e.stopPropagation()">
      <div class="header">
        <h3>{{ title }}</h3>
        <CloseButton
          v-if="closable"
          class="dialog-close"
          @click="closeDialog"
        />
      </div>
      <div class="body">
        <slot>
          Pour accéder à cette fonctionnalité, vous devez souscrire à un
          abonnement.
        </slot>
      </div>
      <div class="footer">
        <router-link
          class="btn-primary"
          :to="{ name: 'Paramètre utilisateur - Compte' }"
          @click="closeDialog"
        >
          Gérer mon abonnement
        </router-link>
      </div>
    </div>
  </Component>
</template>

<script setup>
import { onMounted, ref } from "vue";
import CloseButton from "./CloseButton.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  closable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["open", "close"]);

const dialog = ref();

const openDialog = () => {
  dialog.value.inert = true;
  dialog.value?.showModal?.();
  dialog.value.inert = false;
  emit("open");
};

const closeDialog = () => {
  if (!props.closable) {
    return;
  }
  dialog.value?.close();
  emit("close");
};

defineExpose({
  open: openDialog,
  close: closeDialog,
});

onMounted(() => {
  if (!props.closable) {
    openDialog();
  }
});
</script>

<style lang="scss" scoped>
.popup-dialog {
  border-radius: 4px;
  border: none;
  max-width: 50%;
  margin: auto;
  padding: 0;

  &:is(div) {
    margin: 0 auto;
  }

  &.inline {
    padding: 16px;
    display: flex;
    justify-content: center;
    background-color: transparent;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    width: fit-content;
    padding: 20px 40px;
    box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.16);
    color: var(--clr-n8);
    background-color: white;
  }

  .header {
    h3 {
      margin: 0;
      font-size: 1.125em;
      font-weight: 700;
      text-align: center;
    }

    .dialog-close {
      position: absolute;
      top: 17px;
      right: 13px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .btn-primary {
      color: white;
    }
  }
}
</style>
