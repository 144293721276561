<template>
  <RouterLink
    :to="`/lobbies/${lobby.national_id}`"
    :class="lobby.national_id ? '' : 'disabled'"
  >
    <div class="card card-hover">
      <section class="body">
        <section class="name-and-follaw">
          <div class="pic-and-name">
            <CardProfilePicture
              :picture-url="
                lobby.x_information?.profile_picture
                  ? lobby.x_information.profile_picture
                  : null
              "
              class="picture"
              :size="48"
            />
            <p class="name">{{ lobby.name }}</p>
          </div>
          <div class="follaw">
            <FollawButton
              v-if="lobby.national_id"
              :element-id="lobby.national_id"
              :feature="FOLLAWED_FEATURES.LOBBIES"
            />
          </div>
        </section>

        <section class="organisation-type">
          <p class="type capitalize-letter">{{ lobby.type }}</p>
        </section>

        <section class="lobby-infos">
          <div class="info city">
            <span class="icon"><i class="fa-solid fa-location-dot"></i></span>
            <p>{{ lobby.city }}</p>
          </div>
          <div class="info levels">
            <span class="icon"><i class="fa-solid fa-layer-group"></i></span>
            <p>{{ lobby.levels.join(", ") }}</p>
          </div>
          <div class="info categories">
            <span class="icon"><i class="fa-solid fa-briefcase"></i></span>
            <p>
              {{ formattedCategories
              }}<button
                v-if="lobby.categories.length > 10"
                class="see-more"
                @click="isEveryCategoryShown = !isEveryCategoryShown"
              >
                , ...{{ isEveryCategoryShown ? "moins" : "plus" }}
              </button>
            </p>
          </div>
        </section>

        <section class="stats">
          <div class="border">
            <div class="stat">
              <p>
                Publication<template v-if="lobby.number_of_publications > 1"
                  >s</template
                >
              </p>
              <span class="number">{{
                SINumberFormatter(lobby.number_of_publications)
              }}</span>
            </div>
          </div>

          <div class="latest-activity">
            <div class="latest-activity-stats">
              <div class="stat expenses">
                <p>Dépenses</p>
                <span class="number">
                  {{ lobby.latest_activity.expenses ?? "/" }}
                </span>
              </div>

              <div class="stat money">
                <p>CA</p>
                <span class="number">
                  {{ lobby.latest_activity.money ?? "/" }}
                </span>
              </div>

              <div class="stat employees">
                <p>
                  Employé<template
                    v-if="lobby.latest_activity.number_of_employees > 1"
                    >s</template
                  >
                  <GenericTooltip top left nowrap>
                    <template #trigger>
                      <i class="fa-regular fa-circle-info"></i>
                    </template>
                    <template #text
                      >Exprimé en équivalent temps plein travaillé</template
                    >
                  </GenericTooltip>
                </p>
                <span class="number">
                  {{
                    SINumberFormatter(lobby.latest_activity.number_of_employees)
                  }}
                </span>
              </div>
            </div>
            <p class="period">
              <span>Période concernée :</span>
              {{ dayjs(lobby.latest_activity.start).format("DD/MM/YYYY") }} au
              {{ dayjs(lobby.latest_activity.end).format("DD/MM/YYYY") }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </RouterLink>
</template>

<script setup>
import { ref, computed } from "vue";
import dayjs from "dayjs";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import GenericTooltip from "@/components/reusable/atoms/GenericTooltip.vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { FOLLAWED_FEATURES } from "@/stores/user";

const props = defineProps({
  lobby: {
    type: Object,
    required: true,
  },
});

const isEveryCategoryShown = ref(false);
const formattedCategories = computed(() => {
  return isEveryCategoryShown.value
    ? props.lobby.categories.join(", ")
    : props.lobby.categories.slice(0, 5).join(", ");
});
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.card {
  height: 100%;
  color: var(--clr-n8);
}

.body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .name-and-follaw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .pic-and-name {
      display: flex;
      align-items: center;
      gap: 8px;

      .name {
        font-size: large;
        text-transform: capitalize;
      }
    }

    .follaw {
      width: fit-content;
    }
  }

  .type {
    color: var(--clr-s7);
  }

  .lobby-infos {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: var(--size-dynamic-medium);

    .info {
      display: flex;
      gap: 8px;

      .icon {
        display: inline-flex;
        justify-content: center;
        width: 14px;
        padding: 3px 0;
        color: var(--clr-s6);
      }
    }

    p {
      line-height: 22px;
    }

    .see-more:hover {
      color: var(--clr-s7);
    }
  }

  .stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 11px;
    line-height: 24px;
    height: 100%;

    .border {
      display: flex;
      align-items: center;
      border-right: 1px solid var(--clr-s6);
      padding-right: 8px;
      margin: 8px 0;

      @container (width < 474px) {
        gap: 4px;
        justify-content: center;
        margin: 0;
        border-right: none;
        border-bottom: 1px solid var(--clr-s6);
      }
    }

    .latest-activity {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .latest-activity-stats {
        display: flex;
        gap: 8px;
      }
    }

    .stat {
      padding: 5px 10px;
      text-align: center;

      p {
        color: var(--clr-s7);
      }

      i {
        margin-left: 4px;
      }
    }

    .period {
      width: 100%;
      text-align: center;
      font-size: 0.833rem;
      line-height: 18.66px;

      span {
        color: var(--clr-s7);
      }
    }
  }
}

.disabled {
  pointer-events: none;
}
</style>
