<template>
  <ScrollTop />
  <ExposeMotifs
    :content="legiWorkZoom.expose_motifs"
    :occurrences="legiWorkZoom.expose_motifs_occurrences"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";

import ExposeMotifs from "@/components/legi-work/templates/ExposeMotifs.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const store = useLegiWorkStore();

const { legiWorkZoom } = storeToRefs(store);
</script>
