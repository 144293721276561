<template>
  <LoaderFullPage v-if="store.loadingGlobalInfo" />

  <div v-else class="wrapper">
    <div class="first-row">
      <div class="card">
        <div class="header-title">Nombre d'amendements</div>
        <div class="big-number">
          {{ formatNumbers(store.zoomGlobalInfo.total_amendments) }}
        </div>
      </div>
      <div class="card total-tweets">
        <RouterLink
          v-if="store.zoomGlobalInfo.total_tweets"
          class="clickable-tweets-zone"
          :to="`/legislatives/${store.legiWorkZoom.ref}/social-media#posts-content`"
        >
          <div class="header-title">Nombre de tweets</div>
          <div class="big-number">
            {{ formatNumbers(store.zoomGlobalInfo.total_tweets) }}
          </div>
        </RouterLink>

        <div v-else>
          <div class="header-title">Nombre de tweets</div>
          <div class="big-number">
            {{ formatNumbers(store.zoomGlobalInfo.total_tweets) }}
          </div>
        </div>

        <NoiseLevelBars v-if="isAmendementsAndTweetsAvailable" :level="0" />
      </div>
      <div class="card">
        <div class="header-title">Rapporteurs</div>
        <template v-if="store.legiWorkZoom.current_rapporteurs?.length > 0">
          <PersonSmallCard
            :author="
              store.legiWorkZoom.current_rapporteurs[currentRapporteurIdx]
            "
          />
          <div class="rapporteurs-carousel">
            <button class="btn-secondary" @click="goToPrevRapporteur">
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <div>
              {{ currentRapporteurIdx + 1 }} /
              {{ store.legiWorkZoom.current_rapporteurs.length }}
            </div>
            <button class="btn-secondary" @click="goToNextRapporteur">
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </template>
        <div v-else>Pas de rapporteur actuellement sur le dossier.</div>
      </div>
      <div class="card top-occurrences">
        <div class="header-title">Top occurrences</div>
        <ul
          v-if="
            Object.keys(store.legiWorkZoom.total_occurrences || {}).length > 0
          "
        >
          <li
            v-for="keyword in Object.keys(store.legiWorkZoom.total_occurrences)"
            :key="keyword"
          >
            <span>{{ keyword }}</span>
            <span
              >{{ store.legiWorkZoom.total_occurrences[keyword] }} fois</span
            >
          </li>
        </ul>
        <p v-else>
          Pas d'occurrences dans ce dossier législatif avec la veille
          sélectionnée.
        </p>
      </div>
    </div>

    <div class="instit-picker-row">
      <p>Voir les statistiques de:</p>
      <CheckboxRadioGroup
        v-model="groupsInstitution"
        :options="institutionOptions"
      />
    </div>

    <div v-if="isAmendementsAndTweetsAvailable" class="graph-rows">
      <div class="card">
        <div class="header-title">Activité des groupes</div>
        <BubbleChart
          :chart-data="bubbleChartData"
          :options="bubbleChartOptions"
          :height="300"
        />
      </div>
      <div class="card">
        <div class="header-title">Amendements déposés par</div>
        <PieChart
          v-if="pieChartData.labels.length > 0"
          :chart-data="pieChartData"
          :options="pieChartOptions"
          :height="300"
        />
        <p v-else>Pas d'amendements correspondant</p>
      </div>
    </div>

    <div v-if="isAmendementsAndTweetsAvailable" class="mapping-header">
      <RadioButtons
        :options="mapViewOptions"
        :active-option="activeMapView"
        @select-option="setActiveMapView"
      />
      <template v-if="metricsOptions.length > 1">
        <div class="spacer" />
        <RadioButtons
          :options="metricsOptions"
          :active-option="metric"
          @select-option="metric = $event"
        />
      </template>
    </div>

    <div v-if="isAmendementsAndTweetsAvailable" class="columns-wrapper">
      <template v-if="activeMapView === 'group'">
        <MappingColumn
          v-for="groupName in orderedNonEmptyGroups"
          :key="groupName"
          :column-data="politicalMapping[groupName]"
          :metric="metric"
          :show-group-tags="false"
        >
          <template #header>
            <GroupTag :background-color="getColorForGroup(groupName)">{{
              groupName
            }}</GroupTag>
          </template>
        </MappingColumn>
      </template>
      <template v-else>
        <MappingColumn
          v-for="msName in orderedNonEmptyMs"
          :key="msName"
          :column-data="societalMapping[msName]"
          :metric="metric"
          :show-group-tags="true"
        >
          <template #header>
            <GenericTag
              :background-color="getMajorStakeholderColor(msName).background"
              :color="getMajorStakeholderColor(msName).text"
              ><span class="header-tag-title">{{ msName }}</span></GenericTag
            >
          </template>
        </MappingColumn>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import formatNumbers from "@/utils/formatNumbers";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import MappingColumn from "@/components/social-media/stakeholders/molecules/MappingColumn.vue";
import getMajorStakeholderColor from "@/utils/major-stakeholders-colors";
import NoiseLevelBars from "@/components/legi-work/atoms/NoiseLevelBars.vue";
import PersonSmallCard from "@/components/legi-work/atoms/PersonSmallCard.vue";
import { BubbleChart, PieChart } from "vue-chart-3";
import CheckboxRadioGroup from "@/components/reusable/molecules/CheckboxRadioGroup.vue";

const store = useLegiWorkStore();

const institutionOptions = [
  { text: "Assemblée Nationale", value: "AN" },
  { text: "Sénat", value: "SN" },
];
const groupsInstitution = ref("AN");

const mapViewOptions = [
  { label: "Parti", value: "group" },
  { label: "Parties prenantes", value: "stakeholder" },
];
const activeMapView = ref("group");

const isAmendementsAndTweetsAvailable = computed(() => {
  return (
    store.zoomGlobalInfo.total_amendments > 0 ||
    store.zoomGlobalInfo.total_tweets > 0
  );
});

const metricsOptions = computed(() => [
  ...(activeMapView.value === "group"
    ? [{ label: "Amendements", value: "number_of_amendments" }]
    : []),
  { label: "Tweets", value: "number_of_tweets" },
]);
const metric = ref("number_of_amendments");

const currentRapporteurIdx = ref(0);

const politicalMapping = computed(() => {
  const stakeholderToMatch =
    groupsInstitution.value === "AN" ? /d.put./i : /s.nateur/i;

  const politicalPersons = store.zoomGlobalInfo.personalities.filter(
    (p) =>
      p.major_stakeholder.match(/politique/i) &&
      p.stakeholder.match(stakeholderToMatch)
  );

  const map = {};
  politicalPersons
    .filter((p) => p.group)
    .forEach((p) => {
      const {
        group: { acronym },
      } = p;
      if (!Object.keys(map).includes(acronym)) {
        map[acronym] = [];
      }
      map[acronym].push(p);
    });
  return map;
});

const societalMapping = computed(() => {
  const { personalities } = store.zoomGlobalInfo;

  const map = {};
  personalities.forEach((p) => {
    const { major_stakeholder } = p;
    if (!Object.keys(map).includes(major_stakeholder)) {
      map[major_stakeholder] = [];
    }
    map[major_stakeholder].push(p);
  });
  return map;
});

const orderedNonEmptyGroups = computed(() => {
  return Object.keys(politicalMapping.value)
    .filter((g) => politicalMapping.value[g].length > 0)
    .sort(
      (g1, g2) =>
        politicalMapping.value[g2][0][metric.value] -
        politicalMapping.value[g1][0][metric.value]
    );
});

const orderedNonEmptyMs = computed(() => {
  return Object.keys(societalMapping.value)
    .filter((mS) => societalMapping.value[mS].length > 0)
    .sort(
      (mS1, mS2) =>
        societalMapping.value[mS2][0][metric.value] -
        societalMapping.value[mS1][0][metric.value]
    );
});

function getColorForGroup(groupAcronym) {
  return politicalMapping.value[groupAcronym]?.[0]?.group.color || "#000";
}

function goToPrevRapporteur() {
  currentRapporteurIdx.value -= 1;
  if (currentRapporteurIdx.value === -1) {
    currentRapporteurIdx.value =
      store.legiWorkZoom.current_rapporteurs.length - 1;
  }
}

function goToNextRapporteur() {
  currentRapporteurIdx.value += 1;
  currentRapporteurIdx.value %= store.legiWorkZoom.current_rapporteurs.length;
}

function setActiveMapView(newActiveMapView) {
  activeMapView.value = newActiveMapView;

  if (activeMapView.value === "stakeholder") {
    metric.value = "number_of_tweets";
  }
}

const pieChartData = computed(() => {
  const amPerGroup =
    groupsInstitution.value === "AN"
      ? store.zoomGlobalInfo.groups_an_amendment_count
      : store.zoomGlobalInfo.groups_senat_amendment_count;

  const sortedGroups = Object.keys(amPerGroup).sort(
    (group1, group2) => amPerGroup[group2] - amPerGroup[group1]
  );

  return {
    labels: sortedGroups,
    datasets: [
      {
        backgroundColor: sortedGroups.map(getColorForGroup),
        data: sortedGroups.map((group) => amPerGroup[group]),
      },
    ],
  };
});

const pieChartOptions = {
  plugins: {
    legend: {
      position: "right",
      labels: { usePointStyle: true, pointStyle: "circle" },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

const bubbleChartData = computed(() => {
  const amPerGroup =
    groupsInstitution.value === "AN"
      ? store.zoomGlobalInfo.groups_an_amendment_count
      : store.zoomGlobalInfo.groups_senat_amendment_count;

  const tweetPerGroup =
    groupsInstitution.value === "AN"
      ? store.zoomGlobalInfo.groups_an_tweet_count
      : store.zoomGlobalInfo.groups_senat_tweet_count;

  // Create list of groups by merging groups with amendment and tweet
  const allGroups = Array.from(
    new Set([...Object.keys(amPerGroup), ...Object.keys(tweetPerGroup)])
  );

  return {
    datasets: allGroups.map((groupAcronym) => ({
      label: groupAcronym,
      backgroundColor: getColorForGroup(groupAcronym),
      data: [
        {
          x: amPerGroup[groupAcronym] || 0,
          y: tweetPerGroup[groupAcronym] || 0,
          r: 10,
        },
      ],
    })),
  };
});

const bubbleChartOptions = {
  scales: {
    x: { title: { text: "Nombre d'amendements", display: true } },
    y: { title: { text: "Nombre de tweets", display: true } },
  },
  plugins: {
    legend: {
      labels: { usePointStyle: true, pointStyle: "circle" },
    },
  },
};

store.loadGlobalInfo();
</script>

<style scoped lang="scss">
.card {
  color: var(--clr-s8);
}
.clickable-tweets-zone {
  cursor: pointer;
  color: var(--clr-s8);
  &:hover {
    color: var(--clr-s6);
  }
  &:active {
    color: var(--clr-s7);
  }
}
.first-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  gap: 10px;
}

:deep(.header-title) {
  font-weight: 700;
  padding-bottom: 5px;
}

.top-occurrences {
  height: 175px;
  overflow-y: auto;

  .header-title {
    border-bottom: 2px solid var(--clr-s2);
    align-self: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  li {
    display: flex;
    justify-content: space-between;
  }
}

.big-number {
  font-size: 32px;
}

.rapporteurs-carousel {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-tweets {
  display: flex;
  justify-content: space-between;
}

.mapping-header {
  margin: 10px;
  display: flex;
  align-items: center;

  .spacer {
    flex-grow: 1;
  }
}

.instit-picker-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.columns-wrapper {
  display: flex;
  gap: 5px;

  width: 100%;
  height: 350px;
  overflow-x: auto;
}

.graph-rows {
  margin-top: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}
</style>
