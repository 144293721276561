<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          filter="institutional_groups"
          :items="store.searchedInstituGroups"
          item-label="name"
          item-value="_id"
          :active-filters="selectedFilters.institutional_groups"
          :is-loading="store.isInstitutionalGroupsFilterLoading"
          mode="purple"
          @search="searchInstituGroups"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetInstituGroupsFilter"
        >
          Organes
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>

      <FollawedRadioButtons
        all-match-route="/organes"
        follawed-route="/organes/follawed"
      />
    </div>

    <div class="params-row">
      <TotalMatched :item-count="store.groupsCount" />
      <SelectItemsLimit
        :items-limit="selectedFilters.size"
        @change-limit="changeLimit"
      >
        Organes
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";

const store = useInstituGroupsStore();
const { selectedFilters } = storeToRefs(store);

// All filters in wanted display order with their labels
const filtersNames = {
  institution: "Institution",
  group_type: "Type d'organe",
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  return arr;
});

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchGroupsList();
};
const showScrollTop = computed(() => selectedFilters.value.size > 20);

const resetAllFilters = () => {
  store.resetFilters();
  fetchGroupsList();
};

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchGroupsList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] || [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchGroupsList();
};

function searchInstituGroups(searchText) {
  if (searchText.length > 0) {
    store.getInstitutionalGroupsFilters(searchText);
  } else {
    store.authors = [];
  }
}

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchGroupsList();
};

const resetInstituGroupsFilter = () => {
  store.searchedInstituGroups = [];
  delete selectedFilters.value.institutional_groups;
  fetchGroupsList();
};

const fetchGroupsList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchInstituGroupsList();
};
</script>
