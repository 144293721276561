<template>
  <div class="template">
    <StakeholdersZoomParams />
    <LoaderFullPage v-if="storeStakeholder.isAuthorLoading" />
    <div v-else>
      <StakeholdersZoomDatas />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import StakeholdersZoomParams from "@/components/social-media/stakeholders/organisms/StakeholdersZoomParams.vue";
import StakeholdersZoomDatas from "@/components/social-media/stakeholders/organisms/StakeholdersZoomDatas.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import { useUserSettingsStore } from "@/stores/user-settings";

const route = useRoute();
const storeStakeholder = useStakeholdersStore();
const userSettingsStore = useUserSettingsStore();

const preventGetAuthorZoom =
  storeStakeholder.authorZoom &&
  storeStakeholder.authorZoom._id === route.params._id;

if (!preventGetAuthorZoom && userSettingsStore.hasPermission("SOCIETAL")) {
  storeStakeholder.getAuthorZoom(route.params._id);
}
</script>
