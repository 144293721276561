import { useMutationObserver } from "@vueuse/core";

export const waitForElement = (selector) => {
  return new Promise((resolve) => {
    const observerOptions = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    const { stop } = useMutationObserver(
      document.body,
      (mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "childList" || mutation.type === "attributes") {
            if (document.querySelector(selector)) {
              stop();
              resolve(document.querySelector(selector));
            }
          }
        });
      },
      observerOptions
    );
  });
};
