<template>
  <iframe
    src="https://eliasse.assemblee-nationale.fr/eliasse/index.html"
    frameborder="0"
  ></iframe>
</template>

<style scoped>
iframe {
  height: calc(100vh - 142px);
}
</style>
