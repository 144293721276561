<template>
  <div class="picture" :style="{ width: size + 'px', height: size + 'px' }">
    <img
      :src="!pictureUrl || isPictureBroken ? defaultProfilePicture : pictureUrl"
      alt="Profile picture"
      @error="isPictureBroken = true"
    />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import defaultProfilePicture from "@/assets/img/user.jpeg";

const props = defineProps({
  pictureUrl: {
    type: [String, null],
    required: true,
  },
  size: {
    type: Number,
    default: 50,
  },
});
const isPictureBroken = ref(false);

const sizeInPx = computed(() => `${props.size}px`);
</script>

<style lang="scss" scoped>
.picture {
  box-shadow: 0px 3px 10px #00000014;
  border: 2px solid white;
  border-radius: 50%;
  min-width: v-bind("sizeInPx");
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
