<template>
  <div class="template">
    <AmendmentsZoomParams />
    <LoaderFullPage v-if="store.loading" />
    <AmendmentsZoomDatas v-else />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import AmendmentsZoomParams from "@/components/amendments/organisms/AmendmentsZoomParams.vue";
import AmendmentsZoomDatas from "@/components/amendments/organisms/AmendmentsZoomDatas.vue";
import { useAmendmentsStore } from "@/stores/amendments.js";

const store = useAmendmentsStore();
const route = useRoute();

store.fetchOneAmendment(route.params.id);
</script>
