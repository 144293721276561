<template>
  <div class="card">
    <FloatingBrowseOccurrences v-if="hasOccurrences" />
    <section class="head">
      <GenericTag>
        {{ jorf.nature }}
      </GenericTag>
      <SourceLink
        :href="`https://www.legifrance.gouv.fr/jorf/id/${props.jorf.jorftext_id}`"
        >{{ props.jorf.jorf_title || "JORF" }}</SourceLink
      >
      <FollawButton
        :element-id="props.jorf._id"
        :feature="FOLLAWED_FEATURES.JORFS"
      />
    </section>
    <section class="body">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="title" v-html="props.jorf.title" />
      <p class="issuer">{{ props.jorf.issuer }}</p>

      <!-- Use both spelling (correct then incorrect) to support the typo in the API -->
      <!-- (The second one can be removed once the API is fixed) -->
      <OccurrencesCollapse
        v-if="hasOccurrences"
        :occurrences="jorf.occurrences || jorf.occurences"
        is-secondary-color
      />

      <RecursiveTextContent
        :content="jorf.content"
        :only-show-highlight="false"
        :levels="JORF_LEVELS"
      />
    </section>
  </div>
</template>

<script setup>
import { computed } from "vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import OccurrencesCollapse from "@/components/reusable/atoms/OccurrencesCollapse.vue";
import RecursiveTextContent from "@/components/reusable/molecules/RecursiveTextContent.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import { JORF_LEVELS } from "@/utils/recursive-texts";
import FloatingBrowseOccurrences from "@/components/reusable/atoms/FloatingBrowseOccurrences.vue";

const props = defineProps({
  jorf: {
    type: Object,
    required: true,
  },
});

const hasOccurrences = computed(
  () => Object.keys(props.jorf.occurrences || props.jorf.occurences).length > 0
);
</script>

<style scoped lang="scss">
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.4px solid var(--clr-s2);
  padding-bottom: 12px;
}

.title {
  margin: 4px 0;
}

.issuer {
  margin-bottom: 12px;
  color: var(--clr-s7);
}
</style>
