<template>
  <div class="card">
    <h4>Convocation</h4>
    <p>
      Une commission mixte paritaire a été convoquée le
      {{ getDateShortFormat(data.date_depot) }}
    </p>
  </div>

  <div v-if="rapporteursList?.length > 0" class="card">
    <h4>Rapporteurs</h4>
    <div class="rapporteurs-row">
      <PersonSmallCard v-for="r in rapporteursList" :key="r._id" :author="r" />
    </div>
  </div>

  <div v-if="data.travaux_commission?.rapport?.length > 0" class="card">
    <h4>Rapports</h4>
    <div class="rapports-row">
      <RouterLink
        v-for="r in data.travaux_commission.rapport"
        :key="r.texte_associe"
        class="card"
        :to="`/reports/${r.texte_associe}`"
      >
        <div class="rapport-body">
          <InstitutionBadge :institution="r.institution" />
          <div class="title">Rapport du {{ getDateShortFormat(r.date) }}</div>
        </div>
      </RouterLink>
    </div>
  </div>

  <div v-if="data.cmp_decision.decision" class="card">
    <h4>Décision</h4>
    <p>
      La commission mixte paritaire a été
      <span class="decision">
        <template v-if="isCmpConclusive">CONCLUSIVE</template>
        <template v-else>NON CONCLUSIVE</template>
      </span>
      en date du
      {{ getDateShortFormat(data.cmp_decision.date) }}
    </p>
  </div>
</template>

<script setup>
import InstitutionBadge from "@/components/legi-work/atoms/InstitutionBadge.vue";
import PersonSmallCard from "@/components/legi-work/atoms/PersonSmallCard.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import { getDateShortFormat } from "@/utils/formatDate";
import { computed } from "vue";
import { useRoute } from "vue-router";

const store = useLegiWorkStore();
const route = useRoute();
const { stepIdx } = route.params;

const data = store.legiWorkZoom.timeline[stepIdx];

const rapporteursList = computed(() =>
  data.rapporteurs.map((r) => store.getPersonalityData(r.rapporteur))
);

const isCmpConclusive = computed(() =>
  data.cmp_decision.decision.match(/^accord$/i)
);
</script>

<style scoped lang="scss">
.rapports-row,
.rapporteurs-row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.rapport-header {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--clr-s2);
}
.rapport-body {
  display: flex;
  align-items: center;
  gap: 10px;

  .title {
    font-weight: 700;
  }
}

.decision {
  font-weight: 600;
  text-transform: uppercase;
}
</style>
