<template>
  <div class="breadcrumbs">
    <template v-for="(crumb, index) in crumbsWithTag" :key="index">
      <component :is="crumb.tag" :to="crumb.to ?? null">
        {{ crumb.text }}
      </component>
      <i v-if="index < crumbs.length - 1" class="fa-solid fa-chevron-left"></i>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  crumbs: {
    type: Array,
    required: true,
  },
});

const crumbsWithTag = computed(() => {
  return props.crumbs.map((crumb, index, crumbs) => {
    if (crumb.to) {
      crumb.tag = "RouterLink";
    } else if (index === crumbs.length - 1) {
      crumb.tag = "b";
    } else {
      crumb.tag = "span";
    }
    return crumb;
  });
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  gap: 8px;
}
</style>
