<template>
  <section class="body">
    <!-- Questions -->
    <div class="dates title-small-regular">
      <span>
        {{
          question.closed_at
            ? `Répondue le : ${dayjs(question.closed_at).format("DD/MM/YYYY")}`
            : "Absence de réponse"
        }}
      </span>
      <span>{{ question.legislature }}e législature</span>
    </div>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="title-medium-semibold" v-html="question.title" />

    <StatusTag :status="question.status" />

    <!-- Questions Author and Description -->
    <div class="author-infos-wrapper">
      <div class="author">
        <CardProfilePicture :picture-url="question.author.photo" :size="48" />
        <div class="author-infos">
          <span class="name">{{ question.author.name }}</span>
          <GroupTag
            v-if="question.author.group"
            :group-color="question.author.group.color"
          >
            {{ question.author.group.acronym }}
          </GroupTag>
        </div>
      </div>
      <ul class="infos title-medium-regular">
        <li>{{ capitalizeFirstLetter(question.type_question) }}</li>
        <li>n° : {{ question.number }}</li>
        <li>
          <i class="fas fa-map-marker-alt"></i
          ><span> {{ question.institution }}</span>
        </li>
      </ul>
    </div>

    <div class="details">
      <!-- Ministry-->
      <div>
        <span class="emphasis title-medium-regular"
          >Ministère attributaire : </span
        >{{ question.ministry_responsible }}
      </div>
      <!-- Matched words -->
      <div v-if="question.matched_words && question.matched_words.length > 0">
        <span class="emphasis title-medium-regular"
          >Mot{{ question.matched_words.length > 1 ? "s" : "" }} matché{{
            question.matched_words.length > 1 ? "s" : ""
          }}
          :
        </span>
        {{ listOfWords }}
      </div>
      <!-- Answer limit -->
      <div>
        <span class="emphasis title-medium-regular"
          >Date limite pour répondre :
        </span>
        <!-- Set the created date + 2 Months as expected response -->
        {{ dayjs(question.created_at).add(2, "month").format("DD/MM/YYYY") }}
      </div>
    </div>
  </section>

  <section class="footer">
    <div class="themes-group">
      <ThemeTag
        v-for="(theme, index) in question.themes"
        :key="index"
        :theme="theme"
      />
    </div>

    <SourceLink :href="question.source">Source</SourceLink>
  </section>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import StatusTag from "@/components/reusable/atoms/StatusTag.vue";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
});

const listOfWords = computed(() =>
  props.question.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style scoped lang="scss">
.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dates {
    display: flex;
    justify-content: space-between;
  }

  .author-infos-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    .author {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-right: 1px solid var(--clr-s3);

      .author-infos {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name {
          text-transform: capitalize;
        }
      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 0;
      padding: 0;
      color: var(--clr-s7);
      list-style-type: none;

      i {
        margin-right: 8px;
      }
    }
  }

  .matched-words {
    font-weight: 300;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .emphasis {
    color: var(--clr-s7);
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .themes-group {
    display: flex;
    gap: 4px;
  }
}
</style>
