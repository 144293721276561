<template>
  <div class="englobe-datas">
    <NewsZoomCard
      v-if="!newsStore.loading && newsStore.newsZoom"
      :key="newsStore.newsZoom._id"
      :zoom="newsStore.newsZoom"
    />
  </div>
</template>

<script setup>
import { useNewsStore } from "@/stores/news";
import NewsZoomCard from "@/components/news/atoms/NewsZoomCard.vue";

const newsStore = useNewsStore();
</script>
