<template>
  <ul v-if="Object.keys(content).length > 0" class="card toc">
    <TableOfContentsSection
      v-for="[key, item] in Object.entries(content)"
      :key="key"
      :item="item"
      :occurrences="occurrences"
      :is-collapsed="isCollapsed[item.title] || false"
      @toggle="toggleCollapse"
    />
  </ul>
</template>

<script setup>
import { ref } from "vue";
import TableOfContentsSection from "@/components/legi-work/molecules/TableOfContentsSection.vue";

const isCollapsed = ref({});

defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  occurrences: { type: Object, required: true },
});

function toggleCollapse(itemTitle) {
  isCollapsed.value[itemTitle] = !isCollapsed.value[itemTitle];
}
</script>

<style lang="scss" scoped>
.toc {
  min-width: 300px;
  padding: 16px;
  margin: 0;
  overflow-y: auto;
  color: var(--clr-s8);
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
