<template>
  <div class="sort-wrapper" tabindex="0" @focusout="isMenuOpen = false">
    Trier par :
    <label><slot></slot></label>
    <div class="select-box" @click="isMenuOpen = !isMenuOpen">
      <span class="selected">{{ props.selectedSortItem }}</span>

      <span class="select-arrow">
        <!-- Open -->
        <i v-if="isMenuOpen" class="fa-solid fa-chevron-up"></i>
        <!-- Closed -->
        <i v-else class="fa-solid fa-chevron-down"></i>
      </span>
      <div v-if="isMenuOpen" class="menu">
        <div
          v-for="item in props.items"
          :key="item"
          class="item"
          :class="{ active: item == props.selectedSortItem }"
          @click="$emit('changeSort', item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  selectedSortItem: {
    type: String,
    required: true,
    default: "Sélectionner",
  },
  items: {
    type: Object,
    required: true,
  },
});
const isMenuOpen = ref(false);

defineEmits(["changeSort"]);
</script>

<style lang="scss" scoped>
.sort-wrapper {
  display: flex;
  width: fit-content;
  font-size: 0.938em;
  align-items: center;
  user-select: none;

  .select-box {
    position: relative;
    vertical-align: bottom;
    display: inline-block;
    margin-left: 7px;
    box-shadow: var(--shd-card);
    border-radius: 17px;
    width: fit-content;
    padding: 4px 32px 4px 14px;
    background: white;
    cursor: pointer;
    z-index: 5;

    .select-arrow {
      position: absolute;
      top: 4px;
      right: 9px;

      pointer-events: none;

      .fa-chevron-down,
      .fa-chevron-up {
        color: var(--clr-p7);
      }
    }

    .menu {
      position: absolute;
      top: 22px;
      right: 0;
      box-shadow: 0 1px 6px #0000005d;
      border-radius: 4px;
      width: auto;
      min-width: 100%;
      padding: 4px 0;
      overflow: hidden;
      background: white;

      .item {
        padding: 4px 4px 4px 8px;
        cursor: pointer;

        &:hover {
          background-color: var(--clr-n3);
        }

        &.active {
          background-color: var(--clr-p2);
        }
      }
    }
  }
}
</style>
