<template>
  <div class="card wrapper">
    <div v-if="props.isLoading" class="spinner">
      <hollow-dots-spinner
        :animation-duration="1000"
        color="var(--clr-service-twitter)"
      />
    </div>
    <div v-else-if="props.countNumber" class="one-metric">
      <div class="description">
        {{ props.description }}
      </div>
      <div class="value">{{ formatNumbers(props.countNumber) }}</div>
    </div>
    <p v-else>Donnée(s) absente(s)</p>
  </div>
</template>

<script setup>
import { HollowDotsSpinner } from "epic-spinners";
import formatNumbers from "@/utils/formatNumbers";
const props = defineProps({
  isLoading: { type: Boolean, required: true },
  description: { type: String, required: true },
  countNumber: { type: Number, default: 0 },
});
</script>

<style scoped lang="scss">
.wrapper {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  .spinner {
    margin: 0 auto;
  }
}

.one-metric {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.0625em;
  font-weight: 300;
  .description {
    color: var(--clr-s8);
  }
  .value {
    color: var(--clr-s8);
    font-size: 1.8rem;
    font-weight: 400;
  }
}
</style>
