<template>
  <NominationCard
    :nomination="document"
    :target-url="{
      name: 'Nominations',
      query: {
        ...route.query,
        search: `_id:${document._id}`,
      },
    }"
    has-follaw-button
  />
</template>
<script setup>
import NominationCard from "@/components/nominations/atoms/NominationCard.vue";
import { useRoute } from "vue-router";

const route = useRoute();

defineProps({
  document: {
    type: Object,
    required: true,
  },
});
</script>
