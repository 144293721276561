<template>
  <div
    class="pp-wrapper"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <img
      class="img-bubble"
      :src="person.photo"
      :style="{ borderColor: person.group?.color }"
    />

    <Transition name="fade">
      <div v-if="showTooltip" class="tooltip">
        <span class="person-name">{{ person.name }}</span>
        <BaseLink
          v-if="person.twitter_handle"
          class="person-handle"
          :href="`https://twitter.com/${person.twitter_handle}`"
        >
          @{{ person.twitter_handle }}
        </BaseLink>
        <GroupTag v-if="person.group" :background-color="person.group.color">
          {{ person.group.acronym }}
        </GroupTag>
        <PrmLink v-if="person.is_prm" :to="`/prm-national/${person._id}`" />
        <AuthorLink :author="person" />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import PrmLink from "@/components/social-media/atoms/PrmLink.vue";
import AuthorLink from "@/components/social-media/atoms/AuthorLink.vue";

defineProps({
  person: {
    type: Object,
    required: true,
  },
});

const showTooltip = ref(false);
</script>

<style scoped lang="scss">
@import "@/assets/sass/helpers/variables";

.pp-wrapper {
  position: relative;

  .tooltip {
    position: absolute;
    z-index: 2;
    left: -15px;

    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;

    border-radius: 4px;
    padding: 8px;
    background-color: white;
    box-shadow: var(--shd-card);

    &::after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      bottom: 100%;
      left: 35%;
      border-color: transparent transparent white transparent;
    }

    .person-name {
      white-space: nowrap;
      font-weight: 600;

      text-transform: capitalize;
    }

    .person-handle {
      color: var(--clr-link-default);
    }

    .prm-link {
      color: var(--clr-link-default);

      i {
        font-size: 1.125em;
      }
    }
  }
}

.img-bubble {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid transparent; // color is overriden by component when there is a group color

  box-shadow: 0px 3px 10px 0px #00000014;
}
</style>
