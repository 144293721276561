<template>
  <CheckboxRadioGroup
    v-model="model"
    :options="notificationTypes"
    @update:model-value="emit('valueChanged')"
  />
</template>
<script setup>
import { computed } from "vue";
import CheckboxRadioGroup from "@/components/reusable/molecules/CheckboxRadioGroup.vue";
import { NO_NOTIFICATION } from "@/stores/alert-settings";

const props = defineProps({
  withNoNotificationOption: {
    type: Boolean,
    default: false,
  },
  telegramDisabled: {
    type: Boolean,
    default: false,
  },
  slackDisabled: {
    type: Boolean,
    default: false,
  },
  teamsDisabled: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel({ type: String, required: true });

const emit = defineEmits(["valueChanged"]);

const notificationTypes = computed(() => [
  { value: "email", text: "E-mail", disabled: false },
  { value: "telegram", text: "Télégram", disabled: props.telegramDisabled },
  { value: "slack", text: "Slack", disabled: props.slackDisabled },
  { value: "msteams", text: "Teams", disabled: props.teamsDisabled },
  ...(props.withNoNotificationOption
    ? [
        {
          value: NO_NOTIFICATION,
          text: "Ne pas recevoir de follaw ups",
          disabled: false,
        },
      ]
    : []),
]);
</script>
