<template>
  <ScrollTop />
  <div class="card">
    <div class="header">
      <span class="date">
        {{ getDateShortFormat(props.zoom.created_at) }}
      </span>

      <SourceLink :href="props.zoom.source">Source</SourceLink>
    </div>

    <section class="body">
      <!-- eslint-disable vue/no-v-html-->
      <div class="title">
        <img src="@/assets/img/legislation/gouv_fr.png" alt />
        <div>
          <h3 v-html="props.zoom.title"></h3>
          <div class="author">{{ props.zoom.author }}</div>
        </div>
      </div>

      <div
        v-if="props.zoom.description"
        class="description typo-body-regular"
        v-html="props.zoom.description"
      ></div>

      <div class="content typo-body-regular" v-html="props.zoom.content" />
      <!-- eslint-enable vue/no-v-html-->
    </section>
  </div>
</template>

<script setup>
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const props = defineProps({
  zoom: {
    type: Object,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
.header {
  .date {
    color: var(--clr-n8);
    font-weight: 300;
  }
}

.body {
  .title {
    display: flex;
    margin: 15px 0 -3px 0;

    h3 {
      margin: 0;
      color: var(--clr-s7);
      font-size: 1.188em;
    }

    img {
      margin-right: 10px;
      box-shadow: var(--shd-card);
      border-radius: 100%;
      width: 44px;
      min-width: 44px;
      height: 44px;
    }
  }

  .description {
    margin-top: 18px;
    border: 1px solid var(--clr-s2);
    border-radius: 4px;
    padding: 8px 16px;
    background-color: var(--clr-s1);
    color: var(--clr-s8);

    :deep(p) {
      margin: 0;
      padding: 0;
    }
  }

  .content {
    margin-bottom: 10px;
    color: var(--clr-n8);

    :deep(img) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      max-width: 100%;
    }

    :deep(.fr-callout) {
      background-color: white;
      box-shadow: inset 0.25rem 0 0 0 #6a6af4;
      margin: 0 0 1.5rem;
      padding: 1.5rem;
      position: relative;
    }

    // Justice.gouv specific
    :deep(.article_blck) {
      .center {
        /* Text */
        text-align: center;
        color: var(--clr-n7);
      }

      p {
        margin: 1em 0;
      }
    }
    // End of Justice.gouv
    :deep(figure) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 50%;
      color: var(--clr-n7);

      &:has(blockquote) {
        box-shadow: inset 0.25rem 0 0 0 #eee;
        padding-left: 15px;

        figcaption {
          color: var(--clr-s8);
        }
      }

      blockquote p {
        font-weight: 600;
        font-size: 1.5em;
      }

      ul {
        padding: 0;
        list-style-type: none;
      }
    }

    :deep(figcaption) {
      color: var(--clr-s8);
    }

    /* Remove OG summary */
    :deep(.field--name-field-chapo) {
      display: none;
    }
    :deep(.summary) {
      display: none;
    }
    :deep(.fr-text--lead) {
      display: none;
    }
    :deep(.contents-border-top) {
      display: none;
    }
    /* End of summary removal */
  }
}
</style>
