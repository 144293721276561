<template>
  <div class="card">
    <h3>Informations de contact</h3>
    <template v-if="anyContact">
      <div v-if="store.prmZoom.emails?.length" class="info">
        <span class="label"
          >Email<template v-if="store.prmZoom.emails.length > 1">s</template
          >&nbsp;:</span
        >
        <a
          v-for="(email, index) in store.prmZoom.emails"
          :key="index"
          :href="`mailto:${email}`"
          >{{ email }}</a
        >
      </div>

      <div v-if="store.prmZoom.phone" class="info">
        <span class="label">Téléphone&nbsp;:</span>
        {{ store.prmZoom.phone }}
      </div>

      <div v-if="store.prmZoom.websites?.length" class="info">
        <span class="label"
          >Site<template v-if="store.prmZoom.websites.length > 1">s</template>
          personnel<template v-if="store.prmZoom.websites.length > 1"
            >s</template
          >&nbsp;:</span
        >
        <a
          v-for="(site, index) in store.prmZoom.websites.filter(
            (w) => !w.includes('twitter.com') && !w.includes('facebook.com')
          )"
          :key="index"
          :href="site"
          :title="site"
          target="_blank"
          rel="noopener noreferrer"
          >{{ simplifyUrl(site) }}</a
        >
      </div>

      <div v-if="store.prmZoom.addresse" class="info">
        <span class="label">Adresse&nbsp;:</span>
        {{ store.prmZoom.addresse }}
      </div>

      <div
        v-if="
          store.prmZoom.twitter_link ||
          store.prmZoom.facebook ||
          store.prmZoom.linkedin
        "
        class="info socials"
      >
        <span class="label">Réseaux sociaux&nbsp;:</span>
        <div class="socials">
          <a
            v-if="store.prmZoom.twitter_link"
            :href="store.prmZoom.twitter_link"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fa-brands fa-xl fa-square-x-twitter"></i
          ></a>
          <a
            v-if="store.prmZoom.facebook"
            :href="`https://facebook.com/${store.prmZoom.facebook}`"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fa-brands fa-xl fa-square-facebook"></i
          ></a>
          <a
            v-if="store.prmZoom.linkedin"
            :href="store.prmZoom.linkedin"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fa-brands fa-xl fa-linkedin"></i
          ></a>
        </div>
      </div>

      <div v-if="store.prmZoom.collaborateurs" class="info">
        <span class="label"
          >Collaborateur<template v-if="store.prmZoom.collaborateurs.length > 1"
            >s</template
          >&nbsp;:</span
        >
        <ul>
          <li
            v-for="(collaborateur, index) in store.prmZoom.collaborateurs"
            :key="index"
          >
            {{ collaborateur }}
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <p>Aucune information de contact</p>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { usePrmStore } from "@/stores/prm";

const store = usePrmStore();

const anyContact = computed(() => {
  return (
    store.prmZoom.phone?.length ||
    store.prmZoom.emails?.length ||
    store.prmZoom.websites?.length ||
    store.prmZoom.addresse ||
    store.prmZoom.twitter ||
    store.prmZoom.facebook ||
    store.prmZoom.linkedin ||
    store.prmZoom.collaborateurs?.length
  );
});

// Removes everything before and including the first dot and everything after the first slash in an url
// eg: "https://twitter.com/?lang=fr" becomes "www.twitter.com"
const simplifyUrl = (url) => {
  if (!url.startsWith("http")) {
    url = "https://" + url;
  }

  try {
    return new URL(url).hostname;
  } catch (error) {
    if (error instanceof TypeError) {
      return url;
    }
    throw error;
  }
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 20px;
  height: fit-content;
  line-height: 21.82px;

  h3 {
    margin: 0 0 5px 0;
    font-size: 1em;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.938em;

  .label {
    font-weight: 600;
  }

  &.socials {
    gap: 10px;
    .socials {
      display: flex;
      gap: 5px;
    }
  }

  ul {
    margin: 0;
    padding-left: 25px;
  }
}
</style>
