<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>
    </div>

    <div class="params-row">
      <TotalMatched :item-count="store.reportsCount" />

      <SelectItemsLimit
        :items-limit="selectedFilters.size"
        @change-limit="changeLimit"
      >
        Documents
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useLegiReportStore } from "@/stores/legi-reports";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const store = useLegiReportStore();
const { selectedFilters } = storeToRefs(store);

// All filters in wanted display order with their labels
const filtersNames = {
  institution: "Institution",
  type_: "Type",
  classification: "Classification",
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  return arr;
});

const showScrollTop = computed(() => selectedFilters.value.size > 20);

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchReportsList();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchReportsList();
};

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchReportsList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] || [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchReportsList();
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchReportsList();
};

const fetchReportsList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchLegiReportsList();
};
</script>
