<template>
  <div class="card">
    <div class="card-header">
      <div class="links">
        <BaseLink v-if="legiWork.url_an" :href="legiWork.url_an">
          <InstitutionBadge institution="AN" />
        </BaseLink>
        <BaseLink v-if="legiWork.url_sn" :href="legiWork.url_sn">
          <InstitutionBadge institution="SN" />
        </BaseLink>
        <BaseLink v-if="publishedLawUrl" :href="publishedLawUrl">
          <InstitutionBadge institution="Légifrance" />
        </BaseLink>
      </div>
      <div class="title-and-nature">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="title" v-html="legiWork.title"></div>
        <div class="nature">{{ legiWork.nature }} ({{ legiWork.ref }})</div>
      </div>
      <div class="header-actions">
        <FollawButton
          :element-id="legiWork._id"
          :feature="FOLLAWED_FEATURES.LEGIDOCS"
        />
      </div>
    </div>
    <div class="card-content">
      <div>Auteur<span v-if="legiWork.authors.length > 1">s</span> :</div>
      <div class="authors">
        <div v-for="a in truncatedAuthors" :key="a._id" class="author">
          <span class="author-name">{{ a.name }}</span>
          <GroupTag v-if="a.group" :background-color="a.group.color">{{
            a.group.acronym
          }}</GroupTag>
        </div>
        <button
          v-if="legiWork.authors.length > maxShown"
          class="see-more"
          @click="isAllShown = !isAllShown"
        >
          ...{{ isAllShown ? "moins" : "plus" }}
        </button>
      </div>
      <div v-if="legiWork.themes?.length > 0" class="themes-row">
        <ThemeTag
          v-for="theme in legiWork.themes"
          :key="theme"
          :theme="theme"
        />
      </div>
      <StepTimeline
        v-if="showTimeline"
        :legi-work-ref="legiWork.ref"
        :timeline="legiWork.timeline"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";
import StepTimeline from "../atoms/StepTimeline.vue";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import InstitutionBadge from "../atoms/InstitutionBadge.vue";

const props = defineProps({
  legiWork: { type: Object, required: true },
  showTimeline: { type: Boolean, default: false },
});

const maxShown = 12;
const isAllShown = ref(false);
const truncatedAuthors = computed(() => {
  if (props.legiWork.authors.length <= maxShown || isAllShown.value) {
    return props.legiWork.authors;
  }
  return props.legiWork.authors.slice(0, maxShown);
});

const publishedLawUrl = computed(
  () =>
    // Find the Legifrance URL deeply nested in the timeline object
    props.legiWork.timeline.find((step) =>
      step.dossier_etape.match(/Promulgation/i)
    )?.promulgation.url_jorf_legifrance
);
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  .card-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    .links {
      display: flex;
      gap: 10px;
    }

    .title-and-nature {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      color: var(--clr-s8);

      .title {
        font-size: 17px;
        font-weight: 700;
      }

      .nature {
        color: var(--clr-s7);
      }
    }

    .header-actions {
      display: flex;
      gap: 10px;
      font-size: 1.1rem;
      color: var(--clr-p5);

      button {
        height: 30px; // Follaw button size
        width: 30px;
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      }

      .alert-btn {
        &:hover,
        &:focus {
          stroke-width: 1px;
          border: 1px solid var(--clr-p5);
          box-shadow: var(--shd-card);
          ::before {
            content: "\f849";
          }
        }
        &:active {
          background-color: var(--clr-p2);
        }

        &.selected {
          &:hover,
          &:focus {
            ::before {
              content: "\e018";
            }
          }
        }
      }
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--clr-s8);
    gap: 15px;

    .authors {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;

      .see-more:hover {
        color: var(--clr-s7);
      }
      .author {
        display: flex;
        gap: 5px;
        font-weight: 300;

        .author-name {
          text-transform: capitalize;
        }
      }
    }

    .themes-row {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
  }
}
</style>
