<template>
  <div class="card">
    <div class="card-header">
      <div class="header-title">Graphique temporel des activités</div>
      <div class="header-tools">
        <RadioButtons
          :options="activitiesDisplayOptions"
          :active-option="store.activitiesActiveOption"
          @select-option="selectDisplay($event)"
        />
      </div>
    </div>
    <div v-if="loading" class="loading-wrapper">
      <HollowDotsSpinner :animation-duration="1000" color="var(--clr-p6)" />
    </div>
    <div
      v-else-if="
        Object.keys(activityDatas.publication_timeline).length > 0 ||
        Object.keys(activityDatas.activities_timeline).length > 0
      "
      class="chart-wrapper"
    >
      <BarChart :options="barChartOptions" :chart-data="barChartData" />
    </div>
    <EmptyChart v-else />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { BarChart } from "vue-chart-3";
import { HollowDotsSpinner } from "epic-spinners";
import dayjs from "dayjs";

import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import EmptyChart from "@/components/reusable/molecules/EmptyChart.vue";
import { ACTIVITIES_CHART_OPTIONS } from "@/stores/prm";
import { usePrmStore } from "@/stores/prm";
import { capitalizeFirstLetter, getCSSVariable } from "@/utils/helper.utils";

const store = usePrmStore();

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  activityDatas: {
    type: Object,
    required: true,
  },
});

// Activities Radio buttons
const activitiesDisplayOptions = [
  {
    label: "Année",
    value: ACTIVITIES_CHART_OPTIONS.YEAR,
  },
  {
    label: "Mois",
    value: ACTIVITIES_CHART_OPTIONS.MONTH,
  },
];

const selectDisplay = (event) => {
  store.activitiesActiveOption = event;
  store.fetchOnePersonnality(store.prmZoom._id);
};

const formatLabels = (dataset) => {
  let formattedDataset = dataset;
  if (store.activitiesActiveOption === ACTIVITIES_CHART_OPTIONS.YEAR) {
    formattedDataset = Object.fromEntries(
      Object.entries(dataset).map(([key, value]) => [dayjs(key).year(), value])
    );
  } else if (store.activitiesActiveOption === ACTIVITIES_CHART_OPTIONS.MONTH) {
    formattedDataset = Object.fromEntries(
      Object.entries(dataset).map(([key, value]) => [
        capitalizeFirstLetter(dayjs(key).format("MMMM YYYY")),
        value,
      ])
    );
  }
  return formattedDataset;
};

// Take the labels from the longer dataset between tweets and activities.
// Imposing the labels like this is necessary to keep the order correct
const chartLabels = computed(() =>
  Object.keys(formatLabels(props.activityDatas.publication_timeline)).length >
  Object.keys(formatLabels(props.activityDatas.activities_timeline))
    ? Object.keys(formatLabels(props.activityDatas.publication_timeline))
    : Object.keys(formatLabels(props.activityDatas.activities_timeline))
);

// Activities Chart
const barChartOptions = {
  responsive: true,
  layout: {
    padding: {
      right: 35,
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: { usePointStyle: true, pointStyle: "circle" },
    },
    datalabels: {
      color: "black",
    },
  },
};

const barChartData = computed(() => ({
  labels: chartLabels.value,
  datasets: [
    {
      label: "Publications",
      data: formatLabels(props.activityDatas.publication_timeline),
      backgroundColor: getCSSVariable("--clr-network"),
    },
    {
      label: "Activités parlementaires",
      data: formatLabels(props.activityDatas.activities_timeline),
      backgroundColor: "#83098B",
    },
  ],
}));
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 15px;

  .card-header {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    .header-title {
      font-weight: 600;
    }
  }
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
