<template>
  <!-- eslint-disable vue/no-v-html -->

  <!-- Articles level N -->
  <div
    v-for="article in content.articles || content.article"
    :key="article.num"
    class="article-wrapper typo-body-regular"
  >
    <CollapseSection v-if="!onlyShowHighlight || hasHighlights(article)">
      <template #title>
        <h5 class="collapse-title">{{ article.title }}</h5>
      </template>
      <template #content>
        <article
          v-if="article.content"
          class="article"
          v-html="processRawHtml(article.content)"
        ></article>

        <template v-if="embedAmendments.length > 0">
          <ul class="embedded-amendments">
            <SmallAmendmentCard
              v-for="a in amendmentsForArticle(article.title)"
              :key="a._id"
              :amendment="a"
            />
          </ul>
        </template>
      </template>
    </CollapseSection>
  </div>

  <!-- Raw content at level N -->
  <template v-if="content.content">
    <article class="article" v-html="processRawHtml(content.content)"></article>
  </template>

  <!-- Articles level N+1 -->
  <template v-for="key in childKeys">
    <div v-for="levelN in content?.[key]" :key="levelN.num">
      <CollapseSection v-if="!onlyShowHighlight || hasHighlights(levelN)">
        <template #title>
          <h5 class="collapse-title">{{ levelN.title }}</h5>
        </template>
        <template #content>
          <div class="nested-collapse">
            <RecursiveTextContent
              :content="levelN"
              :embed-amendments="embedAmendments"
              :only-show-highlight="onlyShowHighlight"
              :levels="levels"
            />
          </div>
        </template>
      </CollapseSection>
    </div>
  </template>
</template>

<script setup>
import { computed } from "vue";
import CollapseSection from "../atoms/CollapseSection.vue";
import SmallAmendmentCard from "@/components/legi-work/atoms/SmallAmendmentCard.vue";

const props = defineProps({
  content: { type: Object, required: true },
  embedAmendments: { type: Array, default: () => [] },
  levels: { type: Object, required: true },
  onlyShowHighlight: { type: Boolean, default: true },
});

const childKeys = computed(() =>
  Object.keys(props.content).filter((k) => props.levels.includes(k))
);

function amendmentsForArticle(articleName) {
  return props.embedAmendments.filter((a) => a.article === articleName);
}

// Helper function for the onlyShowHighlight prop logic
function hasHighlights(content) {
  const ok = JSON.stringify(content).includes("mark class");
  return ok;
}

function processRawHtml(rawHtml) {
  return rawHtml.replace(/width='(\d+)'/g, 'style="width: $1px"');
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/helpers/variables.scss";
.article-wrapper {
  color: var(--clr-n8);
}
.nested-collapse {
  padding-left: 20px;
}

.article {
  container-type: inline-size;
}

.collapse-title {
  text-transform: capitalize;
  color: var(--clr-s8);
}

.embedded-amendments {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}

.article {
  :deep(p:first-child) {
    margin-top: 0;
  }
}

:deep(table) {
  border-collapse: collapse;

  tr:nth-child(odd) {
    background-color: var(--clr-n2);
  }

  th {
    padding: 15px 0;
    & > br {
      display: none;
    }
  }

  td {
    padding: 10px;

    & > br {
      display: none;
    }
  }
}
</style>
