<template>
  <div class="collapse collapse-item" :class="{ 'is-active': active }">
    <div class="collapse-header" @click.prevent="toggle">
      <slot name="title"></slot>
      <transition name="rotate" mode="out-in">
        <i v-if="active" key="up" class="fa-solid fa-chevron-up"></i>
        <i v-else key="down" class="fa-solid fa-chevron-down"></i>
      </transition>
    </div>
    <div v-show="active" class="collapse-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  startClosed: { type: Boolean, default: false },
});

const emit = defineEmits(["open", "close"]);

// eslint-disable-next-line vue/no-setup-props-destructure
const active = ref(!props.startClosed); // OK to lose reactivity because we only use the prop to initialize

const toggle = () => {
  if (active.value) {
    active.value = false;
    emit("close");
  } else {
    active.value = true;
    emit("open");
  }
};
</script>

<style lang="scss" scoped>
.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1bfd9;
  margin: 10px 0 5px 0;
  padding-bottom: 5px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;

  .fa-solid {
    margin-left: 10px;
  }
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease-in-out;
}

.rotate-leave-to {
  transform: rotateZ(180deg);
}
</style>
