<template>
  <div
    class="card social-card"
    :class="{ 'social-card__overview': isOverview }"
  >
    <div class="social-card__header">
      <i class="social-card__header__icon fa-regular fa-chart-network" />

      <div class="social-card__header__content">
        <SocialTimestamp
          label="Posté le : "
          :date="createdAt"
          variant="neutral"
        />
        <SourceLink :href="sourceUrl" />
      </div>

      <FollawButton
        :element-id="id"
        :feature="FOLLAWED_FEATURES.PUBLICATIONS"
      />
    </div>

    <SeparatorLine />

    <div class="social-card__wrapper">
      <div class="social-card__wrapper__content">
        <CardProfilePicture :picture-url="author.photo" />

        <div class="social-card__content">
          <div class="social-card__content__header">
            <div class="social-card__content__header__author">
              <i
                class="fa-brands fa-lg"
                :class="socialIcon.name"
                :style="`color: ${socialIcon.color}`"
              />

              <span
                class="typo-body-regular social-card__content__header__name"
              >
                {{ author.name }}

                <PrmAndAuthorTooltip
                  class="social-card__content__header__name__tooltip"
                  :to-prm="author.is_prm ? `/prm-national/${author._id}` : ''"
                  :author="author"
                />
              </span>

              <span
                v-if="handle.url"
                class="social-card__content__header__handle"
              >
                <BaseLink :href="handle.url">@{{ handle.label }}</BaseLink>
              </span>

              <GroupTag
                v-if="author.group"
                :background-color="author.group.color"
              >
                <span class="title-small-regular">
                  {{ author.group.acronym }}
                </span>
              </GroupTag>
            </div>

            <div class="typo-body-regular">
              <slot name="content" />
            </div>
          </div>

          <div class="social-card__content__footer">
            <div class="social-card__content__footer__metrics">
              <template v-for="metric in metrics">
                <Metric
                  v-if="metric.value"
                  :key="metric.label"
                  :icon="metric.icon"
                  :value="metric.value"
                  :label="metric.label"
                />
              </template>
            </div>

            <div class="social-card__content__footer__timestamps">
              <SocialTimestamp
                v-if="lastUpdate"
                label="Métriques mises à jour le : "
                :date="lastUpdate"
              />
            </div>
          </div>
        </div>
      </div>
      <slot name="media" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import PrmAndAuthorTooltip from "@/components/social-media/atoms/PrmAndAuthorTooltip.vue";
import Metric from "@/components/social-media/atoms/SocialMetric.vue";
import SocialTimestamp from "@/components/social-media/atoms/SocialTimestamp.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import SeparatorLine from "@/components/reusable/atoms/SeparatorLine.vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  isOverview: {
    type: Boolean,
    default: true,
  },
  author: {
    type: Object,
    required: true,
  },
  socialIcon: {
    type: Object,
    required: true,
  },
  handle: {
    type: Object,
    required: false,
    default: null,
  },
  reposts: {
    type: String,
    default: null,
  },
  likes: {
    type: String,
    default: null,
  },
  reactions: {
    type: String,
    default: null,
  },
  comments: {
    type: String,
    default: null,
  },
  views: {
    type: String,
    default: null,
  },
  impressions: {
    type: String,
    default: null,
  },
  createdAt: {
    type: String,
    required: true,
  },
  lastUpdate: {
    type: String,
    required: false,
    default: null,
  },
  sourceUrl: {
    type: String,
    required: true,
  },
});

const metrics = ref([
  { icon: "fa-share", value: props.reposts, label: "Partages" },
  { icon: "fa-heart", value: props.likes, label: "J'aime" },
  { icon: "fa-heart", value: props.reactions, label: "Réactions" },
  { icon: "fa-comment", value: props.comments, label: "Commentaires" },
  { icon: "fa-eye", value: props.views, label: "Vues" },
  { icon: "fa-eye", value: props.impressions, label: "Impressions" },
]);
</script>

<style lang="scss" scoped>
:deep(p) {
  margin: 0;
}

.social-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.social-card__header {
  display: flex;
  justify-content: space-between;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--clr-network);
    font-size: 13px;
    color: var(--clr-n1);

    &::before {
      ::before {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
  }
}

.social-card__overview {
  background-color: var(--clr-n2);
  box-shadow: none;
}

.social-card__wrapper {
  display: flex;
  flex: 1;

  &__content {
    display: flex;
    flex: 1;
    gap: 8px;
  }
}

.social-card__content,
.social-card__content__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.social-card__content__header {
  &__author {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__name {
    margin-left: 4px;
    text-transform: capitalize;
    position: relative;

    &__tooltip {
      opacity: 0;
      transform: translateY(10px);
      transition:
        opacity 0.125s ease-in-out,
        transform 0.125s ease-in-out;
      pointer-events: none;
    }

    &:hover .social-card__content__header__name__tooltip {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }

    &__handle:hover {
      text-decoration: underline;
    }
  }
}

.social-card__content__footer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__metrics {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__timestamps {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &__label {
      color: var(--clr-s7);
    }

    &__date {
      color: var(--clr-n6);
    }
  }
}
</style>
