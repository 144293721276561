// This is the generic logic for all "Browse through occurrences" logic in the app

function moveFocus(delta) {
  // Get all nodes with highlight and that are not excluded (skip-focus class)
  const occurrences = document.querySelectorAll(".highlight:not(.skip-focus)");

  // Get index of currently focused element
  const focusIdx = [...occurrences].findIndex((domNode) =>
    domNode.classList.contains("focused")
  );

  // Choose next focused element
  // If no currently focused element, choose the first one
  let newFocusIdx =
    focusIdx === -1 ? 0 : (focusIdx + delta) % occurrences.length;
  while (newFocusIdx < 0) {
    // Handle looping correctly for "previous" case
    newFocusIdx += occurrences.length;
  }

  // Apply changes to the DOM
  if (focusIdx >= 0) {
    occurrences[focusIdx].classList.remove("focused");
  }
  occurrences[newFocusIdx].classList.add("focused");

  // Scroll to new focused element
  occurrences[newFocusIdx].scrollIntoView({
    behavior: "auto",
    block: "center",
  });
}

export function moveToNextFocus() {
  return moveFocus(1);
}

export function moveToPrevFocus() {
  return moveFocus(-1);
}
