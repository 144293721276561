<template>
  <AlertsParams />
  <LoaderFullPage v-if="store.loading" />
  <AlertsListing />
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import AlertsParams from "@/components/alerting/organisms/AlertsParams.vue";
import AlertsListing from "@/components/alerting/organisms/AlertsListing.vue";
import { useAlertsStore } from "@/stores/alerts.js";

const store = useAlertsStore();
store.readAlerts();
</script>
