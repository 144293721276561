<template>
  <div data-product-tour="step-1" />
  <div class="template">
    <HomepageParams />
    <LoaderFullPage v-if="store.loading" />
    <HomepageDatas v-else />
  </div>
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import HomepageParams from "@/components/homepage/organisms/HomepageParams.vue";
import HomepageDatas from "@/components/homepage/organisms/HomepageDatas.vue";
import { useHomepageStore } from "@/stores/homepage";
import { useRoute } from "vue-router";

const store = useHomepageStore();
const route = useRoute();

if (route.fullPath.includes("follawed")) {
  store.isFollawedActive = true;
} else {
  store.isFollawedActive = false;
}

store.basePayload.from = 0;
store.fetchHomepage();
</script>
