<template>
  <img v-if="qrCodeImageSrc" :src="qrCodeImageSrc" alt="QR Code" />
</template>
<script setup>
import QrCodeTest from "@/assets/img/alerting/qr-code-telegram-test.svg";
import QrCodeProduction from "@/assets/img/alerting/qr-code-telegram-follaw-production.svg";
import { computed } from "vue";

const qrCodeImageSrc = computed(() => {
  if (import.meta.env.VITE_ENV === "production") {
    return QrCodeProduction;
  }
  return QrCodeTest;
});
</script>
<style lang="scss" scoped>
img {
  width: 100px;
  height: 100px;
}
</style>
