import dayjs from "dayjs";
import {
  TIME_INTERVALS,
  TIME_INTERVALS_LATEST,
} from "@/constants/time-interval";

export function getStartDateFromPreset(preset) {
  switch (preset) {
    case TIME_INTERVALS_LATEST.LAST_24H:
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    case TIME_INTERVALS_LATEST.LAST_WEEK:
      return dayjs().subtract(1, "week").format("YYYY-MM-DD");
    case TIME_INTERVALS_LATEST.LAST_MONTH:
      return dayjs().subtract(1, "month").format("YYYY-MM-DD");
    case TIME_INTERVALS_LATEST.LAST_YEAR:
      return dayjs().subtract(1, "year").format("YYYY-MM-DD");
    case TIME_INTERVALS_LATEST.LEGI_15:
      return dayjs("2017-06-27T00:00:00+02:00").format("YYYY-MM-DD");
    case TIME_INTERVALS_LATEST.LEGI_16:
      return dayjs("2022-06-28T00:00:00+02:00").format("YYYY-MM-DD");
    case TIME_INTERVALS_LATEST.LEGI_17:
      return dayjs("2024-07-18T00:00:00+02:00").format("YYYY-MM-DD");
  }

  return dayjs().format("YYYY-MM-DD");
}

export function getEndDateFromPreset(preset) {
  switch (preset) {
    case TIME_INTERVALS_LATEST.LEGI_15:
      return dayjs("2022-06-21T00:00:00+02:00").format("YYYY-MM-DD");
    case TIME_INTERVALS_LATEST.LEGI_16:
      return dayjs("2024-06-10T00:00:00+02:00").format("YYYY-MM-DD");
  }

  return undefined;
}

export const getIsoDateRange = (isoDate, interval) => {
  const dateTime = dayjs(isoDate);

  return {
    start: dateTime.startOf(interval).toISOString(),
    end: dateTime.endOf(interval).toISOString(),
  };
};

export const getFormattedDate = (isoDate, interval) => {
  const formatMap = {
    [TIME_INTERVALS.MONTH]: "MMMM YYYY",
    [TIME_INTERVALS.HOUR]: "HH:mm",
    [TIME_INTERVALS.DAY]: "DD/MM/YYYY",
  };

  const format = formatMap[interval] || formatMap[TIME_INTERVALS.DAY];
  const formattedDate = dayjs(isoDate).format(format);

  return formattedDate;
};
