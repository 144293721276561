import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

/**
 * Fetches author filters from the amendments endpoint.
 * @param {string} name - The name of the author.
 * @returns {Promise<Array>} - A promise that resolves to an array of author filters.
 * @throws {Error} - If there is a network error during the API call.
 */
export const fetchSearchAuthors = async (name) => {
  try {
    const response = await httpRequest("post", "/amendments/search_authors", {
      name,
    });

    return response.data.personalities;
  } catch (err) {
    handleNetworkError(err);
  }
};
