<template>
  <NoData v-if="!lineChartData" />

  <LineChart
    v-else
    :key="lineChartKey"
    :chart-data="lineChartData"
    :options="lineChartOptions"
  />
</template>

<script setup>
import { computed } from "vue";
import { LineChart } from "vue-chart-3";
import { Platforms } from "@/utils/social360-platforms";
import { TIME_INTERVALS } from "@/constants/time-interval";
import { capitalizeFirstLetter, getCSSVariable } from "@/utils/helper.utils";
import {
  getDateShortFormat,
  getMonthYearFormat,
  getDateTimeHourFormat,
} from "@/utils/formatDate.js";
import NoData from "../atoms/NoData.vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platforms: {
    type: Array,
    required: true,
  },
  interval: {
    type: String,
    required: false,
    default: TIME_INTERVALS.DAY,
  },
});

const emit = defineEmits(["updateTopDate"]);

const formatDateForUI = (isoDate) => {
  const formatMap = {
    [TIME_INTERVALS.MONTH]: capitalizeFirstLetter(getMonthYearFormat(isoDate)),
    [TIME_INTERVALS.HOUR]: getDateTimeHourFormat(isoDate),
    [TIME_INTERVALS.DAY]: getDateShortFormat(isoDate),
  };

  return formatMap[props.interval] || formatMap[TIME_INTERVALS.DAY];
};

const timelines = computed(() => {
  return {
    [Platforms.TWITTER]: props.data.x_timeline || {},
    [Platforms.TIKTOK]: props.data.tiktok_timeline || {},
    [Platforms.LINKEDIN]: props.data.linkedin_timeline || {},
    [Platforms.INSTAGRAM]: props.data.instagram_timeline || {},
    [Platforms.BLUESKY]: props.data.bluesky_timeline || {},
  };
});

const lineChartDates = computed(() => {
  // Construct a set of available dates by merging dates from all timelines
  const dateSteps = new Set();
  for (const platform in timelines.value) {
    if (!timelines.value[platform]) continue;
    for (const date in timelines.value[platform]) {
      dateSteps.add(date);
    }
  }

  const isoDates = Array.from(dateSteps).sort();
  const formattedDates = isoDates.map(formatDateForUI);

  return {
    isoDates,
    formattedDates,
  };
});

const lineChartKey = computed(
  () =>
    `${props.platforms.join("-")}-${lineChartDates.value.isoDates.join("-")}`
);

const isSinglePointDataset = (data) => {
  const dataPointIndex = data.findIndex((point) => point > 0);
  const isSinglePoint = data.every(
    (point, index) => point === 0 || index === dataPointIndex
  );
  return isSinglePoint;
};

const getPlatformColor = (platform) => {
  const colors = {
    [Platforms.TWITTER]: getCSSVariable("--clr-twitter"),
    [Platforms.TIKTOK]: getCSSVariable("--clr-tiktok"),
    [Platforms.LINKEDIN]: getCSSVariable("--clr-linkedin"),
    [Platforms.INSTAGRAM]: getCSSVariable("--clr-instagram"),
    [Platforms.BLUESKY]: getCSSVariable("--clr-bluesky"),
  };
  return colors[platform];
};

const createDatasetForPlatform = (platform, timeline) => {
  const data = lineChartDates.value.isoDates.map((d) => timeline[d] || 0);
  const hasData = data.some((item) => item > 0);
  if (!hasData || isSinglePointDataset(data)) return null;

  return {
    label: capitalizeFirstLetter(platform),
    backgroundColor: getPlatformColor(platform),
    borderColor: getPlatformColor(platform),
    data,
  };
};

const lineChartData = computed(() => {
  const datasets = props.platforms
    .map((platform) =>
      createDatasetForPlatform(platform, timelines.value[platform])
    )
    .filter(Boolean);

  if (datasets.length === 0) return null;

  return {
    labels: lineChartDates.value.formattedDates,
    datasets,
  };
});

const lineChartOptions = {
  responsive: true,
  onClick: (_, elements, chart) => {
    if (elements.length) {
      const i = elements[0].index;
      const datasetIndex = elements[0].datasetIndex;

      const selectedIsoDate = lineChartDates.value.isoDates[i];
      const selectedPlatform =
        chart.data.datasets[datasetIndex].label.toLowerCase();
      emit("updateTopDate", {
        date: selectedIsoDate,
        platform: selectedPlatform,
      });
    }
  },
  onHover: (evt, elements) => {
    evt.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
  elements: {
    point: { radius: 0, hoverRadius: 3, hitRadius: 20 },
    line: { tension: 0.1 },
  },
  plugins: {
    legend: {
      align: "start",
      position: "right",
      labels: {
        font: {
          size: 13.33,
          family: "DM Sans",
          style: "normal",
          weight: 400,
          lineHeight: 18.662,
        },
        boxWidth: 12,
        boxHeight: 2,
      },
      onHover: (evt) => {
        evt.native.target.style.cursor = "pointer";
      },
      onLeave: (evt) => {
        evt.native.target.style.cursor = "default";
      },
    },

    tooltip: {
      caretPadding: 7,
      footerMarginTop: 10,
      displayColors: false,
      callbacks: {
        title() {
          return "";
        },
        beforeLabel(context) {
          const index = context.dataIndex;
          return context.chart.data.labels[index];
        },
        label(context) {
          return `Réseau social : ${context.dataset.label}`;
        },
        afterLabel(context) {
          return `Publication : ${context.parsed.y}`;
        },
        footer(context) {
          return context[0].raw > 9
            ? `Cliquer pour voir le top 10`
            : `Cliquer pour voir les publications`;
        },
      },
    },
  },
  scales: {
    x: { grid: { display: false } },
    y: {
      ticks: { beginAtZero: true },
      grid: { color: "#c6c3c9" },
    },
  },
};
</script>
