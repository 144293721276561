<template>
  <section class="body">
    <div class="title">
      <!-- eslint-disable vue/no-v-html -->
      <h3 class="title-medium-semibold" v-html="news.title"></h3>
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <div v-show="showDescription" class="content typo-body-regular">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="news.description"></div>
    </div>
    <div class="author">
      <img src="@/assets/img/legislation/gouv_fr.png" alt />
      <span class="title-medium-semibold">{{ news.author }}</span>
    </div>
    <div v-if="(news.matched_words || []).length > 0" class="content">
      <span class="matched-title title-medium-regular">Mots matchés : </span>
      <span class="matched-words">{{ listOfWords }}</span>
    </div>
  </section>
  <section class="footer">
    <SourceLink :href="news.source">{{ news.domain }}</SourceLink>
  </section>
</template>

<script setup>
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { computed } from "vue";

const props = defineProps({
  news: {
    type: Object,
    required: true,
  },
  isHomepage: {
    type: Boolean,
    default: false,
  },
});

// Condition: description exists and is not too long
const showDescription = computed(() => props.news.description?.length <= 1000);

const listOfWords = computed(() =>
  props.news.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;

  .title {
    display: flex;
  }

  .content {
    :deep(p) {
      padding: 0;
      margin: 0;
    }

    .matched-title {
      color: var(--clr-s7);
    }
  }

  .author {
    display: flex;
    gap: 8px;

    img {
      box-shadow: var(--shd-card);
      border-radius: 100%;
      width: 25px;
      min-width: 25px;
      height: 25px;
    }
  }
}

.footer {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: 8px;
}
</style>
