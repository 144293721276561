<template>
  <div v-if="storeStakeholder.authorZoom" class="englobe-datas">
    <div class="stakeholders-zoom-author">
      <AuthorCardWithAllMedia :author="storeStakeholder.authorZoom" />
    </div>
    <RouterView />
  </div>
  <NoData v-else />
</template>

<script setup>
import AuthorCardWithAllMedia from "@/components/social-media/stakeholders/molecules/AuthorCardWithAllMedia.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import { useUserSettingsStore } from "@/stores/user-settings";

const storeStakeholder = useStakeholdersStore();
const userSettingsStore = useUserSettingsStore();

// Empty mentions array before mentions fetching.
storeStakeholder.zoomMentions = [];
storeStakeholder.zoomMentionsCount = 0;
if (
  userSettingsStore.hasPermission("SOCIETAL") &&
  storeStakeholder.authorZoom.x_information
) {
  storeStakeholder.zoomMentionsFilters.from = 0;
  storeStakeholder.getMentionsOfPublications(
    storeStakeholder.authorZoom.x_information?.username
  );
}
</script>

<style scoped lang="scss">
.englobe-datas {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  padding-top: 10px;
}

@media (max-width: 1360px) {
  .englobe-datas {
    grid-template-columns: 1fr;
  }
}

.stakeholders-zoom-author {
  min-width: 0;
}
</style>
